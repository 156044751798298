import { roles } from "app/_utils/rolesUtils";

import { IImmotoolEntity } from "./ImmotoolEntity";
import { ICompanyBasicInformation } from "./Company";
import { IAddress } from "./Address";
import { IFile } from "./File";
import { FinanceFileTypes } from "./Project";
import { LeadFinancialDocumentLine, LeadFinancialDocumentLineType, UnitQuantity } from "./Lead";

export const UserType = {
  CLIENT: "CLIENT",
  USER: "USER",
  SUBCONTRACTOR: "SUBCONTRACTOR",
  EXTERNAL: "EXTERNAL",
} as const;

export type TUserTypes = keyof typeof UserType;

// roles that will always be present
export const DefaultUserRoleType = {
  ADMIN: "ADMIN",
  MASTER: "MASTER",
  NONE: "NONE",
  CUSTOM: "CUSTOM",
} as const;

export type TUserRoleTypes = keyof typeof roles;

export const UserEntityType = {
  ALL: "*",
  PROJECT: "PROJECT",
  BUDGET: "BUDGET",
  PRODUCT: "PRODUCT",
  USER: "USER",
  CLIENT: "CLIENT",
  SUBCONTRACTOR: "SUBCONTRACTOR",
  LEAD: "LEAD",
  TASK: "TASK",
  CHAT: "CHAT",
  USER_ACTION: "USER_ACTION",
  USER_RIGHTS: "USER_RIGHTS",
  PROPCO: "PROPCO",
  KYC_FLOW: "KYC_FLOW",
} as const;

export type TUserEntityTypeKeys = keyof typeof UserEntityType;
export type TUserEntityTypes = typeof UserEntityType[TUserEntityTypeKeys];

export const UserRightType = {
  ALL: "*",

  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  AUDIT_TRAIL: "AUDIT_TRAIL",
  SHARE: "SHARE",
  ANALYSIS: "ANALYSIS",
  HIDDEN_LINE: "HIDDEN_LINE",
  NOTE: "NOTE",
  UPLOAD_PHOTO: "UPLOAD_PHOTO",
  UPDATE_PHOTO: "UPDATE_PHOTO",
  DELETE_PHOTO: "DELETE_PHOTO",
  CONFIDENTIAL_DOCUMENT: "CONFIDENTIAL_DOCUMENT",
} as const;

export type TUserRightTypeKeys = keyof typeof UserRightType;
export type TUserRightTypes = typeof UserRightType[TUserRightTypeKeys];

export interface IUserRight {
  entityType: TUserEntityTypes;
  rightType: TUserRightTypes;
}

// represents the User entity properties sent to the frontend
// cdk/immootool-layers/src/commonLayer/nodejs/entities/users.ts
export interface IUser extends IImmotoolEntity {
  lastSeenAt: Date;
  legalEntityType: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  profilePictureSetup: boolean;
  profilePictureUrl: string;
  profilePictureUrlExpires: Date;
  profilePictureKey: string;
  isDisabled: boolean;
  address: IAddress;
  notes: string;
  userTypes: TUserTypes[];
  language: string;
  gdprApprovedAt: Date;
  referrer: string;
  company: ICompanyBasicInformation;
  rights: IUserRight[];
  roleId: string;
}

export interface IUserOption extends IUser {
  displayName: string;

  /** to be set to true when the user.userTypes contains the EXTERNAL */
  isExternal: boolean;

  uuid: string;
  customOption?: boolean;
}

export interface IUserFile extends IFile {
  userId: string;
  userType: TUserTypes;
}

export type TSubcontractorFile = ICommonSubcontractorFile & TReInvoiceConditional;

interface ICommonSubcontractorFile extends IUserFile {
  projectId: string;
  categories: ISubcontractorFileCategory[];
  visible: boolean;
  fileType: FinanceFileTypes | string;
  selectedForFinance: boolean;
}

type TReInvoiceConditional =
  | { enableReInvoicing?: false; reInvoiceFile?: never }
  | { enableReInvoicing: true; reInvoiceFile: IReInvoiceFile };

interface IReInvoiceFile {
  fileName: string;
  details: ReInvoiceDetail[];
  leadId: string;
  budgetInstalmentId: string;
  includeFileToLeadFile: boolean;
  leadFileId: string;
  useQuantities: boolean;
}

export interface ISubcontractorFileCategory {
  budgetLineCategoryId: string;
  budgetLineCategoryLabel: string;
  lines: ISubcontractorFileLine[];
}

export interface ISubcontractorFileLine {
  id: string;
  budgetLineId: string;
  budgetLineLabel: string;
  vat: number;
  grossAmount: number;
  amount: number;
  discount?: number;
  discountType?: "absolute" | "percentage";
}

export interface ReInvoiceDetail extends Omit<LeadFinancialDocumentLine, "budgetInstalmentId"> {}

export interface ReInvoiceDetailPriceLine extends ReInvoiceDetail {
  margin: number;
  marginType: "absolute" | "percentage";
  quantity: number;
  unit?: UnitQuantity;
  unitPrice?: number;
  vat: number;
  lineType: LeadFinancialDocumentLineType.PRICING;
}

export const isReInvoiceDetailPriceLine = (line: any): line is ReInvoiceDetailPriceLine =>
  line.lineType === LeadFinancialDocumentLineType.PRICING;
