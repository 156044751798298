import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface InvoicedSupplementaryAgreementsCardProps {
  canSeeHiddenLine: boolean;
  project: Partial<IProject>;
}

export const InvoicedSupplementaryAgreementsCard: React.FC<
  InvoicedSupplementaryAgreementsCardProps & HeaderCardProps
> = ({ canSeeHiddenLine, project, ...props }) => {
  const { budgetTotals } = useBudgetsUIContext();

  const totalSA = project.subcontractorsFinanceFiles?.supplementary_agreements?.total?.general ?? 0;
  const totalInvoicedSupplAgreementsPercentage =
    budgetTotals.invoicedSupplementaryAgreements && totalSA
      ? (budgetTotals.invoicedSupplementaryAgreements / totalSA) * 100
      : 0;
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-invoiced-sa"
          value={budgetTotals.invoicedSupplementaryAgreements ?? 0}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={2}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.SUPPLEMENTARY_AGREEMENT_INVOICED" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-invoiced-sa-percentage"
        value={totalInvoicedSupplAgreementsPercentage}
        decimalScale={2}
        suffix={SUFFIX_PERCENTAGE}
      />
    </HeaderCard>
  );
};
