import * as React from "react";
import { SearchTypeValues } from "../../contexts/SearchContext";
import { useIntl } from "react-intl";
import { useSearch } from "../../hooks/useSearch";

interface SearchDateProps {
  name: string;
  value?: string;
}

export const SearchText: React.FC<SearchDateProps> = ({ name, value }): JSX.Element => {
  const intl = useIntl();
  const { setSearchValues } = useSearch();

  return (
    <div className="mr-sm-2 mr-0 mt-2 h-100 flex-grow-1">
      <input
        type="text"
        data-cy={"input-search"}
        className="form-control min-h-40px"
        name={"search.text." + name}
        placeholder={intl.formatMessage({
          id: "COMMON.SEARCH.TITLE",
        })}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchValues(e.target.value, SearchTypeValues.SEARCH_TEXT_COLUMNS, name);
        }}
      />
    </div>
  );
};
