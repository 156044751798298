import { Field, FieldArray, FieldArrayRenderProps, FormikHandlers, useFormikContext } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import { cloneDeep, isEqual, isUndefined, sumBy } from "lodash-es";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { ReactSortable } from "react-sortablejs";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomCard, CustomCardCollapsePosition } from "../../../../_components/CustomCard";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { canEdit, canSeeHiddenLine } from "../../../../_utils/authUtils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import cn from "clsx";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { BudgetLineContent } from "./BudgetLineContent";
import * as projectActions from "../../../PropertiesManagement/_redux/projects/projectsActions";
import {
  IBudget,
  IBudgetLine,
  IBudgetSortedCategory,
  IFormattedBudgetCategory,
} from "../../../../../data/schemas";
import { BudgetLine } from "./BudgetLine";
import { useConfirmationDialogContext } from "../../../../_components/ConfirmationDialog";

export interface IBudgetEditCategoryProps {
  id: string;
  budget: IBudget;
  displayedDataCategory: IFormattedBudgetCategory;
  category?: IBudgetSortedCategory;
  categoryIndex?: number;
  categoryArrayHelpers?: FieldArrayRenderProps;
  saveBudgetFields?: (key: string | string[], value: any) => void;
  disabled: boolean;
}

export const BudgetEditCategory: React.FC<IBudgetEditCategoryProps> = ({
  id,
  budget,
  displayedDataCategory,
  category,
  categoryIndex,
  categoryArrayHelpers,
  saveBudgetFields,
  disabled,
}) => {
  const intl = useIntl();
  const { handleChange, values, setFieldValue } = useFormikContext<IBudget>();
  const { columnWidth, triggerExpandCategories, triggerCollapseCategories } = useBudgetsUIContext();
  const dispatch = useAppDispatch();
  const { setConfirmationDialog } = useConfirmationDialogContext();

  const { groups, session, project } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
    project: state.projects.projectForEdit.current,
  }));

  const handleCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].label = e.target.value;
      saveBudgetFields("sortedLines", res);
      handleChange(e);
    }
  };

  const removeCategory = (
    e: React.MouseEvent<HTMLButtonElement>,
    categoryArrayHelpers: FieldArrayRenderProps
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      e.preventDefault();
      categoryArrayHelpers.remove(categoryIndex);
      const res = cloneDeep(values.sortedLines);
      res.splice(categoryIndex, 1);
      saveBudgetFields("sortedLines", res);
    }
  };

  const addLine = (
    e: React.MouseEvent<HTMLButtonElement>,
    lineArrayHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    lineArrayHelpers.push({
      id: uuid(),
      label: "",
      plannedBudget: 0,
    });
  };

  const moveLine = (sortedLines: IBudgetLine[]) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      let res = cloneDeep(values.sortedLines);
      const category = res[categoryIndex];
      category.lines = sortedLines;
      setFieldValue("sortedLines", res);
      res = res.map(({ chosen, selected, ...restCategory }) => ({
        ...restCategory,
        lines: restCategory.lines.map(({ chosen, selected, ...line }) => line),
      }));
      if (!isEqual(res, budget.sortedLines)) {
        saveBudgetFields("sortedLines", res);
      }
    }
  };

  const setCategoryVisibility = (
    e: React.MouseEvent<HTMLButtonElement>,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].visible = !res[categoryIndex].visible;
      saveBudgetFields("sortedLines", res);
      handleChange({ target: { name: "visible", value: res[categoryIndex].visible } });
      // Change real budget in local
      if (project?.subcontractorsFinanceFiles) {
        const newSubcontractorsFinanceFiles = cloneDeep(project.subcontractorsFinanceFiles);
        const catRealBudget =
          (project.subcontractorsFinanceFiles.orders?.data?.[res[categoryIndex].id]?.sum ?? 0) +
          (project.subcontractorsFinanceFiles.supplementary_agreements?.data?.[
            res[categoryIndex].id
          ]?.sum ?? 0);
        newSubcontractorsFinanceFiles.orders.total.public += res[categoryIndex].visible
          ? catRealBudget
          : -catRealBudget;
        dispatch(
          projectActions.updateProjectFieldLocally(
            "subcontractorsFinanceFiles",
            newSubcontractorsFinanceFiles
          )
        );
      }
    }
  };

  const budgetDifference = React.useMemo(() => {
    if (category) {
      const sffQuoteCategoryTotal =
        (project?.subcontractorsFinanceFiles?.orders?.data?.[category.id]?.sum ?? 0) +
        (project?.subcontractorsFinanceFiles?.supplementary_agreements?.data?.[category.id]?.sum ??
          0);
      return sffQuoteCategoryTotal - sumBy(category?.lines, "plannedBudget");
    }
    return 0;
  }, [project, category]);

  const categoryHasFiles =
    !!displayedDataCategory?.unspecifiedLines?.length ||
    displayedDataCategory?.lines?.some((line) => line.subcontractors?.length);

  const onClickRemoveCategory = React.useMemo(() => {
    if (canEdit(groups, session, "BUDGET")) {
      if (categoryHasFiles) {
        return () =>
          setConfirmationDialog({
            title: intl.formatMessage({ id: "BUDGET.CATEGORY.DELETE" }),
            body: intl.formatMessage({
              id: "BUDGET.REMOVE_CATEGORY_WARNING",
            }),
            hideButtonLabel: intl.formatMessage({
              id: "COMMON.ACTION.OK",
            }),
            hideButtonClassName: "btn-primary",
          });
      } else {
        return (e: React.MouseEvent<HTMLButtonElement>) => removeCategory(e, categoryArrayHelpers!);
      }
    }
    return undefined;
  }, [groups, session, displayedDataCategory]);

  return (
    <CustomCard
      collapsable={true}
      collapsePosition={CustomCardCollapsePosition.LEFT}
      draggable={id !== "unspecifiedCategory" && canEdit(groups, session, "BUDGET")}
      remove={onClickRemoveCategory}
      confirmRemove={!categoryHasFiles}
      collapse={triggerCollapseCategories}
      expand={triggerExpandCategories}
      headerClassName={cn("flex-nowrap", category?.visible ? "bg-primary-o-60" : "bg-info-o-60")}
      headerStyle={{ minHeight: "41px" }}
      parentClassName={"mb-4"}
      header={
        <>
          <BudgetLineContent
            columnWidth={columnWidth}
            selectedBudget={!!budget?.id && budget.id === project?.selectedBudget}
            isHeader={true}
            labelClassName={id === "unspecifiedCategory" ? "mr-6" : ""}
            line={{
              ...displayedDataCategory,
              label:
                id === "unspecifiedCategory" ? (
                  <span className={"pl-3 font-weight-bold"}>{displayedDataCategory.label}</span>
                ) : (
                  <>
                    {canSeeHiddenLine(groups, session) && (
                      <button
                        className={"btn btn-icon btn-link-primary"}
                        onClick={(e) => setCategoryVisibility(e, handleChange)}
                      >
                        <span className="svg-icon svg-icon-xl">
                          <SVG
                            src={toAbsoluteUrl(
                              `/media/svg/icons/General/${
                                category?.visible ? "Visible" : "Hidden"
                              }.svg`
                            )}
                          />
                        </span>
                      </button>
                    )}
                    <Field name={`sortedLines.${categoryIndex}.id`} type="hidden" />
                    <Field
                      name={`sortedLines.${categoryIndex}.label`}
                      component={Input}
                      withFeedbackLabel={false}
                      placeholder={intl.formatMessage({
                        id: "BUDGET.CATEGORY.NAME",
                      })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCategoryChange(e, handleChange)
                      }
                      disabled={disabled}
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                      className="py-2"
                      style={{ height: "auto" }}
                    />
                  </>
                ),
              realDifference: (
                <span
                  className={cn(
                    id !== "unspecifiedCategory" &&
                      (budgetDifference <= 0 ? "text-success" : "text-danger")
                  )}
                >
                  {displayedDataCategory?.realDifference}
                </span>
              ),
              realDifferencePercentage: (
                <span
                  className={cn(
                    id !== "unspecifiedCategory" &&
                      (budgetDifference <= 0 ? "text-success" : "text-danger")
                  )}
                >
                  {displayedDataCategory?.realDifferencePercentage}
                </span>
              ),
              remainingToInvoice: (
                <span
                  className={cn(
                    displayedDataCategory?.remainingToInvoice !== "-" &&
                      displayedDataCategory?.remainingToInvoice.startsWith("-") &&
                      "text-danger"
                  )}
                >
                  {displayedDataCategory?.remainingToInvoice}
                </span>
              ),
            }}
          />
          <div className={"btn ml-4 h-1px py-0"} style={{ cursor: "auto" }}>
            <span className={"svg-icon svg-icon-md"}></span>
          </div>
        </>
      }
    >
      <FieldArray
        name={`sortedLines.${categoryIndex}.lines`}
        render={(lineArrayHelpers) => (
          <>
            <ReactSortable
              list={cloneDeep(category?.lines || [])}
              setList={moveLine}
              swapThreshold={0.65}
              animation={150}
              fallbackOnBody={true}
              handle=".line-handle"
              scroll={true}
              bubbleScroll={true}
            >
              {category?.lines?.map((line, lineIndex) => (
                <BudgetLine
                  key={line.id}
                  displayedDataLine={displayedDataCategory?.lines?.[lineIndex]}
                  line={line}
                  saveBudgetFields={saveBudgetFields}
                  project={project}
                  budget={budget}
                  categoryId={id}
                  categoryIndex={categoryIndex}
                  lineIndex={lineIndex}
                  lineArrayHelpers={lineArrayHelpers}
                  disabled={disabled}
                />
              ))}
            </ReactSortable>
            {budget.id &&
              budget.id === project?.selectedBudget &&
              displayedDataCategory?.unspecifiedLines?.map((displayedDataLine, lineIndex) => {
                return (
                  <BudgetLine
                    key={displayedDataLine.id}
                    displayedDataLine={displayedDataLine}
                    saveBudgetFields={saveBudgetFields}
                    project={project}
                    budget={budget}
                    categoryId={id}
                    categoryIndex={categoryIndex}
                    lineIndex={lineIndex}
                    lineArrayHelpers={lineArrayHelpers}
                    disabled={disabled}
                  />
                );
              })}
            {!disabled && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center justify-content-left"
                  onClick={(e) => addLine(e, lineArrayHelpers)}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="BUDGET.ACTION.ADD.LINE" />
                </button>
              </div>
            )}
          </>
        )}
      />
    </CustomCard>
  );
};
