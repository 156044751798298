import { createSlice } from "@reduxjs/toolkit";
import { isEqual, set } from "lodash-es";
import { ITask, IUser } from "../../../../../data/schemas";
import { API } from "@aws-amplify/api";

interface ITaskState {
  listLoading: boolean;
  chunkLoading: boolean;
  actionsLoading: boolean;
  chunkPromise?: Promise<any>;

  entities: ITask[];
  users: Partial<IUser>[];
  assignees: any[];
  relatedToItemOptions: any[];

  entityForEdit: {
    saved?: ITask;
    current?: Partial<ITask>;
  };

  error: any;
  queryParams: any;
}

const initialClientsState: ITaskState = {
  listLoading: false,
  chunkLoading: false,
  actionsLoading: false,

  entities: [],
  users: [],
  //array of users available for task assignment (users in this list = options in "assignee"-dropdown)
  assignees: [],
  relatedToItemOptions: [],

  entityForEdit: {
    saved: undefined,
    current: undefined,
  },

  error: null,
  queryParams: null,
};

export const callTypes = {
  list: "list",
  action: "action",
  chunk: "chunk",
};

export const TASKS_CHUNKED_FAILED_MESSAGE = "cancelTasksChunked";

export const tasksSlice = (name: string) =>
  createSlice({
    name: "tasks",
    initialState: initialClientsState,
    reducers: {
      catchError: (state, action) => {
        // state.error = `${action.type}: ${action.payload.error}`;
        switch (action.payload.callType) {
          case callTypes.list:
            state.listLoading = false;
            break;
          case callTypes.chunk:
            state.chunkLoading = false;
            break;
          case callTypes.action:
          default:
            state.actionsLoading = false;
            break;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        switch (action.payload.callType) {
          case callTypes.list:
            state.listLoading = true;
            if (
              !isEqual(action.payload?.queryParams, state.queryParams) ||
              action.payload.clearEntities
            ) {
              state.entities = [];
            }
            break;
          case callTypes.chunk:
            state.chunkLoading = true;
            if (action.payload.clearEntities) {
              state.entities = [];
            }
            break;
          case callTypes.action:
          default:
            state.actionsLoading = true;
            break;
        }
      },
      taskFetched: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entityForEdit = {
          saved: action.payload.entityForEdit,
          current: action.payload.entityForEdit,
        };
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.entityForEdit.id) {
            return action.payload.entityForEdit;
          }
          return entity;
        });
        state.error = null;
      },
      tasksFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
      },
      tasksChunkedFetching: (state, action) => {
        const { promise } = action.payload;
        state.chunkPromise = promise;
      },
      taskChunkedCanceled: (state, action) => {
        if (state.chunkPromise) {
          API.cancel(state.chunkPromise, TASKS_CHUNKED_FAILED_MESSAGE);
          state.chunkPromise = undefined;
        }
      },
      tasksChunkedFetched: (state, action) => {
        const { entities, chunkLoading, clearEntities } = action.payload;
        state.chunkLoading = chunkLoading;
        state.error = null;
        if (clearEntities) {
          state.entities = [...entities];
        } else {
          state.entities = [...state.entities, ...entities];
        }
        state.chunkPromise = undefined;
      },
      relatedToItemOptionsFetched: (state, action) => {
        const { entities } = action.payload;
        state.error = null;
        state.relatedToItemOptions = entities.items;
      },
      usersFetched: (state, action) => {
        state.listLoading = false;
        state.error = null;
        state.users = action.payload.users;
      },
      assigneesFetched: (state, action) => {
        state.listLoading = false;
        state.error = null;
        state.assignees = action.payload.users;
      },
      taskFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.entityForEdit.current) {
          state.entityForEdit.current = {};
        }
        set(state.entityForEdit.current, action.payload.key, action.payload.value);
      },
      taskUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.entityForEdit.current) {
          state.entityForEdit.current = {};
        }
        set(state.entityForEdit.current, action.payload.key, action.payload.value);
      },
      taskCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;

        const { task, isSubtaskFlow = false } = action.payload;

        // if chunks are loading we don't push it as it will be fetched on the last fetch call
        if (!state.chunkLoading) {
          state.entities.push(task);
        }

        if (isSubtaskFlow && task.parentId) {
          const { saved, current } = state.entityForEdit;

          const addSubtask = (entity: Partial<ITask> | undefined, toAdd: ITask) =>
            entity && (entity.subtasks = [...(entity.subtasks || []), toAdd]);

          addSubtask(saved, task);
          addSubtask(current, task);

          state.entityForEdit = { saved, current };

          return;
        }

        state.entityForEdit = {
          saved: task,
          current: task,
        };
      },
      commentCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entityForEdit.current!.comments!.unshift(action.payload.comment);
        state.entityForEdit.saved!.comments!.unshift(action.payload.comment);
      },

      commentDeleted: (state, action) => {
        state.actionsLoading = false;
        state.error = null;

        if (state.entityForEdit!.current!.comments) {
          const indexDeletedComment = state.entityForEdit!.current!.comments.findIndex(
            (comment) => comment.id === action.payload.comment.id
          );
          if (indexDeletedComment >= 0) {
            state.entityForEdit!.current!.comments.splice(indexDeletedComment, 1);
            state.entityForEdit!.saved!.comments.splice(indexDeletedComment, 1);
          }
        }
      },
      commentUpdated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        const indexOfCommentToUpdate =
          state.entityForEdit?.current?.comments?.findIndex(
            (comment) => comment.id === action.payload.comment.id
          ) ?? -1;
        state.entityForEdit.current!.comments![indexOfCommentToUpdate]!.content =
          action.payload?.comment?.content;
        state.entityForEdit.saved!.comments![indexOfCommentToUpdate]!.content =
          action.payload?.comment?.content;
      },
      taskUpdated: (state, action) => {
        state.error = null;

        const { task, isSubtaskFlow = false } = action.payload;

        const updateEntities = (entities: Partial<ITask>[] | undefined, task: ITask) => {
          const taskId = task.id;
          const indexToUpdate = entities!.findIndex((item) => item && item.id === taskId);
          if (indexToUpdate < 0) return;
          entities![indexToUpdate] = task;
        };

        updateEntities(state.entities, task);

        if (isSubtaskFlow && task.parentId) {
          const { saved, current } = state.entityForEdit;
          updateEntities(saved!.subtasks, task);
          updateEntities(current!.subtasks, task);

          state.entityForEdit = { ...state.entityForEdit };

          return;
        }

        state.entityForEdit = {
          saved: task,
          current: task,
        };
      },
      resetLanes: (state) => {
        state.error = null;
        state.actionsLoading = false;
      },
      taskDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        const findTaskId = (taskId: string) => (entity: ITask) => entity.id === taskId;
        const filterIgnoreTaskId = (ignoreTaskId: string) => (entity: ITask) =>
          entity.id !== ignoreTaskId;
        const filterIgnoreParentTaskId = (ignoreTaskId: string) => (entity: ITask) => {
          const { parentId } = entity;
          if (!parentId) return true;
          return parentId !== ignoreTaskId;
        };

        const { taskId, parentTaskId } = action.payload;

        const entities = (state.entities = state.entities
          .filter(filterIgnoreTaskId(taskId))
          .filter(filterIgnoreParentTaskId(taskId)));

        if (parentTaskId) {
          const parentTask = entities.find(findTaskId(parentTaskId));

          if (!parentTask || !parentTask.subtasks || parentTask.subtasks.length === 0) return;
          parentTask.subtasks = parentTask.subtasks.filter(filterIgnoreTaskId(taskId));

          state.entityForEdit = {
            saved: parentTask,
            current: parentTask,
          };
        }
      },
    },
  });
