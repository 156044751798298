import * as React from "react";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { FormattedMessage } from "react-intl";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";

export const UnitsOverview: React.FC<HeaderCardProps> = (props) => {
  const {
    productsQty: { total, sold, unsold },
  } = useBudgetRevenueContext();
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-revenues-units-overview"
          value={total}
          displayType="text"
          decimalScale={0}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REVENUE.UNITS_OVERVIEW" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-revenues-units-overview-sold"
        label="BUDGET.REVENUE.SOLD"
        value={sold}
        decimalScale={0}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-units-overview-unsold"
        label="BUDGET.REVENUE.UNSOLD"
        value={unsold}
        decimalScale={0}
      />
    </HeaderCard>
  );
};
