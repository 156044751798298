import { Field, FieldInputProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import cn from "clsx";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { KycInput } from "../kyc-input/KycInput";
import { useWizard } from "../../../../../hooks/useWizard";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";
import { useKycFlow } from "../../../../../hooks/useKycFlow";

export interface KycCheckboxProps {
  field: any;
  checkboxInfo: {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    name: string;
    isChecked: boolean;
  };
  freeTextInfo?: {
    name: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: any;
  };
  inline: boolean;
  label?: string;
  customClasses?: string;
  text: string;
  disabled: boolean;
  checked?: boolean;
  children: any;
  translateLabel: boolean;
  translateText: boolean;
  disableLabel: boolean;
}
export const KycCheckbox: FC<KycCheckboxProps & Partial<FieldInputProps<boolean>>> = ({
  field,
  checkboxInfo,
  freeTextInfo,
  inline = true,
  label,
  customClasses,
  text,
  disabled,
  children,
  checked,
  translateLabel = true,
  translateText = true,
  disableLabel = false,
  ...props
}) => {
  const { fm } = useTranslation();
  const { isReadOnly } = useWizard();
  const { isFieldLocked } = useKycFlow();

  const isCheckboxDisabled = () => {
    return disabled || isReadOnly || isFieldLocked(field);
  };

  const content = (
    <div className={cn("row", customClasses, "mb-1")}>
      <div className="col-lg-12 d-flex">
        <label
          data-cy={formatDataCy(DataCyCompPrefix.CHECKBOX, checkboxInfo.name)}
          className={cn(
            "checkbox checkbox-lg checkbox-single mr-2 form-control-reduce-space",
            isCheckboxDisabled() && !checkboxInfo.isChecked && "text-muted"
          )}
          style={{ cursor: isCheckboxDisabled() ? "default" : "pointer" }}
        >
          <input
            type="checkbox"
            name={checkboxInfo.name}
            disabled={isCheckboxDisabled()}
            checked={checkboxInfo.isChecked}
            onChange={checkboxInfo.onChange}
          />
          <span className="mr-2" style={{ minWidth: 24, minHeight: 24 }} />
          {translateText
            ? fm(text, {
                inputTextField: (
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <Field
                      value={freeTextInfo?.value}
                      name={freeTextInfo?.name}
                      disabled={!checkboxInfo.isChecked || isReadOnly}
                      locked={!checkboxInfo.isChecked}
                      component={KycInput}
                      onChange={freeTextInfo?.onChange}
                    />
                  </div>
                ),
              })
            : text}
        </label>
      </div>
    </div>
  );
  return (
    <>
      {inline ? (
        <div className="d-flex flex-wrap flex-sm-wrap flex-md-wrap flex-lg-nowrap justify-content-center pb-2 ">
          {
            <label
              className={cn("col-lg-6 col-form-label text-left", disableLabel && "text-muted")}
            >
              {label && fm(label)}
            </label>
          }
          <div className={cn("col-lg-6", disableLabel && "text-muted")}>{content}</div>
        </div>
      ) : (
        <>
          {label && <label>{translateLabel ? fm(label) : label}</label>}
          {content}
        </>
      )}
    </>
  );
};
