import { Cell, Header } from "@tanstack/react-table";
import * as React from "react";
import cn from "clsx";
import { CardTableItem, useCardTableContext } from "../context/CardTableContext";

export interface BaseCellRenderProps<T> {
  cell: Cell<T, unknown> | Header<T, unknown>;
  customClasses?: string;
  render: React.ReactNode;
  index: number;
}
export const BaseCellRender = <T extends CardTableItem>({
  cell,
  customClasses,
  render,
  index,
}: BaseCellRenderProps<T>) => {
  const { isScrolled } = useCardTableContext();
  const [offsetLeft, setOffsetLeft] = React.useState(0);

  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setOffsetLeft(node.offsetLeft);
    }
  }, []);

  return (
    <div
      ref={measuredRef}
      className={cn(
        (index === 0 || cell.column.columnDef.meta?.fixed) && "zindex-1",
        cell.column.columnDef.meta?.customWidth ?? "col",
        cell.column.columnDef.meta?.fixed && "position-sticky shadow-right",
        cell.column.columnDef.meta?.fixed && isScrolled && "shadow-sm",
        customClasses
      )}
      style={{
        minWidth: cell.column.columnDef.meta?.minWidth ?? 50,
        left: cell.column.columnDef.meta?.fixed ? offsetLeft : 0,
      }}
    >
      {render}
    </div>
  );
};
