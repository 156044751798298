import * as React from "react";

import "./KycValidationElement.scss";

type KycValidationElementProps = {
  field?: any;
  children?: React.ReactNode;
};

export const KycValidationElement = ({
  field,
  children,
}: KycValidationElementProps): JSX.Element => {
  /*
     NO PR CHECK - WORK IN PROGRESS - TODO: implement validation element
   */

  // const { isHovered, setIsHovered } = useKycFlow();

  // const validationFieldAccept = (
  //   <button
  //     className="btn btn-icon btn-light btn-hover-info btn-md"
  //     // onClick={(e) => openFileInDrawer(e, attachment)}
  //   >
  //     <span className="svg-icon svg-icon-md svg-icon-success">
  //       <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
  //     </span>
  //   </button>
  // );
  //
  // const validationFieldRefuse = (
  //   <button
  //     className="btn btn-icon btn-light btn-hover-info btn-md"
  //     // onClick={(e) => openFileInDrawer(e, attachment)}
  //   >
  //     <span className="svg-icon svg-icon-md svg-icon-danger">
  //       <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
  //     </span>
  //   </button>
  // );

  // return (
  //   <div
  //     className="position-relative"
  //     onMouseEnter={() => setIsHovered(field?.name)}
  //     onMouseLeave={() => setIsHovered("")}
  //   >
  //     {isHovered === field?.name && (
  //       <div className="kyc-validation-element-hover-container">
  //         <div className=" kyc-validation-element-button-container">
  //           {validationFieldAccept}
  //           {validationFieldRefuse}
  //         </div>
  //       </div>
  //     )}
  //     <div className="kyc-validation-element">{children}</div>
  //   </div>
  // );
  return <>{children}</>;
};
