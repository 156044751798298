import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { KycTranslation } from "../../KycTranslation";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycAddressForm } from "../KycAddressForm";
import {
  KYC_V2_OPTIONS_COUNTRIES,
  KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION,
} from "../../KycTranslationUtils";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import { Suffix } from "../../../../_utils/suffixUtils";
import { KycNumberInput } from "../controls/kyc-number-input/KycNumberInput";
import { NumberFormatValues } from "react-number-format";
import { mapNameToKycEntityType } from "../../KycUtils";
import { get } from "lodash-es";

type KycBoShareholderCompanyProps = {
  name: string;
  index: number;
};

export const KycBoShareholderCompany = ({
  name,
  index,
}: KycBoShareholderCompanyProps): JSX.Element => {
  const { values, customHandleChange } = useKycFlow();

  const assembleFieldPath = (fieldName: string) => {
    return `${name}[${index}].${fieldName}`;
  };

  const getKycRelatedEntity = () => {
    return {
      id: get(values, `${name}[${index}].id`),
      type: mapNameToKycEntityType(assembleFieldPath("id")),
    };
  };

  return (
    <>
      <Field
        name={assembleFieldPath("name")}
        component={KycInput}
        placeholder="kodehyve"
        label={KycTranslation.COMPANY_NAME}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name={assembleFieldPath("legalForm")}
        component={KycInput}
        placeholder="S.à r.l."
        label={KycTranslation.LEGAL_FORM}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name={assembleFieldPath("countryCodeEstablishment")}
        component={KycDropdownSelect}
        placeholder="Luxembourg"
        options={KYC_V2_OPTIONS_COUNTRIES}
        translateLabels={true}
        translateRegion={true}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.COUNTRY_OF_ESTABLISHMENT}
      />
      <KycAddressForm name={assembleFieldPath("address")} />
      <Field
        name={assembleFieldPath("percentageOfShareCapital")}
        component={KycNumberInput}
        suffix={Suffix.PERCENTAGE}
        onChange={(e: NumberFormatValues) => {
          customHandleChange({
            target: {
              name: assembleFieldPath("percentageOfShareCapital"),
              value: (e.floatValue ?? 0) / 100 || 0,
            },
          });
        }}
        label={KycTranslation.BENEFICIAL_OWNER_PERCENTAGE_SHARE_CAPITAL}
      />
      <Field
        name={assembleFieldPath("nameOfTradeRegister")}
        component={KycInput}
        placeholder=""
        label={KycTranslation.TRADE_REGISTER_NAME}
        infoLabel={KycTranslation.TRADE_REGISTER_NAME_TOOLTIP}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name={assembleFieldPath("numberInTradeRegister")}
        component={KycInput}
        placeholder=""
        label={KycTranslation.TRADE_REGISTER_NUMBER}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycUploadComponent
        name={assembleFieldPath("evidenceTradeRegisterRCS")}
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_1}
      />
      <KycUploadComponent
        name={assembleFieldPath("evidenceTradeRegisterRBE")}
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_2}
      />
      <KycUploadComponent
        name={assembleFieldPath("evidenceAdditionalDocuments")}
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL}
        othersOnly={true}
        options={KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION}
        multiple={true}
      />
    </>
  );
};
