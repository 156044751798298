/*
  How to create responsive tables with pure CSS using Grid Layout Module | FreeCodeCamp
  https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/

    Responsive tables with pure CSS using Grid Layout Module | CodePen
    https://codepen.io/ShingoNakayama/pen/LMLeRZ
*/

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Switch } from "@material-ui/core";
import objectPath from "object-path";
import { useDidUpdate } from "rooks";
import cn from "clsx";

import { useHtmlClassService } from "_metronic/layout";

import { IRequestSignatory } from "data/schemas";

import { Signatory } from "./Signatory";
import { AddSignatory } from "./AddSignatory";

import { useRequestSignatureDialogContext } from "../RequestSignatureDialogContext";

import "./Signatories.scss";

export interface SignatoriesProps {
  className?: string;
}

export const Signatories: React.FunctionComponent<SignatoriesProps> = ({ className }) => {
  const intl = useIntl();
  const uiService = useHtmlClassService();

  const signatoriesCollectionRef = React.useRef<HTMLDivElement>(null);

  const {
    sendEmail,
    setSendEmail,
    sendSms,
    setSendSms,
    signatories,
    updateSignatory,
    removeSignatory,
  } = useRequestSignatureDialogContext();

  const layoutProps = React.useMemo(() => {
    const { config } = uiService;
    return {
      colorsGrayGray100: objectPath.get(config, "js.colors.gray.gray100"),
      colorsGrayGray300: objectPath.get(config, "js.colors.gray.gray300"),
      colorsGrayGray500: objectPath.get(config, "js.colors.gray.gray500"),
      colorsGrayGray800: objectPath.get(config, "js.colors.gray.gray800"),
      colorsThemeBaseWhite: objectPath.get(config, "js.colors.theme.base.white"),
      colorsThemeBaseDanger: objectPath.get(config, "js.colors.theme.base.danger"),
    };
  }, [uiService]);

  const signatoriesDivStyle = {
    "--label-avatar": `"${intl.formatMessage({ id: "PROFILE.AVATAR" })}"`,
    "--label-name": `"${intl.formatMessage({ id: "COMMON.NAME" })}"`,
    "--label-email": `"${intl.formatMessage({ id: "COMMON.EMAIL" })}"`,
    "--label-phone": `"${intl.formatMessage({ id: "COMMON.MOBILE" })}"`,
    "--label-actions": `"${intl.formatMessage({ id: "ACTION.TITLE" })}"`,
    "--color-bg": layoutProps.colorsThemeBaseWhite,
    "--color-danger": layoutProps.colorsThemeBaseDanger,
    "--color-border": layoutProps.colorsGrayGray100,
    "--color-header-bg": layoutProps.colorsThemeBaseWhite,
    "--color-header-text": layoutProps.colorsGrayGray500,
    "--color-hover-bg": layoutProps.colorsGrayGray300,
    "--color-hover-text": layoutProps.colorsGrayGray800,
  } as any;

  const buildRemoveClickHandler = (signatory: IRequestSignatory) => () =>
    removeSignatory(signatory);

  const isEmpty = signatories.length === 0;

  //--------------------------------------------------------------------------//

  useDidUpdate(() => {
    const { current: signatoriesCollection } = signatoriesCollectionRef;
    if (!signatoriesCollection || signatories.length === 0) return;
    signatoriesCollection.scrollTo({ top: signatoriesCollection.scrollHeight, behavior: "smooth" });
  }, [signatories]);

  //--------------------------------------------------------------------------//

  return (
    <div className={cn("signatories", className)} style={signatoriesDivStyle}>
      {/* @begin: list container */}
      <div ref={signatoriesCollectionRef} className={cn("collection", { empty: isEmpty })}>
        <div className="item item-header">
          <div className="attribute avatar"></div>
          <div className="attribute name"></div>
          <div className="attribute email"></div>
          <div className="attribute phone"></div>
          <div className="attribute actions"></div>
        </div>

        {isEmpty && (
          <div className="item empty">
            <div className="attribute empty">
              {intl.formatMessage({ id: "COMMON.NOT_SPECIFIED" })}
            </div>
          </div>
        )}

        {signatories.map((signatory) => (
          <Signatory
            key={signatory.signatoryHelperId}
            signatory={signatory}
            onRemoveClick={buildRemoveClickHandler(signatory)}
            onUpdate={updateSignatory}
          />
        ))}
      </div>
      {/* @end: list container */}

      <AddSignatory className="mt-2" />

      <div className={"notifications mt-4"}>
        <FormattedMessage id="COMMON.ACTION.NOTIFY_USER_BY" />

        <Switch
          onChange={(e) => setSendEmail(e.target.checked)}
          checked={sendEmail}
          data-cy="switch-send-email"
        />
        <FormattedMessage id="COMMON.EMAIL" />

        <Switch
          onChange={(e) => setSendSms(e.target.checked)}
          checked={sendSms}
          data-cy="switch-send-sms"
        />
        <FormattedMessage id="COMMON.SMS" />
      </div>
    </div>
  );
};

export default Signatories;
