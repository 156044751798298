import { components } from "react-select";
import { Checkbox } from "../../../../Checkbox";
import { MultiSelect } from "../../../../MultiSelect";
import * as React from "react";
import { differenceBy } from "lodash-es";
import { useCardTableContext } from "../../../context/CardTableContext";
import { useIntl } from "react-intl";

export const ToggleColumns = () => {
  const intl = useIntl();
  const { table, hiddenColumnsStorageKey } = useCardTableContext();

  const hideableColumns = table
    .getAllLeafColumns()
    .filter((column) => column.columnDef.enableHiding);

  const visibleColumns = hideableColumns.filter((column) => column.getIsVisible());
  const handleColumnVisibility = (selectedOptions: any) => {
    const hiddenCols = differenceBy(hideableColumns, selectedOptions, "id");
    const formattedHiddenCols = hiddenCols.reduce((acc, col) => ({ ...acc, [col.id]: false }), {});
    localStorage.setItem(hiddenColumnsStorageKey, JSON.stringify(formattedHiddenCols));
    table.setColumnVisibility(formattedHiddenCols);
  };

  return (
    <div className="mr-3">
      <MultiSelect
        options={hideableColumns}
        value={visibleColumns}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option: (option: any) => (
            <components.Option {...option}>
              <Checkbox
                label={option.label}
                translateLabel={false}
                checked={option.isSelected}
                checkboxLarge={false}
                checkboxStyle="align-items-start text-gray-900"
              />
            </components.Option>
          ),
        }}
        placeholder={intl.formatMessage({ id: "CARD_TABLE.FILTER_COLUMNS" })}
        getOptionValue={(column: any) => column.id}
        getOptionLabel={(column: any) => column.columnDef.meta.label ?? column.columnDef.header}
        onChange={handleColumnVisibility}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
      />
    </div>
  );
};
