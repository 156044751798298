import * as React from "react";
import { useEffect, useState } from "react";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { KycChoiceOption, KycFile } from "../../../Kyc";
import { v4 as uuid } from "uuid";
import { isEmpty, orderBy } from "lodash-es";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycInfoBox } from "../../KycInfoBox";
import { KycUploadInput } from "./KycUploadInput";
import { KycFormSectionTitle } from "../../KycFormSectionTitle";
import cn from "clsx";
import { useWizard } from "../../../../../hooks/useWizard";
import { KycUploadTrashButton } from "./KycUploadTrashButton";
import { KycUploadAddButton } from "./KycUploadAddButton";
import { KycUploadFreetext } from "./KycUploadFreetext";
import { KycUploadDropdown } from "./KycUploadDropdown";

type KycUploadComponentProps = {
  multiple?: boolean;
  label?: string;
  name: string;
  options?: KycChoiceOption[];
  infoLabel?: string;
  disabled?: boolean;
  disableLabel?: boolean;
  othersOnly?: boolean;
  subLabel?: string;
  inline?: boolean;
  kycRelatedToEntity?: any;
};

export const KycUploadComponent = ({
  multiple = false,
  label = "",
  name,
  options = [],
  infoLabel = "",
  disabled = false,
  disableLabel = false,
  othersOnly = false,
  subLabel = "",
  inline = true,
  kycRelatedToEntity,
}: KycUploadComponentProps): JSX.Element => {
  const { values } = useKycFlow();
  const { currentFiles, setCurrentFiles } = useKycFile();
  const [isDropdownRequired, setIsDropdownRequired] = useState<boolean>(false);
  const [files, setFiles] = useState<KycFile[]>([]);
  const { isReadOnly } = useWizard();

  const initKycFile = {
    kycId: values?.id,
    categoryId: name,
    fieldPath: name,
    kycRelatedToEntity: kycRelatedToEntity,
    freeText: "",
    option: "",
    index: -1,
    size: 0,
  };

  // TODO check
  useEffect(() => {
    if (!currentFiles) return;

    const filteredFiles = currentFiles.filter((file) => file?.fieldPath?.includes(name)) || [];

    if (isEmpty(filteredFiles)) {
      handleAddFile();
    } else {
      const orderedFilesCreatedAtAsc = orderBy(
        filteredFiles,
        [
          function (object: KycFile) {
            return new Date(object?.createdAt)?.getTime();
          },
        ],
        ["asc"]
      );

      setFiles(orderedFilesCreatedAtAsc);
    }
  }, [currentFiles]);

  const handleAddFile = () => {
    setCurrentFiles([
      ...currentFiles,
      { ...initKycFile, id: uuid(), status: "DRAFT", index: currentFiles.length },
    ]);
  };

  const content = (
    <>
      {files?.map((file: KycFile, index: number) => (
        <div
          key={"kyc-upload-component-" + index}
          className={cn("d-flex", index > 0 && isDropdownRequired && "pt-6")}
        >
          <div className={"w-100"}>
            {!othersOnly && !isEmpty(options) && (
              <div className={cn("d-flex w-100")}>
                <KycUploadDropdown
                  file={file}
                  options={options}
                  setIsDropdownRequired={setIsDropdownRequired}
                />
                <KycUploadTrashButton file={file} disabled={disabled} />
              </div>
            )}
            {!othersOnly && <div className="pt-1" />}
            {(file?.option?.includes("other") || (othersOnly && file?.url)) && (
              <KycUploadFreetext file={file} disabled={disabled} othersOnly={othersOnly} />
            )}
            <div className="pt-1" />
            {((isDropdownRequired && file?.option) || !isDropdownRequired || othersOnly) && (
              <KycUploadInput
                file={file}
                disabled={disabled}
                othersOnly={othersOnly}
                renderTrashButton={!isDropdownRequired}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <>
      <KycFormSectionTitle title={label} infoLabel={infoLabel} subLabel={subLabel} />
      <KycFormElementBaseLayout
        disableLabel={disableLabel}
        content={content}
        disabled={disabled}
        label={inline ? " " : undefined}
        inline={inline}
        isSectionChild={false}
      />
      {multiple && !disabled && !isReadOnly && (
        <>
          <div className="pt-1" />
          {!files?.find((file) => !file?.createdAt) && (
            <KycUploadAddButton kycRelatedToEntity={kycRelatedToEntity} name={name} />
          )}
        </>
      )}
      {infoLabel && <KycInfoBox label={infoLabel} className="mb-4" />}
    </>
  );
};
