import * as React from "react";
import { FormattedMessage } from "react-intl";
import { NumberInput } from "../../../_utils/formUtils";
import { NumberFormatProps } from "react-number-format";
import { isNumber } from "lodash-es";

export interface HeaderCardSubValueProps extends Omit<NumberFormatProps, "value"> {
  label?: string;
  dataCy?: string;
  secondValue?: {
    dataCy: string;
  } & NumberFormatProps;
  value: number | React.ReactNode;
}

export const HeaderCardSubValue: React.FC<HeaderCardSubValueProps> = ({
  children,
  label,
  dataCy,
  secondValue,
  ...props
}) => {
  const { dataCy: secondValueDataCy, ...secondValueProps } = secondValue ?? {};
  return (
    <div className="row font-size-xs text-inverse-light">
      {!!label && (
        <div className={"col"}>
          <FormattedMessage id={label} />
        </div>
      )}
      <div className={"col-auto"}>
        {isNumber(props.value) ? (
          <>
            <NumberInput
              data-cy={dataCy}
              displayType="text"
              fixedDecimalScale={props.fixedDecimalScale ?? true}
              className="font-weight-bold text-right mr-1"
              {...props}
            />
            {!!secondValue && (
              <>
                (
                <NumberInput
                  data-cy={secondValueDataCy}
                  displayType="text"
                  fixedDecimalScale={secondValue.fixedDecimalScale ?? true}
                  className="font-weight-bold text-right"
                  {...secondValueProps}
                />
                )
              </>
            )}
          </>
        ) : (
          props.value
        )}
      </div>
    </div>
  );
};
