import { useEffect } from "react";
import { IUser } from "../../../data/schemas";
import { KycTranslation } from "./KycTranslation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { useTranslation } from "../../hooks/useTranslation";
import { useKyc } from "../../hooks/useKyc";
import { useSubheader } from "../../../_metronic/layout";
import { useHistory, useLocation } from "react-router-dom";
import { KycComplianceViewMode, KycFlow } from "./Kyc";
import { diff } from "deep-object-diff";
import { isEmpty } from "lodash-es";
import * as actions from "../UsersManagement/_redux/usersActions";
import { CLIENT } from "../../_utils/userTypes";

interface KycBreadcrumbsProps {
  userId?: string;
}

export const KycBreadcrumbs = ({ userId }: KycBreadcrumbsProps): JSX.Element => {
  const { fm, fDisplayName } = useTranslation();
  const { currentKycFlow } = useKyc();
  const subheader = useSubheader();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { currentClient }: any = useAppSelector(
    (state) => ({
      currentClient: state?.users?.entityForEdit.saved
        ? state.users.entityForEdit.saved
        : state?.users?.entities?.find((entity: IUser) => entity?.id === userId),
    }),
    shallowEqual
  );
  useEffect(() => {
    const kycClientId = currentKycFlow?.form?.client?.id;
    //redirect to 404 e.g. if user tries to modify/replace clientId with nonsense/bad id and
    // id doesn't match client id in kyc flow anymore
    if (kycClientId && currentClient?.id && kycClientId !== currentClient?.id) {
      history.push(`/error/404`);
    }
  }, [currentKycFlow, currentClient]);

  useEffect(() => {
    dispatch(actions.fetchUserById(userId, [CLIENT]));
  }, [userId]);

  const formatBreadcrumbs = (
    location: any,
    kycFlow?: KycFlow,
    currentClient?: IUser
  ): { title: string; pathname: string }[] => {
    const queryParams = new URLSearchParams(location.search);
    const breadcrumbs = [
      { title: fm("CLIENT.TITLE"), pathname: "/clients" },
      {
        title: (currentClient && fDisplayName(currentClient, false)) || "",
        pathname: `/clients/${userId}`,
      },
    ];

    if (location.search.includes("t=compliance")) {
      breadcrumbs.push({ title: fm(KycTranslation.COMPLIANCE_TITLE), pathname: "" });
    }

    if (location.pathname.includes("/kyc/")) {
      const pathname = location?.pathname?.includes("/kyc/") && `/clients/${userId}?t=compliance`;
      breadcrumbs.push({ title: fm(KycTranslation.COMPLIANCE_TITLE), pathname: pathname || "" });
    }

    if (location.pathname.includes("/kyc/")) {
      let translation: string;

      if (kycFlow?.form?.reference === kycFlow?.form?.client.lead?.name) {
        translation = kycFlow?.form?.client.lead?.name;
      } else {
        translation = `${kycFlow?.form?.client.lead?.name} (${kycFlow?.form?.reference})`;
      }
      if (!queryParams?.has("vm")) {
        breadcrumbs.push({ title: translation, pathname: "" });
      } else {
        breadcrumbs.push({
          title: translation,
          pathname: `/clients/${userId}/kyc/${kycFlow?.id}`,
        });

        switch (queryParams.get("vm")) {
          case KycComplianceViewMode.RISK_ASSESSMENT:
            translation = fm(KycTranslation.STEPS_RISK_EVALUATION_TITLE);

            break;
          case KycComplianceViewMode.FORM:
            translation = fm(KycTranslation.FORM);
            break;
        }
        breadcrumbs.push({ title: translation, pathname: "" });
      }
    }

    return breadcrumbs;
  };
  useEffect(() => {
    const breadcrumbs = formatBreadcrumbs(location, currentKycFlow, currentClient);
    if (!isEmpty(diff(subheader.breadcrumbs, breadcrumbs))) {
      subheader.setBreadcrumbs(breadcrumbs);
    }
  }, [location, userId, currentClient, currentKycFlow]);

  return <></>;
};
