import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { useBudgetsUIContext } from "../../../../BudgetsUIContext";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface InvoicedOrdersCardProps {
  canSeeHiddenLine: boolean;
  project: Partial<IProject>;
}

export const InvoicedOrdersCard: React.FC<InvoicedOrdersCardProps & HeaderCardProps> = ({
  canSeeHiddenLine,
  project,
  ...props
}) => {
  const { budgetTotals } = useBudgetsUIContext();
  const totalOrders = project.subcontractorsFinanceFiles?.orders?.total?.general ?? 0;
  const totalInvoicedOrdersPercentage =
    budgetTotals.invoicedOrders && totalOrders
      ? (budgetTotals.invoicedOrders / totalOrders) * 100
      : 0;
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-invoiced-orders"
          value={budgetTotals.invoicedOrders ?? 0}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={2}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.ORDERS_INVOICED" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-invoiced-orders-percentage"
        value={totalInvoicedOrdersPercentage}
        decimalScale={2}
        suffix={SUFFIX_PERCENTAGE}
      />
    </HeaderCard>
  );
};
