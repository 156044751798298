import "../../../KycWizard.scss";
import * as React from "react";
import { useState } from "react";

import "../../RepresentativeCarousel.scss";
import { Button } from "react-bootstrap";
import { KycHorizontalScrollMenu } from "./KycHorizontalScrollMenu";
import { KycEntityCarousel } from "./KycEntityCarousel";
import { get } from "lodash-es";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { Field, FieldArray } from "formik";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { v4 as uuid } from "uuid";
import { useWizard } from "../../../../../hooks/useWizard";
import { KycFormSectionTitle } from "../../KycFormSectionTitle";
import { DataCyCompPrefix, KycItemType } from "../../../Kyc";
import { formatDataCy } from "../../../KycUtils";

type KycMultiEntityProps = {
  field: any;
  title?: string;
  name: string;
  initObj: any;
  defaultHeaderTitle: string;
  labelButtonAdd: string;
  disabled: boolean;
  infoLabel?: string;
  itemType: KycItemType;
  showDivider?: boolean;
};

export const KycMultiEntity = ({
  field,
  initObj,
  labelButtonAdd,
  defaultHeaderTitle,
  title,
  disabled,
  infoLabel,
  itemType,
  showDivider = true,
}: KycMultiEntityProps): JSX.Element => {
  const { fm } = useTranslation();
  const { values } = useKycFlow();
  const { isReadOnly } = useWizard();

  const [carouselActiveIndex, setCarouselActiveIndex] = useState<number>(0);

  return (
    <FieldArray name={field.name}>
      {({ push, remove }) => (
        <div className="mb-2">
          <KycFormSectionTitle title={title} infoLabel={infoLabel}>
            {!disabled && !isReadOnly && (
              <Button
                data-cy={formatDataCy(DataCyCompPrefix.BUTTON + "-add", field?.name)}
                onClick={() => push({ ...initObj, id: uuid() })}
                className="btn btn-primary"
              >
                {fm(labelButtonAdd)}
              </Button>
            )}
          </KycFormSectionTitle>
          <Field
            component={KycHorizontalScrollMenu}
            disabled={disabled}
            items={get(values, field.name)}
            defaultTitle={defaultHeaderTitle}
            name={field.name}
            remove={remove}
            setRepCarouselActiveIndex={setCarouselActiveIndex}
            repCarouselActiveIndex={carouselActiveIndex}
          />
          {get(values, field.name)?.length > 0 && (
            <KycEntityCarousel
              activeIndex={carouselActiveIndex}
              name={field.name}
              items={get(values, field.name)}
              itemType={itemType}
            />
          )}

          {showDivider && <hr className={"my-4"} />}
        </div>
      )}
    </FieldArray>
  );
};
