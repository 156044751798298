export interface IHistoryParams {
  requestId: string;
}

export const ModalState = {
  NONE: "NONE",
  INFO: "INFO",
  OTP: "OTP",
} as const;

export type TModalState = keyof typeof ModalState;

export const getModalStateValue = (state: TModalState) => ModalState[state] ?? ModalState.NONE;
