import { createSlice } from "@reduxjs/toolkit";
import { isEqual, set } from "lodash-es";

import {
  ICompany,
  IProject,
  IProjectFile,
  IProjectPhoto,
  IProjectSharing,
  IUser,
} from "data/schemas";

import { FilesSliceUtils } from "app/modules/File/filesSliceUtils";

//----------------------------------------------------------------------------//

interface IProjectState {
  listLoading: boolean;
  actionsLoading: boolean;

  entities: IProject[];
  entitiesIndexesMap: Record<string, number>;

  projectOwnerAssignees: ICompany[];

  projectForEdit: {
    saved?: IProject;
    current?: Partial<IProject>;
  };

  relatedToObjects: any[];

  error: any;
  queryParams: any;
}

const initialProjectsState: IProjectState = {
  listLoading: false,
  actionsLoading: false,

  entities: [],
  entitiesIndexesMap: {},

  projectOwnerAssignees: [],

  projectForEdit: {
    saved: undefined,
    current: undefined,
  },
  relatedToObjects: [],

  error: null,
  queryParams: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState: initialProjectsState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        if (!isEqual(action.payload?.queryParams, state.queryParams)) {
          state.entities = [];
          state.entitiesIndexesMap = {};
        }
      } else {
        state.actionsLoading = true;
      }
    },

    projectPreFetched: (state, action) => {
      const { projectId } = action.payload;

      if (!projectId) return;

      state.actionsLoading = true;

      const index = state.entitiesIndexesMap[projectId];
      if (!index) return;
      const project = state.entities[index];

      state.projectForEdit.saved = project;
      state.projectForEdit.current = project;

      state.actionsLoading = false;
    },

    projectFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { projectForEdit } = action.payload;

      state.projectForEdit.saved = projectForEdit;
      state.projectForEdit.current = projectForEdit;
    },

    projectsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;

      const { entities } = action.payload;
      state.entities = entities;

      (entities as IProject[]).forEach((project, index) => {
        state.entitiesIndexesMap[project.id!] = index;
      });
    },

    projectsDetailsFetched: (state, action) => {
      const entities = action.payload.entities as IProject[];
      if (entities.length === 0) return;

      const previousEntities = state.entities;
      for (const updatedProject of entities) {
        const projectId = updatedProject.id!;
        const index = state.entitiesIndexesMap[projectId];
        if (index === undefined) {
          const previousLength = previousEntities.length;
          previousEntities.push(updatedProject);
          state.entitiesIndexesMap[projectId] = previousLength;
          return;
        }

        previousEntities[index] = updatedProject;
      }
      state.entities = [...previousEntities];
    },

    projectRelatedIdsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;

      state.relatedToObjects = action.payload.relatedToObjects;
    },

    projectOwnerAssigneesFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      state.projectOwnerAssignees = [...action.payload.projectOwnerAssignees];
    },

    projectCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { project } = action.payload;

      state.entities.push(project);
      state.projectForEdit.saved = project;
      state.projectForEdit.current = project;
    },

    projectFieldUpdatedLocally: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      if (!state.projectForEdit.current) {
        state.projectForEdit.current = {};
      }

      const { key, value } = action.payload;
      set(state.projectForEdit.current, key, value);
    },

    projectUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { project } = action.payload;

      state.projectForEdit.current = project;
      state.projectForEdit.saved = project;
    },

    projectDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const entityId = action.payload.id;

      state.entities = state.entities.filter((el) => el.id !== entityId);
    },

    fileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { file } = action.payload;

      state.projectForEdit.current?.files?.push(file);
      state.projectForEdit.saved?.files?.push(file);

      const { id, friendlyName, relatedEntity } = file;
      for (const linkedFile of file.linkedFiles ?? []) {
        FilesSliceUtils.linkedFilesAdded({
          entityForEdit: state.projectForEdit,
          fileId: linkedFile.id,
          linkedFiles: [
            {
              id,
              friendlyName,
              relatedEntity,
            },
          ],
        });
      }
    },

    fileUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { file } = action.payload;
      const fileId = file.id;
      const fileFindIndex = (file: IProjectFile) => file.id === fileId;

      const currentFileIndex: number =
        state.projectForEdit.current?.files?.findIndex(fileFindIndex) ?? -1;

      if (state.projectForEdit?.current?.files) {
        const currentFile = state.projectForEdit.current.files[currentFileIndex];
        if (file.friendlyName !== currentFile.friendlyName) {
          FilesSliceUtils.linkedFileRenamed({
            updatedFile: file,
            entityForEdit: state.projectForEdit,
          });
        }
        state.projectForEdit.current.files[currentFileIndex] = file;
      }

      const savedFileIndex: number =
        state.projectForEdit.saved?.files?.findIndex(fileFindIndex) ?? -1;

      if (state.projectForEdit?.saved?.files) {
        state.projectForEdit.saved.files[savedFileIndex] = file;
      }
    },
    linkedFilesAdded: (state, { payload: { fileId, linkedFiles } }) =>
      FilesSliceUtils.linkedFilesAdded({
        entityForEdit: state.projectForEdit,
        fileId,
        linkedFiles,
      }),
    linkedFileRemoved: (state, { payload: { fileId, linkedFileToRemove } }) =>
      FilesSliceUtils.linkedFileRemoved({
        entityForEdit: state.projectForEdit,
        fileId,
        linkedFileToRemove,
      }),
    fileDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const fileId = action.payload.file.id;
      const filesFilter = (file: IProjectFile) => file.id !== fileId;

      const currentFile = state.projectForEdit.current?.files?.find((file) => file.id === fileId);
      for (const linkedFile of currentFile?.linkedFiles ?? []) {
        FilesSliceUtils.linkedFileRemoved({
          entityForEdit: state.projectForEdit,
          fileId: linkedFile.id,
          linkedFileToRemove: currentFile!.id,
        });
      }
      state.projectForEdit.current!.files =
        state.projectForEdit.current?.files?.filter(filesFilter);

      state.projectForEdit.saved!.files = state.projectForEdit.saved?.files?.filter(filesFilter);
    },

    projectSharingRemoved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const userId = action.payload.projectSharing.userId;
      const projectSharingFilter = (ps: IProjectSharing) => ps.userId !== userId;
      const userFilter = (ps: IUser) => ps.id !== userId;

      state.projectForEdit.current!.projectSharings =
        state.projectForEdit.current?.projectSharings?.filter(projectSharingFilter);

      state.projectForEdit.saved!.projectSharings =
        state.projectForEdit.saved?.projectSharings?.filter(projectSharingFilter);

      state.projectForEdit.current!.usersCanAccess =
        state.projectForEdit.current?.usersCanAccess?.filter(userFilter);

      state.projectForEdit.saved!.usersCanAccess =
        state.projectForEdit.saved?.usersCanAccess?.filter(userFilter);
    },

    projectSharingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { projectSharing, user } = action.payload;

      state.projectForEdit.current?.projectSharings?.push(projectSharing);
      state.projectForEdit.saved?.projectSharings?.push(projectSharing);

      state.projectForEdit.current?.usersCanAccess?.push(user);
      state.projectForEdit.saved?.usersCanAccess?.push(user);
    },

    projectSubcontractorRemoved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const userId = action.payload.projectSubcontractor.userId;
      const projectSubcontractorsFilter = (ps: any) => ps.userId !== userId;

      state.projectForEdit.current!.projectSubcontractors =
        state.projectForEdit.current?.projectSubcontractors?.filter(projectSubcontractorsFilter);

      state.projectForEdit.saved!.projectSubcontractors =
        state.projectForEdit.saved?.projectSubcontractors?.filter(projectSubcontractorsFilter);
    },

    projectSubcontractorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { projectSubcontractor } = action.payload;

      state.projectForEdit.current?.projectSubcontractors?.push(projectSubcontractor);
      state.projectForEdit.saved?.projectSubcontractors?.push(projectSubcontractor);
    },

    addBudget: (state, action) => {
      const { budget } = action.payload;

      state.projectForEdit.current?.budgets?.push(budget);
      state.projectForEdit.saved?.budgets?.push(budget);
    },

    deleteBudget: (state, action) => {
      const budgetId = action.payload.id;
      const budgetsFilter = (b: any) => b.id !== budgetId;

      state.projectForEdit.current!.budgets =
        state.projectForEdit.current?.budgets?.filter(budgetsFilter);

      state.projectForEdit.saved!.budgets =
        state.projectForEdit.saved?.budgets?.filter(budgetsFilter);
    },

    signatureRequestCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const signatureResponse = action.payload.signatureResponse;
      const { fileId, fileSignatures } = signatureResponse;

      const fileFindIndex = (file: IProjectFile) => file.id === fileId;

      const currentFileIndex = state.projectForEdit.current?.files?.findIndex(fileFindIndex) ?? -1;

      state.projectForEdit.current?.files?.[currentFileIndex]?.signatures.push(...fileSignatures);

      const savedFileIndex = state.projectForEdit.saved?.files?.findIndex(fileFindIndex) ?? -1;

      state.projectForEdit.saved?.files?.[savedFileIndex]?.signatures.push(...fileSignatures);
    },

    photosFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const photos = action.payload;

      state.projectForEdit.current!.photos = photos;
      state.projectForEdit.saved!.photos = photos;
    },

    photoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.actionsLoading = false;
      const { newPhoto } = action.payload;

      state.projectForEdit.current?.photos?.push(newPhoto);
      state.projectForEdit.saved?.photos?.push(newPhoto);

      state.projectForEdit.current?.photosOrder?.push(newPhoto.id);
      state.projectForEdit.saved?.photosOrder?.push(newPhoto.id);
    },

    photoUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { photo: updatedPhoto } = action.payload;
      const updatedPhotoId = updatedPhoto.id;

      const photoIndex: number =
        state.projectForEdit.current?.photos?.findIndex((photo) => photo.id === updatedPhotoId) ??
        -1;

      updatedPhoto.localPhoto = state.projectForEdit.current?.photos?.[photoIndex]?.localPhoto;

      if (state.projectForEdit?.current?.photos) {
        state.projectForEdit.current.photos[photoIndex] = updatedPhoto;
      }
      if (state.projectForEdit?.saved?.photos) {
        state.projectForEdit.saved.photos[photoIndex] = updatedPhoto;
      }
    },

    photoDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { photoId } = action.payload;
      const photoFilter = (id: string | undefined) => id !== photoId;

      state.projectForEdit.current!.photos = state.projectForEdit.current!.photos!.filter((photo) =>
        photoFilter(photo.id)
      );

      state.projectForEdit.saved!.photos = state.projectForEdit.saved?.photos?.filter((photo) =>
        photoFilter(photo.id)
      );

      state.projectForEdit.current!.photosOrder =
        state.projectForEdit.current?.photosOrder?.filter(photoFilter);
      state.projectForEdit.saved!.photosOrder =
        state.projectForEdit.saved?.photosOrder?.filter(photoFilter);
    },

    photosOrderUpdate: (state, action) => {
      const project = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.projectForEdit.current!.photosOrder = project?.photosOrder;
      state.projectForEdit.saved!.photosOrder = project?.photosOrder;
    },

    photoSharingCreated: (state, action) => {
      const photoSharing = action.payload.photoSharing;
      state.error = null;
      state.actionsLoading = false;
      const photoSharingId = photoSharing.photoId;

      const photosMap = (photo: IProjectPhoto) => {
        if (photo.id === photoSharingId) {
          photo.photoSharings!.push(photoSharing);
        }
        return photo;
      };

      state.projectForEdit.current!.photos = state.projectForEdit.current?.photos?.map(photosMap);

      if (state.projectForEdit.saved) {
        state.projectForEdit.saved.photos = state.projectForEdit.saved?.photos?.map(photosMap);
      }
    },

    photoSharingRemoved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { photoSharing } = action.payload;
      const { photoId: photoSharingId, userId: photoSharingUserId } = photoSharing;

      const photoSharingsFilter = (ps: any) => ps.userId !== photoSharingUserId;

      const photosMap = (photo: IProjectPhoto) => {
        if (photo.id === photoSharingId) {
          photo.photoSharings = photo.photoSharings!.filter(photoSharingsFilter);
        }
        return photo;
      };

      state.projectForEdit.current!.photos = state.projectForEdit.current?.photos?.map(photosMap);

      if (state.projectForEdit?.saved) {
        state.projectForEdit.saved.photos = state.projectForEdit.saved?.photos?.map(photosMap);
      }
    },

    avatarCreated: (state) => {
      state.actionsLoading = false;
      state.error = null;
    },

    resetProjectForEdit: (state) => {
      state.projectForEdit.saved = undefined;
      state.projectForEdit.current = undefined;
    },

    productUpdate: (state, action) => {
      const products = action.payload;
      state.error = null;
      state.actionsLoading = false;

      state.projectForEdit.current!.products = products;
      state.projectForEdit.saved!.products = products;
    },
  },
});
