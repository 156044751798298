// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { PROJECT_PRODUCT_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { canReadAllDocument } from "../../../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { FileDropzone } from "app/_components/FileDropzone";
import { LinkedFiles } from "../../../../../../_components/LinkedFiles";

export function ProjectFileEditForm({ saveFile, file, actionsLoading, onHide, newFile }) {
  const intl = useIntl();

  // Validation schema
  const FileEditSchema = ({ newFile }) =>
    Yup.object().shape({
      file: newFile ? Yup.mixed().required() : Yup.mixed(),
      friendlyName: Yup.string().min(2).max(200).required().label("COMMON.NAME"),
      fileType: Yup.string().required().label("COMMON.TYPE"),
    });

  const { session, groups, project } = useSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
      project: state.projects.projectForEdit.saved,
    }),
    shallowEqual
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={file}
        validationSchema={FileEditSchema({ newFile })}
        onSubmit={(values) => saveFile({ ...values, relatedEntity: { name: project.name } })}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <>
            <Modal.Body className="overlay">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="friendlyName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                      label={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Select
                      name="fileType"
                      label={intl.formatMessage({
                        id: "COMMON.TYPE",
                      })}
                    >
                      {Object.keys(PROJECT_PRODUCT_FILE_TYPES).map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[value] })}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                {newFile && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {actionsLoading && !!values.file ? (
                        <div className="upload-file-container upload-file-container--disabled">
                          <div className="d-flex justify-content-center align-middle">
                            <div className="spinner-grow text-primary mr-4" />
                            <div>
                              <FormattedMessage id="FILE.ACTION.UPLOADING" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <FileDropzone values={values} setFieldValue={setFieldValue} />
                      )}
                    </div>
                  </div>
                )}
                {canReadAllDocument(groups, session, "PROJECT") && (
                  <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
                )}
                <div className="separator separator-solid separator-border-2 my-4"></div>
                <LinkedFiles activeFile={file} />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                disabled={actionsLoading}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <> </>
              <button
                disabled={actionsLoading}
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
