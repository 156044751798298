import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import * as uiHelpers from "../../BudgetsUIHelpers";
import { NoRecordsFoundMessage, sortCaret } from "../../../../../_metronic/_helpers";
import * as React from "react";
import { useIntl } from "react-intl";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { formatCurrency } from "../../../../_utils/mathUtils";
import * as actions from "../../../UsersManagement/_redux/usersActions";
import { useDispatch } from "react-redux";
import { useCustomLocationState } from "../../../../_utils/useCustomLocationState";

type Props = {
  files: any;
  budgetLineLabel: string;
};
export const BudgetLineFileTable: React.FC<Props> = ({ files, budgetLineLabel }) => {
  const intl = useIntl();
  const { filters } = useBudgetsUIContext();
  const dispatch = useDispatch();
  const { setFromUrlTo } = useCustomLocationState();

  const paginationOptions = {
    custom: true,
    totalSize: files?.length ?? 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageNumber,
  };

  const goToSubcontractor = (file: any) => {
    setFromUrlTo({
      url: `/subcontractors/${file.userId}?t=files&h=${file.id}`,
      name: budgetLineLabel,
    });
  };

  const columns = [
    {
      dataField: "userName",
      text: intl.formatMessage({
        id: "COMMON.CREATED.BY",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any) => (
        <button
          className={"btn btn-link-primary"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            goToSubcontractor(row);
          }}
        >
          {cell}
        </button>
      ),
    },
    {
      dataField: "friendlyName",
      text: intl.formatMessage({
        id: "COMMON.FILE_NAME",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: intl.formatMessage({
        id: "COMMON.AMOUNT",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any) => formatCurrency(cell, 2, intl),
    },
  ];

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination paginationProps={{ ...paginationProps, intl: intl }}>
            <BootstrapTable
              {...paginationTableProps}
              id="leads-table"
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              bordered={false}
              defaultSorted={uiHelpers.defaultSorted}
              noDataIndication={() => <NoRecordsFoundMessage entities={files} />}
              hover
              rowStyle={{ cursor: "pointer" }}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  dispatch(actions.openUserFile(row));
                },
              }}
              keyField="id"
              data={files || []}
              columns={columns}
            />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
