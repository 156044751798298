import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { WS_ENDPOINT } from "../../../configuration";
import * as actions from "../../modules/Notifications/_redux/inAppNotificationsActions";
import { useIntl } from "react-intl";
import { addNewChat, addNewChatMessage } from "../Chat/_redux/chatActions";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchAction } from "../PropertiesManagement/_redux/forms/formsActions";
import PropTypes from "prop-types";
import * as userActions from "../UsersManagement/_redux/usersActions";

export interface WebsocketContextValue {
  latestMessage: any;
  setLatestMessage: (message: any) => void;
}

export const WebsocketContext = createContext<WebsocketContextValue>({
  latestMessage: undefined,
  setLatestMessage: (message) => undefined,
});

export const WebsocketProvider = (props: { children: any }): JSX.Element => {
  const intl = useIntl();
  const { children } = props;

  const [connectionOpen, setConnectionOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const dispatch = useAppDispatch();

  const [latestMessage, setLatestMessageInternal] = useState<boolean>();

  const setLatestMessage = (latestMessage: any) => {
    setLatestMessageInternal(latestMessage);
  };
  useEffect(() => {
    dispatch(actions.fetchLatestNotifications(intl, userId));
  }, []);

  useEffect(() => {
    if (!connectionOpen) {
      Auth.currentUserInfo()
        .then((userInfo) => {
          if (userInfo?.attributes?.sub) {
            console.log("sub:", userInfo.attributes?.sub);
            const websocket = new WebSocket(`${WS_ENDPOINT}?user=${userInfo.attributes.sub}`);
            setUserId(userInfo.attributes?.sub);
            websocket.onopen = (ev) => {
              console.log("websocket.onopen", new Date().toISOString());
              setConnectionOpen(true);
            };
            websocket.onclose = (ev) => {
              console.log("websocket.onclose", new Date().toISOString());
              setConnectionOpen(false);
            };
            websocket.onmessage = (ev) => {
              console.log("websocket.onmessage");
              console.log(ev);
              const data = JSON.parse(ev.data);
              console.log(data);
              switch (data.type) {
                case "REFRESH_IN_APP_NOTIFICATION":
                  dispatch(actions.fetchLatestNotifications(intl, userId));
                  break;
                case "ADD_NEW_CHAT":
                  dispatch(addNewChat(data.parameters));
                  break;
                case "ADD_NEW_CHAT_MESSAGE":
                  dispatch(addNewChatMessage(data.parameters));
                  break;
                case "DOCUMENT_GENERATED":
                  if (data?.parameters?.file?.userId && data?.parameters?.action?.id) {
                    dispatch(
                      fetchAction(data?.parameters?.file?.userId, data?.parameters?.action?.id)
                    );
                  }
                  break;
                case "REFRESH_USER_RIGHTS":
                  //When this websocket message has been received by a specific user,
                  //it basically means someone modified his rights(-table).
                  //In this case, we force refresh of the frontend of that user to ensure that
                  //changes will be reflected directly
                  //Backend implementation -> check createUsersRight.ts for additional information
                  dispatch(userActions.fetchUserMe());
                  window.location.reload();
                  break;

                /*
                 alternative mode, set the latest message, which then can be consumed via
                 const { latestMessage } = useWebsocket(); from within a component
                 example can be found in KycContentContainer.tsx
                 */
                case "REFRESH_KYC_FLOW_COMPLIANCE":
                case "REFRESH_KYC_FLOW_BENEFICIAL_OWNERS":
                case "KYC_ZIP_PACKAGE_CREATED":
                case "KYC_ZIP_PACKAGE_IN_PROGRESS":
                  setLatestMessage(data);
                  break;
                default:
                  break;
              }
            };
            websocket.onerror = (ev) => {
              console.log("websocket.onerror");
              console.log(ev);
            };
          }
        })
        .catch();
    }
  }, [connectionOpen]);

  return (
    <WebsocketContext.Provider
      value={{
        latestMessage,
        setLatestMessage,
      }}
    >
      <>{children}</>
    </WebsocketContext.Provider>
  );
};

WebsocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const WebsocketConsumer = WebsocketContext.Consumer;
