import * as React from "react";
import { TInvoiceStatus } from "../../../../data/schemas";
import { BudgetRevenueHeader } from "./components/BudgetRevenueHeader/BudgetRevenueHeader";
import { CardTableItem } from "../../../_components/CardTable/context/CardTableContext";
import { CardTable } from "../../../_components/CardTable/CardTable";
import { createColumnHelper } from "@tanstack/react-table";
import { CardTableCollapseIndicator } from "../../../_components/CardTable/components/buttons/CardTableCollapseIndicator";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberInput } from "../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../_utils/suffixUtils";
import { useBudgetRevenueContext } from "./context/BudgetRevenueContext";
import cn from "clsx";
import { isNumber, isUndefined, sumBy } from "lodash-es";
import { getTotalInvoiceStatus } from "./utils";
import {
  currencyColumns,
  IInstalmentRevenue,
  IRevenueColumns,
  ISupplementRevenue,
  TCurrencyColumns,
} from "./definitions";

const headers: Record<keyof IRevenueColumns, string> = {
  label: "BUDGET.REVENUE.TABLE_COLUMN.DESCRIPTION",
  instalmentPercentage: "BUDGET.REVENUE.TABLE_COLUMN.INSTALMENT",
  expected: "BUDGET.REVENUE.TABLE_COLUMN.TOTAL_EXPECTED",
  sold: "BUDGET.REVENUE.TABLE_COLUMN.TOTAL_SOLD",
  invoiced: "BUDGET.REVENUE.TABLE_COLUMN.INVOICED",
  invoicedPercentage: "BUDGET.REVENUE.TABLE_COLUMN.INVOICED_PERCENTAGE",
  supplementMargin: "BUDGET.REVENUE.TABLE_COLUMN.SUPPLEMENT_MARGIN",
  supplementMarginPercentage: "BUDGET.REVENUE.TABLE_COLUMN.SUPPLEMENT_MARGIN_PERCENTAGE",
  paymentStatus: "BUDGET.REVENUE.TABLE_COLUMN.PAYMENT_STATUS",
};

const paymentStatusStyle: Record<TInvoiceStatus, { translationKey: string; color: string }> = {
  PAID: {
    translationKey: "INVOICE.STATUS.PAID",
    color: "success",
  },
  PARTIALLY_PAID: {
    translationKey: "INVOICE.STATUS.PARTIALLY_PAID",
    color: "info",
  },
  TO_BE_PAID: {
    translationKey: "INVOICE.STATUS.TO_BE_PAID",
    color: "warning",
  },
  OVERDUE: {
    translationKey: "INVOICE.STATUS.OVERDUE",
    color: "danger",
  },
};

interface RevenueTableRow extends IRevenueColumns, CardTableItem {}

export const BudgetRevenue: React.FC = () => {
  const intl = useIntl();

  const { isLoading, instalmentRevenue, supplementRevenue } = useBudgetRevenueContext();

  const calcRevenueTotal = (
    instalments: IInstalmentRevenue[],
    supplements: ISupplementRevenue[]
  ) => {
    const totalLine: Partial<IRevenueColumns> = {};
    const paymentStatusCount: Record<string, number> = {};
    for (const revenueLine of [...instalments, ...supplements]) {
      if (revenueLine.paymentStatus) {
        paymentStatusCount[revenueLine.paymentStatus] =
          (paymentStatusCount[revenueLine.paymentStatus] ?? 0) + 1;
      }
      for (const property of currencyColumns as TCurrencyColumns[]) {
        if (!isUndefined(revenueLine[property])) {
          totalLine[property] = (totalLine[property] ?? 0) + revenueLine[property]!;
        }
      }
      if (revenueLine.instalmentPercentage) {
        totalLine.instalmentPercentage =
          (totalLine.instalmentPercentage ?? 0) + revenueLine.instalmentPercentage;
      }
    }

    totalLine.paymentStatus = getTotalInvoiceStatus(paymentStatusCount);
    if (!isUndefined(totalLine.invoiced) && !isUndefined(totalLine.sold)) {
      totalLine.invoicedPercentage = (totalLine.invoiced / totalLine.sold) * 100;
    }
    if (!isUndefined(totalLine.supplementMargin)) {
      const amountWithoutMargin = sumBy(supplements, "sold") - totalLine.supplementMargin;
      totalLine.supplementMarginPercentage =
        (totalLine.supplementMargin / amountWithoutMargin) * 100;
    }
    return totalLine;
  };

  const extraSupplements = supplementRevenue
    ?.filter((supplement) => !supplement.budgetInstalmentId)
    ?.map((supplement) => ({
      ...supplement,
    }));

  const tableData: RevenueTableRow[] = React.useMemo(() => {
    return [
      {
        id: "ID",
        label: intl.formatMessage({ id: "COMMON.TOTAL" }),
        defaultExpanded: true,
        ...calcRevenueTotal(instalmentRevenue, supplementRevenue),
        children: [
          ...instalmentRevenue.map((instalment): RevenueTableRow => {
            const supplement = supplementRevenue?.find(
              (s) => s.budgetInstalmentId === instalment.id
            );

            return {
              ...instalment,
              ...calcRevenueTotal([instalment], supplement ? [supplement] : []),
              supplementMargin: supplement?.supplementMargin,
              ...(!["SHARE_OF_LAND", "ARCHITECT_ENGINEERING_FEES"].includes(instalment.id)
                ? {
                    children: [
                      {
                        ...instalment,
                        id: instalment.id + "instalment",
                        label: intl.formatMessage({ id: "BUDGET.INSTALMENT.SINGLE" }),
                      },
                      {
                        ...supplement,
                        id: instalment.id + "supplements",
                        label: intl.formatMessage({ id: "BUDGET.REVENUE.TOTAL_SUPPLEMENTS" }),
                      },
                    ],
                  }
                : {}),
            };
          }),
          {
            id: "TOTAL_SUPPLEMENT",
            label: intl.formatMessage({ id: "BUDGET.REVENUE.TOTAL_SUPPLEMENTS" }),
            ...calcRevenueTotal([], extraSupplements),
            children: extraSupplements,
            rowTheme: "info",
          },
        ],
      },
    ];
  }, [instalmentRevenue, supplementRevenue]);

  const columnHelper = createColumnHelper<RevenueTableRow>();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: "COLLAPSE",
        cell: ({ row }) => (
          <>{row.depth > 0 && row.getCanExpand() && <CardTableCollapseIndicator row={row} />}</>
        ),
        meta: {
          minWidth: 0,
          fixed: true,
          customWidth: "w-40px",
        },
      }),
      ...Object.entries(headers).map(([key, translationKey]) => {
        const typedKey = key as keyof IRevenueColumns;
        const column = columnHelper.accessor(typedKey, {
          header: () => (
            <span className={cn(typedKey !== "label" && "d-block text-right")}>
              <FormattedMessage id={translationKey} />
            </span>
          ),
          cell: ({ row, getValue }) => {
            let value = getValue();
            // Empty column render
            if (isUndefined(value)) {
              return "-";
            }

            // Specific column render
            switch (typedKey) {
              case "label":
                return (
                  <div
                    className="d-flex align-items-center flex-grow-1"
                    style={{
                      paddingLeft: `${row.depth > 1 ? row.depth : 0}rem`,
                    }}
                  >
                    {value}
                  </div>
                );
              case "paymentStatus":
                const paymentStatus = paymentStatusStyle[value as TInvoiceStatus];
                return (
                  <span
                    className={cn(
                      "label label-lg label-inline text-nowrap",
                      `label-light-${paymentStatus.color}`
                    )}
                  >
                    <FormattedMessage id={paymentStatus.translationKey} />
                  </span>
                );
            }

            // Default column render
            if (isNumber(value)) {
              return (
                <NumberInput
                  displayType="text"
                  fixedDecimalScale={true}
                  className="font-weight-bold text-right"
                  value={value}
                  decimalScale={typedKey === "supplementMarginPercentage" ? 1 : 0}
                  suffix={
                    currencyColumns.includes(typedKey) ? SUFFIX_EURO_CURRENCY : SUFFIX_PERCENTAGE
                  }
                />
              );
            }
          },
          meta: {
            minWidth: 110,
            label: intl.formatMessage({ id: translationKey }),
          },
          enableHiding: true,
        });
        switch (typedKey) {
          case "label":
            column.meta!.customWidth = "flex-shrink-0 w-200px w-sm-250px w-md-300px w-lg-350px";
            column.meta!.fixed = true;
            column.enableHiding = false;
            break;
          case "paymentStatus":
            column.meta!.minWidth = 135;
        }
        return column;
      }),
    ],

    [headers, intl.locale]
  );

  return isLoading ? (
    <div className="d-flex align-items-center justify-content-center">
      <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
    </div>
  ) : (
    <>
      <BudgetRevenueHeader />
      <div style={{ marginLeft: "-21px", marginRight: "-21px" }}>
        <CardTable data={tableData} columns={columns} multiCardLayout={false} id="BUDGET_REVENUE" />
      </div>
    </>
  );
};
