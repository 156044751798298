import cn from "clsx";
import { KycMaximizeWindowButton } from "./components/KycMaximizeWindowButton";
import { KycContentContainer } from "./KycContentContainer";
import { WizardNavbar } from "../../_components/wizard/WizardNavbar";
import { useWizard } from "../../hooks/useWizard";
import { KycStep } from "./Kyc";
import { Card, CardBody, CardFooter } from "../../../_metronic/_partials/controls";
import * as React from "react";

export const KycFlowContainerContent = (): JSX.Element => {
  const { isExpanded, setIsExpanded, showControls, currentStepId } = useWizard();
  const isFooterHidden = () => {
    if (currentStepId() === KycStep.STEP_START_FLOW) {
      return true;
    }
  };

  return (
    <Card className={cn(isExpanded && "card-maximized")}>
      <div className="kyc-card-header">
        <h2 className="kyc-card-title">KYC/AML/BO</h2>
        <KycMaximizeWindowButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>
      <CardBody style={{ padding: 0 }}>
        <KycContentContainer />
      </CardBody>
      {showControls && (
        <CardFooter
          style={{
            borderTop: !isFooterHidden() ? "1px solid #F5F5F5" : "none",
            padding: "1rem",
            minHeight: 66,
          }}
        >
          {!isFooterHidden() && <WizardNavbar />}
        </CardFooter>
      )}
    </Card>
  );
};
