import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../_metronic/_partials/dropdowns";
import SelectableContext from "react-bootstrap/esm/SelectableContext";

export interface DropdownMenuProps {
  icon?: string;
  disabled?: boolean;
  toggleClass?: string;
  containerClass?: string;
  buttonRef?: any;
  dropDirection?: "up" | "left" | "right" | "down";
  onClick?: DropdownProps["onToggle"];
  id?: string;
}
export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  icon = "/media/svg/icons/General/Other1.svg",
  disabled,
  toggleClass,
  containerClass,
  children,
  buttonRef,
  dropDirection = "up",
  onClick,
  id,
}) => {
  return (
    <>
      <SelectableContext.Provider value={null}>
        <Dropdown drop={dropDirection} className={containerClass} onToggle={onClick} id={id}>
          <Dropdown.Toggle
            as={DropdownCustomToggler}
            disabled={disabled}
            customClass={toggleClass}
            ref={buttonRef}
          >
            <span className="svg-icon svg-icon-md cursor-pointer">
              <SVG src={toAbsoluteUrl(icon)} />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu
            popperConfig={{ strategy: "fixed" }}
            alignRight
            className="dropdown-menu dropdown-menu-left dropdown-menu-anim dropdown-menu-anim-down"
            rootCloseEvent={"mousedown"}
          >
            {children}
          </Dropdown.Menu>
        </Dropdown>
      </SelectableContext.Provider>
    </>
  );
};
