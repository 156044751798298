import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { cloneDeep, isUndefined } from "lodash-es";
import DOMPurify from "dompurify";
import cn from "clsx";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import makeStyles from "@material-ui/core/styles/makeStyles";
import "@ckeditor/ckeditor5-build-classic/build/translations/en-gb";
import "@ckeditor/ckeditor5-build-classic/build/translations/fr";
import "@ckeditor/ckeditor5-build-classic/build/translations/de";

import { ckeConfig } from "./config";

import "./TextEditor.scss";

export interface TextEditorProps {
  name: string;
  data: string;
  saveMethod: Function;
  disabled: boolean;
  toolbarOption: string[] | undefined;
  "data-cy": string;
  size?: string;
  id?: string;
  timeout?: boolean;
}

export const TextEditor: FC<TextEditorProps> = ({ timeout = true, ...props }) => {
  const intl = useIntl();

  const useStyles = makeStyles((theme) => ({
    richTextEditor: {
      "& .ck-editor__main > .ck-editor__editable": {
        minHeight: props?.size,
      },
    },
  }));
  const classes = useStyles();

  const loadLanguage = () => {
    let currentLanguage = cloneDeep(intl.locale);
    switch (currentLanguage) {
      case "fr-FR":
        currentLanguage = "fr";
        break;
      case "de-DE":
        currentLanguage = "de";
        break;
      default:
        currentLanguage = "en";
    }

    return currentLanguage;
  };

  useEffect(() => {
    if (isUndefined(props.disabled)) {
      if (props.name === "content" && props.id) {
        const item = document.querySelector(`#${props.id} .ck-editor__editable`) as HTMLElement;
        if (item) {
          item.focus();
          // TODO : set the focus after the last character
        }
      }
    }
  }, [props.disabled]);

  // If this is triggered at every OnChange it'll break when using markdown shortcut to create title / list,etc..
  // ex: type "#" then type " ". Then infinite loop onChange
  const [onChangeTimeout, setOnChangeTimeout] = useState<NodeJS.Timeout>();
  const onChange = (_: Event, editor: { getData: () => string }) => {
    if (timeout) {
      clearTimeout(onChangeTimeout);
      setOnChangeTimeout(
        setTimeout(() => {
          props.saveMethod(
            props.name,
            DOMPurify.sanitize(editor?.getData(), { USE_PROFILES: { html: true } })
          );
        }, 1000)
      );
    } else {
      props.saveMethod(
        props.name,
        DOMPurify.sanitize(editor?.getData(), { USE_PROFILES: { html: true } })
      );
    }
  };

  return (
    <div
      data-cy={props["data-cy"]}
      className={cn("texteditor", props.size && classes.richTextEditor)}
      id={props.id}
    >
      <CKEditor
        editor={ClassicEditor}
        config={ckeConfig(loadLanguage(), props.disabled ? [] : props.toolbarOption)}
        data={props.data || ""}
        disabled={props.disabled}
        onChange={onChange}
      />
    </div>
  );
};
