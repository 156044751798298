import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import * as React from "react";
import { useFileViewerDrawer } from "../../../../../hooks/useFileViewerDrawer";
import { KycFile } from "../../../Kyc";

interface KycFilePreviewButtonProps {
  file: KycFile;
}

export const KycFilePreviewButton = ({ file }: KycFilePreviewButtonProps): JSX.Element => {
  const { setCurrentDrawerFiles } = useFileViewerDrawer();

  const openFileInDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentDrawerFiles([file]);
  };

  return (
    <button
      className="btn btn-icon btn-light btn-hover-info btn-md ml-1 form-control-reduce-space"
      style={{ zIndex: 1 }}
      onClick={openFileInDrawer}
    >
      <span className="svg-icon svg-icon-md svg-icon-info">
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
      </span>
    </button>
  );
};
