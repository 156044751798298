import * as React from "react";
import cn from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual } from "react-redux";

import { useSubheader } from "_metronic/layout";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { Sorter } from "app/_components/Sorter";

import { defaultSelectedSort, ISelectedSort } from "app/_utils/tasks";
import { canCreate, canReadAll } from "app/_utils/authUtils";
import { TASK_SORTER_OPTION } from "app/_utils/listUtils";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { useTasksUIContext } from "app/modules/PropertiesManagement/pages/tasks/TasksUIContext";

import { TasksCardContent, TasksContentViewMode, TasksSwitchContentView } from "./TasksCardContent";
import { SearchLocal } from "../../../../../_components/Search/SearchLocal";
import ContentLoader from "react-content-loader";

export enum TasksCardDisplayMode {
  PAGE = "page",
  TAB = "tab",
}

export type TTasksCardDisplayMode = `${TasksCardDisplayMode}`;

export interface TasksCardProps {
  relatedTo?: string;
  selectedSort?: ISelectedSort;
  displayMode?: TTasksCardDisplayMode;
}

export const TasksCard: React.FunctionComponent<TasksCardProps> = ({
  relatedTo,
  selectedSort: externalSelectedSort = defaultSelectedSort,
  displayMode = TasksCardDisplayMode.PAGE,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [selectedSort, setSelectedSort] = React.useState<ISelectedSort>(externalSelectedSort);

  const {
    contentView,
    toggleContentView = () => undefined,
    newTaskButtonClick,
  } = useTasksUIContext();

  const { groups, session, isTasksLoading, tasksLength } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
      isTasksLoading: state.tasks.chunkLoading,
      tasksLength: state.tasks.entities?.length ?? 0,
    }),
    shallowEqual
  );

  const subheader = useSubheader();

  React.useEffect(() => {
    if (!displayMode) {
      subheader.setTitle(
        intl.formatMessage({
          id: "TASK.TITLE",
        })
      );
    }
  }, [subheader]);

  React.useEffect(() => {
    dispatch(
      actions.fetchChunkedTasks({
        chunks: "true",
        relatedTo: relatedTo,
        deeplyRelated: relatedTo?.startsWith("PROJECT"),
      })
    );
  }, [relatedTo]);

  React.useEffect(() => {
    return () => {
      dispatch(actions.cancelFetchChunking());
    };
  }, []);

  React.useEffect(() => {
    if (displayMode === TasksCardDisplayMode.TAB) return;
    //fetch options for project filter (only available in global tasks view)
    dispatch(actions.fetchRelatedToItems(intl));
    openRelatedToSelect();
  }, []);

  const [relatedToOptionsLoading, setRelatedToOptionsLoading] = React.useState(false);
  const openRelatedToSelect = () => {
    setRelatedToOptionsLoading(true);
    actions
      .fetchRelatedToItems(intl)(dispatch)
      .then(() => setRelatedToOptionsLoading(false));
  };

  const isTab = displayMode === TasksCardDisplayMode.TAB;

  const cardStyles: React.CSSProperties = {
    ...(isTab ? { boxShadow: "none", minHeight: "60vh" } : {}),
  };

  return (
    <Card className={"h-100"} style={cardStyles}>
      {!isTab ? (
        <CardHeader
          className={"pt-4"}
          title={
            <div className="d-flex">
              <span>
                {intl.formatMessage({
                  id: "TASK.TITLE.DASHBOARD",
                })}
              </span>
              {isTasksLoading ? (
                <div className="d-flex align-items-center">
                  <span>({tasksLength}+)</span>
                  <div className="spinner spinner-track spinner-info spinner-md h-20px" />
                </div>
              ) : (
                <span>({tasksLength})</span>
              )}
            </div>
          }
        >
          <CardHeaderToolbar className={"w-100 pb-3 pt-0"}>
            <div className={"d-md-flex w-100 justify-content-md-between"}>
              <div
                className={"d-md-flex flex-wrap flex-md-shrink-1 flex-md-basis-0"}
                style={{ flexGrow: "6" }}
              >
                {!relatedToOptionsLoading && <SearchLocal useEntityUIContext={useTasksUIContext} />}
                {relatedToOptionsLoading && (
                  <div className="w-100">
                    <ContentLoader width={"100%"} height={40}>
                      <rect x="0" y="10" rx="5" ry="5" width="40%" height="26" />
                      <rect x="41%" y="10" rx="5" ry="5" width="22%" height="26" />
                      <rect x="64%" y="10" rx="5" ry="5" width="22%" height="26" />
                      <rect x="87%" y="10" rx="5" ry="5" width="10%" height="26" />
                    </ContentLoader>
                  </div>
                )}
              </div>
              <div className="d-flex flex-wrap align-self-end flex-md-grow-1 flex-md-shrink-1">
                <div className={"d-flex flex-wrap align-items-end flex-grow-1 mr-md-2 mr-0 mt-2"}>
                  <Sorter
                    disabled={contentView === TasksContentViewMode.GANTT_CHART}
                    selectedSort={selectedSort}
                    setSelectedSort={setSelectedSort}
                    option={TASK_SORTER_OPTION}
                    style={{ width: "100%", maxWidth: "100%" }}
                    parentStyle={{ width: "100%", maxWidth: "100%", flexGrow: "1 !important" }}
                    containerStyle={{ width: "100%" }}
                  />
                </div>

                <TasksSwitchContentView
                  {...{
                    mode: contentView,
                    onChange: toggleContentView,
                    className: "w-sm-100 mr-md-2 mr-0 mt-xs-0 mt-2 min-h-40px",
                  }}
                />

                {canCreate(groups, session, "TASK") && canReadAll(groups, session, "TASK") && (
                  <div className={"align-self-end mt-2 w-sm-100"}>
                    <button
                      type="button"
                      data-cy="button-tasks-new"
                      className="btn btn-primary text-nowrap w-100 w-md-auto min-h-40px"
                      onClick={newTaskButtonClick}
                    >
                      <FormattedMessage id="TASK.ACTION.NEW" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
      ) : (
        <div className={"d-md-flex w-100 justify-content-md-between mb-7 pl-2"}>
          <div
            className={"d-md-flex flex-wrap flex-md-shrink-1 flex-md-basis-0"}
            style={{ flexGrow: "6" }}
          >
            {!relatedToOptionsLoading && <SearchLocal useEntityUIContext={useTasksUIContext} />}
            {relatedToOptionsLoading && (
              <div className="w-100">
                <ContentLoader width={"100%"} height={40}>
                  <rect x="0" y="10" rx="5" ry="5" width="40%" height="26" />
                  <rect x="41%" y="10" rx="5" ry="5" width="22%" height="26" />
                  <rect x="64%" y="10" rx="5" ry="5" width="22%" height="26" />
                  <rect x="87%" y="10" rx="5" ry="5" width="10%" height="26" />
                </ContentLoader>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={"position-relative flex-grow-1"}>
        <CardBody
          className={cn("w-100 h-100 position-absolute d-flex flex-column", {
            "p-0": isTab,
          })}
        >
          <TasksCardContent
            {...{
              isTab,
              contentMode: contentView,
              selectedSort: isTab ? externalSelectedSort : selectedSort,
            }}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default TasksCard;
