import React from "react";
import Dropzone from "react-dropzone";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { getIconFilePath, mapMimeTypeFilename } from "../_utils/fileUtils";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import cn from "clsx";
import { useField } from "formik";

const isDragEvent = (event: any): event is React.DragEvent<HTMLDivElement> =>
  event?.hasOwnProperty("dataTransfer");
const isChangeEvent = (event: any): event is React.ChangeEvent<HTMLInputElement> =>
  event?.hasOwnProperty("target");

export interface FileDropzoneProps {
  values: any;
  setFieldValue: any;
  textField?: string;
  inputName?: string;
  acceptedTypes?: string[];
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  values,
  setFieldValue,
  textField = "",
  inputName = "file",
  acceptedTypes,
}) => {
  const fileChanged = async (
    e: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    let file;
    if (isDragEvent(e)) {
      file = e.dataTransfer?.files?.[0];
    } else if (isChangeEvent(e)) {
      file = e.target?.files?.[0];
    }
    if (file) {
      if (!values.friendlyName && file.name && values.friendlyName !== file.name) {
        await setFieldValue(
          "friendlyName",
          file.name.substring(0, file.name.lastIndexOf(".") || file.name.length)
        );
      }
      await setFieldValue(inputName, file);
    }
  };

  const removeFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFieldValue(inputName, null);
  };

  const [field, meta] = useField(inputName);

  return (
    <Dropzone accept={acceptedTypes}>
      {({ getRootProps, getInputProps, isDragReject, fileRejections }) => (
        <div
          {...getRootProps({
            onDrop: (e) => {
              if (!isDragReject) {
                fileChanged(e);
              }
            },
          })}
          className={cn(
            "upload-file-container",
            ((meta.touched && meta.error) || isDragReject) && "upload-file-container--error"
          )}
          data-cy="drag-n-drop-zone"
        >
          <input
            name={inputName}
            data-cy={`${inputName}-input`}
            {...getInputProps({ onChange: fileChanged })}
          />
          {field.value ? (
            <div className={"d-flex align-items-center"}>
              <span className="svg-icon svg-icon-3x mr-4">
                <SVG src={getIconFilePath(field.value.type)} />
              </span>
              <div className={"font-weight-bold"}>{field.value.name}</div>
              <button
                type="button"
                onClick={removeFile}
                className="btn btn-icon btn-text-danger btn-light-danger btn-hover-danger btn-sm ml-10"
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                </span>
              </button>
            </div>
          ) : (
            <>
              {!!textField && (
                <div className="font-weight-bold">
                  <FormattedMessage id={textField} />
                </div>
              )}
              <div>
                <FormattedMessage id="FILE.ACTION.DRAG_AND_DROP" />
              </div>
              <div>
                <i>
                  <FormattedMessage id="COMMON.OR" />
                </i>
              </div>
            </>
          )}
          <div>
            <button type="button" className="btn btn-outline-primary">
              <FormattedMessage id="FILE.ACTION.BROWSE" />
            </button>
          </div>
          {!!acceptedTypes && (
            <div className={cn("font-size-sm", isDragReject ? "text-danger" : "text-dark-50")}>
              <FormattedMessage
                id={"COMMON.ALLOWED_TYPES"}
                values={{
                  types: acceptedTypes?.map((type) => `*.${mapMimeTypeFilename[type]}`).join(", "),
                }}
              />
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};
