import { IHeaderOverride } from "@cyntler/react-doc-viewer";
import { useFileViewerDrawer } from "../../../hooks/useFileViewerDrawer";
import React from "react";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useTranslation } from "../../../hooks/useTranslation";
import { formatFileFriendlyNameWithLang, handleDownloadKycFile } from "../KycFileUtils";
import { KycMaximizeWindowButton } from "./KycMaximizeWindowButton";

export const KycFileViewerDrawerHeader: IHeaderOverride = (
  state,
  previousDocument,
  nextDocument
) => {
  const { currentDrawerFiles, closeDrawer, setIsMaximised, isMaximised } = useFileViewerDrawer();
  const { fm } = useTranslation();

  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return (
    <div className="row p-5">
      <div className="col-3 text-center" />
      <div className="col-6 text-center">
        <div className="d-flex justify-content-center align-items-center">
          {state.documents?.length > 1 && (
            <Button
              size="sm"
              className="btn btn-icon btn-light-secondary"
              onClick={previousDocument}
              disabled={state.currentFileNo === 0}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
              </span>
            </Button>
          )}
          <h5 className="mx-2">
            {formatFileFriendlyNameWithLang(currentDrawerFiles?.[state.currentFileNo])}
          </h5>
          {state.documents?.length > 1 && (
            <Button
              size="sm"
              className="btn btn-icon btn-light-secondary"
              onClick={nextDocument}
              disabled={state.currentFileNo >= state.documents.length - 1}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className="col-3 text-right">
        <div>
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
            onClick={(e: any) =>
              handleDownloadKycFile(e, currentDrawerFiles?.[state.currentFileNo])
            }
          >
            <span className="svg-icon svg-icon-sm svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
            </span>
          </button>
          <KycMaximizeWindowButton
            size="sm"
            isExpanded={isMaximised}
            setIsExpanded={setIsMaximised}
          />
          <Button size="sm" className="btn btn-light-secondary ml-2" onClick={() => closeDrawer()}>
            {fm("COMMON.CLOSE")}
          </Button>
        </div>
      </div>
    </div>
  );
};
