export enum SignatureTextDefaultSize {
  SIGNATURE = 20,
  INFO = 8,
}

export interface ISignatureTextSize {
  /** signature default font-size is 20 */
  signature?: number;

  /** info default font-size is 8 */
  info?: number;
}
