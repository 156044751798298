import React, { useEffect, useMemo } from "react";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { defaultBootstrapTableOptions, KYC_FILE_EXPLORER_TEXT_SEARCH_FILTER } from "../definitions";
import { KycTranslation } from "../../../KycTranslation";
import cn from "clsx";
import "./KycSummaryTable.scss";
import { useSearch } from "../../../../../hooks/useSearch";
import { KycComplianceDetailsSection } from "../KycComplianceDetailsSection";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import objectPath from "object-path";
import { isEmpty } from "lodash-es";

interface KycFileExplorerProps {
  selectedEntity: { id: string; searchText: string };
  setSelectedEntity: Function;
  kycEntities: any[];
  setKycEntities: Function;
}

export const KycSummary = ({
  selectedEntity,
  setSelectedEntity,
  kycEntities,
}: KycFileExplorerProps): JSX.Element => {
  const { fm } = useTranslation();
  const { setFilters } = useSearch();

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
    };
  }, [uiService]);

  useEffect(() => {
    setFilters([{ ...KYC_FILE_EXPLORER_TEXT_SEARCH_FILTER, selected: selectedEntity?.searchText }]);
  }, [selectedEntity]);

  const columnParentItem = [
    {
      dataField: "text",
      headerClasses: "text-left",
      headerStyle: { width: "5%" },
      classes: "text-left",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any) => (
        <div className="d-flex justify-content-start ">
          <div className=" d-flex align-items-end">
            <span
              className={cn(
                "label label-lg label-inline ml-2 my-auto",
                !isEmpty(row?.missing) ? "label-light-warning" : "label-light-success"
              )}
            >
              {cell}
            </span>
          </div>
        </div>
      ),
    },

    {
      dataField: "name",
      text: fm("COMMON.NAME"),
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
      headerStyle: { width: 250 },
      headerClasses: "text-left",
    },
    {
      dataField: "entityName",
      headerClasses: "text-left",
      headerStyle: { minWidth: 80 },
      classes: "text-left",
      text: fm(KycTranslation.COLUMN_NAMES_ENTITY),
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow: SelectRowProps<any> = {
    mode: "radio",
    style: { backgroundColor: layoutProps.colorsThemeBasePrimary },
    selected: selectedEntity?.id !== "" ? [selectedEntity?.id] : [],
    hideSelectColumn: true,
  };
  return (
    <>
      <KycComplianceDetailsSection title={KycTranslation.EVIDENCE_TITLE}>
        <BootstrapTable
          {...defaultBootstrapTableOptions}
          keyField="id"
          data={kycEntities}
          columns={columnParentItem}
          noDataIndication={() => (
            <NoRecordsFoundMessage
              entities={kycEntities}
              messageId={KycTranslation.NO_FILES_AVAILABLE}
            />
          )}
          selectRow={selectRow}
          rowEvents={{
            onClick: (e, row) => {
              setSelectedEntity(
                row?.name === selectedEntity?.searchText
                  ? { id: "", searchText: "" }
                  : { id: row?.id, searchText: row?.name }
              );
            },
          }}
        >
          <PleaseWaitMessage entities={kycEntities} />
        </BootstrapTable>
      </KycComplianceDetailsSection>
    </>
  );
};
