import React from "react";
import SVG from "react-inlinesvg";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  getFileInFilesByBucketFileId,
  handleDownloadKycFile,
  isPreviewPossible,
} from "../../../KycFileUtils";
import { KycBoTableMode, KycFile, KycSignatory } from "../../../Kyc";
import { OverlayTrigger, RowProps, Tooltip } from "react-bootstrap";
import { KycTranslation } from "../../../KycTranslation";

interface FormatExtraDataProps {
  setCurrentDrawerFiles: Function;
  deleteFile: Function;
  files: KycFile[];
  mode?: KycBoTableMode;
  setCurrentKycFlow: Function;
  signatory: KycSignatory;
  updateSignatoryBoDeclarationDownloadAt: Function;
  fm: Function;
}

export const KycBoDeclarationSingleDownloadColumnFormatter: ColumnFormatter<
  RowProps,
  FormatExtraDataProps
> = (cell, row, rowIndex, formatExtraData) => {
  let filesToDisplay: any[] = [
    formatExtraData && getFileInFilesByBucketFileId(formatExtraData?.files, cell?.fileId),
  ];

  const openFileInDrawer = (e: any) => {
    e.preventDefault();

    if (formatExtraData?.setCurrentDrawerFiles) {
      formatExtraData.setCurrentDrawerFiles(filesToDisplay);
    }
  };

  const handleDownloadFile = (e: any) => {
    handleDownloadKycFile(e, filesToDisplay?.[0]);
    formatExtraData?.updateSignatoryBoDeclarationDownloadAt({
      signatoryId: (row as KycSignatory)?.id,
      downloadedAt: new Date().toISOString(),
    });
  };

  return (
    <div className="d-flex justify-content-end align-items-right">
      {cell?.isOfflineSignatory && !cell?.downloadedAt && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={"tooltip-"} className="kyc-tooltip">
              {formatExtraData?.fm(KycTranslation.BO_DECLARATION_DOWNLOAD_REQUIRED_INFO)}
            </Tooltip>
          }
        >
          <div className="ml-2">
            {formatExtraData?.mode === KycBoTableMode.COMPLIANCE && (
              <span className="svg-icon svg-icon-2x svg-icon-warning mr-2">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
              </span>
            )}
          </div>
        </OverlayTrigger>
      )}
      {/*SINGLE FILE DOWNLOAD BUTTON */}
      {filesToDisplay?.length === 1 && (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={handleDownloadFile}
        >
          <span className="svg-icon svg-icon-sm svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
          </span>
        </button>
      )}

      {/*VIEW FILE BUTTON */}
      {isPreviewPossible(filesToDisplay) && (
        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1 my-auto"
          type="button"
          onClick={openFileInDrawer}
        >
          <span className="svg-icon svg-icon-lg svg-icon-info">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
          </span>
        </button>
      )}
    </div>
  );
};
