import React, { useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import { SignatureInformationModal } from "./SignatureInformationModal";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { OtpModalSignature } from "./OtpModalSignature";
import { downloadFileBase64 } from "../../UsersManagement/_redux/usersCrud";
import { CardPDFSignedViewer } from "../../../_components/CardPDFSignedViewer";

export function PdfViewerPageSignature({ fileUrl, signature, setFileBase64 }) {
  const [signatureInfo, setSignatureInfo] = useState(signature);
  const [modalState, setModalState] = useState("");

  useEffect(() => {
    if (signatureInfo?.id && !signatureInfo?.signedDate) {
      API.get("API", `/signatures/${signatureInfo.id}`, {}).then((response) => {
        setSignatureInfo({ ...signature, ...response });
        downloadFileBase64(response?.fileUrl).then((res) => setFileBase64(res));
      });
    }
  }, [signature]);

  const validateSignatureInfoModal = (requestOtp = true) => {
    setModalState("OTP");
    requestOtp && resendCode();
  };

  const resendCode = () => {
    console.log("resendCode");
    API.post("API", `/signatures/${signatureInfo.id}/otp`, {}).then((response) =>
      console.log(response)
    );
  };

  return (
    <>
      {signature.id && (
        <>
          <SignatureInformationModal
            show={modalState === "INFO"}
            onHide={() => setModalState("")}
            validateSignatureModal={validateSignatureInfoModal}
          />

          <OtpModalSignature
            show={modalState === "OTP"}
            onHide={() => setModalState("")}
            resendCode={resendCode}
            id={signature?.id}
            setFileBase64={setFileBase64}
          />
        </>
      )}
      <CardPDFSignedViewer fileUrl={fileUrl} signatureInfo={signatureInfo} />
    </>
  );
}
