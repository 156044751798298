import { Row } from "@tanstack/react-table";
import { Accordion } from "react-bootstrap";
import cn from "clsx";
import * as React from "react";
import { getRowTheme, TRowTheme } from "../CardTableThemes";
import { CellRender } from "./CellRender";
import { CardTableItem } from "../context/CardTableContext";

export interface RowRenderProps<T> {
  row: Row<T>;
  rowTheme?: TRowTheme;
}

export const RowRender = <T extends CardTableItem>({ row, rowTheme }: RowRenderProps<T>) => (
  <Accordion
    onSelect={() => row.toggleExpanded()}
    className={cn(!row.original.draggable && "drag-disable")}
    activeKey={row.getIsExpanded() ? "0" : undefined}
  >
    <Accordion.Toggle
      eventKey={"0"}
      as="div"
      className={cn("hoverable border-bottom d-flex", getRowTheme(rowTheme, row.depth))}
    >
      {row.getVisibleCells().map((cell, index) => (
        <CellRender cell={cell} rowTheme={rowTheme} index={index} key={cell.id} />
      ))}
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={"0"} className="position-relative">
      <div>
        {row.subRows?.map((subRow) => (
          <RowRender row={subRow} rowTheme={subRow.original.rowTheme ?? rowTheme} key={subRow.id} />
        ))}
      </div>
    </Accordion.Collapse>
  </Accordion>
);
