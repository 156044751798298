import * as React from "react";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";
import { sumBy } from "lodash-es";

export const ExpectedTotalRevenue: React.FC<HeaderCardProps> = (props) => {
  const { soldTotal, unsoldTotal, supplementRevenue } = useBudgetRevenueContext();

  const supplementsTotal = sumBy(supplementRevenue, "sold");
  const total = soldTotal + unsoldTotal + supplementsTotal;
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-revenues-expected-total"
          value={total}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={0}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REVENUE.EXPECTED_TOTAL" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-total-sold-units"
        label="BUDGET.REVENUE.SOLD_UNITS"
        value={soldTotal}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-total-unsold-units"
        label="BUDGET.REVENUE.UNSOLD_UNITS"
        value={unsoldTotal}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-total-supplements"
        label="BUDGET.REVENUE.SUPPLEMENTS"
        value={supplementsTotal}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
      />
    </HeaderCard>
  );
};
