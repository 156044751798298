// the code data logic of the modal is centralized here

import * as React from "react";
import { useIntl } from "react-intl";
import { useDidUpdate } from "rooks";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  NotificationDestination,
  IRequestSignatory,
  IFile,
  IUserOption,
  UserType,
} from "data/schemas";

import { formatDisplayNameIntl } from "app/_utils/userUtils";

//----------------------------------------------------------------------------//

export interface IRequestSignatureDialogContext {
  isLoading: boolean;

  isSendButtonDisabled: boolean;
  shouldShowDialog: boolean;

  selectableUsers: IUserOption[];

  /** list of persons to sign the requested document */
  signatories: IRequestSignatory[];

  closeDialog: () => void;

  addSignatory: (user: IUserOption) => void;
  addSignatories: (users: IUserOption[]) => void;

  updateSignatory: (signatory: IRequestSignatory) => void;
  removeSignatory: (signatory: IRequestSignatory) => void;

  sendEmail: boolean;
  setSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  sendSms: boolean;
  setSendSms: React.Dispatch<React.SetStateAction<boolean>>;

  submit: () => void;
}

const RequestSignatureDialogContext = React.createContext<
  IRequestSignatureDialogContext | undefined
>(undefined);

export const useRequestSignatureDialogContext = () => {
  const context = React.useContext(RequestSignatureDialogContext);

  if (context === undefined) {
    throw new Error(
      "useRequestSignatureDialogContext must be used within a RequestSignatureDialogProvider"
    );
  }

  return context;
};

export interface RequestSignatureDialogProviderProps {
  fileId?: string;
  file?: IFile;
  showDialog?: boolean;
  closeDialog: () => void;
  requestFileSignature: Function;
}

export const RequestSignatureDialogProvider: React.FunctionComponent<RequestSignatureDialogProviderProps> =
  ({ children, fileId, file, showDialog = false, closeDialog, requestFileSignature }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [availableUsers, setAvailableUsers] = React.useState<IUserOption[]>([]);
    const [selectableUsers, setSelectableUsers] = React.useState<IUserOption[]>([]);
    const [signatories, setSignatories] = React.useState<IRequestSignatory[]>([]);
    const [sendEmail, setSendEmail] = React.useState(true);
    const [sendSms, setSendSms] = React.useState(false);

    const [isSubmitting, setSubmitting] = React.useState(false);
    const [isSendButtonDisabled, setSendButtonDisabled] = React.useState(false);

    //------------------------------------------------------------------------//

    const { actionsLoading: isLoading, entities: users } = useSelector(
      (state: any) => state.users,
      shallowEqual
    );

    React.useEffect(() => {
      if (!showDialog) {
        setSignatories([]);
        return;
      }

      setAvailableUsers(
        (users as IUserOption[]).map((user) => ({
          ...user,
          displayName: formatDisplayNameIntl(intl, user, false),
          isExternal: user.userTypes.includes(UserType.EXTERNAL) ?? false,

          uuid: crypto.randomUUID(),
        }))
      );
    }, [showDialog, users]);

    useDidUpdate(() => {
      const isSignatoriesEmpty = signatories.length === 0;
      const isAnyInvalid =
        signatories.filter((signatory) => signatory.isValid === false).length > 0;

      setSendButtonDisabled(isSignatoriesEmpty || isAnyInvalid);

      if (isSignatoriesEmpty) {
        setSelectableUsers([...availableUsers]);
        return;
      }

      const ignoreIds = signatories.reduce((acc, { id }) => {
        if (id) {
          acc.push(id);
        }
        return acc;
      }, [] as string[]);

      if (ignoreIds.length > 0) {
        setSelectableUsers(availableUsers.filter(({ id = "" }) => !ignoreIds.includes(id)));
      }
    }, [availableUsers, signatories]);

    //------------------------------------------------------------------------//

    const addSignatory = (user: IUserOption) => {
      const {
        id,
        email,
        mobile,
        displayName: fullName,
        profilePictureUrl,
        uuid = crypto.randomUUID(),
      } = user;

      const names = fullName.split(" ");
      const namesLength = names.length;

      const firstName = user.firstName || names[0] || "U";
      const lastName = namesLength > 1 ? names[names.length - 1] || "" : "";

      const avatar =
        profilePictureUrl ||
        `${firstName[0].toUpperCase()}${lastName ? lastName[0].toUpperCase() : ""}`;

      const isValid = !!user.email;

      const newSignatory: IRequestSignatory = {
        id,
        avatar,
        email,
        mobile,
        fullName,

        isValid,
        signatoryHelperId: uuid,
      };

      setSignatories((previous) => [...previous, newSignatory]);
    };

    const addSignatories = (users: IUserOption[]) => users?.forEach(addSignatory);

    const updateSignatory = (signatory: IRequestSignatory) => {
      const { signatoryHelperId, fullName, email, mobile, isValid } = signatory;

      setSignatories((signatories) =>
        signatories.map((previousSignatory) => {
          if (previousSignatory.signatoryHelperId === signatoryHelperId) {
            const names = fullName.split(" ");
            const namesLength = names.length;

            const firstName = names[0] || "U";
            const lastName = namesLength > 1 ? names[names.length - 1] || "" : "";

            const avatar = `${firstName[0].toUpperCase()}${
              lastName ? lastName[0].toUpperCase() : ""
            }`;

            return {
              ...previousSignatory,
              avatar,
              fullName,
              email,
              mobile,
              isValid,
            };
          }
          return previousSignatory;
        })
      );
    };

    const removeSignatory = (signatory: IRequestSignatory) => {
      const filterByKey = signatory.signatoryHelperId;
      setSignatories((previous) =>
        previous.filter((signatory) => signatory.signatoryHelperId !== filterByKey)
      );
    };

    const submit = React.useCallback(() => {
      const notifications = [];
      sendEmail && notifications.push(NotificationDestination.EMAIL);
      sendSms && notifications.push(NotificationDestination.SMS);

      const body = {
        file,
        fileId,
        signatories,
        notifications,
      };

      setSubmitting(true);
      dispatch(requestFileSignature(body))
        .then(() => closeDialog())
        .finally(() => setSubmitting(false));
    }, [sendEmail, sendSms, signatories, fileId, file, requestFileSignature]);

    //------------------------------------------------------------------------//

    const contextValue: IRequestSignatureDialogContext = {
      isLoading: isLoading || isSubmitting,

      isSendButtonDisabled:
        isSubmitting || isSendButtonDisabled || (!isSendButtonDisabled && !sendEmail && !sendSms),
      shouldShowDialog: showDialog,

      selectableUsers,
      signatories,

      closeDialog,

      addSignatory,
      addSignatories,

      updateSignatory,
      removeSignatory,

      sendEmail,
      setSendEmail,
      sendSms,
      setSendSms,

      submit,
    };

    return (
      <RequestSignatureDialogContext.Provider value={contextValue}>
        {children}
      </RequestSignatureDialogContext.Provider>
    );
  };

export default RequestSignatureDialogProvider;
