import * as React from "react";
import { canSeeHiddenLine } from "../../../../../_utils/authUtils";
import { sumBy } from "lodash-es";
import { IBudget } from "../../../../../../data/schemas";
import { useAppSelector } from "../../../../../../redux/hooks";
import { shallowEqual } from "react-redux";
import {
  ActualInvoicedBudgetCard,
  InvoicedOrdersCard,
  OrdersCard,
  PlannedBudgetCard,
  RealBudgetCard,
  RemainingToInvoiceBudgetCard,
  SupplementaryAgreementCard,
} from "./cards";
import { InvoicedSupplementaryAgreementsCard } from "./cards/InvoicedSupplementaryAgreementsCard";

export interface BudgetExpensesHeaderProps {
  budget: IBudget;
}

export const BudgetExpensesHeader: React.FC<BudgetExpensesHeaderProps> = ({ budget }) => {
  const { project, session, groups } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.current,
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );
  const [plannedTotal, setPlannedTotal] = React.useState(0);
  const [publicPlannedTotal, setPublicPlannedTotal] = React.useState(0);
  const [hiddenPlannedTotal, setHiddenPlannedTotal] = React.useState<number>();

  React.useEffect(() => {
    let total = 0;
    let publicTotal = 0;
    let hiddenTotal = 0;
    for (const category of budget.sortedLines ?? []) {
      if (category.visible) {
        publicTotal += sumBy(category.lines, "plannedBudget");
      } else {
        hiddenTotal += sumBy(category.lines, "plannedBudget");
      }
      total += sumBy(category.lines, "plannedBudget");
    }
    setPlannedTotal(total);
    if (canSeeHiddenLine(groups, session)) {
      setPublicPlannedTotal(publicTotal);
      setHiddenPlannedTotal(hiddenTotal);
    }
  }, [budget, groups, session]);

  const totalKey = canSeeHiddenLine(groups, session) ? "general" : "public";
  const actualBudget = project?.subcontractorsFinanceFiles
    ? project.subcontractorsFinanceFiles.orders.total[totalKey] +
      project.subcontractorsFinanceFiles.supplementary_agreements.total[totalKey]
    : 0;

  return (
    <div className={"row"}>
      <PlannedBudgetCard
        canSeeHiddenLine={canSeeHiddenLine(groups, session)}
        plannedTotal={plannedTotal}
        hiddenPlannedTotal={hiddenPlannedTotal}
        publicPlannedTotal={publicPlannedTotal}
        className={"gutter-b"}
      />
      {budget.id && project?.selectedBudget === budget.id && (
        <>
          <RealBudgetCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            actualBudget={actualBudget}
            project={project}
            className={"gutter-b"}
          />
          <ActualInvoicedBudgetCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            actualBudget={actualBudget}
            project={project}
            className={"gutter-b"}
          />
          <RemainingToInvoiceBudgetCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            actualBudget={actualBudget}
            project={project}
            className={"gutter-b"}
          />
          <OrdersCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            actualBudget={actualBudget}
            project={project}
            className={"gutter-b"}
          />
          <SupplementaryAgreementCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            actualBudget={actualBudget}
            project={project}
            className={"gutter-b"}
          />
          <InvoicedOrdersCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            project={project}
            className={"gutter-b"}
          />
          <InvoicedSupplementaryAgreementsCard
            canSeeHiddenLine={canSeeHiddenLine(groups, session)}
            project={project}
            className={"gutter-b"}
          />
        </>
      )}
    </div>
  );
};
