import * as React from "react";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useWizard } from "../../../../../hooks/useWizard";
import { findAndDeleteFile, isFileUploaded } from "../../../KycFileUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { difference, isEmpty } from "lodash-es";
import { KycFile } from "../../../Kyc";

type KycUploadComponentProps = {
  disabled?: boolean;
  file?: KycFile;
};

export const KycUploadTrashButton = ({
  disabled = false,
  file,
}: KycUploadComponentProps): JSX.Element => {
  const { currentFiles, setCurrentFiles, deleteFile } = useKycFile();
  const { isReadOnly } = useWizard();

  const handleDeleteFile = (e: any, file: any) => {
    e.preventDefault();
    if (file) {
      if (file.createdAt) {
        deleteFile(file);
      } else {
        const updatedFiles = findAndDeleteFile(currentFiles, file);
        if (!isEmpty(difference(currentFiles, updatedFiles))) {
          setCurrentFiles(updatedFiles);
        }
      }
    }
  };

  return (
    <>
      {((file && isFileUploaded(file)) || file?.option) && !disabled && !isReadOnly && (
        <div className="px-0">
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-md ml-1 form-control-reduce-space h-100"
            onClick={(e: any) => handleDeleteFile(e, file)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </div>
      )}
    </>
  );
};
