import * as React from "react";
import { canEdit } from "../../../../_utils/authUtils";
import { BudgetLineContent } from "./BudgetLineContent";
import { Field, FieldArrayRenderProps, FormikHandlers, useFormikContext } from "formik";
import { NumberInput } from "../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../_utils/suffixUtils";
import { NumberFormatValues } from "react-number-format";
import cn from "clsx";
import { CustomCardLine } from "../../../../_components/CustomCard";
import { cloneDeep, isUndefined } from "lodash-es";
import { IBudget, IBudgetLine, IProject, TFormattedBudgetLine } from "../../../../../data/schemas";
import { useAppSelector } from "../../../../../redux/hooks";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { useCustomLocationState } from "../../../../_utils/useCustomLocationState";
import { Input } from "../../../../../_metronic/_partials/controls";
import { DropdownItem } from "../../../../_components/actions-menu/DropdownItem";
import { Accordion } from "react-bootstrap";
import { useDidUpdate } from "rooks";
import { BudgetSubcontractorLine } from "./BudgetSubcontractorLine";
import { useConfirmationDialogContext } from "../../../../_components/ConfirmationDialog";
import { useIntl } from "react-intl";

export interface BudgetLineProps {
  line?: IBudgetLine;
  budget: IBudget;
  project?: Partial<IProject>;
  categoryId: string;
  displayedDataLine: TFormattedBudgetLine;
  saveBudgetFields?: (key: string | string[], value: any) => void;
  categoryIndex?: number;
  lineIndex: number;
  lineArrayHelpers: FieldArrayRenderProps;
  disabled: boolean;
}

export const BudgetLine: React.FC<BudgetLineProps> = ({
  line,
  displayedDataLine,
  budget,
  project,
  saveBudgetFields,
  categoryIndex,
  lineIndex,
  lineArrayHelpers,
  disabled,
  categoryId,
}) => {
  const { columnWidth, triggerExpandCategories, triggerCollapseCategories } = useBudgetsUIContext();
  const { setFromUrlTo } = useCustomLocationState();
  const { setConfirmationDialog } = useConfirmationDialogContext();
  const intl = useIntl();

  const { groups, session } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  const { handleChange, values, setFieldValue } = useFormikContext<IBudget>();

  const displayLineFiles = (line: any) => {
    setFromUrlTo({ url: `/budgets/${budget.id}/${categoryId}/${line.id}`, name: budget.name });
  };

  const handleLineNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    lineIndex: number,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines) ?? [];
      res[categoryIndex].lines[lineIndex].label = e.target.value;
      saveBudgetFields("sortedLines", res);
      handleChange(e);
    }
  };

  const handleLineAmountChange = (value: NumberFormatValues, lineIndex: number) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].lines[lineIndex]["plannedBudget"] = value.floatValue || 0;
      saveBudgetFields("sortedLines", res);
      setFieldValue("sortedLines", res);
    }
  };

  const removeLine = (
    e: React.MouseEvent<HTMLButtonElement>,
    lineArrayHelpers: FieldArrayRenderProps,
    lineIndex: number
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      e.preventDefault();
      lineArrayHelpers.remove(lineIndex);
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].lines.splice(lineIndex, 1);
      saveBudgetFields("sortedLines", res);
    }
  };

  let lineBudgetDifference = 0;
  if (line) {
    const sffOrderLine = Object.values(
      project?.subcontractorsFinanceFiles?.orders?.data?.[categoryId]?.lines ?? {}
    )?.find((l) => l.budgetLineId === line.id);
    lineBudgetDifference = (sffOrderLine?.amount ?? 0) - line.plannedBudget;
  }

  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleRef = React.useRef<HTMLDivElement>(null);
  useDidUpdate(() => {
    if (toggleRef.current && !isExpanded) {
      toggleRef.current.click();
    }
  }, [triggerExpandCategories]);

  useDidUpdate(() => {
    if (toggleRef.current && isExpanded) {
      toggleRef.current.click();
    }
  }, [triggerCollapseCategories]);

  const onClickRemoveLine = React.useMemo(() => {
    if (canEdit(groups, session, "BUDGET")) {
      if (displayedDataLine?.subcontractors?.length) {
        return () =>
          setConfirmationDialog({
            title: intl.formatMessage({ id: "BUDGET.LINE.DELETE" }),
            body: intl.formatMessage({
              id: "BUDGET.REMOVE_LINE_WARNING",
            }),
            hideButtonLabel: intl.formatMessage({
              id: "COMMON.ACTION.OK",
            }),
            hideButtonClassName: "btn-primary",
          });
      } else {
        return (e: React.MouseEvent<HTMLButtonElement>) =>
          removeLine(e, lineArrayHelpers, lineIndex);
      }
    }
    return undefined;
  }, [groups, session, displayedDataLine]);

  return (
    <Accordion onSelect={() => setIsExpanded(!isExpanded)}>
      <Accordion.Toggle
        ref={toggleRef}
        eventKey={"0"}
        as={CustomCardLine}
        draggable={
          categoryId !== "unspecifiedCategory" && canEdit(groups, session, "BUDGET") && !!line
        }
        actions={
          budget.id === project?.selectedBudget && (
            <DropdownItem
              onClick={() => displayLineFiles(line ?? displayedDataLine)}
              icon={"/media/svg/icons/Files/Selected-file.svg"}
              title={"BUDGET.LINE.RELATED_FILES"}
            />
          )
        }
        removable={canEdit(groups, session, "BUDGET")}
        confirmRemove={!displayedDataLine?.subcontractors?.length}
        remove={onClickRemoveLine}
        customClasses={{
          container: cn(
            !isUndefined(categoryIndex) && values.sortedLines[categoryIndex].visible
              ? "bg-primary-o-15 bg-hover-primary-o-1"
              : "bg-info-o-15 bg-hover-info-o-1",
            !!displayedDataLine?.subcontractors?.length && "cursor-pointer"
          ),
        }}
      >
        {!!displayedDataLine?.subcontractors?.length ? (
          <button type="button" className="btn btn-icon btn-link-primary btn-xs" tabIndex={-1}>
            <i
              className="ki ki-arrow-down icon-nm"
              style={{
                transform: !isExpanded ? "rotate(-90deg)" : "",
                transition: "transform .2s ease",
              }}
            />
          </button>
        ) : (
          <div className={"btn btn-icon btn-xs"}></div>
        )}

        <BudgetLineContent
          columnWidth={columnWidth}
          selectedBudget={!!budget.id && budget.id === project?.selectedBudget}
          line={{
            ...displayedDataLine,
            label: line ? (
              <>
                <Field name={`sortedLines.${categoryIndex}.lines.${lineIndex}.id`} type="hidden" />
                <Field
                  name={`sortedLines.${categoryIndex}.lines.${lineIndex}.label`}
                  className="form-control-sm"
                  component={Input}
                  withFeedbackLabel={false}
                  onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleLineNameChange(e, lineIndex, handleChange)
                  }
                  disabled={disabled}
                />
              </>
            ) : (
              displayedDataLine.label
            ),
            planned: line ? (
              <NumberInput
                className="form-control form-control-sm text-right"
                value={line.plannedBudget}
                suffix={SUFFIX_EURO_CURRENCY}
                decimalScale={2}
                onValueChange={(e: NumberFormatValues) => handleLineAmountChange(e, lineIndex)}
                disabled={disabled}
                size={5}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
              />
            ) : (
              displayedDataLine.planned
            ),
            realDifference: (
              <span
                className={cn(
                  displayedDataLine?.realDifference !== "-" &&
                    (lineBudgetDifference <= 0 ? "text-success" : "text-danger")
                )}
              >
                {displayedDataLine?.realDifference}
              </span>
            ),
            realDifferencePercentage: (
              <span
                className={cn(
                  displayedDataLine?.realDifference !== "-" &&
                    (lineBudgetDifference <= 0 ? "text-success" : "text-danger")
                )}
              >
                {displayedDataLine?.realDifferencePercentage}
              </span>
            ),
            remainingToInvoice: (
              <span
                className={cn(
                  displayedDataLine?.remainingToInvoice !== "-" &&
                    displayedDataLine?.remainingToInvoice.startsWith("-") &&
                    "text-danger"
                )}
              >
                {displayedDataLine?.remainingToInvoice}
              </span>
            ),
          }}
        />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={"0"}>
        <div>
          {displayedDataLine?.subcontractors.map((subcontractorTotal: any, index: number) => (
            <BudgetSubcontractorLine
              budget={budget}
              displayedDataLine={displayedDataLine}
              subcontractorTotal={subcontractorTotal}
              project={project}
              categoryId={categoryId}
              key={index}
            />
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};
