import { Field } from "formik";
import { KycDropdownSelect } from "../../controls/kyc-dropdown-select/KycDropdownSelect";
import { KycChoiceOption, KycLoading } from "../../../Kyc";
import { KycTranslation } from "../../../KycTranslation";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useKycFlow } from "../../../../../hooks/useKycFlow";
import { useLoading } from "../../../../../hooks/useLoading";
import { useWebsocket } from "../../../../../hooks/useWebsocket";

export const KycPropDevCompanyChooser = (): JSX.Element => {
  const { isCurrentlyLoading, addLoading, removeLoading, loadings } = useLoading();
  const { values, setFlowValue } = useKycFlow();

  const handleChangeKycCreationCompany = (e: ChangeEvent<any>) => {
    setFlowValue("propDevCompany", e?.target?.value, true);
    addLoading(KycLoading.GENERATE_BO);
  };

  const [optionsKycCreationCompany, setOptionsKycCreationCompany] = useState<KycChoiceOption[]>([]);

  const mapCompany = (c: any) => {
    return { label: c?.companyName || c?.name, value: c };
  };

  useEffect(() => {
    setOptionsKycCreationCompany(
      values?.kycFlowCompanies?.map((kycFlowCompany: any) => mapCompany(kycFlowCompany))
    );
  }, [values?.kycFlowCompanies]);

  const { latestMessage } = useWebsocket();

  useEffect(() => {
    if (
      latestMessage?.type === "REFRESH_KYC_FLOW_BENEFICIAL_OWNERS" &&
      isCurrentlyLoading(KycLoading.GENERATE_BO)
    ) {
      removeLoading(KycLoading.GENERATE_BO);
    }
  }, [latestMessage]);

  useEffect(() => {
    if (isCurrentlyLoading(KycLoading.GENERATE_BO)) {
      removeLoading(KycLoading.GENERATE_BO);
    }
  }, [loadings]);

  return (
    <Field
      key={"select-kyc-creation-company-" + values?.form?.client?.lead?.id}
      component={KycDropdownSelect}
      name="propDevCompany.id"
      options={optionsKycCreationCompany}
      onChange={handleChangeKycCreationCompany}
      isLoading={isCurrentlyLoading(KycLoading.GENERATE_BO)}
      disabled={false}
      locked={false}
      label={KycTranslation.SELECT_PROPDEV_COMPANY}
      noEntriesLabel={KycTranslation.LEAD_NO_OPTION_ENTRIES}
    />
  );
};
