import * as React from "react";
import { ContextType, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";
import "./Arrows.scss";
import { Button } from "react-bootstrap";
import cn from "clsx";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useWizard } from "../../../../../hooks/useWizard";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>;

type KycHorizontalScrollMenuProps = {
  field: any;
  defaultTitle: string;
  setRepCarouselActiveIndex: any;
  repCarouselActiveIndex: number;
  disabled?: boolean;
  remove: any;
  items: any[];
};

export const KycHorizontalScrollMenu = ({
  field,
  defaultTitle,
  repCarouselActiveIndex,
  setRepCarouselActiveIndex,
  disabled,
  remove,
  items,
}: KycHorizontalScrollMenuProps): JSX.Element => {
  const { fm, fDisplayName } = useTranslation();
  const { isReadOnly } = useWizard();
  const [hovered, setHovered] = useState<number>(-1);

  const handleActiveIndex = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    setRepCarouselActiveIndex(index);
  };

  return (
    <>
      {items?.length > 0 && (
        <div className="mt-2 ml-4" style={{ paddingRight: 12 }}>
          <div>
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
              {items?.map((entity: any, index: number) => (
                <div
                  key={`${field.name}-header-scroll-item-${index}`}
                  role="button"
                  style={{
                    borderLeft: "white",
                    marginLeft: 4,
                    marginRight: 4,
                    display: "inline-block",
                    margin: "10px 10px",
                    minWidth: "160px",
                    userSelect: "none",
                  }}
                  tabIndex={0}
                  // className="card"
                >
                  <div
                    className={cn(
                      "mb-2",
                      "d-flex justify-content-between align-items-center",
                      "border border-top-white border-left-white border-right-white",
                      repCarouselActiveIndex === index
                        ? "border-bottom-primary text-primary"
                        : "border-light"
                    )}
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(-1)}
                  >
                    <div
                      data-cy={formatDataCy(
                        DataCyCompPrefix.BUTTON + "-header-menu-item",
                        field?.name,
                        index
                      )}
                      onClick={(e: React.MouseEvent<HTMLDivElement>) => handleActiveIndex(e, index)}
                    >
                      {fDisplayName(entity, true) || `${fm(defaultTitle)} ${index + 1}`}
                    </div>
                    {!disabled && !isReadOnly && (
                      <Button
                        className={cn(
                          "ml-5",
                          hovered === index ? "visible" : "invisible",
                          "border-0 bg-transparent user-select-none p-1"
                        )}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <i className="fas fa-times text-danger" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </ScrollMenu>
          </div>
        </div>
      )}
    </>
  );
};

const onWheel = (apiObj: ScrollVisibilityApiType, ev: React.WheelEvent): void => {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
};
