import * as React from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";

/*
  WARNING: the following component require a unique and sequential index
*/
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "@react-forked/dnd";

import objectPath from "object-path";
import TimeAgo from "react-timeago";
import { useDebounce, useDidMount, useOnWindowResize } from "rooks";
import cn from "clsx";

import { useHtmlClassService } from "_metronic/layout";

import { ITask, TaskTypes } from "data/schemas";

import { formatDisplayNameMeIntl } from "app/_utils/userUtils";
import { canDelete } from "app/_utils/authUtils";
import {
  getColorForTimeAgo,
  getColorForTimeAgoIcon,
  getDesiredDate,
  getTaskRelatedToAppearance,
  getTaskText,
  getTimeAgoCaption,
  isShowTimeAgo,
} from "app/_utils/tasks";

import { Icon, IconType } from "app/_components/Icon";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useTasksUIContext } from "app/modules/PropertiesManagement/pages/tasks/TasksUIContext";

import "./TaskItem.scss";
import { TooltipHandler } from "../../../../../../../../../_components/TooltipHandler";

const buildDataCypressId = (name: string) =>
  `task-item-${name
    ?.toLowerCase()
    .replaceAll(".", "")
    .replaceAll(" ", "-")
    .replaceAll("[", "")
    .replaceAll("]", "")
    .replaceAll("'", "")}`;

export interface TaskItemProps {
  item: ITask;
  itemIndex?: number;
  disableDrop?: boolean;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  style: any;
}

export const TaskItemContentInner: React.FunctionComponent<TaskItemProps> = ({
  item,
  disableDrop = false,
  provided,
  snapshot,
  style: styleProps,
}) => {
  const intl = useIntl();
  const uiService = useHtmlClassService();
  const [isResizing, setResizing] = React.useState(false);

  const placeholderDivRef = React.useRef<HTMLDivElement>(null);

  const { session, groups } = useSelector(
    (state: any) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const layoutProps = React.useMemo(() => {
    const { config } = uiService;
    return {
      colorsGrayGray700: objectPath.get(config, "js.colors.gray.gray700"),
      colorsThemeBasePrimary: objectPath.get(config, "js.colors.theme.base.primary"),
      colorsThemeBaseWarning: objectPath.get(config, "js.colors.theme.base.warning"),
      colorsThemeBaseSuccess: objectPath.get(config, "js.colors.theme.base.success"),
      colorsThemeBaseDanger: objectPath.get(config, "js.colors.theme.base.danger"),
      colorsThemeBaseInfo: objectPath.get(config, "js.colors.theme.base.info"),
    };
  }, [uiService]);

  const tasksUIContext = useTasksUIContext();

  const tasksUIProps = React.useMemo(() => {
    const { newTaskButtonClick, openEditTaskPage } = tasksUIContext;
    return {
      newTaskButtonClick,
      openEditTaskPage,
    };
  }, [tasksUIContext]);

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const onDeleteCardButtonClick =
    (taskToDelete: ITask) => (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDeleteEntityDialog({
        action: { fn: actions.deleteTask, props: taskToDelete.id },
        entityType:
          taskToDelete.subtasks && taskToDelete.subtasks.length > 0 ? "TASK_PARENT" : "TASK",
      });
    };
  const onCardClick = (cardId: string) => () => {
    const { openEditTaskPage } = tasksUIProps;
    if (!openEditTaskPage) return;
    openEditTaskPage(cardId);
  };

  const taskRelatedToAppearance = getTaskRelatedToAppearance(item?.relatedTo);

  const { itemId, isPlanned, cypressId, uniqueClassName, placeholderClassName } =
    React.useMemo(() => {
      const itemId = item.id as string;
      return {
        itemId,
        isPlanned: item.plannedStartDate && item.dueDate,
        cypressId: buildDataCypressId(item.name),
        uniqueClassName: `task-item__${itemId}`,
        placeholderClassName: `placeholder__task-item__${itemId}`,
      };
    }, [item]);

  const updatePlaceholderDimensions = React.useCallback(() => {
    setResizing(false);

    const taskItemEl = document.querySelector(`.${uniqueClassName}`);

    if (!taskItemEl) return;

    const taskItemStyles = window.getComputedStyle(taskItemEl);

    const shadowEl = document.querySelector(`.${placeholderClassName}`) as HTMLElement;

    if (!shadowEl) return;

    const { width, height } = taskItemStyles;
    shadowEl.style["minWidth"] = width;
    shadowEl.style["minHeight"] = height;
  }, [uniqueClassName, placeholderClassName]);

  const updatePlaceholderDimensionsDebounced = useDebounce(updatePlaceholderDimensions, 300);

  const resetPlaceholderDimensions = () => {
    const { current: shadowDiv } = placeholderDivRef;
    if (!shadowDiv) return;
    shadowDiv.style["minWidth"] = "unset";
    shadowDiv.style["minHeight"] = "unset";
  };

  useDidMount(() => {
    updatePlaceholderDimensions();
  });

  const resizeHandler = React.useCallback(() => {
    if (!isResizing) {
      setResizing(true);
      resetPlaceholderDimensions();
      return;
    }
    updatePlaceholderDimensionsDebounced();
  }, [isResizing, resetPlaceholderDimensions, updatePlaceholderDimensionsDebounced]);

  useOnWindowResize(resizeHandler);

  const getStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
  ): any => {
    if (!style) return {};

    if (!snapshot.isDragging) {
      return {
        ...style,
        transform: "none",
        transition: "var(--transitionTaskLaneItemOpacityValue)",
      };
    }

    if (!snapshot.isDropAnimating || !disableDrop) {
      return style;
    }

    return {
      ...style,
      transitionDuration: `0.001s`,
    };
  };

  const taskName = getTaskText(intl, item.name, item.attachments);

  return (
    <div
      id={`${cypressId}-${itemId}`}
      data-cy={cypressId}
      className={cn(
        "task-item",
        uniqueClassName,
        "d-flex bg-white border border-rounded user-select-none p-2",
        snapshot.isDragging ? "shadow-lg" : "shadow-sm"
      )}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle({ ...provided.draggableProps.style, ...styleProps }, snapshot)}
      onClick={onCardClick(itemId)}
    >
      {canDelete(groups, session, "TASK") && item?.taskType !== TaskTypes.FILLOUT_AML_KYC_FORM_V2 && (
        <button
          data-cy="button-delete-task-item"
          type="button"
          onClick={onDeleteCardButtonClick(item)}
          className="task-item-close-button border-0 bg-transparent user-select-none"
          aria-label="Close"
        >
          <i className="task-item-close-button-icon fas fa-times" />
        </button>
      )}

      <div data-cy="text-task-title" className="task-item-title user-select-none mb-0">
        <TooltipHandler
          id={`item-tooltip:task-id:${item.id}`}
          message={taskName}
          disabled={taskName.length < 32}
        >
          <div className="ellipsis">{taskName}</div>
        </TooltipHandler>
      </div>

      {!!item.assignee && formatDisplayNameMeIntl(intl, item.assignee, session?.id) && (
        <small data-cy="text-task-assignee" className="task-item-assignee user-select-none mt-2">
          {formatDisplayNameMeIntl(intl, item.assignee, session?.id)}
        </small>
      )}

      <div className="bottom-icons mt-3" key={itemId}>
        {isPlanned && <Icon name={IconType.GANTT_CHART} width={17} className="gantt-chart" />}

        {taskRelatedToAppearance?.icon && (
          <i
            style={{
              fontSize: 16,
              color: taskRelatedToAppearance?.color || "f2f2f2",
            }}
            className={`fas fa-${taskRelatedToAppearance?.icon} icon-md`}
          />
        )}

        {isShowTimeAgo(item) && (
          <div className="task-text-timeago" style={getColorForTimeAgo(item, layoutProps)}>
            <div className="task-text-timeago--icon">
              <i
                style={{
                  color: getColorForTimeAgoIcon(item, layoutProps),
                }}
                className="fas fa-clock icon-md"
              />
            </div>
            <div className="task-text-timeago--label">
              <TimeAgo
                data-cy="text-task-timeago"
                className="user-select-none"
                date={getDesiredDate(item)}
                formatter={(value, unit) => getTimeAgoCaption(item, value, unit, intl)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TaskItemContent = React.memo(TaskItemContentInner);

export default TaskItemContent;
