import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { Button } from "react-bootstrap";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycTranslation } from "../../KycTranslation";
import { KycFormTitle } from "../layout/KycFormTitle";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { KycNotificationWidget } from "../KycNotificationWidget";
import { KycTextarea } from "../controls/kyc-textarea/KycTextarea";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";

export const KycStepRejectAndRequestFormCompletion = (): JSX.Element => {
  const { fm } = useTranslation();
  const { customHandleChange, values } = useKycFlow();
  const isRefusedAndShouldBeResend = false;

  const { setShowModal, setConfig } = useKycConfirmation();

  const handleClick = (e: any, t: any) => {
    e.preventDefault();
    setConfig({
      type: t,
      kyc: values,
      kycId: values.id,
    });
    setShowModal(true);
  };

  return (
    <>
      <KycFormTitle
        label={KycTranslation.STEPS_REJECT_AND_REQUEST_FORM_COMPLETION_TITLE}
        // TODO fix new attempt thing
        additionalText={isRefusedAndShouldBeResend ? "(NEW ATTEMPT)" : ""}
      />

      <KycCenteredStepBodyLayout>
        <Field
          disabled={true}
          name="form.client.displayName"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.CLIENT_NAME}
        />
        <Field
          disabled={true}
          name="form.client.email"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label="USER.INFO.EMAIL"
        />
        <Field
          disabled={true}
          name="form.client.mobile"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label="USER.INFO.MOBILE"
        />
        <Field
          disabled={false}
          name="clientNotes[0]"
          component={KycTextarea}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.MESSAGE_REASON_FOR_ADDITIONAL_REQUEST}
        />
        <Field
          infoLabel={KycTranslation.INFO_NOTIFICATION_IN_APP}
          component={KycNotificationWidget}
          name="notification"
          isDeadline={true}
          label="COMMON.ACTION.NOTIFY_CLIENT_BY"
        />

        <div className="mt-8 mx-auto">
          <Button
            onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_RESEND)}
            className="btn-primary"
          >
            {fm("COMMON.ACTION.REQUEST")}
          </Button>
        </div>
      </KycCenteredStepBodyLayout>
    </>
  );
};
