import { DropdownMenu } from "../../../../../_components/actions-menu/DropdownMenu";
import { DropdownItem } from "../../../../../_components/actions-menu/DropdownItem";
import * as React from "react";
import { useEffect, useState } from "react";
import { useKyc } from "../../../../../hooks/useKyc";
import { KycTranslation } from "../../../KycTranslation";
import { KycPackageDownloadDialog } from "../../KycPackageDownloadDialog";
import { KycLoading } from "../../../Kyc";
import { handleDownload } from "../../../KycFileUtils";
import { useWebsocket } from "../../../../../hooks/useWebsocket";
import { useLoading } from "../../../../../hooks/useLoading";

export const KycFileExplorerDropdownMenu = (): JSX.Element => {
  const { currentKycFlow, downloadKycPackage } = useKyc();
  const { latestMessage, setLatestMessage } = useWebsocket();
  const { removeLoading } = useLoading();

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (latestMessage?.type === "KYC_ZIP_PACKAGE_CREATED") {
      handleDownload(latestMessage?.parameters?.url, "test.zip");
      removeLoading(KycLoading.DOWNLOAD_KYC_PACKAGE);
      setShowModal(false);
      setLatestMessage({});
    }
  }, [latestMessage]);

  const triggerKycPackageArchiveGeneration = (e: any, kycId: string) => {
    e.preventDefault();
    setShowModal(true);
    downloadKycPackage(kycId);
  };

  return (
    <>
      <KycPackageDownloadDialog
        key={"kyc-package-download-dialog-" + showModal}
        config={{
          labelTitle: KycTranslation.KYC_PACKAGE_MODAL_TITLE,
          labelContent: KycTranslation.KYC_PACKAGE_MODAL_CONTENT,
          labelLoading: KycTranslation.KYC_PACKAGE_MODAL_LOADING,
          isLoading: KycLoading.DOWNLOAD_KYC_PACKAGE,
          isLoadingData: KycLoading.DOWNLOAD_KYC_PACKAGE,
          progressMessageContent: KycTranslation.KYC_PACKAGE_MODAL_PROGRESS_MESSAGE_CONTENT,
          showModal: showModal,
        }}
      />
      {currentKycFlow?.files && (
        <DropdownMenu toggleClass="ml-4" dropDirection="down">
          <DropdownItem
            onClick={(e: any) => triggerKycPackageArchiveGeneration(e, currentKycFlow?.id)}
            title={KycTranslation.ACTION_DOWNLOAD_PACKAGE}
            classes={{ container: "text-secondary" }}
          />
        </DropdownMenu>
      )}
    </>
  );
};
