import React, { ChangeEvent, useEffect } from "react";
import { Field } from "formik";
import { KycTranslation } from "../../KycTranslation";
import { get } from "lodash-es";
import { KycSelectFileOwner } from "../KycSelectFileOwner";
import { KycRadioGroup } from "../controls/kyc-radio-group/KycRadioGroup";
import { KycFlowStatus, KycWorkflow } from "../../Kyc";
import { KycDatePicker } from "../controls/kyc-date-picker/KycDatePicker";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycNumberInput } from "../controls/kyc-number-input/KycNumberInput";
import { KycFormTitle } from "../layout/KycFormTitle";
import { fetchUsersByUserType } from "../../../UsersManagement/_redux/usersActions";
import { CLIENT, USER } from "../../../../_utils/userTypes";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";

import {
  KYC_V2_OPTIONS_LEGAL_ENTITY_TYPES,
  KYC_V2_OPTIONS_LEGAL_TYPES,
} from "../../KycTranslationUtils";
import { useSession } from "../../../../hooks/useSession";
import { Suffix } from "app/_utils/suffixUtils";
import { canReadAll } from "../../../../_utils/authUtils";
import { isLegalClient, statusAsArray } from "../../KycUtils";

export const KycStepAdminPage1 = (): JSX.Element => {
  const { customHandleChange, values, isClientViewer } = useKycFlow();
  const { session } = useSession();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT] }));
  }, []);

  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  return (
    <>
      <KycFormTitle
        label={
          isClientViewer
            ? KycTranslation.STEPS_ADMIN_PAGE_1_TITLE_CLIENT
            : KycTranslation.STEPS_ADMIN_PAGE_1_TITLE
        }
      />
      <Field
        name="form.client.displayName"
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.CLIENT_NAME}
      />
      <Field
        disabled={true}
        name="form.client.email"
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label="USER.INFO.EMAIL"
      />
      <Field
        disabled={true}
        name="form.client.mobile"
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label="USER.INFO.MOBILE"
      />
      <Field
        key={"select-legal-entity-" + values?.id}
        component={KycDropdownSelect}
        name="form.client.legalEntityType"
        options={KYC_V2_OPTIONS_LEGAL_ENTITY_TYPES}
        translateLabels={true}
        disabled={!get(values, "form.client.id")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.LEGAL_TYPE}
      />
      {isLegalClient(values) && (
        <Field
          label={" "}
          component={KycDropdownSelect}
          name="form.client.legalType"
          isRequired={true}
          options={KYC_V2_OPTIONS_LEGAL_TYPES}
          translateLabels={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
      )}
      <Field
        name="form.client.lead.name"
        component={KycInput}
        disabled={true}
        isLocked
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label="LEAD.TITLE.NAME"
      />

      <Field
        name="form.reference"
        component={KycInput}
        disabled={!get(values, "form.client.lead.id")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.REFERENCE_NAME}
      />
      <Field
        name="form.client.lead.product.name"
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.PROPERTY_NAME}
      />
      {/*Requested to disable in https://kodehyve.atlassian.net/browse/IMT-1890. Not removed yet, in case it will be requested to re-add*/}
      {/*<Field*/}
      {/*  name="form.clientIs"*/}
      {/*  component={KycInput}*/}
      {/*  onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}*/}
      {/*  label={KycTranslation.CLIENT_IS}*/}
      {/*/>*/}
      {/*<Field*/}
      {/*  name="form.counterparty"*/}
      {/*  component={KycInput}*/}
      {/*  onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}*/}
      {/*  label={KycTranslation.COUNTERPARTY}*/}
      {/*/>*/}
      <hr className="pb-2" />
      <Field
        className="form-control react-datepicker-ignore-onclickoutside no-validation"
        name="form.transactionDate"
        component={KycDatePicker}
        label={KycTranslation.DATE_OF_TRANSACTION}
        withFeedbackLabel={false}
        onChange={(val: any) =>
          customHandleChange({
            target: { name: "form.transactionDate", value: val?.toISOString() ?? null },
          })
        }
        showTimeSelect={false}
        dateFormat="P"
        popperPlacement="bottom-start"
        autoComplete="off"
      />
      <Field
        name="form.transactionAmount"
        component={KycNumberInput}
        suffix={Suffix.EURO}
        onChange={(e: any) =>
          customHandleChange({
            target: {
              name: "form.transactionAmount",
              value: e.floatValue || 0,
            },
          })
        }
        label={KycTranslation.TRANSACTION_AMOUNT}
      />
      <hr className="pb-2" />

      {!isClientViewer && canReadAll(groups, session, "KYC_FLOW") && <KycSelectFileOwner />}

      <Field
        name="form.purposeAndNature"
        component={KycInput}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.PURPOSE_AND_NATURE}
        infoLabel={KycTranslation.INFO_PURPOSE_OF_THE_ACQUISITION}
      />
      <Field
        component={KycRadioGroup}
        disabled={
          isClientViewer ||
          statusAsArray.indexOf(values?.status) >=
            statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_PENDING)
        }
        name="form.workflowChoice"
        label={KycTranslation.FORM_COMPLETE_WHO}
        choices={[
          {
            label: KycTranslation.WORKFLOW_COMPLETE_BY_CLIENT,
            value: KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_CLIENT,
          },
          {
            label: KycTranslation.WORKFLOW_COMPLETE_BY_ADMIN,
            value: KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN,
          },
        ]}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      {values?.form.workflowChoice === KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN &&
        !isClientViewer && (
          <Field
            component={KycRadioGroup}
            name="form.isClientPresent"
            label={KycTranslation.CLIENT_PRESENT}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />
        )}
    </>
  );
};
