import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useRequestSignatureDialogContext } from "./RequestSignatureDialogContext";

export const RequestSignatureDialogFooter: React.FunctionComponent = () => {
  const { isSendButtonDisabled, closeDialog, submit } = useRequestSignatureDialogContext();

  return (
    <div>
      <button
        type="button"
        className="btn btn-light mr-4"
        data-cy="button-cancel-signature"
        onClick={closeDialog}
      >
        <FormattedMessage id="COMMON.ACTION.CANCEL" />
      </button>

      <button
        type="button"
        className="btn btn-primary"
        data-cy="button-send-signature"
        onClick={submit}
        disabled={isSendButtonDisabled}
      >
        <FormattedMessage id="COMMON.ACTION.SEND" />
      </button>
    </div>
  );
};

export default RequestSignatureDialogFooter;
