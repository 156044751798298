import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycTranslation } from "../../KycTranslation";
import { KycChoiceOption, KycClientLead, KycFlow, KycFlowStatus, KycLoading } from "../../Kyc";
import { isEmpty, set, uniq } from "lodash-es";
import { useKyc } from "../../../../hooks/useKyc";
import { useSession } from "../../../../hooks/useSession";
import Select from "react-select";
import { FORM_INIT, getLangShort } from "../../KycUtils";

import { v4 as uuid } from "uuid";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { mapKycClient } from "../../KycMappingUtils";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { useIntl } from "react-intl";
import { useLoading } from "../../../../hooks/useLoading";
import { canCreate } from "../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../redux/hooks";
import { shallowEqual } from "react-redux";

type KycStartFlowActionProps = {
  cId: string;
};

export const KycStartFlowAction = ({ cId }: KycStartFlowActionProps): JSX.Element => {
  const { session } = useSession();
  const { fm, fDisplayName } = useTranslation();
  const { isCurrentlyLoading } = useLoading();
  const { currentKycClientLeads, currentKycClients, currentKycFlows, getKycClientLeads } = useKyc();
  const { setShowModal, setConfig } = useKycConfirmation();
  const [optionsLeads, setOptionsLeads] = useState<any[]>([]);
  const [kycClientChosen, setKycClientChosen] = useState<any>();
  const [leadsWithKycNotFinished, setLeadsWithKycNotFinished] = useState<string[]>([]);
  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsGrayGray400: objectPath.get(uiService.config, "js.colors.gray.gray400"),
    };
  }, [uiService]);

  const { groups } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const customStyles = {
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };

  const { originalEntityForEdit } = useAppSelector(
    (state) => ({
      originalEntityForEdit: state?.users?.entityForEdit.saved,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (optionsLeads?.length === 0) {
      getKycClientLeads(cId);
    }
  }, [optionsLeads]);

  useEffect(() => {
    if (currentKycClientLeads?.length > 0) {
      setOptionsLeads(
        currentKycClientLeads?.map((lead: KycClientLead) => ({
          label: lead?.name,
          value: lead,
          isDisabled: leadsWithKycNotFinished?.includes(lead?.id),
        }))
      );
    }
  }, [leadsWithKycNotFinished]);

  const isTerminalStatus = (status: KycFlowStatus) => {
    return [
      KycFlowStatus.KYC_REJECTED,
      KycFlowStatus.KYC_APPROVED,
      KycFlowStatus.CANCELED,
    ]?.includes(status);
  };

  useEffect(() => {
    setLeadsWithKycNotFinished(
      uniq(
        currentKycFlows
          .filter((kyc: KycFlow) => !isTerminalStatus(kyc?.status))
          ?.map((kyc) => {
            return kyc?.form?.client?.lead?.id;
          })
      ) ?? []
    );
  }, [currentKycFlows, currentKycClients, currentKycClientLeads]);

  useEffect(() => {
    if (!isEmpty(optionsLeads)) {
      setCreateFlowConfig(KycDialogConfig.KYC_FLOW_CREATE, optionsLeads, kycClientChosen);
    }
  }, [optionsLeads, kycClientChosen]);

  const getInitForm = (kycClient?: any): KycFlow => {
    const initForm: KycFlow = { ...FORM_INIT(fDisplayName, fm, session) };

    set(initForm, "id", uuid());
    set(initForm, "form.client", kycClient);
    set(initForm, "form.reference", kycClient?.lead?.name);
    set(initForm, "form.transactionAmount", kycClient?.lead?.sellingPrice);
    set(initForm, "lang", getLangShort(intl.locale) ?? "en");

    return initForm;
  };

  const handleClick = (e: any, t: KycDialogConfig, clientLead?: KycClientLead) => {
    e.preventDefault();
    setCreateFlowConfig(t, optionsLeads, clientLead);
    setShowModal(true);
  };

  const handleChangeLead = (leadChosen: KycChoiceOption) => {
    if (!originalEntityForEdit) {
      return;
    }

    const kycClient = mapKycClient(originalEntityForEdit, fDisplayName, leadChosen?.value);
    setKycClientChosen(kycClient);
  };

  const setCreateFlowConfig = (
    t: KycDialogConfig,
    optionsLeads: any[],
    clientLead?: KycClientLead
  ) => {
    if (!originalEntityForEdit) {
      return;
    }
    const init = getInitForm(clientLead);

    setConfig({
      type: t,
      kyc: init,
      kycId: init.id,
      children: (
        <div className="mt-5" data-cy="select-kyc-active-file">
          <Select
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={customStyles}
            placeholder=""
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: layoutProps.colorsThemeBasePrimary,
                primary: layoutProps.colorsThemeBasePrimary,
              },
            })}
            isDisabled={isCurrentlyLoading(KycLoading.GET_KYC_CLIENTS)}
            options={optionsLeads}
            classNamePrefix="kyc-select"
            onChange={handleChangeLead}
          />
        </div>
      ),
    });
  };

  return (
    <>
      {canCreate(groups, session, "KYC_FLOW") && (
        <Button
          data-cy="button-kyc-create-kyc-flow"
          onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_CREATE)}
          className="btn-primary w-sm-100"
          disabled={
            !optionsLeads ||
            optionsLeads?.length === 0 ||
            optionsLeads?.filter((option) => !option.isDisabled)?.length === 0
          }
        >
          {fm(KycTranslation.ACTION_CREATE_KYC_FLOW)}
        </Button>
      )}
    </>
  );
};
