import { Form, Formik } from "formik";
import { KycFlowV2Provider } from "../../../../contexts/KycFlowContext";
import { FORM_INIT } from "../../KycUtils";
import { useTranslation } from "../../../../hooks/useTranslation";
import { ReactNode } from "react";
import { useSession } from "../../../../hooks/useSession";
import { KycFlow } from "../../Kyc";

type KycFormBaseProps = {
  initValues?: KycFlow;
  children?: ReactNode;
};

export const KycFlowFormBase = ({ initValues, children }: KycFormBaseProps) => {
  const { fm, fDisplayName } = useTranslation();
  const { session } = useSession();
  return (
    <Formik
      enableReinitialize={false}
      //TODO disabled, because field array & formik with large amount
      //causes input lag in (at least in dev)
      //to be reevaluated depending if and how validation needs to be applied
      validateOnChange={false}
      initialValues={initValues ?? FORM_INIT(fDisplayName, fm, session)}
      onSubmit={(data) => {}}
    >
      <Form className="form form-label-right" style={{ width: "100%" }}>
        <KycFlowV2Provider>{children}</KycFlowV2Provider>
        {/*<PersistFormikValues name="kyc-v2-flow" />*/}
      </Form>
    </Formik>
  );
};
