/*eslint no-useless-computed-key: "off"*/
/*eslint-env es6*/

import * as React from "react";
import objectPath from "object-path";
import { useIntl } from "react-intl";
import { Droppable } from "@react-forked/dnd";
import chroma from "chroma-js";
import { chunk, flatMap, sumBy } from "lodash-es";

import { useHtmlClassService } from "_metronic/layout";

import { ITask } from "data/schemas";

import { ILaneDefinition } from "app/_utils/listUtils";
import { ISelectedSort } from "app/_utils/tasks";

import { TaskItemContent, TaskItemProps } from "./TaskItem";
import { sortedTasksLaneItems, TaskLaneItem, TaskLaneItemProps } from "./TaskLaneItem";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import "react-virtualized/styles.css";

import "./TasksLane.scss";
import ReactDOM from "react-dom";

const DEFAULT_LANE_BG = "#fcfcfc";
const DARKEN_LANE_BG = chroma(DEFAULT_LANE_BG).darken(0.3).hex();

const grayChroma = chroma("#333333");
const LANE_ITEM_HEADER_TEXT_COLOR = grayChroma.brighten(0.85).hex();
const GANTT_CHART_ICON_COLOR = grayChroma.brighten(1.5).hex();

export interface TasksLaneProps {
  ind: number;
  hideLane: boolean;
  disableDrop?: boolean;
  laneDefinition: ILaneDefinition;
  selectedSort: ISelectedSort;
  tasks: ITask[];
}

interface RowProps {
  index: number;
  style: any;
  key: string;
  parent: any;
}

export const TasksLane: React.FunctionComponent<TasksLaneProps> = ({
  ind,
  hideLane,
  disableDrop = false,
  laneDefinition,
  selectedSort,
  tasks,
}) => {
  const intl = useIntl();
  const uiService = useHtmlClassService();

  const colorsThemeLightPrimary = objectPath.get(uiService.config, "js.colors.theme.light.primary");

  const [chunkedTasksToRender, setChunkedTasksToRender] = React.useState<TaskLaneItemProps[][]>([]);

  React.useEffect(() => {
    if (tasks.length === 0) {
      setChunkedTasksToRender([]);
      return;
    }

    const laneId = laneDefinition.id;

    // build the initial data structure of the items to be rendered at the lane
    // with the same status of the lane id
    let taskLaneItems: TaskLaneItemProps[] = tasks.map((item) => {
      const { status, subtasks } = item;

      const renderItem = status === laneId;
      const subitems = subtasks
        .filter((subtask) => subtask.status === laneId)
        .map((item) => ({ item } as TaskItemProps));

      return {
        item,
        renderItem,
        subitems,
      };
    });

    taskLaneItems = sortedTasksLaneItems(taskLaneItems, selectedSort);

    // check the item (parent task) and also the subitems
    taskLaneItems = taskLaneItems.map((laneItem) => {
      laneItem.subitems = sortedTasksLaneItems(laneItem.subitems, selectedSort);
      return laneItem;
    });

    // cleanup the array
    taskLaneItems = taskLaneItems.filter((item) => item.subitems.length > 0 || item.renderItem);

    // define the itemIndex (needed by the react-dnd draggable component)
    // and that index needs to be a sequential number
    let indexValue = 0;
    taskLaneItems = taskLaneItems.map((item) => {
      item.itemIndex = item.renderItem ? indexValue++ : -1;

      const { subitems } = item;
      if (subitems.length > 0) {
        item.subitems = subitems.map((subitem) => {
          return {
            ...subitem,
            itemIndex: indexValue++,
          };
        });
      }

      return item;
    });

    setChunkedTasksToRender(chunk(taskLaneItems, 25));
  }, [intl, laneDefinition, selectedSort, tasks]);

  const laneDataCyId = `task-board-lane-${laneDefinition?.id}`;

  const getRowHeight = (index: number) => {
    const task = flatMap(chunkedTasksToRender)[index];
    if (!task) return 0;
    let maxAdd = task.subitems.length > 1 ? (task.subitems.length - 1) * 94 : 0;
    maxAdd += task.renderItem ? (task.subitems.length > 0 ? 94 : 0) : 24;
    maxAdd += 94;
    return maxAdd;
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 88,
  });

  const getRowRender = ({ index, style, key, parent }: RowProps) => {
    const task = flatMap(chunkedTasksToRender)[index];
    if (!task) return null;

    const patchedStyle = {
      ...style,
      left: style.left + 4,
      top: style.top + 4,
      width: `calc(${style.width} - ${4 * 2}px)`,
    };

    return (
      <CellMeasurer cache={cache} parent={parent} key={key} rowIndex={index}>
        <TaskLaneItem
          item={task.item}
          subitems={task.subitems}
          renderItem={task.renderItem}
          style={patchedStyle}
        />
      </CellMeasurer>
    );
  };

  return (
    <>
      <Droppable
        ignoreContainerClipping={true}
        key={ind}
        droppableId={`${ind}`}
        isDropDisabled={disableDrop}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const task = flatMap(tasks, (t) => (t.subtasks ? [t].concat(...t.subtasks) : [t])).find(
            (t) => t.id === rubric.draggableId
          );
          return (
            task && (
              <TaskItemContent provided={provided} snapshot={snapshot} item={task} style={{}} />
            )
          );
        }}
      >
        {(provided, snapshot) => {
          const flattenedChunks = flatMap(chunkedTasksToRender);
          const itemCount = sumBy(flattenedChunks, (t) =>
            t.renderItem ? t.subitems.length + 1 : t.subitems.length
          );
          return (
            <List
              data-cy={laneDataCyId}
              height={739}
              rowHeight={(params) => getRowHeight(params.index)}
              width={324}
              rowCount={itemCount}
              ref={(ref) => {
                // react-virtualized has no way to get the list's ref that I can so
                // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                if (ref) {
                  // eslint-disable-next-line react/no-find-dom-node
                  const innerRef = ReactDOM.findDOMNode(ref);
                  if (innerRef instanceof HTMLElement) {
                    innerRef.setAttribute("data-cy", laneDataCyId);
                    provided.innerRef(innerRef);
                  }
                }
              }}
              style={
                {
                  ["--taskItemOpacity"]: hideLane ? 0 : 1,
                  ["--darkenLaneBg"]: DARKEN_LANE_BG,
                  ["--laneItemHeaderTextColor"]: LANE_ITEM_HEADER_TEXT_COLOR,
                  ["--iconGanttChartColor"]: GANTT_CHART_ICON_COLOR,
                  background: snapshot.isDraggingOver ? colorsThemeLightPrimary : DEFAULT_LANE_BG,
                  transition: "background-color 0.2s ease",
                  width: "100% !important",
                  overflowY: hideLane ? "hidden" : "auto",
                } as any
              }
              rowRenderer={getRowRender}
            />
          );
        }}
      </Droppable>
    </>
  );
};

export default TasksLane;
