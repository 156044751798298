import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Dropdown } from "react-bootstrap";
import cn from "clsx";

export interface DropdownItemProps {
  onClick: Function;
  icon?: string;
  title: string;
  classes?: {
    container?: string;
    svg?: string;
    text?: string;
  };
}
export const DropdownItem: FC<DropdownItemProps> = ({ onClick, icon, title, classes }) => {
  return (
    <Dropdown.Item
      className={cn("d-flex align-items-center", classes?.container)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      {icon && (
        <span className={cn("svg-icon svg-icon-md mr-2", classes?.svg)}>
          <SVG src={toAbsoluteUrl(icon)} />
        </span>
      )}
      <span className={cn(classes?.text)}>
        <FormattedMessage id={title} />
      </span>
    </Dropdown.Item>
  );
};
