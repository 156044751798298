import { Button } from "react-bootstrap";
import { useWizard } from "../../hooks/useWizard";
import { KycFlowStatus, KycStep } from "../../modules/kyc-v2/Kyc";
import cn from "clsx";
import { useTranslation } from "../../hooks/useTranslation";
import { useKycFlow } from "../../hooks/useKycFlow";
import { useKyc } from "../../hooks/useKyc";
import * as React from "react";
import { WIZARD_ORIENTATION } from "./Wizard";
import { useLoading } from "../../hooks/useLoading";
import { isLegalClient } from "../../modules/kyc-v2/KycUtils";

export const WizardNavbar = (): JSX.Element => {
  const {
    activeStep,
    steps,
    nextStep,
    previousStep,
    currentStepId,
    isExpanded,
    stepperOrientation,
  } = useWizard();

  const { fm } = useTranslation();
  const { values, setFlowValues } = useKycFlow();
  const { updateKycFlow } = useKyc();
  const { loadingsCount } = useLoading();

  const handleNextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (
      currentStepId() === KycStep.STEP_DISCLAIMER &&
      values?.form?.acceptTerms?.isAccepted &&
      !values?.form?.acceptTerms?.isNextClicked
    ) {
      setFlowValues(
        {
          "form.acceptTerms.isNextClicked": true,
          status: KycFlowStatus.ADMIN_FILLING_IN,
        },
        true
      );
    } else {
      updateKycFlow({ ...values });
    }
    nextStep();
  };

  const isBackButtonHidden = () => {
    return [KycStep.STEP_DISCLAIMER, KycStep.STEP_ADMIN_PAGE_1].includes(
      currentStepId() as KycStep
    );
  };

  const isNextButtonHidden = () => {
    return (
      [
        KycStep.STEP_ADMIN_PAGE_2,
        KycStep.STEP_RISK_EVALUATION,
        KycStep.STEP_SUCCESS,
        KycStep.STEP_CLIENT_PAGE_4,
      ].includes(currentStepId() as KycStep) || activeStep === steps?.length - 1
    );
  };

  const isNextButtonDisabled = () => {
    //PropDev user can not go to next page if he didn't accept terms & conditions (via checkbox) on disclaimer page
    if (currentStepId() === KycStep.STEP_DISCLAIMER && !values?.form?.acceptTerms?.isAccepted) {
      return true;
    }

    //PropDev must select a Lead or he cannot go to next page
    // (=he won't be able to send a request to fill out the form to the client)
    if (
      currentStepId() === KycStep.STEP_ADMIN_PAGE_1 &&
      (!values?.form?.client?.lead?.id ||
        (isLegalClient(values) && !values?.form?.client?.legalType))
    ) {
      return true;
    }

    //user is on final/last page of the flow (=there are no further steps, don't show next button)
    if (activeStep === steps?.length - 1) {
      return true;
    }

    //next button shown by default
    return false;
  };

  return (
    <>
      {stepperOrientation === WIZARD_ORIENTATION.VERTICAL && (
        <div style={{ width: "25%", maxWidth: "500px", marginRight: 0 }} />
      )}
      <div className="mx-auto" style={{ width: "100%", maxWidth: 1200 }}>
        <div
          className={cn(
            "d-flex ",
            isExpanded ? "justify-content-center" : "justify-content-between"
          )}
        >
          <Button
            data-cy="button-kyc-form-previous"
            disabled={activeStep === 0 || loadingsCount > 0}
            onClick={previousStep}
            className={cn(
              "mx-1 btn-light form-control-reduce-space",
              isBackButtonHidden() && "invisible"
            )}
          >
            {fm("COMMON.ACTION.BACK")}
          </Button>
          <Button
            data-cy="button-kyc-form-next"
            disabled={isNextButtonDisabled() || loadingsCount > 0}
            variant="contained"
            onClick={handleNextStep}
            className={cn(
              "mx-1 bg-primary border-primary text-white form-control-reduce-space",
              isNextButtonHidden() && "invisible"
            )}
          >
            {fm("COMMON.ACTION.NEXT")}
          </Button>
        </div>
      </div>
    </>
  );
};
