import * as React from "react";
import cn from "clsx";
import { BudgetLineColumnsTypes, StaticBudgetLineColumns } from "../../../../../data/schemas";

export interface BudgetLineContentProps {
  columnWidth: Record<BudgetLineColumnsTypes, number>;
  line: { label: React.ReactNode } & Record<BudgetLineColumnsTypes, React.ReactNode>;
  isHeader?: boolean;
  selectedBudget?: boolean;
  labelClassName?: string;
}

export const BudgetLineContent: React.FC<BudgetLineContentProps> = ({
  columnWidth,
  line,
  isHeader,
  selectedBudget,
  labelClassName,
}) => {
  return (
    <div className="d-flex flex-grow-1">
      <div
        className={cn("flex-shrink-0 d-flex align-items-center", !selectedBudget && "flex-grow-1")}
      >
        <div
          className={cn(
            "d-flex",
            selectedBudget ? "w-200px w-sm-250px w-md-300px w-lg-350px" : "w-100",
            labelClassName
          )}
        >
          {line.label}
        </div>
      </div>
      <div
        className={cn(
          "px-2 d-flex align-items-center justify-content-end",
          isHeader ? "font-weight-bold" : "font-size-sm",
          selectedBudget && "col"
        )}
        style={{
          minWidth: columnWidth.planned,
          boxSizing: "content-box",
        }}
      >
        {line.planned}
      </div>
      {selectedBudget && (
        <>
          {Object.values(StaticBudgetLineColumns).map((column) => (
            <BudgetColumn
              isHeader={isHeader}
              columnWidth={columnWidth}
              line={line}
              column={column}
              key={column}
            />
          ))}
        </>
      )}
    </div>
  );
};

export interface BudgetColumnProps {
  isHeader?: boolean;
  columnWidth: Record<BudgetLineColumnsTypes, number>;
  line: { label: React.ReactNode } & Record<BudgetLineColumnsTypes, React.ReactNode>;
  column: BudgetLineColumnsTypes;
}

const BudgetColumn: React.FC<BudgetColumnProps> = ({ isHeader, columnWidth, line, column }) => (
  <div
    className={cn(
      "px-2 d-flex align-items-center justify-content-end col",
      isHeader ? "font-weight-bold" : "font-size-sm"
    )}
    style={{
      minWidth: columnWidth[column],
      boxSizing: "content-box",
    }}
  >
    {line[column]}
  </div>
);
