import * as React from "react";
import { useAppSelector } from "../../../../../../redux/hooks";
import { sumBy } from "lodash-es";
import { FinanceFileType, isLeadFinancialDocumentPriceLine } from "../../../../../../data/schemas";

export interface ILeadContext {
  usedConstructionPriceWithReducedVat: number;
  initialCredit: number;
  remainingCredit: number;
}

const LeadContext = React.createContext<ILeadContext>({} as ILeadContext);

export function useLeadContext() {
  return React.useContext(LeadContext);
}

export const LeadProvider: React.FC = ({ children }) => {
  const { leadForEdit, generalSettings } = useAppSelector((state) => ({
    leadForEdit: state.leads.leadForEdit.current,
    generalSettings: state.settings.settingsForEdit.saved,
  }));

  const settings = leadForEdit?.project?.projectOwner ?? generalSettings;

  const usedConstructionPriceWithReducedVat = React.useMemo(() => {
    if (!leadForEdit?.financialDocuments || !settings) {
      return 0;
    }
    return sumBy(leadForEdit?.financialDocuments, (financialDocument) => {
      if (
        financialDocument.fileType === FinanceFileType.INVOICE &&
        financialDocument.selectedForFinance
      ) {
        return sumBy(financialDocument.content, (line) => {
          let sum = 0;
          if (isLeadFinancialDocumentPriceLine(line)) {
            if (line.vat === settings.reducedVat) {
              sum += line.amount;
            }
            if (line.relatedFinancialDocuments) {
              sum += sumBy(line.relatedFinancialDocuments, (financialDocument) => {
                return sumBy(financialDocument.content, (SALine) =>
                  isLeadFinancialDocumentPriceLine(SALine) && SALine.vat === settings.reducedVat
                    ? SALine.amount
                    : 0
                );
              });
            }
          }
          return sum;
        });
      } else {
        return 0;
      }
    });
  }, [leadForEdit?.financialDocuments, settings]);

  const initialCredit =
    (leadForEdit?.constructionPriceWithReducedVat ?? 0) *
    ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));

  let remainingCredit =
    initialCredit -
    (usedConstructionPriceWithReducedVat ?? 0) *
      ((settings?.defaultVat ?? 0) - (settings?.reducedVat ?? 0));
  // Case when the user forced the reduced VAT when advised not to
  if (remainingCredit < 0) {
    remainingCredit = 0;
  }
  const value = {
    usedConstructionPriceWithReducedVat,
    initialCredit,
    remainingCredit,
  };

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>;
};
