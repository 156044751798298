import { Carousel } from "react-bootstrap";
import "../../../KycWizard.scss";
import { KycClientPage2LegalEntityPersonalInfo } from "../../steps/KycClientPage2LegalEntityPersonalInfo";

import "../../RepresentativeCarousel.scss";
import { KycItemType } from "../../../Kyc";
import { KycBoShareholderCompany } from "../../steps/KycBoShareholderCompany";

type RepresentativeCarouselProps = {
  name: string;
  activeIndex: number;
  items: any[];
  itemType: KycItemType;
};

export const KycEntityCarousel = ({
  name,
  activeIndex,
  items,
  itemType,
}: RepresentativeCarouselProps): JSX.Element => {
  const getChildrenForItemType = (name: string, index: number) => {
    switch (itemType) {
      case KycItemType.PERSONAL_INFO:
        return <KycClientPage2LegalEntityPersonalInfo name={name} index={index} />;
      case KycItemType.SHAREHOLDER_COMPANY:
        return <KycBoShareholderCompany name={name} index={index} />;
    }
  };

  return (
    <div className="mb-8">
      <Carousel indicators={false} controls={false} interval={null} activeIndex={activeIndex}>
        {items?.map((entity: any, index: number) => (
          <Carousel.Item key={`${name}-carousel-item-${index}`}>
            {getChildrenForItemType(name, index)}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
