import { toAbsoluteUrl } from "../../_metronic/_helpers";

const fileIconsBasePath = "/media/svg/icons/Files/";

export const mapMimeTypeFilename: Record<string, string> = {
  "text/csv": "csv",
  "application/gzip": "zip",
  "application/zip": "zip",
  "application/x-7z-compressed": "zip",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xls",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "ppt",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "doc",
  "application/pdf": "pdf",
  "application/xml": "xml",
  "text/xml": "xml",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/bmp": "",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.ms-excel": "xls",
  "application/xhtml+xml": "html",
  "text/html": "html",
  "video/mp4": "mp4",
  "video/mpeg": "",
  "text/plain": "txt",
};

export const getIconFilePath = (mimeType?: string) => {
  let iconFilename = mimeType ? mapMimeTypeFilename[mimeType] : "file";
  //if no specific svg available, use default file.svg
  if (!iconFilename || iconFilename === "") {
    iconFilename = "file";
  }
  return toAbsoluteUrl(fileIconsBasePath + iconFilename + ".svg");
};

export const exportCSV = (CSVname: string, content: any) => {
  const exportedDate = new Date();

  const exportedFilename = CSVname + "_" + exportedDate.toISOString().split("T")[0] + ".csv";

  const csvContent =
    "data:text/csv;charset=utf-8," + content.map((e: any) => e.join(",")).join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", exportedFilename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
