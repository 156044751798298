import React, { ChangeEvent, FC } from "react";
import { SearchTypeValues } from "../../contexts/SearchContext";
import { FormattedMessage } from "react-intl";
import { useSearch } from "../../hooks/useSearch";
import cn from "clsx";

interface SearchCheckBoxProps {
  name: string;
  label: string;
  isChecked?: boolean;
  isFlexGrow?: boolean;
}

export const SearchCheckBox: FC<SearchCheckBoxProps> = ({
  name,
  label,
  isChecked,
  isFlexGrow,
}): JSX.Element => {
  const { setSearchValues } = useSearch();

  return (
    <div
      className={cn(`mr-sm-2 mr-0 mt-2 d-flex align-items-center`, { "flex-grow-1": isFlexGrow })}
    >
      <label className="checkbox checkbox-lg checkbox-single">
        <input
          data-cy={"filter-checkbox-" + name.toLowerCase()}
          type="checkbox"
          name={"filter.checkbox." + name}
          checked={isChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchValues(e.target.checked.toString(), SearchTypeValues.SEARCH_CHECKBOX, name);
          }}
        />
        <span className={cn("mr-2")} style={{ minWidth: 24 }}></span>
        <FormattedMessage id={label} />
      </label>
    </div>
  );
};
