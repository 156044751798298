/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import * as React from "react";
import { shallowEqual } from "react-redux";
import { isEqual, mergeWith } from "lodash-es";
import { FormattedMessage, useIntl } from "react-intl";

import * as actions from "../../../_redux/leads/leadsActions";
import * as productActions from "../../../_redux/products/productsActions";
import * as projectActions from "../../../_redux/projects/projectsActions";
import { fetchProjects } from "../../../_redux/projects/projectsActions";

import cn from "clsx";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { LeadEditForm } from "./LeadEditForm";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ProductEditForm } from "../../products/product-edit/ProductEditForm";
import { ClientsActions } from "../clients-tab/clients-actions/ClientsActions";
import { AddClientDialog } from "../clients-tab/clients-actions/AddClientDialog";
import { RemoveClientDialog } from "../clients-tab/clients-actions/RemoveClientDialog";
import { useQueryState } from "react-router-use-location-state";
import {
  canCreate,
  canEdit,
  canSeeAuditTrail,
  canSeeNote,
  canSeeClientsTabInLead,
} from "../../../../../_utils/authUtils";
import { ProjectEditForm } from "../../projects/project-edit/ProjectEditForm";
import { TasksPageTab } from "../../tasks/TasksPageTab";
import { isAddressValid } from "../../../../../_utils/addressUtils";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useAutoSave } from "../../../../../_utils/useAutoSave";
import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { UsersUIProvider } from "../../users/UsersUIContext";
import { UsersTable } from "../../users/users-table/UsersTable";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";
import { ProductEditInformationForm } from "../../products/product-edit/ProductEditInformationForm";
import { TASK_SORTER_OPTION } from "../../../../../_utils/listUtils";
import { Locker } from "../../../../../_components/Locker";
import { Photos } from "../../../../../_components/Photos";
import { FilesTab } from "../files-tab/FilesTab";
import { Sorter } from "app/_components/Sorter";

import {
  TasksContentViewMode,
  TasksSwitchContentView,
  useTasksSwitchContentView,
} from "app/modules/PropertiesManagement/pages/tasks/TasksCard/TasksCardContent/TasksSwitchContentView";
import { EntityEditInstalments } from "../../../../../_components/EntityEditInstalments";
import { LeadInstalmentTable } from "../../../../Lead/LeadInstalmentTable";
import { AuditTrailViewer } from "../../../../../_components/AuditTrail/AuditTrailViewer";
import { useAuditTrail } from "../../../../../hooks/useAuditTrail";
import { LEAD } from "../../../../../_utils/dataTypes";
import { CLIENT } from "../../../../../_utils/userTypes";
import { LeadProvider } from "./LeadContext";

const initLead = {
  id: undefined,
  name: "",
  status: "OPEN",
  sellingPrice: 0,
  invoiceAddressType: "CUSTOM",
  invoiceAddress: {},
  shareOfLandSellingPrice: 0,
  architectEngineeringFees: 0,
  constructionPriceWithReducedVat: 0,
};

const mergeWithInitLead = (obj) => {
  return mergeWith({ ...initLead }, obj, (dest, src) => (src === null ? dest : undefined));
};

const PRODUCT_ID_QUERY_PARAM_ID = "p";
const CLIENT_IDS_QUERY_PARAM_ID = "c";
const REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN =
  process.env.REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN === "true";
console.log("REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN:", REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN);

export function LeadEdit({
  history,
  match: {
    params: { id },
  },
  location,
}) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Subheader
  const subheader = useSubheader();

  // Tabs
  const [tab, setTab] = useQueryState("t", "general");
  const [action, setAction] = useQueryState("a", "");
  const [queryId, setQueryId] = useQueryState("qid", "");
  const [, setHighlightId] = useQueryState("h", "");
  const [title, setTitle] = React.useState("");

  const { contentView, toggleContentView } = useTasksSwitchContentView();
  const [triggerOpenNewTaskDialog, setTriggerOpenNewTaskDialog] = React.useState("");

  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const {
    actionsLoading,
    leadForEdit,
    originalLeadForEdit,
    leadClients,
    groups,
    session,
    projects,
    product,
    project,
    isLoadingProduct,
    isLoadingProject,
  } = useAppSelector(
    (state) => ({
      actionsLoading: state.leads?.actionsLoading || state.leadClients?.actionsLoading || false,
      leadClients: state.leadClients.entities,
      originalLeadForEdit: state.leads.leadForEdit.saved
        ? state.leads.leadForEdit.saved
        : state.leads.entities?.find((entity) => entity.id === id),
      leadForEdit: state.leads.leadForEdit.current
        ? state.leads.leadForEdit.current
        : state.leads.entities?.find((entity) => entity.id === id),
      groups: state.auth.groups,
      session: state.auth.session,
      projects: state.projects.entities,
      product: state?.products?.productForEdit?.current,
      project: state.projects?.projectForEdit.current,
      isLoadingProduct: state.products.actionsLoading || state.products.listLoading,
      isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
    }),
    shallowEqual
  );
  useAutoSave(leadForEdit, originalLeadForEdit, actions.updateLead);
  const readOnly = id ? !canEdit(groups, session, "LEAD") : !canCreate(groups, session, "LEAD");

  const { setFromUrlTo, goBack } = useCustomLocationState();
  const [selectedSort, setSelectedSort] = React.useState();

  React.useEffect(() => {
    if (projects.length === 0) {
      dispatch(fetchProjects());
    }
  }, []);

  React.useEffect(() => {
    dispatch(actions.fetchLeadDetails(id));
  }, [id]);

  const { fetchAuditTrail, setCurrentEntityObject } = useAuditTrail();

  React.useEffect(() => {
    if (tab === "history") {
      fetchAuditTrail(id, LEAD);
    }
    if (tab !== "files") {
      setHighlightId("");
    }
  }, [tab]);

  React.useEffect(() => {
    if (leadForEdit) {
      setCurrentEntityObject(leadForEdit);
    }
  }, [leadForEdit]);

  React.useEffect(() => {
    let _title = "";
    if (!id || originalLeadForEdit?.id === id) {
      _title = !id ? intl.formatMessage({ id: "LEAD.TITLE.NEW" }) : originalLeadForEdit.name;
      subheader.setTitle(_title);
    }
    setTitle(_title);

    // Check if product and/or clients are already selected
    if (!originalLeadForEdit && !id) {
      const productId = new URLSearchParams(location.search).get(PRODUCT_ID_QUERY_PARAM_ID);
      const clientIds = new URLSearchParams(location.search).get(CLIENT_IDS_QUERY_PARAM_ID);
      if (productId) {
        if (clientIds) {
          dispatch(actions.fetchLeadProduct(productId)).then((product) => {
            if (!product) {
              redirectToProductsList();
            } else {
              dispatch(actions.fetchLeadClients(clientIds.split(","), redirectToClientsList));
            }
          });
        } else {
          redirectToClientsList();
        }
      } else {
        redirectToProductsList();
      }
    }
  }, [originalLeadForEdit, id, location]);

  // Add this variable to check if the change of invoiceAddressType is from user or on init
  const [initInvoiceAddressType, setInitInvoiceAddressType] = React.useState(true);

  React.useEffect(() => {
    if (!id) {
      const _title = `${intl.formatMessage({
        id: "LEAD.TITLE.NEW_LEAD_FOR",
      })}  ${leadForEdit?.product?.name ? leadForEdit?.product?.name : "..."}`;
      setTitle(_title);
      // If a lead user address is valid, then set it by default for invoice address, else it's already set to CUSTOM
      if (leadForEdit?.users && initInvoiceAddressType) {
        for (const user of leadForEdit.users) {
          if (isAddressValid(user.address)) {
            setInitInvoiceAddressType(false);
            saveLeadFields("invoiceAddressType", user.id);
            break;
          }
        }
      }
    }
  }, [leadForEdit, id]);

  const saveLeadFields = (key, value) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (const k of Object.keys(key)) {
        dispatch(actions.updateLeadFieldLocally(key[k], value[k]));
      }
    } else {
      dispatch(actions.updateLeadFieldLocally(key, value));
    }
  };

  const saveLeadProductFields = (key, value) => {
    key = `product.${key}`;
    saveLeadFields(key, value);
  };

  const submitLead = () => {
    if (!id) {
      //Create new Lead
      if (leadForEdit?.product) {
        const users = !!leadForEdit?.users ? [...leadForEdit.users] : [];
        const notes = `<h2>${leadForEdit?.name} - Notes</h2><p>&nbsp;</p><p>&nbsp;</p>`;
        const body = { ...leadForEdit, productId: leadForEdit.product.id, notes, users };
        if (!!body?.product?.projectId) {
          body.projectId = body.product.projectId;
        }
        delete body.clients;
        delete body.product;

        dispatch(actions.createLead(mergeWithInitLead(body))).then((lead) => {
          history.push(`/leads/${lead.id}${history.location.search}`);
        });
      } else {
        history.push("/leads/new/products");
      }
    }
  };

  const backButtonClick = () => {
    goBack("/leads");
  };

  const redirectToProductsList = () => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.delete(PRODUCT_ID_QUERY_PARAM_ID);
    history.push(`/leads/new/products?${queryParams.toString()}`);
  };

  const redirectToClientsList = () => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.delete(CLIENT_IDS_QUERY_PARAM_ID);
    history.push(`/leads/new/clients?${queryParams.toString()}`);
  };

  const clientsUIEvents = {
    openEntityDetailsPage: (client) => {
      subheader.setTitle(formatDisplayNameIntl(intl, client));
      setFromUrlTo({ url: `/clients/${client.id}`, name: leadForEdit.name });
    },
    openRemoveUser: (leadClientId) => {
      setAction("removeClient");
      setQueryId(leadClientId);
    },
    canOpenRemoveUser: () => canEdit(groups, session, "LEAD"),
  };

  const openTaskDetails = () => {
    setTriggerOpenNewTaskDialog(new Date().toISOString());
  };
  return (
    <LeadProvider>
      <Card style={{ height: tab === "tasks" ? "calc(100% - 1rem)" : "auto" }}>
        {actionsLoading && <ModalProgressBar />}

        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backButtonClick}
              data-cy="button-back"
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              {!id && <FormattedMessage id="COMMON.ACTION.CANCEL" />}
            </button>
            {!readOnly && !id && leadForEdit?.product?.id && (
              <button
                type="submit"
                className="btn btn-primary ml-2"
                onClick={submitLead}
                data-cy="button-lead-create"
                disabled={actionsLoading}
              >
                <FormattedMessage id="COMMON.ACTION.CREATE" />
              </button>
            )}
            {tab === "tasks" && canCreate(groups, session, "TASK") && (
              <div className={"d-flex ml-4"}>
                <Sorter
                  {...{
                    selectedSort,
                    setSelectedSort,
                    option: TASK_SORTER_OPTION,
                    disabled: contentView === TasksContentViewMode.GANTT_CHART,
                  }}
                />

                <TasksSwitchContentView
                  {...{
                    className: "ml-4",
                    mode: contentView,
                    onChange: toggleContentView,
                  }}
                />

                <button
                  type="button"
                  className="btn btn-primary ml-2"
                  onClick={() => openTaskDetails()}
                >
                  <FormattedMessage id="TASK.ACTION.NEW" />
                </button>
              </div>
            )}
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody className={cn(tab === "tasks" && "d-flex flex-column")}>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab("general")}>
              <a
                className={`nav-link ${tab === "general" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "general").toString()}
              >
                <FormattedMessage id="COMMON.GENERAL" />
                {!isEqual({ ...leadForEdit, notes: "" }, { ...originalLeadForEdit, notes: "" }) &&
                  "*"}
              </a>
            </li>
            {id && (
              <>
                {leadForEdit?.project && (
                  <>
                    <li className="nav-item" onClick={() => setTab("project")}>
                      <a
                        className={`nav-link ${tab === "project" && "active"}`}
                        data-toggle="tab"
                        role="button"
                        aria-selected={(tab === "project").toString()}
                      >
                        <FormattedMessage id="PROJECT.TITLE.SINGLE" />
                      </a>
                    </li>
                  </>
                )}
                <li className="nav-item" onClick={() => setTab("property")}>
                  <a
                    className={`nav-link ${tab === "property" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "property").toString()}
                  >
                    <FormattedMessage id="COMMON.TAB.TITLE.PRODUCT" />
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("files")}>
                  <a
                    className={`nav-link ${tab === "files" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "files").toString()}
                  >
                    <FormattedMessage id="COMMON.TAB.TITLE.FILE" />
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("photos")}>
                  <a
                    className={`nav-link ${tab === "photos" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "photos").toString()}
                  >
                    <FormattedMessage id="COMMON.TAB.TITLE.PHOTO" />
                  </a>
                </li>
                {canSeeNote(groups, session, "LEAD") && (
                  <li className="nav-item" onClick={() => setTab("notes")}>
                    <a
                      className={`nav-link ${tab === "notes" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "notes").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="NOTE.TITLE" />
                    </a>
                  </li>
                )}
                {canSeeClientsTabInLead(groups, session) && (
                  <li className="nav-item" onClick={() => setTab("clients")}>
                    <a
                      className={`nav-link ${tab === "clients" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "clients").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="CLIENT.TITLE" />
                    </a>
                  </li>
                )}
                <li className="nav-item" onClick={() => setTab("tasks")}>
                  <a
                    className={`nav-link ${tab === "tasks" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    data-cy="tab-actions"
                    aria-selected={(tab === "tasks").toString()}
                  >
                    <FormattedMessage id="TASKS.TITLE" />
                  </a>
                </li>
                {canSeeAuditTrail(groups, session, "LEAD") && (
                  <li className="nav-item" onClick={() => setTab("history")}>
                    <a
                      className={`nav-link ${tab === "history" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "history").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="HISTORY.TITLE" />
                    </a>
                  </li>
                )}
                {!REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN && leadForEdit?.budgetInstalments && (
                  <li className="nav-item" onClick={() => setTab("instalments")}>
                    <a
                      className={`nav-link ${tab === "instalments" && "active"}`}
                      data-toggle="tab"
                      role="button"
                      aria-selected={(tab === "instalments").toString()}
                    >
                      <FormattedMessage id="LEAD.PROVISIONAL_PLAN" />
                    </a>
                  </li>
                )}
              </>
            )}
          </ul>
          <div className={cn("mt-5", tab === "tasks" && "flex-grow-1")}>
            {tab === "general" && (
              <>
                <LeadEditForm
                  actionsLoading={actionsLoading}
                  lead={mergeWithInitLead(leadForEdit)}
                  saveLeadFields={saveLeadFields}
                  submitLead={submitLead}
                  disabled={readOnly}
                />
                {leadForEdit?.product && projects && (
                  <>
                    <h3 className={"mt-14"}>
                      <FormattedMessage id="PRODUCT.TITLE.INFORMATION" />
                    </h3>
                    <hr />
                    <ProductEditForm
                      actionsLoading={actionsLoading}
                      product={leadForEdit.product}
                      projects={projects}
                      saveProductFields={saveLeadProductFields}
                      submitProduct={null}
                      disabled={true}
                      hiddenFields={["projectId"]}
                    />
                  </>
                )}
              </>
            )}

          {tab === "project" && !!leadForEdit?.projectId && leadForEdit?.project && (
            <ProjectEditForm
              project={leadForEdit?.project}
              saveProjectFields={null}
              submitProject={null}
              disabled={true}
            />
          )}
          {tab === "property" && id && leadForEdit && (
            <ProductEditInformationForm
              actionsLoading={actionsLoading}
              product={leadForEdit.product}
              saveProductFields={null}
              disabled={true}
              projects={projects}
            />
          )}
          {tab === "files" && !!leadForEdit?.id && (
            <FilesTab leadForEdit={leadForEdit} readOnly={readOnly} />
          )}
          {tab === "photos" && id && !!product && (
            <>
              {leadForEdit?.product?.projectId && (
                <>
                  {!!project && (
                    <Photos
                      item={project}
                      actions={projectActions}
                      parentType={"project"}
                      displayLabel={true}
                      isLoading={isLoadingProject}
                    />
                  )}
                </>
              )}
              <div>
                {!!product && (
                  <Photos
                    item={product}
                    actions={productActions}
                    parentType={"product"}
                    displayLabel={true}
                    isLoading={isLoadingProduct}
                  />
                )}
              </div>
            </>
          )}
          {tab === "notes" && leadForEdit && canSeeNote(groups, session, "LEAD") && (
            <TextEditor
              name={"notes"}
              data={leadForEdit?.notes}
              saveMethod={saveLeadFields}
              disabled={readOnly}
            />
          )}
          {tab === "clients" && leadForEdit?.id && canSeeClientsTabInLead(groups, session) && (
            <UsersUIProvider
              readOnly={!(canEdit(groups, session, "LEAD") && leadClients.length > 1)}
              context={"leadClients"}
              uiEvents={clientsUIEvents}
              userType={CLIENT}
            >
              {canEdit(groups, session, "LEAD") && (
                <>
                  <RemoveClientDialog
                    show={action === "removeClient" && !!queryId}
                    id={queryId}
                    leadId={leadForEdit.id}
                    onHide={() => {
                      setAction("");
                      setQueryId("");
                    }}
                  />
                  <AddClientDialog
                    id={leadForEdit.id}
                    show={action === "addClient"}
                    onHide={() => {
                      setAction("");
                      setQueryId("");
                    }}
                  />
                  <ClientsActions
                    openAddClientToLead={() => {
                      setAction("addClient");
                    }}
                  />
                </>
              )}
              <UsersTable />
            </UsersUIProvider>
          )}
          {tab === "tasks" && leadForEdit && id && (
            <TasksPageTab
              {...{
                triggerOpenNewTaskDialog,
                contentView,
                toggleContentView,
                history,
                selectedSort,
                relatedToName: leadForEdit.name,
                relatedTo: `LEAD#${leadForEdit.id}`,
              }}
            />
          )}
          {tab === "history" && canSeeAuditTrail(groups, session, "LEAD") && (
            <AuditTrailViewer currentEntity={leadForEdit} />
          )}

            {!REACT_APP_HIDE_LEAD_PROVISIONAL_PLAN &&
              tab === "instalments" &&
              leadForEdit?.budgetInstalments && (
                <EntityEditInstalments
                  budgetInstalments={leadForEdit?.budgetInstalments ?? []}
                  disabled={true}
                  componentTable={LeadInstalmentTable}
                  entities={[
                    {
                      financialDetails: {
                        price: leadForEdit.sellingPrice,
                        shareOfLand: leadForEdit.shareOfLandSellingPrice,
                        architectEngineeringFees: leadForEdit.architectEngineeringFees,
                      },
                    },
                  ]}
                />
              )}
          </div>
        </CardBody>
      </Card>
    </LeadProvider>
  );
}
