import * as React from "react";
import { useState } from "react";
import { API } from "@aws-amplify/api";
import PropTypes from "prop-types";
import {
  KycComplianceViewMode,
  KycEventBody,
  KycFlow,
  KycFlowStatus,
  KycLoading,
  KycNotification,
  KycSignatory,
  KycSignatureDocumentType,
  KycStepEvent,
} from "../modules/kyc-v2/Kyc";
import { useHistory } from "react-router-dom";
import { useLoading } from "../hooks/useLoading";
import { useWizard } from "../hooks/useWizard";
import { isEmpty, mergeWith, set } from "lodash-es";
import { useLocation } from "react-router";
import { useKycConfirmation } from "../hooks/useKycConfirmation";
import { isKycFlowInTerminalState, WIZARD_STEP_DEFINITION } from "../modules/kyc-v2/KycUtils";
import { useSession } from "../hooks/useSession";
import { useAuditTrail } from "../hooks/useAuditTrail";
import { useIntl } from "react-intl";

type KycV2Props = {
  children: React.ReactNode;
};

export interface KycV2ContextValue {
  createKycFlow: (flow: KycFlow) => Promise<void>;
  deleteKycFlow: (kycId: string) => Promise<void>;
  updateKycFlow: (flow: KycFlow, forceUpdate?: boolean) => Promise<void>;
  updateKycFlowStatus: (flow: KycFlow, status: KycFlowStatus) => Promise<void>;
  updateKycFlowSignatories: (flow: KycFlow, signatories: KycSignatory[]) => Promise<void>;
  getKycFlow: (kycId: string, options?: { type?: string; createAudit?: boolean }) => Promise<any>;
  getKycByUserId: (userId: string) => Promise<any>;
  currentKycFlows: any[];
  currentKycClients: any[];
  getKycClients: () => Promise<any>;
  setCurrentKycClientLeads: (kycCurrentClientLeads: any[]) => void;
  currentKycClientLeads: any[];
  getKycClientLeads: (clientId: string) => Promise<any>;
  sendKycRequest: (kycId: string, notification: KycNotification) => Promise<any>;
  rejectAndSendKycRequest: (
    kycId: string,
    notification: KycNotification,
    message: string
  ) => Promise<any>;

  currentKycFlow?: KycFlow;
  setCurrentKycFlow: (updatedCurrentFlow: KycFlow | undefined) => void;
  setDisabledPdfGeneration: (disabled: boolean) => void;
  disabledPdfGeneration: boolean;
  completeKycFlowPhase: (kycId: string) => Promise<any>;
  signaturesComplete: (kycId: string) => Promise<any>;
  downloadKycPackage: (kycId: string) => Promise<void>;
  postBO: (kycId: string, body?: any) => Promise<any>;
  sendSignatureRequest: (
    kycId: string,
    signatories: KycSignatory[],
    signatureDocumentType: KycSignatureDocumentType,
    notification: KycNotification
  ) => Promise<any>;
  createKycEventMessage: (kycId: string, eventName: KycStepEvent, options?: any) => Promise<void>;
}

export const KycContext = React.createContext<KycV2ContextValue>({
  createKycFlow: (flow) => Promise.resolve(),
  updateKycFlow: (flow, forceUpdate) => Promise.resolve(),
  updateKycFlowStatus: (flow, status) => Promise.resolve(),
  updateKycFlowSignatories: (flow: KycFlow, signatories: KycSignatory[]) => Promise.resolve(),
  deleteKycFlow: (kycId) => Promise.resolve(),
  getKycFlow: (kycId, options?) => Promise.resolve(),
  getKycByUserId: (userId) => Promise.resolve(),
  currentKycClients: [],
  currentKycFlows: [],
  getKycClients: () => Promise.resolve(),
  getKycClientLeads: (clientId) => Promise.resolve(),
  currentKycClientLeads: [],
  setCurrentKycClientLeads: (kycCurrentClientLeads) => undefined,
  sendKycRequest: (kycId, KycNotification) => Promise.resolve(),
  rejectAndSendKycRequest: (kycId, KycNotification, message) => Promise.resolve(),
  completeKycFlowPhase: (kycId) => Promise.resolve(),
  signaturesComplete: (kycId) => Promise.resolve(),
  currentKycFlow: undefined,
  downloadKycPackage: (kycId: string) => Promise.resolve(),
  setCurrentKycFlow: (updatedCurrentFlow) => undefined,
  setDisabledPdfGeneration: (disabled) => undefined,
  disabledPdfGeneration: false,
  postBO: (kycId, body) => Promise.resolve(),
  sendSignatureRequest: (kycId, signatories, signatureDocumentType, notification) =>
    Promise.resolve(),
  createKycEventMessage: (kycId, eventName, options) => Promise.resolve(),
});

export const KycV2Provider: React.FunctionComponent<KycV2Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const { children } = props;
  const { session } = useSession();
  const { addLoading, removeLoading } = useLoading();

  const { setActiveStep, activeStep, steps, isReadOnly } = useWizard();
  const { setShowModal } = useKycConfirmation();
  const { setEventsManually } = useAuditTrail();

  const [currentKycFlow, setCurrentKycFlowInternal] = useState<KycFlow>();
  const [currentKycClients, setCurrentKycClients] = useState<any[]>([]);
  const [currentKycClientLeads, setCurrentKycClientLeadsInternal] = useState<any[]>([]);
  const [disabledPdfGeneration, setDisabledPdfGeneration] = useState(false);
  const [currentKycFlows, setCurrentKycFlowsInternal] = useState<any[]>([]);

  const createKycFlow = async (flow: KycFlow) => {
    console.debug("create kyc flow", flow);
    addLoading(KycLoading.CREATE_KYC_FLOW);
    const body = { ...flow };

    await API.post("API", `/kyc`, { body })
      .then((response) => {
        setCurrentKycFlowInternal(response);
        setCurrentKycFlowsInternal([...currentKycFlows, response]);
        setActiveStep(0);

        history.push(
          `/clients/${flow?.form?.client?.id}/kyc/${flow.id}?vm=${KycComplianceViewMode.CREATED}`
        );
      })
      .catch((e) => {})
      .finally(() => {
        removeLoading(KycLoading.CREATE_KYC_FLOW);
      });
  };

  const sendKycRequest = async (kycId: string, notification: KycNotification) => {
    console.debug("send kyc request", kycId, notification);
    addLoading(KycLoading.CREATE_KYC_FLOW_REQUEST);
    const body = { ...notification };

    await API.post("API", `/kyc/${kycId}/request`, { body })
      .then((response) => {
        setCurrentKycFlowInternal(response);
        setActiveStep(0);
      })
      .finally(() => {
        removeLoading(KycLoading.CREATE_KYC_FLOW_REQUEST);
      });
  };

  const rejectAndSendKycRequest = async (
    kycId: string,
    notification: KycNotification,
    message: string
  ) => {
    console.debug("send kyc request", kycId, notification);
    addLoading(KycLoading.CREATE_KYC_FLOW_REQUEST);
    const body = { notification: notification, message: message };

    await API.post("API", `/kyc/${kycId}/reject`, { body })
      .then((response) => {
        setCurrentKycFlowInternal(response);
      })
      .finally(() => {
        removeLoading(KycLoading.CREATE_KYC_FLOW_REQUEST);
      });
  };

  const getKycByUserId = async (userId: string) => {
    console.debug("get kycs for user id", userId);
    addLoading(KycLoading.LIST_KYC_FOR_USER_ID);

    await API.get("API", `/kyc/clients/kyc/${userId}`, {})
      .then((response) => {
        const userKycs: KycFlow[] = response.kycs;
        const filtered = userKycs?.filter((kyc: KycFlow) => kyc?.form?.client?.id === userId);
        setCurrentKycFlowsInternal(filtered);
      })
      .finally(() => {
        removeLoading(KycLoading.LIST_KYC_FOR_USER_ID);
      });
  };

  const completeKycFlowPhase = async (kycId: string) => {
    console.debug("complete kyc form", kycId);
    addLoading(KycLoading.COMPLETE_KYC_FLOW_PHASE);
    const body = { accepted: true };

    await API.post("API", `/kyc/${kycId}/complete`, { body })
      .then((response) => {
        setCurrentKycFlowInternal(response);
      })
      .finally(() => {
        removeLoading(KycLoading.COMPLETE_KYC_FLOW_PHASE);
      });
  };

  const updateFlowList = (response: any) => {
    const updatedCurrentKycFlows = [...currentKycFlows];
    let indexKycToUpdate = updatedCurrentKycFlows.findIndex((kycFlow: KycFlow) =>
      response?.kyc?.id ? kycFlow.id === response?.kyc?.id : kycFlow.id === response.id
    );

    if (indexKycToUpdate !== -1) {
      updatedCurrentKycFlows[indexKycToUpdate] = mergeWith(
        updatedCurrentKycFlows[indexKycToUpdate],
        response?.diff || response
      );
      setCurrentKycFlowsInternal(updatedCurrentKycFlows);
    } else {
      updatedCurrentKycFlows.push(response);
      setCurrentKycFlowsInternal(updatedCurrentKycFlows);
    }
  };

  const updateKycFlow = async (flow: KycFlow, forceUpdate?: boolean) => {
    if ((isReadOnly && !forceUpdate) || isKycFlowInTerminalState(flow)) {
      console.debug("no update");
      return;
    }

    console.debug("update kyc", flow?.id, forceUpdate);
    if (flow?.id === "0") {
      return;
    }
    addLoading(KycLoading.UPDATE_KYC_FLOW);

    const body = {
      ...flow,
      isGeneratePdf: true,
      events: [],
      currentPage:
        flow?.status === KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING
          ? WIZARD_STEP_DEFINITION.STEP_RISK_EVALUATION
          : steps[activeStep],
    };
    setCurrentKycFlowInternal(flow);
    updateFlowList(flow);

    await API.post("API", `/kyc/${flow.id}`, { body })
      .then((response) => {
        updateFlowList(response);
        if (response.kyc) {
          const updatedKycFlow = mergeWith(currentKycFlow, response?.kyc);
          setCurrentKycFlowInternal(updatedKycFlow);
        } else {
          const updatedKycFlow = mergeWith(currentKycFlow, response);
          setCurrentKycFlowInternal(updatedKycFlow);
        }
      })
      .finally(() => {
        removeLoading(KycLoading.UPDATE_KYC_FLOW);
      });
  };

  const updateKycFlowStatus = async (kyc: KycFlow, status: KycFlowStatus) => {
    if (isKycFlowInTerminalState(kyc)) {
      return;
    }
    console.debug("update kyc status", kyc?.id);
    addLoading(KycLoading.UPDATE_KYC_FLOW);
    updateFlowList({ ...kyc, status: status });
    const body = {
      isGeneratePdf: true,
      status: status,
      riskAssessmentStatus: kyc.riskAssessmentStatus,
    };
    await API.post("API", `/kyc/${kyc?.id}`, { body })
      .then((response) => {
        const updatedKycFlow = mergeWith(currentKycFlow, response?.kyc ? response.kyc : response);
        setCurrentKycFlowInternal(updatedKycFlow);
      })
      .finally(() => {
        removeLoading(KycLoading.UPDATE_KYC_FLOW);
      });
  };

  const updateKycFlowSignatories = async (kyc: KycFlow, signatories: KycSignatory[]) => {
    if (isKycFlowInTerminalState(kyc)) {
      return;
    }
    console.debug("update kyc signatories", signatories);
    addLoading(KycLoading.UPDATE_KYC_FLOW);

    const body = { isGeneratePdf: true, signatories: signatories };
    await API.post("API", `/kyc/${kyc?.id}`, { body })
      .then((response) => {
        updateFlowList(response);

        const updatedKycFlow = mergeWith(currentKycFlow, response?.diff);
        setCurrentKycFlowInternal(updatedKycFlow);
      })
      .finally(() => {
        removeLoading(KycLoading.UPDATE_KYC_FLOW);
      });
  };

  const deleteKycFlow = async (kycId: string) => {
    addLoading(KycLoading.DELETE_KYC_FORM);
    console.debug("delete kyc", kycId);
    await API.del("API", `/kyc/${kycId}`, {})
      .then((response) => {
        const currentKycs = [...currentKycFlows.filter((kycFlow) => kycFlow.id !== kycId)];
        setCurrentKycFlowsInternal(currentKycs);
        setShowModal(false);
        if (location?.pathname?.includes("/kyc/")) {
          const parts = location?.pathname.split("/");
          const clientId = parts.slice(2, 3)[0];
          if (clientId) {
            history.push(`/clients/${clientId}?t=compliance`);
          }
        }
      })
      .finally(() => {
        removeLoading(KycLoading.DELETE_KYC_FORM);
      });
  };

  const getKycFlow = async (
    kycId: string,
    options: { type?: string; createAudit?: boolean } = { createAudit: false }
  ) => {
    console.debug("get kyc", kycId);

    if (kycId === "0") {
      return;
    }
    addLoading(KycLoading.GET_KYC_FORM);

    await API.get("API", `/kyc/${kycId}`, {
      queryStringParameters: { createAudit: options?.createAudit },
    })
      .then((response) => {
        let res = response || {};
        //case where response is ok but has not content. This happens when client tries to access
        //the kyc flow form (e.g. using link), where kyc is still in progress,
        //but client hasn't been asked to review of fill out the form.

        //In this case, client doesn't retrieve kyc data and we only show the success screen"
        if (isEmpty(response)) {
          set(res, "form.client.id", session?.id);
        }
        if (options?.type === "REFRESH_KYC_FLOW_BENEFICIAL_OWNERS") {
          res = { ...currentKycFlow, signatories: response?.signatories };
        }
        updateFlowList(res);
        setCurrentKycFlowInternal(res);
      })
      .finally(() => {
        removeLoading(KycLoading.GET_KYC_FORM);
      });
  };

  const getKycClients = async () => {
    addLoading(KycLoading.GET_KYC_CLIENTS);
    await API.get("API", `/kyc/clients`, {})
      .then((response) => {
        setCurrentKycClients(response);
      })
      .finally(() => {
        removeLoading(KycLoading.GET_KYC_CLIENTS);
      });
  };

  const getKycClientLeads = async (clientId: string) => {
    addLoading(KycLoading.GET_KYC_CLIENT_LEADS);
    await API.get("API", `/kyc/clients/${clientId}`, {})
      .then((response) => {
        setCurrentKycClientLeads(response);
      })
      .finally(() => {
        removeLoading(KycLoading.GET_KYC_CLIENT_LEADS);
      });
  };

  const downloadKycPackage = async (kycId: string) => {
    addLoading(KycLoading.DOWNLOAD_KYC_PACKAGE);
    await API.post("API", `/kyc/${kycId}/files/download`, { body: { lang: intl?.locale } })
      .then((response) => {})
      .catch(() => {
        removeLoading(KycLoading.DOWNLOAD_KYC_PACKAGE);
      });
  };

  const postBO = async (kycId: string, body?: any) => {
    addLoading(KycLoading.GENERATE_BO);
    await API.post("API", `/kyc/${kycId}/bo`, { body: body })
      .then((response) => {
        setCurrentKycFlowInternal(response);
      })
      .catch()
      .finally(() => {
        removeLoading(KycLoading.GENERATE_BO);
      });
  };

  const setCurrentKycFlow = (updatedCurrentFlow: KycFlow | undefined) => {
    setCurrentKycFlowInternal(updatedCurrentFlow);
  };

  const signaturesComplete = async (kycId: string) => {
    addLoading(KycLoading.SIGNATURES_COMPLETE);

    await API.post("API", `/kyc/${kycId}/signaturesComplete`, {})
      .then((response) => {
        if (response.kyc) {
          const updatedKycFlow = mergeWith(currentKycFlow, response?.kyc);
          setCurrentKycFlowInternal(updatedKycFlow);
        }
      })
      .finally(() => {
        removeLoading(KycLoading.SIGNATURES_COMPLETE);
      });
  };

  //signature
  const sendSignatureRequest = async (
    kycId: string,
    signatories: KycSignatory[],
    signatureDocumentType: KycSignatureDocumentType,
    notification: KycNotification
  ) => {
    addLoading(KycLoading.SEND_SIGNATURE_REQUEST);

    const body = {
      signatories: signatories,
      signatureDocumentType: signatureDocumentType,
      notification: notification,
    };

    await API.post("API", `/kyc/${kycId}/request/signature`, { body })
      .then((response) => {
        updateFlowList(response);

        const updatedKycFlow = mergeWith(currentKycFlow, response?.diff || response);
        setCurrentKycFlowInternal(updatedKycFlow);
      })
      .finally(() => {
        removeLoading(KycLoading.SEND_SIGNATURE_REQUEST);
      });
  };

  const createKycEventMessage = async (kycId: string, eventName: KycStepEvent, options?: any) => {
    const body: KycEventBody = { status: eventName, options };
    await API.post("API", `/kyc/${kycId}/audit`, { body }).then((response) => {
      response?.items && setEventsManually(response?.items);
    });
  };

  const setCurrentKycClientLeads = (kycClientLeads: any[]) => {
    setCurrentKycClientLeadsInternal(kycClientLeads);
  };

  return (
    <KycContext.Provider
      value={{
        currentKycFlow,
        createKycFlow,
        setCurrentKycFlow,
        signaturesComplete,
        disabledPdfGeneration,
        setDisabledPdfGeneration,
        updateKycFlow,
        updateKycFlowStatus,
        updateKycFlowSignatories,
        deleteKycFlow,
        getKycFlow,
        currentKycClients,
        getKycClients,
        getKycClientLeads,
        currentKycClientLeads,
        setCurrentKycClientLeads,
        sendKycRequest,
        sendSignatureRequest,
        rejectAndSendKycRequest,
        completeKycFlowPhase,
        postBO,
        getKycByUserId,
        currentKycFlows,
        downloadKycPackage,
        createKycEventMessage,
      }}
    >
      {children}
    </KycContext.Provider>
  );
};

KycV2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycV2Consumer = KycContext.Consumer;
