import * as Yup from "yup";
import { SUBCONTRACTOR } from "../../../../../../_utils/userTypes";
import { isFinanceFile } from "../../../../../../../data/schemas";

export interface UserFileFormValidationSchemaProps {
  uploadingFile: boolean;
  uploadOrEdit: boolean;
  rectoVerso: boolean;
}

export const UserFileFormValidationSchema = ({
  uploadingFile,
  uploadOrEdit,
  rectoVerso,
}: UserFileFormValidationSchemaProps) =>
  Yup.object({
    file: uploadingFile ? Yup.mixed().required() : Yup.mixed(),
    fileBack: Yup.mixed().when("fileType", {
      is: "ID",
      then: (schema) => (!rectoVerso && uploadingFile ? schema.required() : schema),
    }),
    friendlyName: Yup.string().min(2).max(200).required().label("COMMON.NAME"),
    fileType: Yup.string().required().label("COMMON.TYPE"),
    userType: Yup.string(),
    projectId: Yup.string(),
    categories: uploadOrEdit
      ? Yup.array().when(["userType", "fileType"], {
          is: (userType: string, fileType: string) =>
            userType === SUBCONTRACTOR && isFinanceFile(fileType),
          then: Yup.array().of(
            Yup.object().shape({
              budgetLineCategoryId: Yup.string().when("budgetLineCategoryLabel", {
                is: undefined,
                then: (schema) => schema.required(),
              }),
              budgetLineCategoryLabel: Yup.string().required(),
              lines: Yup.array().of(
                Yup.object().shape({
                  id: Yup.string(),
                  budgetLineId: Yup.string().when("budgetLineLabel", {
                    is: undefined,
                    then: (schema) => schema.required(),
                  }),
                  budgetLineLabel: Yup.string().required(),
                  vat: Yup.number(),
                  amount: Yup.number(),
                })
              ),
            })
          ),
        })
      : Yup.array(),
    visible: Yup.bool(),
    notifyUser: Yup.array().of(Yup.string()),
    enableReInvoicing: Yup.bool(),
    reInvoiceFile: Yup.object().when("enableReInvoicing", {
      is: true,
      then: Yup.object().shape({
        budgetInstalmentId: Yup.string(),
        leadId: Yup.string().label("LEAD.TITLE.SINGLE").required(),
        fileName: Yup.string().label("COMMON.FILE_NAME").required(),
        details: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required(),
            amount: Yup.number(),
            vat: Yup.number(),
            margin: Yup.number(),
          })
        ),
        includeFileToLeadFile: Yup.bool(),
        useQuantities: Yup.bool(),
      }),
    }),
  });
