import { BootstrapTableProps, PaginationOptions, SortOrder } from "react-bootstrap-table-next";
import { KycTranslation } from "../../KycTranslation";
import { SearchTypeValues } from "../../../../contexts/SearchContext";

export const defaultPaginationOptions: Partial<PaginationOptions> = {
  custom: true,
  pageStartIndex: 1,
  page: 1,
  sizePerPage: 10,
  paginationSize: 10,
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
  ],
};

export const fileExplorerPaginationOptions: Partial<PaginationOptions> = {
  ...defaultPaginationOptions,
  sizePerPage: 20,
  paginationSize: 20,
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 20 },
  ],
};

export const initialFilter = {
  filter: {
    text: "",
  },
  filterSelected: [],
  pageNumber: 1,
  pageSize: 25,
};

export const defaultSorted: [{ dataField: any; order: SortOrder }] = [
  { dataField: "updatedAt", order: "desc" },
];

export const defaultBootstrapTableOptions: Partial<BootstrapTableProps> = {
  wrapperClasses: "table-responsive",
  classes: "table table-head-custom table-vertical-center",
  bordered: false,
  condensed: true,
  bootstrap4: true,
  hover: true,
  defaultSorted: defaultSorted,
};

export const KYC_FILE_EXPLORER_TEXT_SEARCH_FILTER = {
  name: "COMMON.SEARCH.TITLE",
  value: "text",
  type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
  columns: [
    { value: "labels.entityName", label: "COMMON.NAME" },
    { value: "labels.entity", label: KycTranslation.COLUMN_NAMES_ENTITY },
    { value: "labels.evidenceCategory", label: KycTranslation.EVIDENCE_TITLE },
    { value: "labels.evidenceDocumentType", label: "COMMON.TYPE" },
    { value: "labels.filename", label: KycTranslation.PLACEHOLDER_FILENAME },
  ],
};
