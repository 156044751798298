import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//----------------------------------------------------------------------------//

const getTimeInTwoMinutes = () => {
  const fewMinutesLater = new Date();
  fewMinutesLater.setMinutes(fewMinutesLater.getMinutes() + 2);
  return fewMinutesLater;
};

export type TEmptyCallbackFn = () => void;
export type TRequestDocSignFn = (otpCode: string) => void;

export interface SignatureOtpModalProps {
  isSigning?: boolean;
  isInvalidOtp?: boolean;
  show?: boolean;

  requestOtpCode: TEmptyCallbackFn;
  requestDocSign: TRequestDocSignFn;

  onHide: TEmptyCallbackFn;
}

export const SignatureOtpModal: React.FunctionComponent<SignatureOtpModalProps> = ({
  isSigning = false,
  isInvalidOtp = false,
  show = false,

  requestOtpCode,
  requestDocSign,

  onHide,
}) => {
  const [code, setCode] = React.useState("");
  const [nextResendCode, setNextResendCode] = React.useState(getTimeInTwoMinutes());

  const buttonResendCodeClick = () => {
    requestOtpCode();
    setNextResendCode(getTimeInTwoMinutes());
  };

  const triggerDocSign = () => {
    requestDocSign(code);
  };

  const innerOnHide = () => {
    setCode("");
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={innerOnHide}
      aria-labelledby="signature-information-modal"
      centered
      size={"lg"}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={"SIGN.ENTER_CODE"} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          <FormattedMessage id={"SIGN.CODE"} />
          {isInvalidOtp && (
            <span className="text-danger">
              <FormattedMessage id="AUTH.VALIDATION.INVALID" values={{ name: "" }} />
            </span>
          )}
        </label>
        <input
          data-cy="input-signature-otp"
          type={"text"}
          className={"form-control"}
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className={"text-right"}>
          <button
            type="button"
            className="btn btn-outline-primary mr-4"
            onClick={() => buttonResendCodeClick()}
            disabled={(nextResendCode && nextResendCode > new Date()) || isSigning}
          >
            <FormattedMessage id={"SIGN.RESEND_CODE"} />
          </button>
          <button
            //8 = current otp length
            disabled={!code || (code && code.length !== 8) || isSigning}
            data-cy="button-signature-validate-and-sign"
            type="button"
            onClick={() => triggerDocSign()}
            className={`btn btn-primary btn-elevate ml-4 ${
              isSigning && "spinner spinner-white spinner-right"
            }`}
          >
            <FormattedMessage id={"SIGN.VALIDATE_AND_SIGN"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignatureOtpModal;
