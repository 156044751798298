import * as usersCrud from "../../../UsersManagement/_redux/usersCrud";
import { callTypes, TASKS_CHUNKED_FAILED_MESSAGE, tasksSlice } from "./tasksSlice";
import * as tasksCrud from "./tasksCrud";
import { dashboardSlice } from "../../../Dashboard/_redux/dashboardSlice";
import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../../Common/SnackbarNotificationsHandler";

const contextualActions = {
  default: tasksSlice("tasks").actions,
  dashboard: dashboardSlice("dashboard").actions,
};

const actions = contextualActions.default;
const actionsDashboard = contextualActions.dashboard;

export const updateTaskLocally = (taskForEdit) => (dispatch) => {
  dispatch(actions.taskUpdatedLocally({ taskForEdit }));
};

export const updateTaskFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.taskFieldUpdatedLocally({ key, value }));
};

export const fetchTasks =
  (queryParams = {}) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list, queryParams }));
    return tasksCrud
      .listTasks(queryParams)
      .then((entities) => {
        dispatch(actions.tasksFetched({ entities }));
      })
      .catch((error) => {
        // console.log("error:", error);
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.QUERY_TASKS, dispatch);
      });
  };

export const fetchChunkedTasks =
  (queryParams = {}) =>
  (dispatch) => {
    const clearEntities = queryParams.chunks === "true";
    dispatch(
      actions.startCall({
        callType: callTypes.chunk,
        clearEntities,
        queryParams,
      })
    );
    tasksCrud
      .listTasks(queryParams, dispatch, actions.tasksChunkedFetching)
      .then((entities) => {
        const Items = [];
        const qp = [];
        entities?.forEach((e) => {
          if (e?.Items?.length > 0) {
            Items.push(...e.Items);
            if (e.LastEvaluatedKey) {
              qp.push(e.Items[0].status + "|" + e.LastEvaluatedKey);
            }
          }
        });
        const listLoading = qp.length !== 0;
        dispatch(
          actions.tasksChunkedFetched({
            entities: Items,
            chunkLoading: listLoading,
            clearEntities,
          })
        );
        if (listLoading) {
          dispatch(fetchChunkedTasks({ ...queryParams, signal: queryParams.signal, chunks: qp }));
        }
      })
      .catch((error) => {
        if (error?.message !== TASKS_CHUNKED_FAILED_MESSAGE) {
          dispatch(actions.catchError({ error, callType: callTypes.chunk }));
          processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.QUERY_TASKS, dispatch);
        }
      });
  };

export const taskFetched = (tasks) => (dispatch) => {
  dispatch(actions.tasksFetched({ entities: tasks }));
};

export const cancelFetchChunking = () => (dispatch) => {
  dispatch(actions.taskChunkedCanceled());
};

export const fetchRelatedToItems = (intl) => (dispatch) => {
  return tasksCrud
    .fetchRelatedToItemOptions(intl)
    .then((entities) => {
      dispatch(actions.relatedToItemOptionsFetched({ entities }));
    })
    .catch((error) => {
      console.log("error:", error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.QUERY_TASKS, dispatch);
    });
};

export const fetchAssignees =
  (queryParams = {}, intl) =>
  (dispatch) => {
    return usersCrud
      .getByUserType(queryParams, intl)
      .then((response) => {
        const users = response.items;
        dispatch(actions.assigneesFetched({ users: users }));
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_USERS, dispatch);
      });
  };

export const fetchTaskById =
  (id, displayLoader = true) =>
  (dispatch) => {
    if (!id) {
      return dispatch(actions.taskFetched({ entityForEdit: undefined }));
    }

    if (displayLoader) {
      dispatch(actions.startCall({ callType: callTypes.action }));
    }
    return tasksCrud
      .getById(id)
      .then((response) => {
        const entityForEdit = response;
        dispatch(actions.taskFetched({ entityForEdit }));
      })
      .catch((error) => {
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_TASKS, dispatch);
      });
  };

export const createTask = (task, showSnackbar) => (dispatch) => {
  return tasksCrud
    .createTask(task)
    .then((taskResponse) => {
      dispatch(actions.taskCreated({ task: taskResponse }));
      dispatch(actionsDashboard.taskCreated({ task: taskResponse }));
      if (showSnackbar) {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_TASK, dispatch);
      }
      return taskResponse;
    })
    .catch((error) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_TASK, dispatch);
    });
};

export const updateTask =
  (task, filterFromTasks = false) =>
  (dispatch) => {
    dispatch(actions.taskUpdated({ task, filterFromTasks }));

    // avoid subtasks duplication
    const taskToUpdate = { ...task, subtasks: [] };

    return tasksCrud
      .updateTask(taskToUpdate)
      .then((taskResponse) => {
        dispatch(actions.taskUpdated({ task: taskResponse, filterFromTasks }));
      })
      .catch((error) => {
        console.log("updateTask error:", error);
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_TASK, dispatch);
      });
  };

export const updateComment = (comment) => (dispatch) => {
  return tasksCrud
    .updateComment(comment)
    .then((response) => {
      const { taskComment } = response;
      dispatch(actions.commentUpdated({ comment: taskComment }));
    })
    .catch((error) => {
      console.log("update comment error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_COMMENT, dispatch);
    });
};

export const createComment = (comment) => (dispatch) => {
  return tasksCrud
    .createComment(comment)
    .then((response) => {
      dispatch(actions.commentCreated({ comment: response }));
    })
    .catch((error) => {
      console.log("create comment error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_COMMENT, dispatch);
    });
};
export const deleteComment = (comment) => (dispatch) => {
  return tasksCrud
    .deleteComment(comment)
    .then((response) => {
      dispatch(actions.commentDeleted({ comment: comment }));
    })
    .catch((error) => {
      console.log("delete comment error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_COMMENT, dispatch);
    });
};

export const deleteTask = (taskId) => (dispatch) => {
  return tasksCrud
    .deleteTask(taskId)
    .then((taskResponse) => {
      dispatch(actions.taskDeleted({ taskId: taskId }));
      dispatch(actionsDashboard.taskDeleted({ taskId: taskId }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_TASK, dispatch);
    })
    .catch((error) => {
      console.log("deleteTask error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_TASK, dispatch);
    });
};

//----------------------------------------------------------------------------//

export const createSubask = (subtask, showSnackbar) => (dispatch) => {
  return tasksCrud
    .createTask(subtask)
    .then((taskResponse) => {
      dispatch(actions.taskCreated({ task: taskResponse, isSubtaskFlow: true }));
      dispatch(actionsDashboard.taskCreated({ task: taskResponse, isSubtaskFlow: true }));
      if (showSnackbar) {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_SUBTASK, dispatch);
      }
      return taskResponse;
    })
    .catch((error) => {
      console.log("createSubtask error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_SUBTASK, dispatch);
    });
};

export const updateSubtask = (subtask) => (dispatch) => {
  dispatch(actions.taskUpdated({ task: subtask, isSubtaskFlow: true }));
  return tasksCrud
    .updateTask(subtask)
    .then((subtaskResponse) => {
      dispatch(actions.taskUpdated({ task: subtaskResponse, isSubtaskFlow: true }));
    })
    .catch((error) => {
      console.log("updateSubtask error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_SUBTASK, dispatch);
    });
};

export const deleteSubtask = (subtask) => (dispatch) => {
  const { id: taskId, parentId: parentTaskId } = subtask;
  return tasksCrud
    .deleteTask(taskId)
    .then(() => {
      dispatch(actions.taskDeleted({ taskId, parentTaskId }));
      dispatch(actionsDashboard.taskDeleted({ taskId, parentTaskId }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_SUBTASK, dispatch);
    })
    .catch((error) => {
      console.log("deleteSubtask error:", error);
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_SUBTASK, dispatch);
    });
};

//----------------------------------------------------------------------------//
