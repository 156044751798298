import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface RealBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const RealBudgetCard: React.FC<RealBudgetCardProps & HeaderCardProps> = ({
  canSeeHiddenLine,
  project,
  actualBudget,
  ...props
}) => {
  const publicBudget = project.subcontractorsFinanceFiles
    ? project.subcontractorsFinanceFiles.orders.total.public +
      project.subcontractorsFinanceFiles.supplementary_agreements.total.public
    : 0;

  const hiddenBudget = actualBudget - publicBudget;

  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-actual-total"
          value={actualBudget}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={2}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REAL" />}
      {...props}
    >
      {canSeeHiddenLine && (
        <>
          <HeaderCardSubValue
            dataCy="input-budget-public-actual-total"
            label="COMMON.PUBLIC"
            value={publicBudget}
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
          <HeaderCardSubValue
            dataCy="input-budget-hidden-actual-total"
            label="COMMON.HIDDEN"
            value={hiddenBudget}
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        </>
      )}
    </HeaderCard>
  );
};
