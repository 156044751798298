import * as React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "clsx";

import { toAbsoluteUrl } from "_metronic/_helpers";
import { Card, CardBody, CardFooter, CardHeader } from "_metronic/_partials/controls";

import { DateUtils } from "app/_utils/DateUtils";

import { PdfViewer } from "app/modules/Common/PdfViewer/PdfViewer";

import { ISignatureInfo } from "data/schemas";

interface CardPDFSignedViewerProps {
  signatureInfo: Partial<ISignatureInfo>;
  fileUrl: string;

  setModalState?: Function;
  extraCardClassName?: string;
  disableSignButton?: boolean;
}

export const CardPDFSignedViewer: React.FC<CardPDFSignedViewerProps> = ({
  signatureInfo,
  fileUrl,
  setModalState,
  extraCardClassName,
  disableSignButton = false,
}) => {
  const intl = useIntl();

  return (
    <Card className={cn("h-100", extraCardClassName)}>
      <CardHeader title={intl.formatMessage({ id: "SIGN.TITLE" })} />
      <CardBody
        className={"p-0 d-flex align-items-center justify-content-center position-relative"}
        style={{ minHeight: "40vh" }}
      >
        <div className={"w-100 h-100 position-absolute"}>
          <PdfViewer fileUrl={fileUrl} />
        </div>
      </CardBody>
      <CardFooter className={"p-4 p-sm-6"}>
        <div className={"text-right"} data-cy="text-signature-signed-confirmation">
          {signatureInfo?.signedDate ? (
            <>
              {signatureInfo.sealedAt ? (
                <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg")} />
                </span>
              ) : (
                <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-done.svg")} />
                </span>
              )}

              {/* <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
                  </span> */}

              <FormattedMessage
                id="SIGN.SIGNED_AT"
                values={{
                  b: (chunks: any) => <b>{chunks}</b>,
                  date: DateUtils.format(new Date(signatureInfo.signedDate), intl, true),
                  user: signatureInfo.signedBy,
                }}
              />
            </>
          ) : (
            <>
              {!!setModalState && (
                <button
                  data-cy="button-signature-sign"
                  type="button"
                  className="btn btn-primary"
                  disabled={disableSignButton}
                  onClick={() => setModalState("INFO")}
                >
                  <FormattedMessage id={"SIGN.ACTION"} />
                </button>
              )}
            </>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};
