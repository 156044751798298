import { IntlShape } from "react-intl";
import { DateUtils } from "./DateUtils";
import { formatDisplayNameMeIntl } from "./userUtils";
import { sortBy } from "lodash";
import { ENTITY_ICON } from "./listUtils";
import { ILead, IProduct, IProject, IUser } from "../../data/schemas";

interface Options {
  id: string;
  label: string;
  icon: string;
  color: string;
  options: any[];
}

interface Option {
  id: string;
  name: string;
  value?: any;
}

export interface DataSelectFields {
  projects: (IProject | Option)[];
  products: (IProduct | Option)[];
  leads: (ILead | Option)[];
  users: (IUser | Option)[];
  clients: (IUser | Option)[];
  subcontractors: (IUser | Option)[];
}

export const formatDataUtils = (data: any, intl: IntlShape): any => {
  switch (typeof data) {
    case "boolean": {
      if (data) {
        return intl.formatMessage({ id: "COMMON.ACTION.YES" });
      }
      return intl.formatMessage({ id: "COMMON.ACTION.NO" });
    }
    case "string": {
      return DateUtils.formatDateString(data, intl);
    }
    case "number": {
      return data;
    }
    default: {
      return "";
    }
  }
};

export function formatDataSelect(
  data: DataSelectFields,
  intl: IntlShape,
  shouldTranslate: boolean = true
) {
  const response: { items: Options[] } = {
    items: [],
  };

  if (data.projects?.length > 0) {
    response.items = [
      {
        id: "projects",
        label: intl.formatMessage({ id: "PROJECT.TITLE" }),
        ...ENTITY_ICON.PROJECT,
        options: sortBy(
          [
            ...data.projects.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "PROJECT",
              ...ENTITY_ICON.PROJECT,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.products?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "products",
        label: intl.formatMessage({ id: "PRODUCT.TITLE" }),
        ...ENTITY_ICON.PRODUCT,
        options: sortBy(
          [
            ...data.products?.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "PRODUCT",
              ...ENTITY_ICON.PRODUCT,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.leads?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "leads",
        label: intl.formatMessage({ id: "LEAD.TITLE" }),
        ...ENTITY_ICON.LEAD,
        options: sortBy(
          [
            ...data.leads.map((i: any) => ({
              label: i.name,
              value: i.value ?? i,
              type: "LEAD",
              ...ENTITY_ICON.LEAD,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.clients?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "clients",
        label: intl.formatMessage({ id: "CLIENT.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.clients.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "CLIENT",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.users?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "users",
        label: intl.formatMessage({ id: "USER.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.users.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "USER",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }
  if (data.subcontractors?.length > 0) {
    response.items = [
      ...response.items,
      {
        id: "subcontractors",
        label: intl.formatMessage({ id: "SUBCONTRACTOR.TITLE" }),
        ...ENTITY_ICON.USER,
        options: sortBy(
          [
            ...data.subcontractors.map((i: any) => ({
              label: shouldTranslate ? formatDisplayNameMeIntl(intl, i) : i.name,
              value: i.value ?? i,
              type: "SUBCONTRACTOR",
              ...ENTITY_ICON.USER,
            })),
          ],
          "label"
        ),
      },
    ];
  }

  return response;
}
