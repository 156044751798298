import React from "react";
import SVG from "react-inlinesvg";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { getFilesForKycFile, handleDownloadKycFile, orderByLang } from "../../../KycFileUtils";
import { KycFileItemLang } from "./KycFileItemLang";
import { KycBoTableMode, KycFile } from "../../../Kyc";
import { IntlShape } from "react-intl";
import cn from "clsx";
import { isEmpty, orderBy } from "lodash-es";

interface FormatExtraDataProps {
  intl: IntlShape;
  setCurrentDrawerFiles: Function;
  deleteFile: Function;
  files: KycFile[];
  mode?: KycBoTableMode;
  setCurrentKycFlow: Function;
}

const getLatestLangFilesForLang = (sourceFiles: KycFile[], lang: string) => {
  const fEN = sourceFiles?.filter((file: KycFile) => file?.lang === lang);
  if (fEN?.length === 1) {
    return fEN?.[0];
  }

  const orderedByUpdatedDesc = orderBy(
    fEN,
    [
      function (object: KycFile) {
        return new Date(object.updatedAt)?.getTime();
      },
    ],
    ["desc"]
  );

  return orderedByUpdatedDesc?.[0];
};

export const KycFileDownloadColumnFormatter: ColumnFormatter<any, FormatExtraDataProps> = (
  cell,
  file,
  rowIndex,
  formatExtraData
) => {
  let filesToDisplay = getFilesForKycFile(cell || file, formatExtraData?.files);

  if (filesToDisplay && Object.values(filesToDisplay).length > 3) {
    filesToDisplay = [
      getLatestLangFilesForLang(filesToDisplay, "en-GB"),
      getLatestLangFilesForLang(filesToDisplay, "fr-FR"),
      getLatestLangFilesForLang(filesToDisplay, "de-DE"),
    ];
  }

  return (
    <div className="d-flex justify-content-end align-items-right">
      {/*MULTI-LANG FILE DOWNLOAD BUTTONS */}
      <span className={cn(!isEmpty(filesToDisplay) && "d-flex px-2")}>
        {filesToDisplay &&
          formatExtraData?.files &&
          orderByLang(Object.values(filesToDisplay), formatExtraData?.intl)?.map(
            (file: KycFile, index) => (
              <KycFileItemLang key={"file-lang" + index} file={file} index={index} />
            )
          )}
      </span>

      {/*SINGLE FILE DOWNLOAD BUTTON */}
      {filesToDisplay?.length === 1 && (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={(e: any) => handleDownloadKycFile(e, filesToDisplay?.[0])}
        >
          <span className="svg-icon svg-icon-sm svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
          </span>
        </button>
      )}
    </div>
  );
};
