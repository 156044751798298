import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface PlannedBudgetCardProps {
  canSeeHiddenLine: boolean;
  plannedTotal: number;
  publicPlannedTotal?: number;
  hiddenPlannedTotal?: number;
}

export const PlannedBudgetCard: React.FC<PlannedBudgetCardProps & HeaderCardProps> = ({
  canSeeHiddenLine,
  plannedTotal,
  publicPlannedTotal,
  hiddenPlannedTotal,
  ...props
}) => {
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-planned-total"
          value={plannedTotal}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={2}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.PLANNED" />}
      {...props}
    >
      {canSeeHiddenLine && (
        <>
          <HeaderCardSubValue
            dataCy="input-budget-public-planned-total"
            label="COMMON.PUBLIC"
            value={publicPlannedTotal}
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
          <HeaderCardSubValue
            dataCy="input-budget-hidden-planned-total"
            label="COMMON.HIDDEN"
            value={hiddenPlannedTotal}
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        </>
      )}
    </HeaderCard>
  );
};
