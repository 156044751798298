import * as React from "react";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { sumBy } from "lodash-es";
import cn from "clsx";
import {
  IRelatedFinancialDocument,
  ISettings,
  isLeadFinancialDocumentPriceLine,
} from "../../../../../../../../data/schemas";
import { NumberFormatValues } from "react-number-format";
import { useFormikContext } from "formik";
import { ILeadFileInvoiceForm } from "./LeadFileInvoiceForm";
import { MixVATTooltip, WarningVATTooltip } from "./LeadInvoiceTooltips";

export interface LeadInvoiceProps {
  financialDocument: IRelatedFinancialDocument;
  lineIndex: number;
  financialDocumentIndex: number;
  settings: ISettings;
  constructionPriceWithReducedVat?: number;
  currentConstructionPriceWithReducedVat?: number;
  isLineFinancialDocument: boolean;
}

export const LeadInvoiceSA: React.FunctionComponent<LeadInvoiceProps> = ({
  financialDocument,
  lineIndex,
  financialDocumentIndex,
  settings,
  constructionPriceWithReducedVat,
  currentConstructionPriceWithReducedVat,
  isLineFinancialDocument,
}) => {
  const { setFieldValue } = useFormikContext<ILeadFileInvoiceForm>();
  return (
    <>
      {!isLineFinancialDocument && (
        <div className="d-flex flex-grow-1 align-items-center mt-4 ml-4">
          <span
            className="font-size-xs font-weight-bold mr-2 flex-shrink-0"
            data-cy={`input-lead-invoice-${lineIndex}-doc-${financialDocumentIndex}-title`}
          >
            {financialDocument.title}
          </span>
          <div className="separator separator-solid separator-border-2 flex-grow-1" />
          <NumberInput
            displayType="text"
            suffix={SUFFIX_EURO_CURRENCY}
            className="font-size-sm font-weight-bold ml-2 mr-3"
            value={sumBy(financialDocument.content, "amount")}
            data-cy={`input-lead-invoice-${lineIndex}-doc-${financialDocumentIndex}-amount`}
          />
        </div>
      )}
      {financialDocument.content.map((financialDocumentLine, financialDocumentLineIndex) => (
        <div className="form-row flex-grow-1 ml-3 mt-2" key={financialDocumentLineIndex}>
          <div className={cn("d-flex align-items-center col")}>
            <div className="flex-grow-1">
              <div
                className="form-control form-control-sm border-0 h-auto"
                data-cy={`input-lead-invoice-${lineIndex}-doc-${financialDocumentIndex}-line-${financialDocumentLineIndex}-label`}
              >
                {financialDocumentLine.label}
              </div>
            </div>
          </div>
          {isLeadFinancialDocumentPriceLine(financialDocumentLine) && (
            <>
              <div className="col max-w-70px d-flex">
                <NumberInput
                  name={`content.${lineIndex}.relatedFinancialDocuments.${financialDocumentIndex}.content.${financialDocumentLineIndex}.vat`}
                  data-cy={`input-lead-invoice-${lineIndex}-doc-${financialDocumentIndex}-line-${financialDocumentLineIndex}-vat`}
                  className="form-control form-control-sm text-right"
                  suffix={SUFFIX_PERCENTAGE}
                  decimalScale={0}
                  value={financialDocumentLine.vat * 100}
                  onValueChange={(e: NumberFormatValues) =>
                    setFieldValue(
                      `content.${lineIndex}.relatedFinancialDocuments.${financialDocumentIndex}.content.${financialDocumentLineIndex}.vat`,
                      (e.floatValue ?? 0) / 100
                    )
                  }
                />
              </div>
              <div className="col max-w-150px d-flex align-items-center">
                <NumberInput
                  displayType="text"
                  suffix={SUFFIX_EURO_CURRENCY}
                  className="form-control form-control-sm text-right border-0"
                  value={financialDocumentLine.amount}
                  data-cy={`input-lead-invoice-${lineIndex}-doc-${financialDocumentIndex}-line-${financialDocumentLineIndex}-amount`}
                />
                {financialDocumentLine.hasMixVat && (
                  <MixVATTooltip
                    line={financialDocumentLine}
                    constructionPriceWithReducedVat={constructionPriceWithReducedVat}
                    currentConstructionPriceWithReducedVat={currentConstructionPriceWithReducedVat}
                    defaultVat={settings.defaultVat}
                    reducedVat={settings.reducedVat}
                  />
                )}
                {financialDocumentLine.hasWarningVat && <WarningVATTooltip />}
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};
