import { KycInfoBoxBig } from "./KycInfoBoxBig";
import * as React from "react";
import { ReactNode } from "react";
import { useTranslation } from "../../../hooks/useTranslation";

interface KycFormSectionTitleProps {
  title?: string;
  subLabel?: string;
  infoLabel?: string;
  children?: ReactNode;
}

export const KycFormSectionTitle = ({
  title,
  subLabel,
  infoLabel,
  children,
}: KycFormSectionTitleProps): JSX.Element => {
  const { fm } = useTranslation();
  return (
    <>
      {title && (
        <div
          className="d-flex justify-content-between align-items-center pb-4 pt-lg-5"
          style={{ paddingRight: 12 }}
        >
          <div>
            <div className="d-flex align-items-center ">
              <h6 className="text-left my-auto">{fm(title)}</h6>
              {infoLabel && <KycInfoBoxBig label={infoLabel} />}
            </div>
            <div>{subLabel && fm(subLabel)}</div>
          </div>
          {children}
        </div>
      )}
    </>
  );
};
