import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "app/modules/Common/SnackbarNotificationsHandler";
import { createSignatureRequest } from "app/modules/UsersManagement/_redux/usersCrud";

import { ISignaturesRequestBody, ISignaturesResponseBody } from "data/schemas";

export const requestFileSignature = (
  actions: any,
  signatureRequest: ISignaturesRequestBody,
  dispatch: Function,
  callTypes: any
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return createSignatureRequest(signatureRequest)
    .then((signatureResponse: ISignaturesResponseBody) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REQUEST_FILE_SIGNATURE, dispatch);

      dispatch(actions.signatureRequestCreated({ signatureResponse }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.REQUEST_FILE_SIGNATURE, dispatch);
    });
};
