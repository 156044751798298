import { IImmotoolEntity } from "./ImmotoolEntity";
import { SubcontractorFinanceType } from "./Project";

export interface IBudget extends IImmotoolEntity {
  projectId: string;
  projectName: string;
  projectOwner: any;
  name: string;
  sortedLines: TBudgetSortedLines;
  budgetInstalments: TBudgetInstalments;
  hiddenLineExist?: boolean;
  plannedTotal?: number;
  budgetStatus?: string;
}

export type TBudgetSortedLines = IBudgetSortedCategory[];

export type TBudgetInstalments = IBudgetInstalment[];

export interface IBudgetSortedCategory extends IImmotoolEntity {
  id: string;
  budgetId?: string;
  label: string;
  sequence: number;
  visible: boolean;
  lines: IBudgetLine[];
  chosen?: boolean;
  selected?: boolean;
}

export interface IBudgetLine extends IImmotoolEntity {
  id: string;
  label: string;
  budgetId?: string;
  categoryId?: string;
  plannedBudget: number;
  sequence: number;
  chosen?: boolean;
  selected?: boolean;
}

export interface IFormattedBudgetCategory extends TFormattedBudgetLine {
  lines: TFormattedBudgetLine[];
  unspecifiedLines?: TFormattedBudgetLine[];
}

export const BudgetLineColumns = {
  PLANNED: "planned",
  QUOTES: "quotes",
  ORDERS: "orders",
  SUPPLEMENTARY_AGREEMENTS: "supplementary_agreements",
  ORDERS_AND_SUPPL_AGREEMENTS: "ordersAndSupplAgreements",
  REAL_DIFFERENCE: "realDifference",
  REAL_DIFFERENCE_PERCENTAGE: "realDifferencePercentage",
  INVOICED: "invoiced",
  INVOICED_PERCENTAGE: "invoicedPercentage",
  REMAINING_TO_INVOICE: "remainingToInvoice",
  INVOICED_ORDERS: "invoicedOrders",
  REMAINING_ORDERS_TO_INVOICE: "remainingOrdersToInvoice",
  INVOICED_ORDERS_PERCENTAGE: "invoicedOrdersPercentage",
  INVOICED_SUPPLEMENTARY_AGREEMENTS: "invoicedSupplementaryAgreements",
  REMAINING_SUPPLEMENTARY_AGREEMENTS_TO_INVOICE: "remainingSupplementaryAgreementsToInvoice",
  INVOICED_SUPPLEMENTARY_AGREEMENTS_PERCENTAGE: "invoicedSupplementaryAgreementsPercentage",
  DISCOUNTS: "discounts",
} as const;

const {
  PLANNED,
  QUOTES,
  SUPPLEMENTARY_AGREEMENTS,
  ORDERS,
  INVOICED,
  INVOICED_ORDERS,
  INVOICED_SUPPLEMENTARY_AGREEMENTS,
  DISCOUNTS,
} = BudgetLineColumns;
const { PLANNED: removeProps, ...rest } = BudgetLineColumns;
export const StaticBudgetLineColumns = rest;
export const BudgetLineBaseProps = {
  PLANNED,
  QUOTES,
  SUPPLEMENTARY_AGREEMENTS,
  ORDERS,
  INVOICED,
  INVOICED_ORDERS,
  INVOICED_SUPPLEMENTARY_AGREEMENTS,
  DISCOUNTS,
};

export const BudgetLineIncludingTaxProps = {
  QUOTE_INCLUDING_TAX: "quotes_including_tax",
  SUPPLEMENTARY_AGREEMENT_INCLUDING_TAX: "supplementary_agreements_including_tax",
  ORDER_INCLUDING_TAX: "orders_including_tax",
  INVOICE_INCLUDING_TAX: "invoiced_including_tax",
  INVOICED_ORDERS_INCLUDING_TAX: "invoicedOrders_including_tax",
  INVOICED_SUPPLEMENTARY_AGREEMENTS_INCLUDING_TAX: "invoicedSupplementaryAgreements_including_tax",
  DISCOUNTS_INCLUDING_TAX: "discounts_including_tax",
};
export type BudgetLineBasePropsType = typeof BudgetLineBaseProps[keyof typeof BudgetLineBaseProps];

export type BudgetLineColumnsTypes = typeof BudgetLineColumns[keyof typeof BudgetLineColumns];

export type TFormattedBudgetLine = {
  id?: string;
  label?: any;
  subcontractors?: any;
  vat?: number;
} & Record<BudgetLineColumnsTypes, string>;

export enum BudgetFinanceType {
  QUOTE = "quotes",
  SUPPLEMENTARY_AGREEMENT = "supplementary_agreements",
  ORDER = "orders",
  INVOICED = "invoiced",
}

export const SFFRelatedBudgetFinanceType = {
  [SubcontractorFinanceType.QUOTE]: BudgetFinanceType.QUOTE,
  [SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT]: BudgetFinanceType.SUPPLEMENTARY_AGREEMENT,
  [SubcontractorFinanceType.ORDER]: BudgetFinanceType.ORDER,
  [SubcontractorFinanceType.INVOICE]: BudgetFinanceType.INVOICED,
} as const;

export enum BudgetFinanceIncludingTaxType {
  QUOTE_INCLUDING_TAX = "quotes_including_tax",
  SUPPLEMENTARY_AGREEMENT_INCLUDING_TAX = "supplementary_agreements_including_tax",
  ORDER_INCLUDING_TAX = "orders_including_tax",
  INVOICE_INCLUDING_TAX = "invoiced_including_tax",
}

export const SFFRelatedBudgetFinanceIncludingTaxType = {
  [SubcontractorFinanceType.QUOTE]: BudgetFinanceIncludingTaxType.QUOTE_INCLUDING_TAX,
  [SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT]:
    BudgetFinanceIncludingTaxType.SUPPLEMENTARY_AGREEMENT_INCLUDING_TAX,
  [SubcontractorFinanceType.ORDER]: BudgetFinanceIncludingTaxType.ORDER_INCLUDING_TAX,
  [SubcontractorFinanceType.INVOICE]: BudgetFinanceIncludingTaxType.INVOICE_INCLUDING_TAX,
} as const;

export type SFFRelatedBudgetFinanceTypes =
  typeof SFFRelatedBudgetFinanceType[keyof typeof SFFRelatedBudgetFinanceType];

export interface IBudgetInstalment extends IImmotoolEntity {
  id: string;
  budgetId?: string;
  label: string;
  instalment: number;
  sequence: number;
  chosen?: boolean;
  selected?: boolean;
}
