import React from "react";
import {
  KycBoTableMode,
  KycBoType,
  KycFlowStatus,
  KycNotificationDestination,
  KycSignatory,
  KycStepEvent,
} from "../../../Kyc";
import { useTranslation } from "../../../../../hooks/useTranslation";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { useFileViewerDrawer } from "../../../../../hooks/useFileViewerDrawer";
import {
  getStatusProperties,
  getTranslationCategory,
  KycStatusMode,
} from "../../../KycTranslationUtils";
import { sortDateFunc } from "../../../../../_utils/columnUtils";
import { useIntl } from "react-intl";
import { KycTranslation } from "../../../KycTranslation";
import {
  getSignatureByBoId,
  insertIf,
  isStatus,
  isStatusBetweenInclusive,
} from "../../../KycUtils";
import cn from "clsx";
import { useKyc } from "../../../../../hooks/useKyc";
import { defaultBootstrapTableOptions, defaultPaginationOptions } from "../definitions";
import { isEmpty } from "lodash-es";
import { KycFileItemLangChoose } from "../column-formatters/KycFileItemLangChoose";
import { KycBoDeclarationDownloadColumnFormatter } from "../column-formatters/KycBoDeclarationDownloadColumnFormatter";
import { KycBoDeclarationSingleDownloadColumnFormatter } from "../column-formatters/KycBoDeclarationSingleDownloadColumnFormatter";
import { KycNotificationChoose } from "../column-formatters/KycNotificationChoose";
import { KycUploadInputSimple } from "../../controls/kyc-upload-simple/KycUploadInputSimple";
import { getFileForId } from "../../../KycFileUtils";

interface KycBeneficialOwnerTableProps {
  mode: KycBoTableMode;
  setSignatories: Function;
  signatories: KycSignatory[];
}

export const KycBeneficialOwnerTable = ({
  mode,
  signatories,
  setSignatories,
}: KycBeneficialOwnerTableProps): JSX.Element => {
  const intl = useIntl();
  const { fm, fPhoneNumber } = useTranslation();
  const { setCurrentDrawerFiles } = useFileViewerDrawer();
  const { currentKycFlow, updateKycFlowSignatories, createKycEventMessage } = useKyc();

  const getBoTypeName = (boType: KycBoType) => {
    switch (boType) {
      case KycBoType.NATURAL_PERSON:
        return fm(KycTranslation.BO_TYPE_NATURAL_PERSON);
      case KycBoType.LEGAL_PERSON:
        return fm(KycTranslation.BO_TYPE_LEGAL_PERSON);
      case KycBoType.LEGAL_PERSON_MORE_25:
        return fm(KycTranslation.BO_TYPE_LEGAL_PERSON_MORE_25);
    }
  };

  const updateSignatory = (updatedSignatory: KycSignatory) => {
    const indexOfSignatory = signatories?.findIndex(
      (signatory) => signatory?.id === updatedSignatory?.id
    );
    if (indexOfSignatory > -1) {
      const updatedSignatories = [...signatories];
      updatedSignatories[indexOfSignatory] = updatedSignatory;

      setSignatories(updatedSignatories);
    }
  };

  const updateSignatoryNotificationMethods = (signatoryNotification: any) => {
    const indexOfSignatory = signatories?.findIndex(
      (signatory) => signatory?.id === signatoryNotification?.signatoryId
    );
    const s: KycSignatory = { ...signatories[indexOfSignatory] };
    if (s) {
      if (
        signatoryNotification?.notificationMethods?.includes(KycNotificationDestination.OFFLINE)
      ) {
        s.boDeclaration.isOfflineSignatory = true;
        s.boDeclaration.notificationMethods = [];
      } else {
        s.boDeclaration.isOfflineSignatory = false;
        s.boDeclaration.notificationMethods = signatoryNotification?.notificationMethods;
      }
    }
    updateSignatory(s);
  };

  const updateSignatoryBoDeclarationLang = (signatoryParams: any) => {
    const indexOfSignatory = signatories?.findIndex(
      (signatory) => signatory?.id === signatoryParams?.signatoryId
    );
    const s: KycSignatory = { ...signatories[indexOfSignatory] };
    if (s) {
      s.boDeclaration.fileId = s?.files?.[signatoryParams?.lang]?.id;
      s.boDeclaration.lang = signatoryParams?.lang;
    }
    updateSignatory(s);
  };

  const updateSignatoryBoDeclarationDownloadAt = (signatoryParams: any) => {
    const indexOfSignatory = signatories?.findIndex(
      (signatory) => signatory?.id === signatoryParams?.signatoryId
    );
    const s: KycSignatory = { ...signatories[indexOfSignatory] };
    if (s) {
      s.boDeclaration.downloadedAt = signatoryParams?.downloadedAt;
    }
    updateSignatory(s);

    if (s?.boDeclaration?.isOfflineSignatory) {
      currentKycFlow &&
        createKycEventMessage(currentKycFlow?.id, KycStepEvent.ADMIN_DOWNLOAD_PDF_FOR_BO, {
          boName: s?.fullName,
          boEmail: s?.email,
        });
    }

    currentKycFlow && updateKycFlowSignatories(currentKycFlow, signatories);
  };

  const isLangSet = () => {
    return !isEmpty(signatories.filter((signatory) => signatory?.boDeclaration?.lang !== ""));
  };

  const basicColumns: ColumnDescription[] = [
    {
      dataField: "fullName",
      headerClasses: "text-left text-nowrap",
      classes: "",
      text: fm(KycTranslation.BO_NAME),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      headerClasses: "text-nowrap",
      classes: "",
      text: fm("USER.INFO.EMAIL"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any) => <span>{getTranslationCategory(cell, fm)}</span>,
    },
    {
      dataField: "mobile",
      headerClasses: "text-left text-nowrap",
      classes: "",
      text: fm("USER.INFO.MOBILE"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any) => <>{cell && fPhoneNumber(cell)}</>,
    },
    {
      dataField: "type",
      headerClasses: "text-nowrap",
      classes: "",
      text: fm("COMMON.TYPE"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any) => <>{getBoTypeName(cell)}</>,
    },
  ];

  const isBoDeclarationUploadDisabled = (boDeclaration: any) => {
    if (currentKycFlow) {
      return !isStatus(currentKycFlow?.status, KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING);
    }
    return true;
  };

  const columns: ColumnDescription[] = [
    ...basicColumns,
    {
      dataField: "id",
      headerClasses: "text-nowrap",
      classes: "",
      text: fm("SIGN.TITLE"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any) => {
        const statusProps = getStatusProperties(
          getSignatureByBoId(currentKycFlow, cell)?.status,
          KycStatusMode.BO,
          fm
        );

        return (
          <div style={{ fontSize: "1.2em", fontWeight: 400 }}>
            <span className={cn("label label-lg label-inline", statusProps?.color)}>
              <div style={{ fontSize: "1.0em" }}>
                {statusProps?.label || fm("KYC.V2.STATUS_BO_OVERALL_DRAFT")}
              </div>
            </span>
          </div>
        );
      },
    },
    {
      dataField: "boDeclarationUpload",
      isDummyField: true,
      headerClasses: "text-nowrap",
      classes: "align",
      text: fm("FILE.ACTIONS.UPLOAD.NEW"),
      formatter: (cell: any, row: any) => (
        <>
          {isStatusBetweenInclusive(
            currentKycFlow?.status as KycFlowStatus,
            KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED,
            KycFlowStatus.BO_DECLARATIONS_SIGNATURE_PENDING
          ) && (
            <div>
              {row?.boDeclaration?.isOfflineSignatory && (
                <KycUploadInputSimple
                  disabled={isBoDeclarationUploadDisabled(row?.boDeclaration)}
                  file={currentKycFlow && getFileForId(currentKycFlow, row?.boDeclaration?.fileId)}
                  signatory={row}
                />
              )}
            </div>
          )}
        </>
      ),
    },
    {
      dataField: "boDeclarationNotificationChannelChoice",
      isDummyField: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: fm(KycTranslation.BO_DECLARATION_NOTIFICATION),
      sort: true,
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      formatter: (cell: any, row: any) => (
        <div className={cn(!isEmpty(cell?.files) && "d-flex")}>
          <KycNotificationChoose
            cell={cell}
            row={row}
            mode={mode}
            updateSignatoryNotificationMethods={updateSignatoryNotificationMethods}
          />
        </div>
      ),
    },
    ...insertIf(true, {
      dataField: "files",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: fm("COMMON.LANGUAGE"),
      sort: true,
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      formatter: (cell: any, row: any) => (
        <>
          <div className={cn(!isEmpty(cell?.files) && "d-flex")}>
            <KycFileItemLangChoose
              files={currentKycFlow?.files ?? []}
              cell={cell}
              signatory={row}
              mode={mode}
              updateSignatoryBoDeclarationLang={updateSignatoryBoDeclarationLang}
            />
          </div>
        </>
      ),
    }),
    ...insertIf(!isLangSet(), {
      dataField: "filesGood",
      headerClasses: "text-center",
      classes: "text-right",
      sort: true,
      text: "",
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      formatter: KycBoDeclarationDownloadColumnFormatter,
      formatExtraData: {
        intl,
        files: currentKycFlow?.files,
        setCurrentDrawerFiles: setCurrentDrawerFiles,
        mode: mode,
      },
    }),
    ...insertIf(isLangSet(), {
      dataField: "boDeclaration",
      headerClasses: "text-center",
      classes: "text-right",
      text: fm("COMMON.ACTIONS"),
      sort: true,
      sortCaret: sortCaret,
      sortFunc: sortDateFunc,
      formatter: KycBoDeclarationSingleDownloadColumnFormatter,
      formatExtraData: {
        files: currentKycFlow?.files,
        setCurrentDrawerFiles: setCurrentDrawerFiles,
        mode: mode,
        updateSignatoryBoDeclarationDownloadAt: updateSignatoryBoDeclarationDownloadAt,
        fm: fm,
      },
    }),
  ];

  return (
    <PaginationProvider
      pagination={paginationFactory({
        ...defaultPaginationOptions,
        totalSize: currentKycFlow?.signatories?.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          // <Pagination isLoading={isLoading} paginationProps={paginationProps}>
          <BootstrapTable
            key={"kyc-bo-table-" + mode + "-" + currentKycFlow?.status}
            {...paginationTableProps}
            {...defaultBootstrapTableOptions}
            hover={false}
            keyField="id"
            data={signatories}
            columns={columns}
            rowStyle={{ cursor: "pointer" }}
            noDataIndication={() => (
              <NoRecordsFoundMessage
                entities={signatories}
                messageId={KycTranslation.NO_SIGNATORIES_AVAILABLE}
              />
            )}
          >
            <PleaseWaitMessage entities={signatories} />
          </BootstrapTable>
          // </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
