import type { FC, ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

interface LoadingContextValue {
  loadings: Set<string>;
  addLoading: (type?: string, id?: string) => void;
  removeLoading: (type?: string, id?: string) => void;
  setProgress: (type?: string, id?: string) => void;
  isCurrentlyLoading: (type?: string, id?: string) => boolean;
  loadingsCount: number;
}

interface LoadingProviderProps {
  children?: ReactNode;
}

export const LoadingContext = createContext<LoadingContextValue>({
  loadings: new Set(),
  addLoading: (type, id) => undefined,
  setProgress: (type, id) => undefined,
  removeLoading: (type, id) => undefined,
  isCurrentlyLoading: (type, id) => false,
  loadingsCount: 0,
});

export const LoadingProvider: FC<LoadingProviderProps> = (props) => {
  const { children } = props;
  const [loadingsCount, setLoadingsCount] = useState<number>(0);
  const [loadings, setLoadings] = useState<Set<string>>(new Set());

  const getKey = (type = "", id = "") => (type && id ? `${type}_${id}` : type || id || "");

  useEffect(() => {
    setLoadingsCount(Object.keys(loadings)?.length);
  }, [loadings]);

  const addLoading = (type?: string, id?: string) => {
    setLoadings((current) => new Set(current)?.add(getKey(type, id)));
  };

  const removeLoading = (type?: string, id?: string) => {
    setLoadings((current) => {
      const currentLoadings = new Set(current);
      currentLoadings?.delete(getKey(type, id));
      return currentLoadings;
    });
  };

  const isCurrentlyLoading = (type?: string, id?: string) => {
    if (!type && !id) {
      return false;
    }
    return loadings.has(getKey(type, id));
  };

  const setProgress = (type?: string, id?: string) => {};

  return (
    <LoadingContext.Provider
      value={{
        loadings,
        addLoading,
        setProgress,
        removeLoading,
        loadingsCount,
        isCurrentlyLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LoadingsConsumer = LoadingContext.Consumer;
