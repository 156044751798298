import * as React from "react";
import cn from "clsx";
import { DropdownMenu } from "../actions-menu/DropdownMenu";
import { DropdownItem } from "../actions-menu/DropdownItem";
import { DeletePopover } from "./DeletePopover";

export enum CustomCardLineSize {
  "SM" = "SM",
  "MD" = "MD",
  "LG" = "LG",
}

export interface CustomCardLineProps {
  draggable?: boolean;
  remove?: Function;
  removable?: boolean;
  confirmRemove?: boolean;
  customClasses?: {
    container?: string;
    dragButton?: string;
    actionsButton?: string;
  };
  actions?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  size?: CustomCardLineSize;
}

export const CustomCardLine = React.forwardRef<HTMLDivElement, CustomCardLineProps>(
  (
    {
      children,
      draggable,
      remove,
      removable,
      confirmRemove = true,
      customClasses,
      actions,
      size = CustomCardLineSize.MD,
      ...props
    },
    ref
  ) => {
    const [showRemove, setShowRemove] = React.useState(false);
    const targetRemove = React.useRef<HTMLButtonElement>(null);

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "bg-white border-bottom d-flex align-items-center p-2",
          draggable && "draggable-line",
          size === CustomCardLineSize.SM && "min-h-35px",
          size === CustomCardLineSize.MD && "min-h-45px",
          size === CustomCardLineSize.LG && "min-h-55px",
          customClasses?.container
        )}
      >
        {draggable ? (
          <button
            type="button"
            className={cn(
              "btn btn-icon btn-xs mr-2 line-handle w-15px position-relative",
              customClasses?.dragButton
            )}
            tabIndex={-1}
            onClick={(e) => e.stopPropagation()}
          >
            <i className="ki ki-more-ver position-absolute" style={{ left: "-1px" }} />
            <i className="ki ki-more-ver position-absolute" style={{ left: "3px" }} />
          </button>
        ) : (
          <div className={"w-15px mr-2"}></div>
        )}
        {children}
        {!!actions || remove ? (
          <DropdownMenu
            containerClass={cn("ml-4", customClasses?.actionsButton)}
            buttonRef={targetRemove}
            disabled={!actions && !removable}
          >
            {actions}
            {removable && typeof remove !== "undefined" && (
              <>
                <DropdownItem
                  onClick={() => {
                    if (confirmRemove) {
                      setShowRemove(!showRemove);
                    } else {
                      remove();
                    }
                  }}
                  icon={"/media/svg/icons/General/Trash.svg"}
                  title={"COMMON.DELETE"}
                  classes={{ container: "text-danger", svg: "svg-icon-danger" }}
                />
                <DeletePopover
                  {...{
                    showRemove,
                    targetRemove: targetRemove.current!,
                    remove,
                    setShowRemove,
                    title: "BUDGET.LINE.DELETE",
                  }}
                />
              </>
            )}
          </DropdownMenu>
        ) : (
          <div
            className={"btn btn-icon btn-clean btn-sm ml-4 h-1px py-0"}
            style={{ cursor: "auto" }}
          >
            <span className={"svg-icon svg-icon-md"}></span>
          </div>
        )}
      </div>
    );
  }
);
