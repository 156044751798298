import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FieldArray, FieldArrayRenderProps, FormikHelpers, useFormikContext } from "formik";
import { ReactSortable } from "react-sortablejs";
import { BudgetEditCategory } from "./components/BudgetEditCategory";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../../redux/hooks";
import { flatMap, isEqual, max } from "lodash-es";
import { useBudgetsUIContext } from "../BudgetsUIContext";
import { shallowEqual } from "react-redux";
import {
  computeCategory,
  formatCategory,
  formatContentColumns,
  sumBudgetLineBaseProps,
} from "../BudgetsUtils";
import {
  BudgetLineColumns,
  BudgetLineColumnsTypes,
  IBudget,
  IBudgetSortedCategory,
  StaticBudgetLineColumns,
  SubcontractorFinanceType,
} from "../../../../data/schemas";
import ScrollContainer from "react-indiana-drag-scroll";
import { downloadCSV } from "../components/BudgetExport";
import { useGetTextWidth } from "../../../_utils/useGetTextWidth";
import { useGetBudgetTextFont } from "./hooks/useGetBudgetTextFont";
import { useBreakpointChange } from "../../../_utils/useBreakpointChange";
import { BudgetExpensesHeader } from "./components/BudgetExpensesHeader/BudgetExpensesHeader";
import { CustomCard } from "../../../_components/CustomCard";
import { BudgetLineContent } from "./components/BudgetLineContent";
import cn from "clsx";

export interface IBudgetEditExpensesProps {
  saveBudgetFields: (key: string | string[], value: any) => void;
  budget: IBudget;
  disabled: boolean;
}

export const BudgetEditExpenses: React.FC<IBudgetEditExpensesProps> = ({
  saveBudgetFields,
  budget,
  disabled,
}) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<IBudget>();
  const {
    columnWidth,
    setColumnWidth,
    expandCategories,
    collapseCategories,
    setBudgetTotals,
    budgetTotals,
    displayedData,
    setDisplayedData,
  } = useBudgetsUIContext();
  const budgetTextFont = useGetBudgetTextFont();
  const getTextWidth = useGetTextWidth();

  const breakpointChange = useBreakpointChange();

  const addCategory = (
    e: React.MouseEvent<HTMLButtonElement>,
    categoryArrayHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    categoryArrayHelpers.push({
      id: uuid(),
      label: "",
      visible: true,
      lines: [{ id: uuid(), label: "", plannedBudget: 0 }],
    });
  };

  const moveCategory = (
    setFieldValue: FormikHelpers<IBudget>["setFieldValue"],
    sortedCategories: IBudgetSortedCategory[]
  ) => {
    if (sortedCategories.length) {
      setFieldValue("sortedLines", sortedCategories);
      const res = sortedCategories.map(({ chosen, selected, ...category }) => ({
        ...category,
        lines: category.lines.map(({ chosen, selected, ...line }) => line),
      }));
      if (!isEqual(res, budget.sortedLines)) {
        saveBudgetFields("sortedLines", res);
      }
    }
  };

  const { project, budgetLoading } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.current,
      budgetLoading: state.budgets.actionsLoading,
    }),
    shallowEqual
  );

  const headers: Record<BudgetLineColumnsTypes, string> = React.useMemo(
    () => ({
      planned: intl.formatMessage({ id: "BUDGET.PLANNED" }),
      quotes: intl.formatMessage({ id: "FINANCE.QUOTES" }),
      orders: intl.formatMessage({ id: "FINANCE.ORDERS" }),
      supplementary_agreements: intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }),
      ordersAndSupplAgreements: intl.formatMessage({
        id: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS",
      }),
      realDifference: intl.formatMessage({ id: "BUDGET.DIFFERENCE" }),
      realDifferencePercentage: intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }),
      invoiced: intl.formatMessage({ id: "BUDGET.INVOICED" }),
      invoicedPercentage: intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }),
      remainingToInvoice: intl.formatMessage({ id: "BUDGET.REMAINING_TO_INVOICE" }),
      invoicedOrders: intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS" }),
      remainingOrdersToInvoice: intl.formatMessage({ id: "BUDGET.REMAINING_ORDERS_TO_INVOICE" }),
      invoicedOrdersPercentage: intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS_PERCENT" }),
      invoicedSupplementaryAgreements: intl.formatMessage({
        id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
      }),
      remainingSupplementaryAgreementsToInvoice: intl.formatMessage({
        id: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
      }),
      invoicedSupplementaryAgreementsPercentage: intl.formatMessage({
        id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
      }),
      discounts: intl.formatMessage({ id: "BUDGET.DISCOUNTS" }),
    }),
    [intl]
  );

  React.useEffect(() => {
    if (budget) {
      // Create an object before render with all readonly values in order to calculate the width of the largest object in each column
      const hasUnspecifiedCategory = Object.values(SubcontractorFinanceType).some(
        (financeType) =>
          !!project?.subcontractorsFinanceFiles?.[financeType]?.data?.unspecifiedCategory
      );
      const computedCategories = budget.sortedLines?.map((category) =>
        computeCategory(category.id, project, category)
      );
      const computedUnspecifiedCategory =
        hasUnspecifiedCategory && computeCategory("unspecifiedCategory", project);
      setBudgetTotals(
        sumBudgetLineBaseProps([
          ...computedCategories,
          ...(computedUnspecifiedCategory ? [computedUnspecifiedCategory] : []),
        ])
      );
      const displayedDataRes = computedCategories?.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: formatCategory(category, intl),
        }),
        {}
      );
      if (hasUnspecifiedCategory) {
        displayedDataRes.unspecifiedCategory = formatCategory(
          { ...computedUnspecifiedCategory, label: <FormattedMessage id="BUDGET.NOT_SPECIFIED" /> },
          intl
        );
      }
      setDisplayedData(displayedDataRes);
    }
    return () => {
      setDisplayedData(undefined);
    };
  }, [project, budget]);

  const formattedBudgetTotals = formatContentColumns({ ...budgetTotals, intl });

  React.useEffect(() => {
    /**
     * When all data is ready we set the column width with the biggest element we have for each column.
     */
    if (displayedData) {
      const textsLengths = Object.values(BudgetLineColumns).reduce(
        (acc, column) => ({
          ...acc,
          [column]: [getTextWidth(headers[column], budgetTextFont.headers) + 1],
        }),
        {} as Record<BudgetLineColumnsTypes, number[]>
      );
      textsLengths[BudgetLineColumns.PLANNED].push(100); // 100 is for the minimum input size of planned line
      for (const category of [...Object.values(displayedData), formattedBudgetTotals]) {
        for (const column of Object.values(BudgetLineColumns)) {
          textsLengths[column].push(
            getTextWidth(category[column], budgetTextFont.categoryHeaders) + 1
          );
        }
      }
      const flattenedLines = flatMap(Object.values(displayedData), "lines");
      for (const line of flattenedLines) {
        for (const column of Object.values(BudgetLineColumns)) {
          textsLengths[column].push(getTextWidth(line[column], budgetTextFont.lines) + 1);
        }
      }
      setColumnWidth(
        Object.values(BudgetLineColumns).reduce(
          (acc, column) => ({ ...acc, [column]: max(textsLengths[column]) ?? 0 }),
          {} as Record<BudgetLineColumnsTypes, number>
        )
      );
    }
  }, [displayedData, breakpointChange]);
  return (
    <>
      <BudgetExpensesHeader budget={budget} />
      <div className="d-flex justify-content-end mb-4">
        <OverlayTrigger
          overlay={
            <Tooltip id="budgets-expand-tooltip">
              <FormattedMessage id="TOOLTIP.EXPAND_ALL" />
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-light-secondary mr-3"
            onClick={expandCategories}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Size.svg")} />
            </span>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="budgets-collapse-tooltip">
              <FormattedMessage id="TOOLTIP.COLLAPSE_ALL" />
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-light-secondary mr-3"
            onClick={collapseCategories}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Scale.svg")} />
            </span>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="budgets-download-tooltip">
              <FormattedMessage id="TOOLTIP.EXPORT_CSV" />
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-light-secondary"
            onClick={() => {
              downloadCSV(budget, project, values, intl);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      </div>
      {budget && displayedData && ((!budget.id && !budgetLoading) || !!project) ? (
        <ScrollContainer
          hideScrollbars={false}
          ignoreElements={"input, .card-handle, .line-handle"}
          className={"form-group d-flex pr-4"}
          style={{ fontFamily: "Roboto" }}
        >
          <div className={"flex-grow-1"}>
            <div className="d-flex px-2 pb-2" style={{ fontFamily: "'Roboto Mono', monospace" }}>
              <div className={"btn ml-4 h-1px py-0"} style={{ cursor: "auto" }}>
                <span className={"svg-icon svg-icon-md"}></span>
              </div>
              <div
                className="d-flex flex-grow-1 font-size-xs font-weight-bold line-height-sm"
                style={{ color: "#6a6a6a" }}
              >
                <div className="flex-shrink-0">
                  <div className="w-200px w-sm-250px w-md-300px w-lg-350px" />
                </div>
                <div
                  className={"px-2 col text-right"}
                  style={{ minWidth: columnWidth?.planned, boxSizing: "content-box" }}
                >
                  {headers.planned}
                </div>
                {budget.id && budget.id === project?.selectedBudget && (
                  <>
                    {Object.values(StaticBudgetLineColumns).map(
                      (column: BudgetLineColumnsTypes) => (
                        <div
                          className={"px-2 col text-right"}
                          style={{ minWidth: columnWidth[column], boxSizing: "content-box" }}
                          key={column}
                        >
                          {headers[column]}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
              <div className={"btn ml-4 h-1px py-0"} style={{ cursor: "auto" }}>
                <span className={"svg-icon svg-icon-md"}></span>
              </div>
            </div>
            <CustomCard
              headerClassName={"pl-10 bg-dark-o-30"}
              headerStyle={{ minHeight: "41px" }}
              parentClassName={"mb-4"}
              header={
                <>
                  <BudgetLineContent
                    columnWidth={columnWidth}
                    selectedBudget={!!budget?.id && budget.id === project?.selectedBudget}
                    isHeader={true}
                    labelClassName={"mr-6"}
                    line={{
                      ...formattedBudgetTotals,
                      label: (
                        <span className={"font-weight-bold"}>
                          <FormattedMessage id={"COMMON.TOTAL"} />
                        </span>
                      ),
                      realDifference: (
                        <span
                          className={cn(
                            formattedBudgetTotals?.realDifference &&
                              (formattedBudgetTotals?.realDifference.startsWith("-")
                                ? "text-success"
                                : "text-danger")
                          )}
                        >
                          {formattedBudgetTotals?.realDifference}
                        </span>
                      ),
                      realDifferencePercentage: (
                        <span
                          className={cn(
                            formattedBudgetTotals?.realDifference &&
                              (formattedBudgetTotals?.realDifference.startsWith("-")
                                ? "text-success"
                                : "text-danger")
                          )}
                        >
                          {formattedBudgetTotals?.realDifferencePercentage}
                        </span>
                      ),
                      remainingToInvoice: (
                        <span
                          className={cn(
                            formattedBudgetTotals?.remainingToInvoice !== "-" &&
                              formattedBudgetTotals?.remainingToInvoice.startsWith("-") &&
                              "text-danger"
                          )}
                        >
                          {formattedBudgetTotals?.remainingToInvoice}
                        </span>
                      ),
                    }}
                  />
                  <div className={"btn ml-4 h-1px py-0"} style={{ cursor: "auto" }}>
                    <span className={"svg-icon svg-icon-md"}></span>
                  </div>
                </>
              }
            ></CustomCard>
            <FieldArray
              name="sortedLines"
              validateOnChange={false}
              render={(categoryArrayHelpers) => (
                <>
                  <ReactSortable
                    list={values.sortedLines ?? []}
                    setList={(sortedCategories) => moveCategory(setFieldValue, sortedCategories)}
                    animation={150}
                    handle=".card-handle"
                    scroll={true}
                    bubbleScroll={true}
                  >
                    {values.sortedLines?.map((category, categoryIndex) => (
                      <BudgetEditCategory
                        id={category.id}
                        budget={budget}
                        displayedDataCategory={displayedData[category.id]}
                        category={category}
                        categoryIndex={categoryIndex}
                        categoryArrayHelpers={categoryArrayHelpers}
                        saveBudgetFields={saveBudgetFields}
                        key={category.id}
                        disabled={disabled}
                      />
                    ))}
                  </ReactSortable>
                  {budget.id &&
                    budget.id === project?.selectedBudget &&
                    displayedData.unspecifiedCategory && (
                      <BudgetEditCategory
                        budget={budget}
                        id={"unspecifiedCategory"}
                        displayedDataCategory={displayedData["unspecifiedCategory"]}
                        disabled={true}
                      />
                    )}
                  {!disabled && (
                    <button
                      type="button"
                      className="btn btn-light w-100 mt-2 d-flex align-items-center justify-content-start"
                      onClick={(e) => addCategory(e, categoryArrayHelpers)}
                    >
                      <i className="ki ki-plus icon-nm" />
                      <FormattedMessage id="BUDGET.ACTION.CATEGORY.ADD" />
                    </button>
                  )}
                </>
              )}
            />
          </div>
        </ScrollContainer>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
        </div>
      )}
    </>
  );
};
