import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface ActualInvoicedBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const ActualInvoicedBudgetCard: React.FC<ActualInvoicedBudgetCardProps & HeaderCardProps> =
  ({ canSeeHiddenLine, project, actualBudget, ...props }) => {
    const totalKey = canSeeHiddenLine ? "general" : "public";
    const totalInvoiced = project.subcontractorsFinanceFiles
      ? project.subcontractorsFinanceFiles.invoices.total[totalKey]
      : 0;
    const totalInvoicedPercentage =
      totalInvoiced && actualBudget ? (totalInvoiced / actualBudget) * 100 : 0;
    return (
      <HeaderCard
        mainContent={
          <NumberInput
            data-cy="input-budget-invoiced-total"
            value={totalInvoiced}
            displayType="text"
            suffix={SUFFIX_EURO_CURRENCY}
            decimalScale={2}
            fixedDecimalScale={true}
            className="ml-auto font-weight-bold"
          />
        }
        label={<FormattedMessage id="BUDGET.ACTUAL_BUDGET_INVOICED" />}
        {...props}
      >
        <HeaderCardSubValue
          dataCy="input-budget-invoiced-total-percentage"
          value={totalInvoicedPercentage}
          decimalScale={2}
          suffix={SUFFIX_PERCENTAGE}
        />
      </HeaderCard>
    );
  };
