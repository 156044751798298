/* eslint-disable jsx-a11y/anchor-is-valid */
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Switch, useLocation } from "react-router-dom";

import { ContentRoute } from "_metronic/layout";
import "_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { LanguageSelectorDropdown } from "_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { BRAND_URL } from "configuration";

import AcceptGDPR from "./AcceptGDPR";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import Login from "./Login";

export function AuthPage() {
  const { challengeName, session, isAuthorized } = useSelector(({ auth }) => {
    return {
      challengeName: auth.challengeName,
      session: auth.session,
      isAuthorized: auth.tokens?.accessToken != null,
    };
  }, shallowEqual);

  const location = useLocation();

  const redirectQueryParam =
    new URLSearchParams(location?.search).get("redirect_uri") ||
    (location.pathname.includes("/auth") || location.pathname.includes("/logout")
      ? ""
      : encodeURIComponent(location.pathname + location.search));

  const renderOrRedirect =
    (Component, options = {}) =>
    () => {
      const {
        shouldCheckChallengeName = true,
        shouldCheckAutorized = true,
        shouldCheckGDPR = true,
      } = options;

      if (shouldCheckChallengeName && challengeName === "NEW_PASSWORD_REQUIRED")
        return <Redirect to="/auth/change-password" />;

      if (shouldCheckGDPR && session && isAuthorized && !session.gdprApprovedAt)
        return <Redirect to="/auth/gdpr" />;

      if (shouldCheckAutorized && !challengeName && !isAuthorized)
        return <Redirect to="/auth/login" />;

      return <Component />;
    };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-30 p-lg-10"
            style={{
              backgroundImage: `url(${BRAND_URL}/bg_left_desktop_login.png)`,
            }}
          />
          {/*end::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Languages*/}
            <div className="d-flex  justify-content-end">
              <LanguageSelectorDropdown />
            </div>
            {/*end::Languages*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column flex-column-fluid flex-center mb-10 mb-sm-20">
              <img
                src={`${BRAND_URL}/logo_dark.png`}
                alt="Logo"
                className="max-w-180px max-w-lg-200px max-h-120px mb-10 mb-sm-20"
              />

              <Switch>
                <ContentRoute
                  path="/auth/login"
                  render={renderOrRedirect(Login, {
                    shouldCheckAutorized: false,
                  })}
                />
                <ContentRoute
                  path="/auth/change-password"
                  render={renderOrRedirect(ChangePassword, { shouldCheckChallengeName: false })}
                />
                <ContentRoute
                  path="/auth/gdpr"
                  render={renderOrRedirect(AcceptGDPR, {
                    shouldCheckGDPR: false,
                  })}
                />

                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />

                <Redirect from="/auth" exact={true} to="/auth/login" />

                <Redirect
                  to={`/auth/login${
                    redirectQueryParam ? `?redirect_uri=${redirectQueryParam}` : ""
                  }`}
                />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex flex-column-auto align-items-center mt-5">
              <div className="text-dark-75 d-flex">
                <span className="text-muted font-weight-bold mr-2">
                  {new Date().getFullYear()} &copy;
                </span>
                {` `}
                <a
                  href="https://www.kodehyve.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-dark-75 text-hover-primary pr-3 font-weight-bold"
                >
                  kodehyve
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
