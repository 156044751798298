import { API } from "@aws-amplify/api";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../../_utils/userTypes";
import { orderBy } from "lodash-es";
import { formatDataSelect } from "../../../../_utils/formatDataUtils";

export async function createTask(task) {
  const body = { ...task };

  if (!body.assigneeId && body.assignee?.id) {
    body.assigneeId = body.assignee.id;
  }
  if (body.relatedTo === null) delete body.relatedTo;
  return await API.post("API", "/tasks", { body: body });
}

export async function createTaskContactClient(task, clientId) {
  const body = { ...task };

  if (!body.assigneeId && body.assignee?.id) {
    body.assigneeId = body.assignee.id;
  }
  if (body.relatedTo === null) delete body.relatedTo;
  return await API.post("API", "/tasks", { body: body });
}

export async function createComment(comment) {
  const body = { ...comment };
  return await API.post("API", `/tasks/${comment.taskId}/comments`, { body });
}

export async function updateComment(comment) {
  const body = { ...comment };
  return await API.post("API", `/tasks/${comment.taskId}/comments/${comment.id}`, {
    body,
  });
}

export async function deleteComment(comment) {
  return await API.del("API", `/tasks/${comment.taskId}/comments/${comment.id}`, {});
}

export async function getById(id) {
  return await API.get("API", `/tasks/${id}`, {});
}

export async function updateTask(task) {
  const body = { ...task };
  return await API.post("API", `/tasks/${task.id}`, { body });
}

export async function deleteTask(id) {
  return await API.del("API", `/tasks/${id}`, {});
}

export async function listTasks(
  { chunks, relatedTo, deeplyRelated },
  dispatch,
  tasksChunkedFetching
) {
  const queryParameters = { queryStringParameters: {} };
  if (relatedTo) {
    queryParameters.queryStringParameters.relatedTo = relatedTo;
    queryParameters.queryStringParameters.deeplyRelated = deeplyRelated;
  }
  if (chunks) {
    queryParameters.queryStringParameters.chunks = chunks;
  }
  const promise = API.get("API", "/tasks", queryParameters);
  if (dispatch && tasksChunkedFetching) dispatch(tasksChunkedFetching({ promise }));
  const response = await promise;

  const tasks = {};

  tasks.items = orderBy(response, ["dueDate", "createdAt"], ["asc", "asc"]);
  return tasks.items;
}

export async function fetchRelatedToItemOptions(intl) {
  const [respProjects, respProducts, respLeads, respAllUsers] = await Promise.all([
    API.get("API", "/projects", {}),
    API.get("API", "/products", {}),
    API.get("API", "/leads", {}),
    API.get("API", "/users", {}),
  ]);

  const respClients = [];
  const respUsers = [];
  const respSubcontractors = [];

  for (const user of respAllUsers) {
    if (user.userTypes.includes(USER)) {
      respUsers.push(user);
    } else if (user.userTypes.includes(CLIENT)) {
      respClients.push(user);
    } else if (user.userTypes.includes(SUBCONTRACTOR)) {
      respSubcontractors.push(user);
    }
  }

  return formatDataSelect(
    {
      projects: respProjects,
      products: respProducts,
      leads: respLeads,
      clients: respClients,
      users: respUsers,
      subcontractors: respSubcontractors,
    },
    intl
  );
}
