import { createContext, FunctionComponent, useContext } from "react";

import { TasksContentViewMode, TTasksContentViewMode } from "./TasksCard/TasksCardContent";
import { formatDisplayNameIntl } from "../../../../_utils/userUtils";
import { ITask } from "../../../../../data/schemas";
import {
  IFilterSearchTypeText,
  IFilterSearchTypeDate,
  IFilterSearchTypeDropdown,
  SearchTypeValues,
} from "../../../../contexts/SearchContext";
import { useIntl } from "react-intl";
import { DataSelectFields, formatDataSelect } from "../../../../_utils/formatDataUtils";

export interface ITasksUIEvents {
  newTaskButtonClick?: () => void;

  buildEditTaskUrl?: (id: string) => string;
  openEditTaskPage?: (id: string) => void;

  toggleContentView?: (contentView?: TTasksContentViewMode) => void;
  toggleGanttChartDetails?: (showDetails?: boolean) => void;
}

export interface ITasksUIContext extends ITasksUIEvents {
  ganttShowDetails: boolean;
  contentView: TTasksContentViewMode;
  filtersUI?: (IFilterSearchTypeText | IFilterSearchTypeDate | IFilterSearchTypeDropdown)[];
}

const TasksUIContext = createContext<ITasksUIContext>({
  ganttShowDetails: false,
  contentView: TasksContentViewMode.COLUMNS,
});

export interface TasksUIProviderProps {
  ganttShowDetails?: boolean;
  contentView?: TTasksContentViewMode;
  tasksUIEvents?: ITasksUIEvents;
}

interface Option {
  label: string;
  value: any;
}

export const TasksUIProvider: FunctionComponent<TasksUIProviderProps> = ({
  ganttShowDetails = false,
  contentView = TasksContentViewMode.COLUMNS,
  tasksUIEvents,
  children,
}) => {
  const intl = useIntl();

  const value = {
    ganttShowDetails,
    contentView,
    newTaskButtonClick: tasksUIEvents?.newTaskButtonClick,
    buildEditTaskUrl: tasksUIEvents?.buildEditTaskUrl,
    openEditTaskPage: tasksUIEvents?.openEditTaskPage,
    toggleContentView: tasksUIEvents?.toggleContentView,
    toggleGanttChartDetails: tasksUIEvents?.toggleGanttChartDetails,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [
          { value: "name", label: "COMMON.NAME" },
          { value: "description", label: "COMMON.TYPE" },
          { value: "assignee", label: "TASK.LABEL.ASSIGNEE" },
        ],
      },
      {
        name: "TASK.LABEL.RELATED_TO",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "relatedTo",
        values: [],
        groupBy: (options: Option[], entities?: ITask[]) => {
          const data: DataSelectFields = {
            projects: [],
            products: [],
            leads: [],
            clients: [],
            users: [],
            subcontractors: [],
          };
          options?.forEach((option: Option) => {
            const task = entities?.find((x: ITask) => x.relatedTo === option.value);
            if (task && task.relatedToType) {
              const field = (task.relatedToType.toLowerCase() + "s") as keyof typeof data;
              if (data[field]) {
                data[field].push({
                  name: task.relatedToName,
                  id: option.value,
                  value: option.value,
                });
              }
            }
          });
          const formattedData = formatDataSelect(data, intl, false);
          return formattedData.items;
        },
      },
      {
        name: "TASK.LABEL.ASSIGNEE",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "assignee",
        values: [],
        formatter: (user: any) => formatDisplayNameIntl(intl, user, false),
        groupBy: (options: Option[]) => {
          const data: DataSelectFields = {
            projects: [],
            products: [],
            leads: [],
            clients: [],
            users: [],
            subcontractors: [],
          };
          options?.forEach((option: Option) => {
            if (option.value.userTypes && option.value.userTypes.length > 0) {
              const field = (option.value.userTypes[0].toLowerCase() + "s") as keyof typeof data;
              if (data[field]) {
                data[field].push({
                  name: option.label,
                  id: option.value.id,
                  value: option.value,
                });
              }
            }
          });
          const formattedData = formatDataSelect(data, intl, false);
          return formattedData.items;
        },
      },
      {
        name: "TASK.LABEL.DATE.DUE",
        value: "dueDate",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
        isFuture: true,
      },
      {
        name: "COMMON.CREATED.AT",
        value: "createdAt",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
      },
      {
        name: "COMMON.UPDATED.AT",
        value: "updatedAt",
        type: SearchTypeValues.SEARCH_DATE,
        isAdvanced: true,
      },
      {
        // TODO : add translation "hide subtasks"
        name: "TASK.SUBTASKS.TITLE",
        value: "parentId",
        type: SearchTypeValues.SEARCH_CHECKBOX,
        isAdvanced: true,
        isChecked: true,
        validator: (entity: ITask, value: string) => {
          if (value !== "true") {
            return entity.parentId === undefined;
          } else {
            return true;
          }
        },
      },
    ],
  };

  return <TasksUIContext.Provider value={value}>{children}</TasksUIContext.Provider>;
};

export const useTasksUIContext = () => useContext(TasksUIContext);

export default TasksUIProvider;
