import { Step, StepLabel, Stepper } from "@material-ui/core";
import { useWizard } from "../../hooks/useWizard";
import { useTranslation } from "../../hooks/useTranslation";
import { KycFlowStatus, KycStep, KycWorkflow } from "../../modules/kyc-v2/Kyc";
import { KycTranslation } from "../../modules/kyc-v2/KycTranslation";
import cn from "clsx";
import { useKycFlow } from "../../hooks/useKycFlow";
import { WIZARD_ORIENTATION } from "./Wizard";
import { canSendForm } from "../../modules/kyc-v2/KycUtils";
import { canEdit, isOwner } from "../../_utils/authUtils";
import { useSession } from "../../hooks/useSession";
import { useAppSelector } from "../../../redux/hooks";

export const WizardVerticalStepperIndicator = (): JSX.Element => {
  const { activeStep, steps, stepperOrientation, currentStepId, setActiveStep } = useWizard();
  const { values, isClientViewer, isStatus } = useKycFlow();
  const { fm } = useTranslation();
  const { session } = useSession();
  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  //TODO improvement: okay to have condition but not optimal. Probably better to find more general and shorter
  //translations, so that we don't need to have these custom conditions
  const getStepTitle = (step: any) => {
    switch (step.id) {
      case KycStep.STEP_ADMIN_PAGE_1:
        return isClientViewer ? fm(KycTranslation.STEPS_ADMIN_PAGE_1_TITLE_CLIENT) : fm(step.title);
      case KycStep.STEP_ADMIN_PAGE_2:
        if (isStatus(KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED)) {
          return fm(KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_REJECT_RESEND_FORM_COMPLETION);
        } else {
          return values?.form?.workflowChoice === KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN
            ? fm(KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_ADMIN)
            : fm(KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_CLIENT);
        }

      default:
        return fm(step?.title);
    }
  };

  const handleClick = (e: any, index: number) => {
    e.preventDefault();
    if (canEdit(groups, session, "KYC_FLOW") && canSendForm(values)) {
      setActiveStep(index);
    }
  };

  return (
    <Stepper
      nonLinear={canEdit(groups, session, "KYC_FLOW") && canSendForm(values)}
      hidden={
        currentStepId() === KycStep.STEP_START_FLOW || currentStepId() === KycStep.STEP_SUCCESS
      }
      activeStep={activeStep}
      orientation={stepperOrientation === WIZARD_ORIENTATION.VERTICAL ? "vertical" : "horizontal"}
      className={cn(stepperOrientation === WIZARD_ORIENTATION.VERTICAL ? "pt-2" : "pt-10 pb-0")}
    >
      {steps
        ?.filter((step) => step?.id !== KycStep.STEP_START_FLOW)
        ?.map((step: any, index: number) => {
          const stepProps: any = {};
          if (step?.title) {
            return (
              <Step
                key={"step-header-" + step?.id}
                {...stepProps}
                onClick={(e) => handleClick(e, index)}
              >
                <StepLabel
                  className={cn("text-left", {
                    "cursor-pointer":
                      (canEdit(groups, session, "KYC_FLOW") || isOwner(session, values)) &&
                      canSendForm(values),
                  })}
                >
                  {getStepTitle(step)}
                </StepLabel>
              </Step>
            );
          } else {
            return <></>;
          }
        })}
    </Stepper>
  );
};
