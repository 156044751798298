import * as React from "react";
import { useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import PropTypes from "prop-types";
import { KycFile, KycLoading } from "../modules/kyc-v2/Kyc";
import { findAndDeleteFile, findAndReplaceFile } from "../modules/kyc-v2/KycFileUtils";
import { useLoading } from "../hooks/useLoading";
import { useKyc } from "../hooks/useKyc";
import axios, { AxiosRequestConfig } from "axios";

type KycFileProps = {
  children: React.ReactNode;
};

export interface KycFileContextValue {
  currentFiles: any[];
  setCurrentFiles: React.Dispatch<React.SetStateAction<any[]>>;
  getFileByFieldPath: (fieldPath: string) => any;
  saveFile: (file: KycFile, setProgress?: any) => Promise<void>;
  replaceFile: (file: KycFile) => Promise<void>;
  updateFile: (file: KycFile) => Promise<void>;
  deleteFile: (file: any) => Promise<void>;
}

export const KycFileContext = React.createContext<KycFileContextValue>({
  currentFiles: [],
  setCurrentFiles: (files) => undefined,
  getFileByFieldPath: (fieldPath) => undefined,
  saveFile: (file, setProgress) => Promise.resolve(),
  replaceFile: (file) => Promise.resolve(),
  updateFile: (file) => Promise.resolve(),
  deleteFile: (file) => Promise.resolve(),
});

export const KycFileProvider: React.FunctionComponent<KycFileProps> = (props) => {
  const { addLoading, removeLoading } = useLoading();
  const { currentKycFlow, updateKycFlow } = useKyc();

  const { children } = props;
  const [currentFiles, setCurrentFiles] = useState<any[]>([]);

  useEffect(() => {
    setCurrentFiles(currentKycFlow?.files || []);
  }, [currentKycFlow?.files]);

  const saveFile = async (file: KycFile, setProgress?: any) => {
    if (!currentKycFlow) {
      return;
    }

    if (!file?.name) {
      console.error("bad filename", file);
      return;
    }

    const body = { ...file };
    setCurrentFiles((cFiles) => findAndReplaceFile(cFiles, { ...file, status: "LOCAL" }));
    addLoading(KycLoading.SAVE_FILE, file.id);
    await API.post("API", `/kyc/${currentKycFlow.id}/files`, { body }).then((response) => {
      if (file.body) {
        try {
          const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent) => {
              if (setProgress) {
                setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              }
              if (progressEvent.loaded === progressEvent.total) {
                removeLoading(KycLoading.SAVE_FILE, file.id);
              }
            },
          };
          axios.put(response.putUrl, file.body, config);
          setCurrentFiles((cFiles) =>
            findAndReplaceFile(cFiles, { ...response, status: "UPLOADED_TO_S3" })
          );
        } catch (e) {
          console.error("axios error:", e);
        }
      }
    });
  };

  const updateFile = async (file: KycFile) => {
    if (!currentKycFlow) {
      return;
    }
    addLoading(KycLoading.UPDATE_FILE, file.id);
    setCurrentFiles((cFiles) => findAndReplaceFile(cFiles, file));
    const body = { ...file };
    await API.post("API", `/kyc/${currentKycFlow.id}/files/${file?.id}`, { body })
      .then((response) => {
        setCurrentFiles((cFiles) => findAndReplaceFile(cFiles, { ...response, url: file?.url }));
      })
      .finally(() => {
        removeLoading(KycLoading.UPDATE_FILE, file.id);
      });
  };

  const replaceFile = async (file: KycFile) => {
    if (!currentKycFlow) {
      return;
    }
    addLoading(KycLoading.SAVE_FILE, file.id);

    if (!file?.name) {
      console.error("bad filename", file);
      return;
    }

    const body = { ...file };
    setCurrentFiles((cFiles) => findAndReplaceFile(cFiles, { ...file, status: "LOCAL" }));

    await API.post("API", `/kyc/${currentKycFlow.id}/files`, { body })
      .then((response) => {
        try {
          const config = {
            onUploadProgress: (progressEvent: { loaded: any }) => {
              if (progressEvent.loaded / file?.body?.size === 1) {
                removeLoading(KycLoading.SAVE_FILE, file.id);
              }
            },
          };
          axios.put(response.putUrl, file.body, config);
          setCurrentFiles((cFiles) =>
            findAndReplaceFile(cFiles, { ...response, status: "UPLOADED_TO_S3" })
          );

          const indexSignatureOfFile = currentKycFlow.signatures?.findIndex(
            (signature: any) => signature?.fileId === file.id
          );
          if (indexSignatureOfFile > -1) {
            const updatedSignatures: any = [...currentKycFlow.signatures];
            const updatedSignature: any = updatedSignatures[indexSignatureOfFile];
            updatedSignatures[indexSignatureOfFile] = { ...updatedSignature, status: "SIGNED" };
            updateKycFlow({ ...currentKycFlow, signatures: updatedSignatures }, true);
          }
        } catch (e) {
          console.error("axios error:", e);
        }
      })
      .finally(() => {
        removeLoading(KycLoading.SAVE_FILE, file.id);
      });
  };

  const deleteFile = async (file: any) => {
    if (!currentKycFlow) {
      return;
    }
    addLoading(KycLoading.DELETE_FILE, file.id);
    setCurrentFiles((cFiles) => findAndDeleteFile(cFiles, file));
    await API.del("API", `/kyc/${currentKycFlow.id}/files/${file?.id}`, {}).finally(() => {
      removeLoading(KycLoading.DELETE_FILE, file.id);
    });
  };

  const getFileByFieldPath = (fieldPath: string) => {
    return currentFiles?.find((file) => file?.fieldPath === fieldPath);
  };

  return (
    <KycFileContext.Provider
      value={{
        currentFiles,
        setCurrentFiles,
        getFileByFieldPath,
        saveFile,
        replaceFile,
        updateFile,
        deleteFile,
      }}
    >
      {children}
    </KycFileContext.Provider>
  );
};

KycFileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycFileConsumer = KycFileContext.Consumer;
