import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { orderByLang } from "../modules/kyc-v2/KycFileUtils";

export interface KycFileViewerDrawerContextValue {
  openDrawer: Function;
  closeDrawer: Function;
  isMaximised: boolean;
  setIsMaximised: Function;
  showDrawer: boolean;
  currentDrawerFiles?: any[];
  setCurrentDrawerFiles: (files: any[]) => void;
}

export const KycFileViewerDrawerContext = createContext<KycFileViewerDrawerContextValue>({
  openDrawer: () => undefined,
  closeDrawer: () => undefined,
  isMaximised: false,
  setIsMaximised: (value: boolean) => undefined,
  showDrawer: false,
  currentDrawerFiles: undefined,
  setCurrentDrawerFiles: (files) => [],
});

export const KycFileViewerDrawerProvider = (props: { children: any }): JSX.Element => {
  const { children } = props;
  const [currentDrawerFiles, setCurrentDrawerFilesInternal] = useState<any[]>();
  const [showDrawer, setShowDrawerInternal] = useState<boolean>(false);
  const [isMaximised, setIsMaximisedInternal] = useState<boolean>(false);
  const intl = useIntl();

  const setCurrentDrawerFiles = (files?: any[]): void => {
    setCurrentDrawerFilesInternal(orderByLang(files ?? [], intl));
  };

  const openDrawer = () => {
    setShowDrawerInternal(true);
  };
  const closeDrawer = () => {
    setShowDrawerInternal(false);
  };

  useEffect(() => {
    if (!showDrawer) {
      setCurrentDrawerFilesInternal(undefined);
    }
  }, [showDrawer]);

  useEffect(() => {
    if (currentDrawerFiles) {
      openDrawer();
    }
  }, [currentDrawerFiles]);

  const setIsMaximised = (value: boolean) => {
    setIsMaximisedInternal(value);
  };

  return (
    <KycFileViewerDrawerContext.Provider
      value={{
        openDrawer,
        closeDrawer,
        showDrawer,
        currentDrawerFiles,
        setCurrentDrawerFiles,
        isMaximised,
        setIsMaximised,
      }}
    >
      {children}
    </KycFileViewerDrawerContext.Provider>
  );
};

KycFileViewerDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycFileViewerDrawerConsumer = KycFileViewerDrawerContext.Consumer;
