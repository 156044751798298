import * as React from "react";
import PropTypes from "prop-types";
import { uniqWith, isEqual, cloneDeep } from "lodash-es";
import { searchUtils } from "../_utils/searchUtils";
import { useIntl } from "react-intl";
import moment from "moment";

interface SearchFilter {
  values: any[];
  type: string;
}
export enum SearchTypeValues {
  SEARCH_TEXT = "text",
  SEARCH_TEXT_COLUMNS = "text_columns",
  SEARCH_DROPDOWN = "dropdown",
  SEARCH_DATE = "date",
  SEARCH_CHECKBOX = "checkbox",
}

export interface IFilterSearchType {
  name: string;
  value: string;
  type: string;
  // current value
  selected?: any;
  // specify if the filter should be show by an "advanced button"
  isAdvanced?: boolean;
}

export interface IFilterSearchTypeText extends IFilterSearchType {
  columns?: SearchFilter[];
}

export interface IFilterSearchTypeDate extends IFilterSearchType {
  isFuture?: boolean;
}

export interface IFilterSearchTypeCheckBox extends IFilterSearchType {
  isChecked?: boolean;
  // custom validator
  validator: (entity: any, value: any) => boolean;
}

export interface IFilterSearchTypeDropdown extends IFilterSearchType {
  labelKey?: string;
  values: any[];
  isMultiple?: boolean;
  // To format the value to be displayed
  formatter?: (value: any) => undefined;
  // To group the values in an option
  groupBy?: (values: any, entities?: any) => undefined;
  // custom validator instead of basic isEqual
  validator?: (entity: any, value: any) => boolean;
  isSpecificValues?: boolean;
}

interface SearchProviderProps {
  children: React.ReactNode;
}

export interface SearchContextValue {
  setEntities: React.Dispatch<React.SetStateAction<any[]>>;
  entitiesFiltered: any[];
  entities: any[];

  setSearchValues: (value: string, key: string, type?: string) => void;
  filters: (
    | IFilterSearchTypeText
    | IFilterSearchTypeDate
    | IFilterSearchTypeDropdown
    | IFilterSearchTypeCheckBox
  )[];
  setFilters: (
    values: (
      | IFilterSearchTypeText
      | IFilterSearchTypeDate
      | IFilterSearchTypeDropdown
      | IFilterSearchTypeCheckBox
    )[]
  ) => void;
}

const escapedProps = ["updatedAt", "lastSeenAt", "profilePictureUrl", "profilePictureUrlExpires"];

export const SearchContext = React.createContext<SearchContextValue>({
  setEntities: (values) => undefined,
  entitiesFiltered: [],
  entities: [],
  setSearchValues: (value, key, type) => undefined,
  filters: [],
  setFilters: (values) => undefined,
});

export const SearchProvider: React.FC<SearchProviderProps> = (props) => {
  const { children } = props;
  const intl = useIntl();
  const [filters, setFilters] = React.useState<
    (
      | IFilterSearchTypeText
      | IFilterSearchTypeDate
      | IFilterSearchTypeDropdown
      | IFilterSearchTypeCheckBox
    )[]
  >([]);
  const [entities, setEntities] = React.useState<any[]>([]);
  const [entitiesFiltered, setEntitiesFiltered] = React.useState<any[]>(entities);
  // Some properties can change between 2 same object. As they are not useful we escape them
  const removeProperties = (obj: any, propList: string[]) => {
    let returnValue = cloneDeep(obj);
    if (typeof returnValue === "object") {
      for (const value of propList) {
        if (obj[value] !== undefined) {
          returnValue = Object.keys(obj)
            .filter((key) => !propList.includes(key))
            // eslint-disable-next-line
            .reduce((res: any, key) => ((res[key] = obj[key]), res), {});
        }
      }
    }
    return returnValue;
  };
  (filters ?? []).forEach((filter) => {
    if (filter.type === SearchTypeValues.SEARCH_DROPDOWN) {
      const filterDD = filter as IFilterSearchTypeDropdown;
      filterDD.values = filterDD.isSpecificValues
        ? filterDD.values
        : uniqWith(
            entities
              ?.filter((e) => e[filterDD.value] !== undefined)
              .map((e) => removeProperties(e[filterDD.value], escapedProps)),
            isEqual
          );
    }
  });

  const setSearchValues = (value: string, type: string, key: string = "") => {
    switch (type) {
      case SearchTypeValues.SEARCH_TEXT_COLUMNS:
        setFilters((prevState) => {
          return prevState.map((filter) => {
            if (filter.name === key) filter.selected = value;
            return filter;
          });
        });
        break;
      case SearchTypeValues.SEARCH_DROPDOWN:
      case SearchTypeValues.SEARCH_DATE:
        setFilters((prevState) => {
          return prevState.map((filter) => {
            if (filter.value === key) filter.selected = value;
            return filter;
          });
        });
        break;
      case SearchTypeValues.SEARCH_CHECKBOX:
        setFilters((prevState) => {
          return prevState.map((filter) => {
            if (filter.value === key) {
              const FilterCB = filter as IFilterSearchTypeCheckBox;
              FilterCB.selected = value;
              FilterCB.isChecked = value === "true";
            }
            return filter;
          });
        });
        break;
    }
  };

  const applySearch = () => {
    let values = entities ?? [];
    filters?.forEach((filter) => {
      switch (filter.type) {
        case SearchTypeValues.SEARCH_TEXT_COLUMNS:
          const filterText = filter as IFilterSearchTypeText;
          values = searchUtils(filterText.selected?.toString(), values, filterText.columns, intl);
          break;
        case SearchTypeValues.SEARCH_DROPDOWN:
          const filterDropdown = filter as IFilterSearchTypeDropdown;
          if (filterDropdown.selected !== undefined && filterDropdown.selected !== "*") {
            if (filterDropdown.validator !== undefined) {
              values = values.filter((v: any) =>
                // @ts-ignore
                filterDropdown.validator(v, filterDropdown.selected)
              );
            } else {
              values = values.filter((v: any) =>
                isEqual(
                  removeProperties(v[filterDropdown.value], escapedProps),
                  removeProperties(filterDropdown.selected, escapedProps)
                )
              );
            }
          }
          break;
        case SearchTypeValues.SEARCH_DATE:
          const FilterDate = filter as IFilterSearchTypeDate;
          if (FilterDate.selected !== undefined && FilterDate.selected !== "*") {
            const splitDateSel = FilterDate.selected.split(",");
            const dateToCompare = moment().add(
              splitDateSel[0] as string,
              splitDateSel[1] as "M" | "y"
            );
            if (FilterDate.isFuture) {
              const currentDt = moment().set("hour", 0).set("minute", 0);
              values = values.filter((v: any) => {
                const filterDt = moment(v[filter.value]);
                return !!v[filter.value] && filterDt > currentDt && filterDt <= dateToCompare;
              });
            } else
              values = values.filter(
                (v: any) => !!v[filter.value] && moment(v[filter.value]) > dateToCompare
              );
          }
          break;
        case SearchTypeValues.SEARCH_CHECKBOX:
          const FilterCB = filter as IFilterSearchTypeCheckBox;
          if (!!FilterCB.selected) {
            values = values.filter((v: any) =>
              // @ts-ignore
              FilterCB.validator(v, FilterCB.selected)
            );
          }
          break;
      }
    });
    setEntitiesFiltered(values);
  };

  React.useEffect(() => {
    applySearch();
  }, [filters, intl.locale]);

  React.useEffect(() => {
    applySearch();
  }, [entities]);

  return (
    <SearchContext.Provider
      value={{
        setEntities,
        entitiesFiltered,
        entities,
        setSearchValues,
        filters,
        setFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SearchConsumer = SearchContext.Consumer;
