import React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../../../UsersManagement/_redux/usersActions";
import { UserFileEditDialogHeader } from "./UserFileEditDialogHeader";
import { UserFileEditForm } from "./UserFileEditForm";
import { useUserFilesUIContext } from "../UserFilesUIContext";
import { useAppSelector } from "../../../../../../../redux/hooks";
import * as leadActions from "../../../../../PropertiesManagement/_redux/leads/leadsActions";
import { useIntl } from "react-intl";
import { FinanceFileType, isReInvoiceDetailPriceLine } from "../../../../../../../data/schemas";
import * as notificationsActions from "../../../../../Notifications/_redux/snackBarNotificationsActions";
import { v4 as uuid } from "uuid";
import { useCustomLocationState } from "../../../../../../_utils/useCustomLocationState";
import { formatDisplayNameIntl } from "../../../../../../_utils/userUtils";
import { unselectSelectedForFinance } from "../../../../../PropertiesManagement/_redux/leads/leadsCrud";

export function UserFileEditDialog() {
  const intl = useIntl();
  const {
    selectedId,
    setIds,
    showEditFileDialog,
    closeEditFileDialog,
    isUploadingFile,
    initFile,
    readOnly,
    userTypes,
    addLinkedFiles,
    removeLinkedFile,
    userId,
  } = useUserFilesUIContext();
  const { setFromUrlTo } = useCustomLocationState();

  const dispatch = useDispatch();
  const { actionsLoading, userFileForEdit, savedFile, originalEntityForEdit, session, projects } =
    useAppSelector(
      (state) => ({
        actionsLoading: state.users.actionsLoading,
        userFileForEdit: state.users.entityForEdit?.current?.files?.find(
          (file) => file.id === selectedId
        ),
        originalEntityForEdit: state.users.entityForEdit?.saved,
        savedFile: state.users.entityForEdit?.saved?.files?.find((file) => file.id === selectedId),
        session: state.auth.session,
        projects: state.projects.entities,
      }),
      shallowEqual
    );

  const unloadHandler = (event) => {
    event.preventDefault();
    return (event.returnValue = null);
  };

  const saveFile = async (userFile) => {
    window.addEventListener("beforeunload", unloadHandler);
    // if id of file does NOT EXIST,
    //  - in that case:
    //          1. check if we want to upload a new file-item (including the file itself) -> isUploading=true
    //          2. if isUploading=false, only the file-item (db entry) is created, the file itself is not available yet
    //             -> this describes the "request file" functionality, where a user requires a document from a third party
    //             (e.g. a user that buys a house and needs to provide documents like, for example, a copy of an identity card)
    //
    if (!selectedId) {
      if (isUploadingFile) {
        // create new file-item (including binary upload to cloud-storage)
        await dispatch(actions.uploadUserFile(userFile)).then(async ({ file: createdFile }) => {
          // create and link a generated lead file when re-invoicing is enabled for this file
          setIds([]);
          closeEditFileDialog();

          if (userFile.enableReInvoicing) {
            await createLeadFile({ userFile, createdFile });
          }
          window.removeEventListener("beforeunload", unloadHandler);
        });
      } else {
        // create a file-request-item -> a new file-item gets created in db, but the binary is not uploaded to cloud-storage (e.g. binary not available yet)
        dispatch(actions.createRequestUserFile(userFile)).then(() => {
          setIds([]);
          closeEditFileDialog();
          window.removeEventListener("beforeunload", unloadHandler);
        });
      }
    } else {
      // update file-item properties (e.g. name / document-type/category like ID or PAYSLIP)
      // note: changes only on file-item in db, not on the binary-file itself
      if (!isUploadingFile) {
        dispatch(actions.updateUserFile(userFile, userTypes[0])).then(async () => {
          setIds([]);
          closeEditFileDialog();

          await handleReInvoicingOnUpdate(userFile);
          window.removeEventListener("beforeunload", unloadHandler);
        });
      } else {
        //case where user uploads a binary-file (pdf, jpg/png,...) to an existing file-request-item
        dispatch(actions.uploadRequestedUserFile(userFile)).then(() => {
          setIds([]);
          closeEditFileDialog();
          window.removeEventListener("beforeunload", unloadHandler);
        });
      }
    }
  };

  const handleReInvoicingOnUpdate = async (userFile) => {
    if (!savedFile.enableReInvoicing && userFile.enableReInvoicing) {
      // Re-invoicing was not active yet, we create the active file
      await createLeadFile({ userFile });
    } else if (savedFile.enableReInvoicing && userFile.enableReInvoicing) {
      // Re-invoicing already exists and is not removed, only updates the active file information
      if (savedFile.reInvoiceFile.leadFileId !== userFile.reInvoiceFile.leadFileId) {
        // leadFileId has changed, meaning we create a new file
        const linkedFileToRemove = userFile.linkedFiles.find(
          (linkedFile) => linkedFile.id === savedFile.reInvoiceFile.leadFileId
        );
        if (linkedFileToRemove) {
          await removeLinkedFile(userFile, linkedFileToRemove);
          userFile.linkedFiles = userFile.linkedFiles.filter(
            (linkedFile) => linkedFile.id !== savedFile.reInvoiceFile.leadFileId
          );
        }
        // Update the old lead file to unselect the selected for finance property
        await unselectSelectedForFinance({
          leadId: savedFile.reInvoiceFile.leadId,
          id: savedFile.reInvoiceFile.leadFileId,
        });
        await createLeadFile({ userFile });
      } else if (
        savedFile.reInvoiceFile.budgetInstalmentId !== userFile.reInvoiceFile.budgetInstalmentId
      ) {
        await dispatch(leadActions.updateFinancialDocument(formatFinancialDocument(userFile)));
      }
    } else if (savedFile.enableReInvoicing && !userFile.enableReInvoicing) {
      // TODO We might want to edit this when versioning will be a thing
      // Re-invoicing exists but is being removed, do nothing
    }
  };

  const createLeadFile = async ({ userFile, createdFile }) => {
    const notificationId = uuid();
    dispatch(
      notificationsActions.createNotification({
        id: notificationId,
        type: "info",
        title: "SNACKBAR.INFO.CLIENT_SUPPLEMENTARY_AGREEMENT_BEING_CREATED",
        autoHide: false,
        removable: false,
      })
    );
    const leadFile = await dispatch(
      leadActions.createFinancialDocument(formatFinancialDocument(userFile, createdFile), () =>
        setFromUrlTo({
          url: `/leads/${userFile.reInvoiceFile.leadId}?t=files&fileId=${userFile.reInvoiceFile.leadFileId}`,
          name: formatDisplayNameIntl(intl, originalEntityForEdit),
        })
      )
    );

    const { id, friendlyName, relatedEntity } = leadFile;
    await addLinkedFiles(createdFile ?? userFile, [{ id, friendlyName, relatedEntity }]);
    dispatch(notificationsActions.removeNotification(notificationId));
  };

  const saveMultipleFiles = async (...userFiles) => {
    if (userFiles.length > 0) {
      for (const userFile of userFiles) {
        await saveFile(userFile);
      }
    }
  };

  const formatFinancialDocument = (userFileForm, createdUserFile) => {
    const project = projects.find((p) => p.id === userFileForm.projectId);
    const { file, categories, reInvoiceFile, linkedFiles, ...leadFile } = userFileForm;
    let total = 0;
    return {
      ...leadFile,
      ...reInvoiceFile,
      propCoId: project?.projectOwnerId,
      id: reInvoiceFile.leadFileId,
      fileType: FinanceFileType.SUPPLEMENTARY_AGREEMENT,
      language: intl.locale,
      title: reInvoiceFile.fileName,
      friendlyName: reInvoiceFile.fileName,
      userFile: {
        id: userFileForm?.id ?? createdUserFile.id,
        userId: userFileForm?.userId ?? createdUserFile.userId,
        fileExtension: userFileForm?.fileExtension ?? createdUserFile.fileExtension,
      },
      content: userFileForm.reInvoiceFile.details?.map((detail) => {
        const { margin, marginType, ...detailRest } = detail;
        const res = detailRest;
        res.budgetInstalmentId = reInvoiceFile.budgetInstalmentId;
        if (isReInvoiceDetailPriceLine(detail)) {
          const quantity = reInvoiceFile.useQuantities ? detail.quantity : 1;
          const amount = (detail.unitPrice ?? 0) * quantity;
          const marginValue =
            marginType === "absolute" ? margin ?? 0 : (amount * (margin ?? 0)) / 100;
          total += amount + marginValue;
          res.amount = amount + marginValue;
          res.unitPrice = res.amount / quantity;
          // Remove the quantity from the object after the needed price calculations in order to not be shown it in the generated pdf
          if (!reInvoiceFile.useQuantities) {
            delete res.quantity;
          }
        }
        return res;
      }),
      amount: total,
    };
  };

  return (
    <Modal
      show={showEditFileDialog}
      onHide={closeEditFileDialog}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      scrollable
      backdrop="static"
      size={"xl"}
    >
      <UserFileEditDialogHeader
        userFileForEdit={userFileForEdit}
        isUploadingFile={isUploadingFile}
        showEditFileDialog={showEditFileDialog}
      />
      <UserFileEditForm
        saveFile={saveFile}
        saveMultipleFiles={saveMultipleFiles}
        actionsLoading={actionsLoading}
        file={userFileForEdit || selectedId || initFile}
        onHide={closeEditFileDialog}
        isUploadingFile={isUploadingFile}
        readOnly={readOnly && userId !== session.id}
        userTypes={userTypes}
      />
    </Modal>
  );
}
