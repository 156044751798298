import { KycFileViewerDrawerProvider } from "../../../contexts/KycFileViewerDrawerContext";
import { KycFileViewerDrawer } from "./KycFileViewerDrawer";
import { KycFileProvider } from "../../../contexts/KycFileContext";
import { KycComplianceProvider } from "../../../contexts/KycComplianceContext";
import { KycComplianceContainer } from "./compliance/KycComplianceContainer";
import { KycConfirmationProvider } from "../../../contexts/KycConfirmationContext";
import * as React from "react";
import { KycV2Provider } from "../../../contexts/KycContext";

interface KycComplianceTabProps {
  userId: string;
}

export const KycComplianceTab = ({ userId }: KycComplianceTabProps): JSX.Element => {
  return (
    <KycV2Provider>
      <KycFileViewerDrawerProvider>
        <KycFileProvider>
          <KycConfirmationProvider>
            <KycComplianceProvider>
              <KycComplianceContainer userId={userId} />
              <KycFileViewerDrawer />
            </KycComplianceProvider>
          </KycConfirmationProvider>
        </KycFileProvider>
      </KycFileViewerDrawerProvider>
    </KycV2Provider>
  );
};
