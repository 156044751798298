import * as React from "react";
import { useEffect } from "react";
import { useKyc } from "../../../../hooks/useKyc";
import { KycComplianceDropdownMenu } from "./KycComplianceDropdownMenu";
import { useTranslation } from "../../../../hooks/useTranslation";
import { Button } from "react-bootstrap";
import { KycComplianceViewMode, KycFlowStatus, KycRiskAssessmentStatus, KycStep } from "../../Kyc";
import { KycTranslation } from "../../KycTranslation";
import { getFlowDefinition, isKycFlowInTerminalState, statusAsArray } from "../../KycUtils";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { cloneDeep } from "lodash-es";
import { useSession } from "../../../../hooks/useSession";
import { useAppSelector } from "../../../../../redux/hooks";
import { canEdit } from "../../../../_utils/authUtils";
import { useWizard } from "../../../../hooks/useWizard";

interface KycComplianceItemToolbarProps {
  viewMode: KycComplianceViewMode | undefined;
  setViewMode: any;
}

export const KycComplianceItemToolbar = ({
  viewMode,
  setViewMode,
}: KycComplianceItemToolbarProps): JSX.Element => {
  const { currentKycFlow, updateKycFlow } = useKyc();
  const { values } = useKycFlow();
  const { session } = useSession();
  const { fm } = useTranslation();
  const { setActiveStep, setIsReadOnly, setFlowDefinition, setShowControls } = useWizard();

  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  const { setConfig, setShowModal } = useKycConfirmation();

  const handleClick = (e: any, t: any, isRiskAssessmentRejected = false) => {
    e.preventDefault();

    const updatedKyc: any = cloneDeep(values);

    if (t === KycDialogConfig.KYC_FLOW_RISK_ASSESSMENT_ACCEPT) {
      updatedKyc.riskAssessmentStatus = KycRiskAssessmentStatus.VALIDATED;
    }

    if (isRiskAssessmentRejected) {
      updatedKyc.riskAssessmentStatus = KycRiskAssessmentStatus.REJECTED;
    }

    setConfig({
      type: t,
      kyc: updatedKyc,
      kycId: currentKycFlow?.id,
    });
    setShowModal(true);
  };

  const handleOpenRiskAssessment = (e: any) => {
    e.preventDefault();
    setViewMode(KycComplianceViewMode.RISK_ASSESSMENT);
    if (
      currentKycFlow &&
      currentKycFlow.riskAssessmentStatus === KycRiskAssessmentStatus.IN_PROGRESS
    ) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  };

  useEffect(() => {
    if (viewMode === KycComplianceViewMode.RISK_ASSESSMENT) {
      if (
        currentKycFlow &&
        currentKycFlow.riskAssessmentStatus === KycRiskAssessmentStatus.IN_PROGRESS
      ) {
        setIsReadOnly(false);
      } else {
        setIsReadOnly(true);
      }
    }
    if (
      currentKycFlow &&
      currentKycFlow?.riskAssessmentStatus === KycRiskAssessmentStatus.INIT &&
      statusAsArray.indexOf(currentKycFlow.status) >=
        statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_DONE) &&
      viewMode === KycComplianceViewMode.RISK_ASSESSMENT
    ) {
      const updatedKyc: any = cloneDeep(values);
      updatedKyc.riskAssessmentStatus = KycRiskAssessmentStatus.IN_PROGRESS;
      updateKycFlow(updatedKyc, true);
    }
  }, [viewMode, values?.riskAssessmentStatus]);

  const handleOpenFlow = (
    e: React.MouseEvent<HTMLElement>,
    openAtStep: KycStep,
    stepOnly = false
  ) => {
    e.preventDefault();
    if (currentKycFlow) {
      let flowDef = [openAtStep];
      if (!stepOnly) {
        flowDef = getFlowDefinition(
          currentKycFlow,
          false,
          true,
          setIsReadOnly,
          setActiveStep,
          setShowControls,
          groups,
          session
        );
      }
      setFlowDefinition(flowDef);
      setActiveStep(
        isStatus(KycFlowStatus.ADMIN_FILLING_IN) && currentKycFlow?.form?.acceptTerms?.isAccepted
          ? 1
          : 0
      );
      setViewMode(KycComplianceViewMode.FORM);
    }
  };

  const isStatus = (status: KycFlowStatus) => {
    return currentKycFlow?.status === status;
  };

  const getLabelForOpenForm = (status: KycFlowStatus, groups: any, session: any) => {
    if (canEdit(groups, session, "KYC_FLOW")) {
      if (
        statusAsArray.indexOf(status) <
          statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_PENDING) &&
        isStatus(KycFlowStatus.ADMIN_FILLING_IN)
      ) {
        return fm(KycTranslation.OPEN_FORM_CONTINUE);
      }
      if (
        statusAsArray.indexOf(status) >= statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_DONE) &&
        statusAsArray.indexOf(status) <
          statusAsArray.indexOf(KycFlowStatus.ADMIN_RISK_ASSESSMENT_PENDING)
      ) {
        return fm(KycTranslation.OPEN_FORM_REVIEW_FORM_DATA);
      }
    }
    return fm(KycTranslation.OPEN_FORM_DEFAULT);
  };

  return (
    <div
      style={{
        zIndex: 1,
        right: 0,
        position: "absolute",
      }}
    >
      {/*BUTTON TO OPEN KYC FORM*/}
      {currentKycFlow && (
        <div className="d-flex justify-content-end mr-8">
          <KycComplianceDropdownMenu
            kycId={currentKycFlow?.id}
            status={currentKycFlow.status}
            kycFlow={currentKycFlow}
          />
          <>
            {(viewMode === KycComplianceViewMode.COMPLIANCE_DETAILS ||
              viewMode === KycComplianceViewMode.RISK_ASSESSMENT) && (
              <Button
                data-cy="button-kyc-toolbar-open-form"
                size="sm"
                onClick={(e) => handleOpenFlow(e, KycStep.STEP_ADMIN_PAGE_1)}
                className="mx-1 btn-light"
              >
                {getLabelForOpenForm(currentKycFlow?.status, groups, session)}
              </Button>
            )}
          </>

          {/*BUTTON TO OPEN RISK ASSESSMENT PAGE*/}
          {!(
            isKycFlowInTerminalState(currentKycFlow) &&
            currentKycFlow?.riskAssessmentStatus === KycRiskAssessmentStatus.INIT
          ) &&
            (viewMode === KycComplianceViewMode.COMPLIANCE_DETAILS ||
              viewMode !== KycComplianceViewMode.RISK_ASSESSMENT) &&
            statusAsArray.indexOf(currentKycFlow?.status) >=
              statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_DONE) && (
              <>
                <Button
                  data-cy="button-kyc-toolbar-open-risk-assessment"
                  size="sm"
                  onClick={(e) => handleOpenRiskAssessment(e)}
                  className="mx-1 btn-primary"
                >
                  {fm(KycTranslation.STEPS_RISK_EVALUATION_TITLE)}
                </Button>
              </>
            )}

          {/* BUTTONS TO APPROVE/REJECT KYC FLOW*/}
          {canEdit(groups, session, "KYC_FLOW") && (
            <>
              {statusAsArray.indexOf(currentKycFlow?.status) >=
                statusAsArray.indexOf(KycFlowStatus.BO_DECLARATIONS_SIGNATURE_DONE) &&
                viewMode === KycComplianceViewMode.COMPLIANCE_DETAILS &&
                !isKycFlowInTerminalState(currentKycFlow) && (
                  <>
                    <Button
                      data-cy="button-kyc-toolbar-reject-flow"
                      size="sm"
                      onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_REJECT)}
                      className="mx-1 btn-danger"
                    >
                      {fm(KycTranslation.ACTION_KYC_FLOW_REJECT)}
                    </Button>
                    <Button
                      data-cy="button-kyc-toolbar-approve-flow"
                      size="sm"
                      onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_APPROVED)}
                      className="mx-1 btn-success"
                    >
                      {fm(KycTranslation.ACTION_KYC_FLOW_APPROVE)}
                    </Button>
                  </>
                )}

              {/* BUTTON TO RESEND KYC FORM COMPLETION REQUEST (WITH NOTE) */}
              {viewMode === KycComplianceViewMode.FORM &&
                statusAsArray.indexOf(currentKycFlow?.status) >=
                  statusAsArray.indexOf(KycFlowStatus.CLIENT_REVIEW_DONE) &&
                statusAsArray.indexOf(currentKycFlow?.status) <
                  statusAsArray.indexOf(KycFlowStatus.ADMIN_RISK_ASSESSMENT_VALIDATED) && (
                  <>
                    <Button
                      data-cy="button-kyc-toolbar-reject-and-request-form-completion"
                      size="sm"
                      onClick={(e) =>
                        handleOpenFlow(e, KycStep.STEP_REJECT_AND_REQUEST_FORM_COMPLETION, true)
                      }
                      className="mx-1 btn-primary"
                    >
                      {fm(KycTranslation.STEPS_REJECT_AND_REQUEST_FORM_COMPLETION_TITLE)}
                    </Button>
                  </>
                )}

              {/*RISK ASSESSMENT VALIDATE AND REJECT BUTTONS*/}
              {viewMode === KycComplianceViewMode.RISK_ASSESSMENT &&
                currentKycFlow?.riskAssessmentStatus === KycRiskAssessmentStatus.IN_PROGRESS && (
                  <>
                    <Button
                      data-cy="button-kyc-toolbar-validate-risk-assessment"
                      size="sm"
                      onClick={(e: any) =>
                        handleClick(e, KycDialogConfig.KYC_FLOW_RISK_ASSESSMENT_ACCEPT)
                      }
                      className="mx-1 btn-success"
                    >
                      {fm(KycTranslation.ACTION_VALIDATE)}
                    </Button>

                    <Button
                      data-cy="button-kyc-toolbar-reject-flow"
                      size="sm"
                      onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_REJECT, true)}
                      className="mx-1 btn-danger"
                    >
                      {fm(KycTranslation.ACTION_KYC_FLOW_REJECT)}
                    </Button>
                  </>
                )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
