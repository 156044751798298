import React from "react";
import DocRenderer from "@cyntler/react-doc-viewer";
import { PdfViewer } from "../../Common/PdfViewer/PdfViewer";
import { renderToolbarBasic } from "../../Common/PdfViewer/CustomToolbarBasic";

// @ts-ignore
export const CustomPdfRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div className="w-100 pt-5 position-relative ">
      {currentDocument?.uri && (
        <PdfViewer fileUrl={currentDocument.uri} toolbar={renderToolbarBasic} />
      )}
    </div>
  );
};

CustomPdfRenderer.fileTypes = ["pdf", "application/pdf"];
CustomPdfRenderer.weight = 1;
