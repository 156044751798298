import { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { cloneDeep, isEqual, sortBy } from "lodash-es";
import { KycTranslation } from "../KycTranslation";
import { KycDropdownSelect } from "./controls/kyc-dropdown-select/KycDropdownSelect";
import { KycFlow } from "../Kyc";
import { useKycFlow } from "../../../hooks/useKycFlow";
import { Field } from "formik";
import { useTranslation } from "../../../hooks/useTranslation";
import { KycInput } from "./controls/kyc-input/KycInput";
import { mapFileOwner } from "../KycMappingUtils";
import { IUser, UserType } from "../../../../data/schemas";
import { canEdit, canReadAll } from "../../../_utils/authUtils";
import { useSession } from "../../../hooks/useSession";

export const KycSelectFileOwner = (): JSX.Element => {
  const { users, groups } = useSelector(
    (state: any) => ({
      groups: state.auth.groups,
      users: state.users.entities,
    }),
    shallowEqual
  );

  const { session } = useSession();

  const { values, setFlowValues, customHandleChange, isClientViewer } = useKycFlow();
  const { fDisplayName } = useTranslation();

  const handleChange = (e: any) => {
    const res: KycFlow = cloneDeep(values);
    if (e?.target?.value && !isEqual(res.fileOwner, mapFileOwner(e?.target?.value, fDisplayName))) {
      const userSelected = users.find((user: any) => user.id === e?.target.value?.id);
      if (userSelected) {
        res.fileOwner = mapFileOwner(userSelected, fDisplayName);
        setFlowValues(res);
      }
    }
  };
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    setOptions(
      sortBy(
        users
          ?.filter(
            (user: IUser) =>
              user.userTypes.includes(UserType.USER) &&
              user.id !== values?.form?.client?.id &&
              ((canEdit(groups, user, "KYC_FLOW") && canReadAll(groups, user, "CLIENT")) ||
                user.id === session.id)
          )
          .map((user: any) => ({
            label: fDisplayName(user, true),
            value: user,
          })),
        "label"
      )
    );
  }, [users]);

  return (
    <>
      {isClientViewer ? (
        <Field
          name="fileOwner.displayName"
          component={KycInput}
          disabled={true}
          isLocked
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.FILE_OWNER}
        />
      ) : (
        <Field
          key={"select-file-owner-" + values?.id}
          component={KycDropdownSelect}
          name="fileOwner.id"
          options={options}
          onChange={handleChange}
          label={KycTranslation.FILE_OWNER}
        />
      )}
    </>
  );
};
