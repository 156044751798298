import * as React from "react";
import { BudgetLineContent } from "./BudgetLineContent";
import cn from "clsx";
import { CustomCardLine, CustomCardLineSize } from "../../../../_components/CustomCard";
import { IBudget, IProject, TFormattedBudgetLine } from "../../../../../data/schemas";
import { useBudgetsUIContext } from "../../BudgetsUIContext";
import { Accordion } from "react-bootstrap";
import { useDidUpdate } from "rooks";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { DropdownItem } from "../../../../_components/actions-menu/DropdownItem";
import { useCustomLocationState } from "../../../../_utils/useCustomLocationState";

export interface BudgetSubcontractorLineProps {
  subcontractorTotal?: any;
  budget: IBudget;
  project?: Partial<IProject>;
  displayedDataLine: TFormattedBudgetLine;
  categoryId: string;
}

export const BudgetSubcontractorLine: React.FC<BudgetSubcontractorLineProps> = ({
  subcontractorTotal,
  displayedDataLine,
  budget,
  project,
  categoryId,
}) => {
  const { columnWidth, triggerExpandCategories, triggerCollapseCategories } = useBudgetsUIContext();

  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleRef = React.useRef<HTMLDivElement>(null);
  useDidUpdate(() => {
    if (toggleRef.current && !isExpanded) {
      toggleRef.current.click();
    }
  }, [triggerExpandCategories]);

  useDidUpdate(() => {
    if (toggleRef.current && isExpanded) {
      toggleRef.current.click();
    }
  }, [triggerCollapseCategories]);

  const { setFromUrlTo } = useCustomLocationState();
  const displayLineFiles = (subcontractorTotal: any, fileId?: string) => {
    let url = `/budgets/${budget.id}/${categoryId}/${displayedDataLine.id}?subcontractorId=${subcontractorTotal.userId}`;
    if (fileId) {
      url += `&fileId=${fileId}`;
    }
    setFromUrlTo({
      url: url.toString(),
      name: budget.name,
    });
  };

  return (
    <Accordion onSelect={() => setIsExpanded(!isExpanded)}>
      <Accordion.Toggle
        ref={toggleRef}
        eventKey={"0"}
        as={CustomCardLine}
        customClasses={{
          container: cn(
            "bg-hover-gray-300",
            !!displayedDataLine?.subcontractors?.length && "cursor-pointer"
          ),
        }}
        actions={
          budget.id === project?.selectedBudget && (
            <DropdownItem
              onClick={() => displayLineFiles(subcontractorTotal)}
              icon={"/media/svg/icons/Files/Selected-file.svg"}
              title={"BUDGET.LINE.RELATED_FILES"}
            />
          )
        }
      >
        {!!subcontractorTotal?.files?.length ? (
          <button type="button" className="btn btn-icon btn-link-primary btn-xs" tabIndex={-1}>
            <i
              className="ki ki-arrow-down icon-nm"
              style={{
                transform: !isExpanded ? "rotate(-90deg)" : "",
                transition: "transform .2s ease",
              }}
            />
          </button>
        ) : (
          <div className={"btn btn-icon btn-xs"}></div>
        )}

        <BudgetLineContent
          columnWidth={columnWidth}
          selectedBudget={!!budget.id && budget.id === project?.selectedBudget}
          line={{
            ...subcontractorTotal,
            label: <span className={"pl-4"}>{subcontractorTotal?.label}</span>,
            remainingToInvoice: (
              <span
                className={cn(
                  subcontractorTotal?.remainingToInvoice !== "-" &&
                    subcontractorTotal?.remainingToInvoice.startsWith("-") &&
                    "text-danger"
                )}
              >
                {subcontractorTotal?.remainingToInvoice}
              </span>
            ),
          }}
        />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={"0"}>
        <div>
          {subcontractorTotal?.files?.map((subcontractorTotalFile: any, index: number) => (
            <CustomCardLine
              key={index}
              size={CustomCardLineSize.SM}
              actions={
                budget.id === project?.selectedBudget && (
                  <DropdownItem
                    onClick={() =>
                      displayLineFiles(subcontractorTotal, subcontractorTotalFile.fileId)
                    }
                    icon={"/media/svg/icons/Files/Selected-file.svg"}
                    title={"BUDGET.LINE.RELATED_FILES"}
                  />
                )
              }
              customClasses={{ container: "bg-hover-gray-200" }}
            >
              <div className={"btn btn-icon btn-xs"}></div>
              <BudgetLineContent
                columnWidth={columnWidth}
                selectedBudget={!!budget.id && budget.id === project?.selectedBudget}
                line={{
                  ...subcontractorTotalFile,
                  label: (
                    <span className={"pl-4"}>
                      <span className="svg-icon svg-icon-primary mr-2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-Empty.svg")} />
                      </span>
                      {subcontractorTotalFile?.label}
                    </span>
                  ),
                  remainingToInvoice: (
                    <span
                      className={cn(
                        subcontractorTotalFile?.remainingToInvoice !== "-" &&
                          subcontractorTotalFile?.remainingToInvoice.startsWith("-") &&
                          "text-danger"
                      )}
                    >
                      {subcontractorTotalFile?.remainingToInvoice}
                    </span>
                  ),
                }}
              />
            </CustomCardLine>
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};
