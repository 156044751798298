import * as React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { ModalProgressBar } from "_metronic/_partials/controls";

import { useRequestSignatureDialogContext } from "./RequestSignatureDialogContext";
import { Signatories } from "./Signatories";
import { RequestSignatureDialogFooter } from "./RequestSignatureDialogFooter";

export const RequestSignatureDialogContent: React.FunctionComponent = () => {
  const { isLoading, shouldShowDialog, closeDialog } = useRequestSignatureDialogContext();

  return (
    <Modal
      show={shouldShowDialog}
      onHide={closeDialog}
      keyboard={false}
      aria-labelledby="request-signature-modal"
      backdrop="static"
      centered
      size="xl"
    >
      {isLoading && <ModalProgressBar variant="query" />}

      <Modal.Header closeButton>
        <Modal.Title id="request-signature-modal">
          <FormattedMessage id="FILE.ACTIONS.SIGNATURE.REQUEST" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-6">
        <Signatories />
      </Modal.Body>

      <Modal.Footer>
        <RequestSignatureDialogFooter />
      </Modal.Footer>
    </Modal>
  );
};

export default RequestSignatureDialogContent;
