import React, { ChangeEvent } from "react";
import { Field } from "formik";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { Button } from "react-bootstrap";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycTranslation } from "../../KycTranslation";
import { KycFlowStatus, KycWorkflow } from "../../Kyc";
import { KycFormTitle } from "../layout/KycFormTitle";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { KycNotificationWidget } from "../KycNotificationWidget";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { canSendForm } from "../../KycUtils";
import { canEdit } from "../../../../_utils/authUtils";
import { useSession } from "../../../../hooks/useSession";
import { useAppSelector } from "../../../../../redux/hooks";

export const KycStepAdminPage2 = (): JSX.Element => {
  const { fm } = useTranslation();
  const { customHandleChange, values } = useKycFlow();
  const { session } = useSession();
  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  const getStepTitle = () => {
    if (values?.status === KycFlowStatus.ADMIN_RISK_ASSESSMENT_DECLINED) {
      return KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_REJECT_RESEND_FORM_COMPLETION;
    } else {
      return values?.form?.workflowChoice === KycWorkflow.FLOW_KYC_WORKFLOW_COMPLETE_BY_ADMIN
        ? KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_ADMIN
        : KycTranslation.STEPS_ADMIN_PAGE_2_TITLE_COMPLETE_BY_CLIENT;
    }
  };

  const { setShowModal, setConfig } = useKycConfirmation();

  const handleClick = (e: any, t: any) => {
    e.preventDefault();
    setConfig({
      type: t,
      kyc: values,
      kycId: values.id,
    });
    setShowModal(true);
  };

  return (
    <>
      <KycFormTitle label={getStepTitle()} />

      <KycCenteredStepBodyLayout>
        <Field
          disabled={true}
          name="form.client.displayName"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label={KycTranslation.CLIENT_NAME}
        />
        <Field
          disabled={true}
          name="form.client.email"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label="USER.INFO.EMAIL"
        />
        <Field
          disabled={true}
          name="form.client.mobile"
          component={KycInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          label="USER.INFO.MOBILE"
        />
        <Field
          component={KycNotificationWidget}
          name="notification"
          isDeadline={true}
          label="COMMON.ACTION.NOTIFY_CLIENT_BY"
          infoLabel={KycTranslation.INFO_NOTIFICATION_IN_APP}
        />
        <div className="mt-8 mx-auto">
          <Button
            data-cy="button-kyc-launch"
            onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_SEND_COMPLETION_REQUEST)}
            className="btn-primary"
            disabled={!canEdit(groups, session, "KYC_FLOW") || !canSendForm(values)}
          >
            {fm("COMMON.ACTION.LAUNCH")}
          </Button>
        </div>
      </KycCenteredStepBodyLayout>
    </>
  );
};
