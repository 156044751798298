import * as React from "react";
import { SearchTypeValues } from "../../contexts/SearchContext";
import { CustomSelect } from "../CustomSelect";
import { useIntl } from "react-intl";
import { useSearch } from "../../hooks/useSearch";
import cn from "clsx";

interface SearchDropdownProps {
  name: string;
  label: string;
  labelKey?: string;
  values: any[];
  groupBy?: (values: any, entities?: any) => undefined;
  formatter?: (value: any) => undefined;
  isMultiple?: boolean;
  isFlexGrow?: boolean;
}

export const SearchDropdown: React.FC<SearchDropdownProps> = ({
  name,
  label,
  labelKey,
  values,
  groupBy,
  formatter,
  isMultiple,
  isFlexGrow,
}): JSX.Element => {
  const { setSearchValues, entities } = useSearch();
  const shouldGroupBy = (values: any) => {
    return groupBy ? groupBy(values, entities) : values;
  };
  const intl = useIntl();
  const options = shouldGroupBy(
    values.map((option: any) => ({
      value: option,
      label: labelKey
        ? intl.formatMessage({ id: labelKey + "." + option })
        : formatter
        ? formatter(option)
        : option.toString(),
    }))
  );

  return (
    <div
      className={cn(`mr-sm-2 mr-0 mt-2`, { "flex-grow-1": isFlexGrow })}
      data-cy={"filter-dropdown-" + name.toLowerCase()}
    >
      <CustomSelect
        placeholder={intl.formatMessage({ id: label })}
        styles={{
          control: { width: "100%", maxWidth: "100%", minHeight: "40px", minWidth: "240px" },
        }}
        noOptionsMessage={() => null}
        options={options}
        clearable={true}
        openMenuOnClick={true}
        onChange={(selected: any) => {
          if (selected === null) {
            setSearchValues("*", SearchTypeValues.SEARCH_DROPDOWN, name);
            return;
          }
          setSearchValues(selected.value, SearchTypeValues.SEARCH_DROPDOWN, name);
        }}
        getOptionValue={(o: any) => o.value}
        disabled={options.length === 0}
      />
    </div>
  );
};
