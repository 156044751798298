import "./KycWizard.scss";
import { KycFlowFormBase } from "app/modules/kyc-v2/components/layout/KycFlowFormBase";
import { isKycAvailable } from "../../_utils/configUtils";
import { KycFileViewerDrawer } from "./components/KycFileViewerDrawer";
import { KycFileViewerDrawerProvider } from "../../contexts/KycFileViewerDrawerContext";
import { KycV2Provider } from "../../contexts/KycContext";
import { SessionProvider } from "../../contexts/SessionContext";
import { WizardProvider } from "app/contexts/WizardContext";
import { KycFlowContainerContent } from "./KycFlowContainerContent";
import { LoadingProvider } from "../../contexts/LoadingContext";
import { KycFileProvider } from "../../contexts/KycFileContext";
import { KycConfirmationProvider } from "../../contexts/KycConfirmationContext";

export const KycFlowContainer = (): JSX.Element => {
  return (
    <>
      {isKycAvailable() && (
        <SessionProvider>
          <LoadingProvider>
            <WizardProvider>
              <KycV2Provider>
                <KycFileProvider>
                  <KycFlowFormBase>
                    <KycConfirmationProvider>
                      <KycFileViewerDrawerProvider>
                        <KycFlowContainerContent />
                        <KycFileViewerDrawer />
                      </KycFileViewerDrawerProvider>
                    </KycConfirmationProvider>
                  </KycFlowFormBase>
                </KycFileProvider>
              </KycV2Provider>
            </WizardProvider>
          </LoadingProvider>
        </SessionProvider>
      )}
    </>
  );
};
