import { DropdownMenu } from "../../../../_components/actions-menu/DropdownMenu";
import { DropdownItem } from "../../../../_components/actions-menu/DropdownItem";
import * as React from "react";
import { KycTranslation } from "../../KycTranslation";
import { KycFlow, KycFlowStatus } from "../../Kyc";
import { useKycConfirmation } from "../../../../hooks/useKycConfirmation";
import { KycDialogConfig } from "../KycConfirmationDialogContainer";
import { canDelete, canEdit, canReadAll, isOwner } from "../../../../_utils/authUtils";
import { useSession } from "../../../../hooks/useSession";
import { useAppSelector } from "../../../../../redux/hooks";

interface ComplianceKycItemContextMenuProps {
  kycId: string;
  kycFlow: KycFlow;
  status: KycFlowStatus | undefined;
}

export const KycComplianceDropdownMenu = ({
  kycId,
  kycFlow,
  status,
}: ComplianceKycItemContextMenuProps): JSX.Element => {
  const enum LINK_TARGET {
    KYC_FORM = "KYC_FORM",
    KYC_TASK = "KYC_TASK",
  }
  const { setConfig, setShowModal } = useKycConfirmation();
  const { session } = useSession();
  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  const handleClick = (e: any, dialogConfig: KycDialogConfig) => {
    e.preventDefault();

    setConfig({
      type: dialogConfig,
      kyc: kycFlow,
      kycId: kycId,
    });
    setShowModal(true);
  };

  const handleOpen = (e: any, destination: LINK_TARGET) => {
    e.preventDefault();
    switch (destination) {
      case LINK_TARGET.KYC_FORM:
        window.open(`/clients/${kycFlow?.form?.client?.id}/kyc/${kycFlow?.id}`, "_blank");
        break;
      case LINK_TARGET.KYC_TASK:
        window.open("/tasks/" + kycFlow?.taskId + "/edit", "_blank");
        break;
      default:
        return;
    }
  };

  return (
    <>
      {(canReadAll(groups, session, "KYC_FLOW") ||
        canEdit(groups, session, "KYC_FLOW") ||
        canDelete(groups, session, "KYC_FLOW" || isOwner(session.id))) && (
        <DropdownMenu dropDirection="down" icon={"/media/svg/icons/General/Other2.svg"}>
          {canReadAll(groups, session, "KYC_FLOW") && (
            <DropdownItem
              data-cy="button-kyc-dropdown-menu-open-task"
              onClick={(e: any) => handleOpen(e, LINK_TARGET.KYC_TASK)}
              title={KycTranslation.ACTION_OPEN_TASK}
            />
          )}
          {(canDelete(groups, session, "KYC_FLOW") || isOwner(session, kycFlow)) && (
            <DropdownItem
              data-cy="button-kyc-dropdown-menu-delete-flow"
              onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_DELETE)}
              title="COMMON.DELETE"
              classes={{ container: "text-danger" }}
            />
          )}

          {status &&
            ![
              KycFlowStatus.KYC_APPROVED,
              KycFlowStatus.KYC_REJECTED,
              KycFlowStatus.CANCELED,
            ].includes(status) &&
            canEdit(groups, session, "KYC_FLOW") && (
              <>
                <DropdownItem
                  data-cy="button-kyc-dropdown-menu-reject-flow"
                  onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_REJECT)}
                  title={KycTranslation.ACTION_KYC_FLOW_REJECT}
                  classes={{ container: "text-danger" }}
                />
                <DropdownItem
                  data-cy="button-kyc-dropdown-menu-abort-flow"
                  onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_ABORT)}
                  title={KycTranslation.ACTION_KYC_FLOW_ABORT}
                  classes={{ container: "text-warning" }}
                />
                <DropdownItem
                  data-cy="button-kyc-dropdown-menu-approve-flow"
                  onClick={(e: any) => handleClick(e, KycDialogConfig.KYC_FLOW_APPROVED)}
                  title={KycTranslation.ACTION_KYC_FLOW_APPROVE}
                  classes={{ container: "text-success" }}
                />
              </>
            )}
        </DropdownMenu>
      )}
    </>
  );
};
