import * as React from "react";
import { ExpandAll } from "./actions/ExpandAll";
import { CollapseAll } from "./actions/CollapseAll";
import { useCardTableContext } from "../../context/CardTableContext";
import { ToggleColumns } from "./actions/ToggleColumns";

export interface CardTableActionsProps {
  canExpand?: boolean;
  canCollapse?: boolean;
  canToggleColumns?: boolean;
  customActions?: React.ReactNode;
}
export const CardTableActions = ({
  canExpand = true,
  canCollapse = true,
  canToggleColumns = true,
  customActions,
}: CardTableActionsProps) => {
  const { table } = useCardTableContext();
  return (
    <div className="d-flex justify-content-end mb-4">
      {canToggleColumns && <ToggleColumns />}
      {table.getCanSomeRowsExpand() && (
        <>
          {canExpand && <ExpandAll />}
          {canCollapse && <CollapseAll />}
        </>
      )}
      {customActions}
    </div>
  );
};
