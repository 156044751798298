import * as React from "react";
import { useEffect, useState } from "react";
import "../kyc-radio-group/KycRadioGroup.scss";
import { FormControl } from "react-bootstrap";
import "./KycUploadInput.scss";
import { KycFile, KycLoading } from "../../../Kyc";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { isPreviewPossible } from "../../../KycFileUtils";
import { useLoading } from "../../../../../hooks/useLoading";
import { useWizard } from "../../../../../hooks/useWizard";
import { KycFieldLock } from "../KycFieldLock";
import { KycUploadTrashButton } from "./KycUploadTrashButton";
import { KycFilePreviewButton } from "./KycFilePreviewButton";
import { KycUploadProgress } from "./KycUploadProgress";
import { KycUploadButton } from "./KycUploadButton";

type KycUploadInputProps = {
  file?: KycFile;
  disabled: boolean;
  othersOnly?: boolean;
  renderTrashButton?: boolean;
};

export const KycUploadInput = ({
  disabled,
  file,
  othersOnly = false,
  renderTrashButton = false,
}: KycUploadInputProps): JSX.Element => {
  const { isCurrentlyLoading } = useLoading();
  const { isReadOnly } = useWizard();
  const { updateFile } = useKycFile();

  const [friendlyName, setFriendlyName] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    setFriendlyName(file?.friendlyName ?? "");
  }, [file]);

  const handleFriendlyName = (e: any) => {
    setFriendlyName(e.target.value);
  };

  const handleUpdateFriendlyName = (e: any) => {
    e.preventDefault();
    if (file) {
      updateFile({ ...file, friendlyName: friendlyName });
    }
  };

  const field: any = {
    name: file?.fieldPath,
    value: file?.friendlyName,
  };

  const showFileUploadButton = !(
    file?.url ||
    isReadOnly ||
    disabled ||
    isCurrentlyLoading(KycLoading.SAVE_FILE, file?.id)
  );

  return (
    <div className="d-flex w-100">
      <div className="w-100">
        {/* FRIENDLY NAME */}
        <KycFieldLock field={field} disabled={disabled}>
          <FormControl
            className="form-control-reduce-space"
            style={{ flex: "1" }}
            disabled={true}
            aria-label="filename"
            aria-describedby="basic-addon2"
            value={friendlyName}
            onBlur={handleUpdateFriendlyName}
            onChange={handleFriendlyName}
          />
        </KycFieldLock>
      </div>
      <div>
        {showFileUploadButton && <KycUploadButton file={file} setProgress={setProgress} />}
        {isCurrentlyLoading(KycLoading.SAVE_FILE, file?.id) ? (
          <KycUploadProgress file={file} progress={progress} />
        ) : (
          <div className="d-flex">
            {file?.url && file && isPreviewPossible(file) && <KycFilePreviewButton file={file} />}
            {renderTrashButton && !othersOnly && <KycUploadTrashButton file={file} />}
          </div>
        )}
      </div>
    </div>
  );
};
