import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotPassword, forgotPasswordSubmit } from "../_redux/authCrud";
import { Input } from "../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const initialValuesRequestCode = {
  email: "",
};

const initialValuesChangePassword = {
  code: "",
  password: "",
  confirmPassword: "",
};

interface ForgotPasswordProps {
  intl: IntlShape;
}

interface IDeliveryDetails {
  DeliveryMedium: string;
  Destination: string;
}

interface IMessage {
  type: string;
  text: string;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ intl }) => {
  Yup.addMethod(Yup.string, "lowercase", function () {
    return this.test("test-lowercase", function (value) {
      const { path, createError } = this;
      if (value && !/[a-z]/.test(value)) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.LOWERCASE" }, { value: 1 }),
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, "uppercase", function () {
    return this.test("test-uppercase", function (value) {
      const { path, createError } = this;
      if (value && !/[A-Z]/.test(value)) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.UPPERCASE" }, { value: 1 }),
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.string, "number", function () {
    return this.test("number", function (value) {
      const { path, createError } = this;
      if (value && !/\d/.test(value)) {
        return createError({
          path,
          message: intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.NUMBER" }, { value: 1 }),
        });
      }
      return true;
    });
  });

  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);
  const [deliveryDetails, setDeliveryDetails] = useState<undefined | IDeliveryDetails>();
  const [message, setMessage] = useState<undefined | IMessage>(undefined);

  const renderRequestCodeForm = () => {
    const RequestPasswordSchema = Yup.object().shape({
      email: Yup.string()
        .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" }))
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
    });
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesRequestCode}
        validationSchema={RequestPasswordSchema}
        onSubmit={(values) => {
          setMessage(undefined);
          setIsSendingCode(true);
          forgotPassword(values.email)
            .then((response) => {
              setDeliveryDetails(response.CodeDeliveryDetails);
              setEmail(values.email);
              setCodeSent(true);
            })
            .catch((error) => {
              console.log("Error:", error);
              setIsSendingCode(false);
              let message = {
                type: "SUCCESS",
                text: intl.formatMessage({ id: "AUTH.FORGOT_PASSWORD.EMAIL_SEND" }),
              };
              if (error.code === "LimitExceededException") {
                message = {
                  type: "DANGER",
                  text: intl.formatMessage({ id: "AUTH.FORGOT_PASSWORD.ERROR.LIMIT_EXCEEDED" }),
                };
              }
              setMessage(message);
            });
        }}
      >
        {() => (
          <Form className="form">
            {message && <ErrorMessage message={message} />}
            <div className="form-group fv-plugins-icon-container">
              <Field
                name="email"
                component={Input}
                label={intl.formatMessage({
                  id: "COMMON.EMAIL",
                })}
                className={"form-control-lg form-control-solid h-auto py-5 px-6"}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="COMMON.ACTION.CANCEL" />
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isSendingCode}
              >
                <span>
                  <FormattedMessage id="COMMON.ACTION.SUBMIT" />
                </span>
                {isSendingCode && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const renderConfirmationForm = () => {
    const ChangePasswordSchema = Yup.object().shape({
      code: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
      password: Yup.string()
        .min(8, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 8`)
        .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`)
        .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
        .lowercase()
        .uppercase()
        // @ts-ignore
        .number(),
      passwordConfirmation: Yup.string()
        .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
        .oneOf(
          [Yup.ref("password"), null],
          intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.MATCH" })
        ),
    });
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesChangePassword}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
          setMessage(undefined);
          setIsConfirming(true);
          forgotPasswordSubmit(email, values.code, values.password)
            .then(() => setConfirmed(true))
            .catch((error) => {
              let errorMessageKey;
              switch (error.code) {
                case "LimitExceededException":
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR.LIMIT_EXCEEDED";
                  break;
                case "CodeMismatchException":
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR.CODE_MISMATCH";
                  break;
                default:
                  errorMessageKey = "AUTH.FORGOT_PASSWORD.ERROR";
                  break;
              }
              setMessage({
                type: "DANGER",
                text: intl.formatMessage({ id: errorMessageKey }),
              });
              setIsConfirming(false);
            });
        }}
      >
        {() => (
          <Form className="form">
            {message && <ErrorMessage message={message} />}
            <div className={"mb-5 font-size-lg"}>
              <span className="svg-icon svg-icon-lg svg-icon-info mr-1">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/info-circle.svg")} />
              </span>
              {deliveryDetails?.DeliveryMedium === "EMAIL" ? (
                <FormattedMessage
                  id={"AUTH.FORGOT_PASSWORD.CHECK_EMAIL"}
                  values={{ destination: deliveryDetails?.Destination }}
                />
              ) : (
                <FormattedMessage
                  id={"AUTH.FORGOT_PASSWORD.CHECK_SMS"}
                  values={{ destination: deliveryDetails?.Destination }}
                />
              )}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className="form-group">
                <Field
                  name="code"
                  component={Input}
                  className={"form-control-lg form-control-solid h-auto py-5 px-6"}
                  label={intl.formatMessage({ id: "AUTH.CONFIRMATION_CODE" })}
                />
              </div>
              <div className="form-group">
                <Field
                  name="password"
                  component={Input}
                  type={"password"}
                  className={"form-control-lg form-control-solid h-auto py-5 px-6"}
                  label={intl.formatMessage({ id: "AUTH.PASSWORD" })}
                />
              </div>
            </div>
            <div className="form-group">
              <Field
                name="passwordConfirmation"
                component={Input}
                type={"password"}
                className={"form-control-lg form-control-solid h-auto py-5 px-6"}
                label={intl.formatMessage({ id: "AUTH.PASSWORD.REPEAT" })}
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="COMMON.ACTION.CANCEL" />
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isConfirming}
              >
                <span>
                  <FormattedMessage id="COMMON.ACTION.SUBMIT" />
                </span>
                {isConfirming && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {!codeSent ? (
            <FormattedMessage id="USER.EMAIL.FORGOT.PASSWORD" />
          ) : (
            !confirmed && <FormattedMessage id="PROFILE.PASSWORD.NEW" />
          )}
        </h3>
        <div className="text-muted font-weight-bold">
          {!codeSent ? (
            <FormattedMessage id="USER.EMAIL.RESET.ENTER.EMAIL" />
          ) : (
            !confirmed && <FormattedMessage id="PROFILE.PASSWORD.NEW.ENTER" />
          )}
        </div>
      </div>
      {!codeSent ? (
        renderRequestCodeForm()
      ) : !confirmed ? (
        renderConfirmationForm()
      ) : (
        <div className="d-flex flex-column align-items-center">
          <span className="svg-icon svg-icon-9x svg-icon-success mb-4">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
          </span>
          <h1>
            <FormattedMessage id={"AUTH.FORGOT_PASSWORD.CHANGED"} />
          </h1>
          <Link className="btn btn-link-primary font-size-h4" to="/auth">
            <FormattedMessage id={"AUTH.FORGOT_PASSWORD.SUCCESS_LINK"} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(ForgotPassword));

interface ErrorMessageProps {
  message: IMessage;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <>
      {message.type === "DANGER" && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{message.text}</div>
        </div>
      )}
      {message.type === "SUCCESS" && (
        <div className="mb-10 alert success-custom bg-light-success py-5 px-9 alert-dismissible">
          <div className="text-success font-weight-bold">{message.text}</div>
        </div>
      )}
    </>
  );
};
