import { formatCurrency } from "../../_utils/mathUtils";
import {
  BudgetLineBaseProps,
  BudgetLineBasePropsType,
  BudgetLineIncludingTaxProps,
  IBudgetLine,
  IBudgetSortedCategory,
  IProject,
  ISFFFile,
  SFFRelatedBudgetFinanceType,
  SFFRelatedBudgetFinanceIncludingTaxType,
  SubcontractorFinanceType,
  SubcontractorsFinanceFiles,
  TFormattedBudgetLine,
} from "../../../data/schemas";
import { IntlShape } from "react-intl";
import { SUFFIX_PERCENTAGE } from "../../_utils/suffixUtils";
import { sortBy } from "lodash-es";

export const sumBudgetLineBaseProps = (data: any[]) =>
  data.reduce((acc, dataObj) => {
    for (const financeType of Object.values(BudgetLineBaseProps)) {
      if (dataObj[financeType]) {
        acc[financeType] = (acc[financeType] ?? 0) + dataObj[financeType];
      }
    }
    for (const financeType of Object.values(BudgetLineIncludingTaxProps)) {
      if (dataObj[financeType]) {
        acc[financeType] = (acc[financeType] ?? 0) + dataObj[financeType];
      }
    }
    return acc;
  }, {});

export const computeUnspecifiedLines = (categoryId: string, project?: Partial<IProject>) =>
  Object.values(SubcontractorFinanceType).reduce<any[]>(
    (acc, sffType) => [
      ...acc,
      ...(Object.entries(
        project?.subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines ?? {}
      )
        ?.filter(([, line]) => !line.budgetLineId)
        .map(([id, line]: any) => computeLine({ project, categoryId, line: { ...line, id } })) ??
        []),
    ],
    []
  );

export interface ComputeLineProps {
  project?: Partial<IProject>;
  categoryId: string;
  line: IBudgetLine;
}

export const computeCategory = (
  categoryId: string,
  project?: Partial<IProject>,
  category?: IBudgetSortedCategory
) => {
  const computedLines =
    category?.lines?.map((line) => computeLine({ project, categoryId, line })) ?? [];
  const unspecifiedLines = computeUnspecifiedLines(categoryId, project);
  const linesTotal = sumBudgetLineBaseProps([...computedLines, ...unspecifiedLines]);
  return {
    id: categoryId,
    label: category?.label,
    ...linesTotal,
    lines: computedLines,
    unspecifiedLines,
  };
};

export const computeLine = ({ project, categoryId, line }: ComputeLineProps) => {
  const subcontractors = computeSubcontractors(
    project?.subcontractorsFinanceFiles,
    categoryId,
    line.id
  );
  const subcontractorsTotal = sumBudgetLineBaseProps(subcontractors);
  return {
    id: line.id,
    ...subcontractorsTotal,
    label: line.label,
    planned: line.plannedBudget,
    subcontractors,
  };
};

export const computeSubcontractors = (
  subcontractorsFinanceFiles: SubcontractorsFinanceFiles | undefined,
  categoryId: string,
  budgetLineId: string
) => {
  let res: any[] = [];
  const invoicesToHide: Record<string, string[]> = {};
  for (const sffType of Object.values(SubcontractorFinanceType)) {
    const sffLine =
      subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines?.[budgetLineId];
    if (sffLine) {
      for (const file of sffLine.files) {
        const { userId, userName, id: fileId } = file;
        if (invoicesToHide[userId]?.find((f: string) => f === fileId)) {
          continue;
        }
        let subcontractor = res.find((sub) => sub.userId === userId);
        if (!subcontractor) {
          subcontractor = {
            userId,
            label: userName,
            files: [],
          };
          res.push(subcontractor);
        }
        const computedFile = computeFile({
          file,
          sffType,
          subcontractorsFinanceFiles,
          categoryId,
          budgetLineId,
          invoicesToHide,
        });
        const updatedSubcontractorValues = sumBudgetLineBaseProps([subcontractor, computedFile]);
        for (const prop in updatedSubcontractorValues) {
          subcontractor[prop] = updatedSubcontractorValues[prop];
        }
        subcontractor.files.push(computedFile);
      }
    }
  }
  return sortBy(res, "label");
};

interface ComputeFileProps {
  file: ISFFFile;
  sffType: SubcontractorFinanceType;
  subcontractorsFinanceFiles?: SubcontractorsFinanceFiles;
  categoryId: string;
  budgetLineId: string;
  invoicesToHide: any;
}

const computeFile = ({
  file,
  sffType,
  subcontractorsFinanceFiles,
  categoryId,
  budgetLineId,
  invoicesToHide,
}: ComputeFileProps) => {
  const budgetFinanceType = SFFRelatedBudgetFinanceType[sffType];
  const budgetFinanceIncludingTaxType = SFFRelatedBudgetFinanceIncludingTaxType[sffType];
  const computedFile: any = {
    label: file.friendlyName,
    fileType: file.fileType,
    fileId: file.id,
    [budgetFinanceIncludingTaxType]:
      sffType === SubcontractorFinanceType.INVOICE
        ? file.grossAmount + file.grossAmount * file.vat
        : file.amount + file.amount * file.vat,
    [budgetFinanceType]:
      sffType === SubcontractorFinanceType.INVOICE ? file.grossAmount : file.amount,
  };
  if (sffType === SubcontractorFinanceType.INVOICE) {
    computedFile.discounts = file.grossAmount - file.amount;
  }
  // Temporary use linked files to link orders/SA to invoices
  if (
    [SubcontractorFinanceType.ORDER, SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT].includes(
      sffType
    ) &&
    file.linkedFiles
  ) {
    for (const linkedFile of file.linkedFiles) {
      const invoice = subcontractorsFinanceFiles?.invoices?.data?.[categoryId]?.lines?.[
        budgetLineId
      ]?.files?.find((f) => f.id === linkedFile.id);
      if (invoice) {
        if (!invoicesToHide[file.userId]) {
          invoicesToHide[file.userId] = [invoice.id];
        } else {
          invoicesToHide[file.userId].push(invoice.id);
        }
        computedFile.invoiced = (computedFile.invoiced ?? 0) + invoice.grossAmount;
        computedFile.invoiced_including_tax =
          computedFile.invoiced + computedFile.invoiced * file.vat;
        computedFile.discounts = invoice.grossAmount - invoice.amount;
        computedFile.discounts_including_tax =
          computedFile.discounts + computedFile.discounts * file.vat;
        if (sffType === SubcontractorFinanceType.ORDER) {
          computedFile.invoicedOrders = (computedFile.invoicedOrders ?? 0) + invoice.grossAmount;
          computedFile.invoicedOrders_including_tax =
            computedFile.invoicedOrders + computedFile.invoicedOrders * file.vat;
        } else {
          computedFile.invoicedSupplementaryAgreements =
            (computedFile.invoicedSupplementaryAgreements ?? 0) + invoice.grossAmount;
          computedFile.invoicedSupplementaryAgreements_including_tax =
            computedFile.invoicedSupplementaryAgreements +
            computedFile.invoicedSupplementaryAgreements * file.vat;
        }
      }
    }
  }
  return computedFile;
};

export const formatCategory = (category: any, intl: IntlShape) => ({
  ...formatContentColumns({
    ...category,
    intl,
  }),
  lines: category.lines?.map((line: any) => formatLine(line, intl)),
  unspecifiedLines: category.unspecifiedLines?.map((line: any) => formatLine(line, intl)),
});

export const formatLine = (line: any, intl: IntlShape) => ({
  ...formatContentColumns({
    ...line,
    intl,
  }),
  subcontractors: line.subcontractors.map((subcontractor: any) => ({
    userId: subcontractor.userId,
    ...formatContentColumns({ ...subcontractor, intl }),
    files: subcontractor.files.map((file: any) => ({
      fileId: file.fileId,
      fileType: file.fileType,
      ...formatContentColumns({ ...file, intl }),
    })),
  })),
});

type FormatBudgetLineProps = {
  intl: IntlShape;
  label?: any;
  id?: string;
} & Partial<Record<BudgetLineBasePropsType, number>>;
export const formatContentColumns = ({
  id,
  planned,
  quotes,
  supplementary_agreements,
  orders,
  invoiced,
  invoicedOrders,
  invoicedSupplementaryAgreements,
  discounts,
  intl,
  label,
}: FormatBudgetLineProps): TFormattedBudgetLine => {
  const totalReal = (orders ?? 0) + (supplementary_agreements ?? 0);
  const realDifferenceCategory = totalReal && planned ? totalReal - planned : 0;
  return {
    id,
    label,
    planned: planned ? formatCurrency(planned, 2, intl) : "-",
    quotes: quotes ? formatCurrency(quotes, 2, intl) : "-",
    supplementary_agreements: supplementary_agreements
      ? formatCurrency(supplementary_agreements, 2, intl)
      : "-",
    ordersAndSupplAgreements: totalReal ? formatCurrency(totalReal, 2, intl) : "-",
    orders: orders ? formatCurrency(orders, 2, intl) : "-",
    realDifference:
      totalReal && planned
        ? (realDifferenceCategory >= 0 ? "+" : "") + formatCurrency(realDifferenceCategory, 2, intl)
        : "-",
    realDifferencePercentage:
      totalReal && planned
        ? (realDifferenceCategory >= 0 ? "+" : "") +
          intl.formatNumber((realDifferenceCategory / planned) * 100, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          SUFFIX_PERCENTAGE
        : "-",
    invoiced: invoiced ? formatCurrency(invoiced, 2, intl) : "-",
    invoicedPercentage:
      invoiced && totalReal
        ? intl.formatNumber((invoiced / totalReal) * 100, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + SUFFIX_PERCENTAGE
        : "-",
    remainingToInvoice:
      totalReal || invoiced ? formatCurrency((totalReal ?? 0) - (invoiced ?? 0), 2, intl) : "-",
    invoicedOrders: invoicedOrders ? formatCurrency(invoicedOrders, 2, intl) : "-",
    remainingOrdersToInvoice: orders
      ? formatCurrency(orders - (invoicedOrders ?? 0), 2, intl)
      : "-",
    invoicedOrdersPercentage:
      orders && invoicedOrders
        ? intl.formatNumber((invoicedOrders / orders) * 100, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + SUFFIX_PERCENTAGE
        : "-",
    invoicedSupplementaryAgreements: invoicedSupplementaryAgreements
      ? formatCurrency(invoicedSupplementaryAgreements, 2, intl)
      : "-",
    remainingSupplementaryAgreementsToInvoice: supplementary_agreements
      ? formatCurrency(supplementary_agreements - (invoicedSupplementaryAgreements ?? 0), 2, intl)
      : "-",
    invoicedSupplementaryAgreementsPercentage:
      supplementary_agreements && invoicedSupplementaryAgreements
        ? intl.formatNumber((invoicedSupplementaryAgreements / supplementary_agreements) * 100, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + SUFFIX_PERCENTAGE
        : "-",
    discounts: discounts ? formatCurrency(discounts, 2, intl) : "-",
  };
};
