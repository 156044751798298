import * as React from "react";
import { createContext, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import { KycConfirmationDialogConfiguration, KycFlow } from "../modules/kyc-v2/Kyc";
import {
  KycConfirmationDialogContainer,
  KycDialogConfig,
} from "../modules/kyc-v2/components/KycConfirmationDialogContainer";

export interface Config {
  type?: KycDialogConfig;
  kycId?: string;
  kyc?: KycFlow;
  children?: ReactNode;
}

export interface KycConfirmationContextValue {
  showModal: boolean;
  setShowModal: Function;

  config: Config | undefined;
  setConfig: Function;
}

export const KycConfirmationContext = createContext<KycConfirmationContextValue>({
  showModal: false,
  setShowModal: (value: boolean) => undefined,

  config: undefined,
  setConfig: (params: KycConfirmationDialogConfiguration) => undefined,
});

export const KycConfirmationProvider = (props: { children: any }): JSX.Element => {
  const { children } = props;

  const [showModal, setShowModalInternal] = useState<boolean>(false);
  const [config, setConfigInternal] = useState<Config>({});

  const setConfig = (config: Config) => {
    setConfigInternal(config);
  };

  const setShowModal = (value: boolean) => {
    setShowModalInternal(value);
  };

  return (
    <KycConfirmationContext.Provider
      value={{
        showModal,
        setShowModal,
        config,
        setConfig,
      }}
    >
      {children}
      <KycConfirmationDialogContainer />
    </KycConfirmationContext.Provider>
  );
};

KycConfirmationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycConfirmationConsumer = KycConfirmationContext.Consumer;
