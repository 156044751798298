import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

type KycMaximizeWindowButtonProps = {
  isExpanded: boolean;
  setIsExpanded: any;
  size?: "sm" | "lg";
};

export const KycMaximizeWindowButton = ({
  isExpanded,
  setIsExpanded,
  size = "lg",
}: KycMaximizeWindowButtonProps): JSX.Element => {
  const toggleExpand = (e: any) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Button
        size={size}
        type="button"
        className="btn btn-icon btn-light-secondary"
        onClick={toggleExpand}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl(
              isExpanded
                ? "/media/svg/icons/General/Scale.svg"
                : "/media/svg/icons/General/Size.svg"
            )}
          />
        </span>
      </Button>
    </>
  );
};
