/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./LeadFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { useSelector } from "react-redux";
import { canReadAllDocument } from "../../../../../_utils/authUtils";
import { SearchTypeValues } from "../../../../../contexts/SearchContext";

const LeadFilesUIContext = createContext();

export function useLeadFilesUIContext() {
  return useContext(LeadFilesUIContext);
}

export function LeadFilesUIProvider({ currentLeadId, children, readOnly }) {
  const filesContext = useFilesContext();

  const [leadId, setLeadId] = useState(currentLeadId);
  const [filters, setFilters] = useState(initialFilter);
  const [isInvoice, setIsInvoice] = useState(false);

  const { groups, session } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const initFile = {
    id: undefined,
    file: undefined,
    friendlyName: "",
    fileType: "-",
    leadId,
    isConfidential: canReadAllDocument(groups, session, "LEAD"),
  };
  useEffect(() => {
    initFile.leadId = currentLeadId;
    setLeadId(currentLeadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLeadId]);

  const value = {
    ...filesContext,
    leadId,
    setLeadId,
    filters,
    setFilters,
    initFile,
    readOnly,
    isInvoice,
    setIsInvoice,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [...filesContext.filterList, { value: "invoiceStatus", label: "COMMON.STATUS" }],
      },
      {
        name: "COMMON.TYPE",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "fileType",
        values: [],
        labelKey: "LEAD.FILE.TYPES",
      },
      {
        name: "COMMON.STATUS",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "invoiceStatus",
        values: [],
        labelKey: "INVOICE.STATUS",
      },
      { name: "COMMON.UPDATED.AT", value: "uploadedAt", type: SearchTypeValues.SEARCH_DATE },
    ],
  };

  return <LeadFilesUIContext.Provider value={value}>{children}</LeadFilesUIContext.Provider>;
}
