import { exportCSV } from "../../../_utils/fileUtils";
import { IntlShape } from "react-intl";
import { computeCategory } from "../BudgetsUtils";
import { SubcontractorFinanceType } from "../../../../data/schemas";
import { cloneDeep } from "lodash-es";

export const downloadCSV = (budget: any, project: any, values: any, intl: IntlShape) => {
  const headers = [
    intl.formatMessage({ id: "COMMON.DESCRIPTION" }),
    intl.formatMessage({ id: "BUDGET.PLANNED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.PLANNED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.QUOTES" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.QUOTES" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "FINANCE.ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.ORDERS_AND_SUPPLEMENTARY_AGREEMENTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.REMAINING_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.REMAINING_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.REMAINING_ORDERS_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.REMAINING_ORDERS_TO_INVOICE" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.INVOICED_ORDERS_PERCENT" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),

    intl.formatMessage({
      id: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.REMAINING_SUPPLEMENTARY_AGREEMENT_TO_INVOICE",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({
      id: "BUDGET.INVOICED_SUPPLEMENTARY_AGREEMENT_PERCENT",
    }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DISCOUNTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.EXCL_TAX" }),
    intl.formatMessage({ id: "BUDGET.DISCOUNTS" }) +
      " " +
      intl.formatMessage({ id: "COMMON.INCL_TAX" }),
  ];

  const content = [headers];
  const contentSubcontractors = [cloneDeep(headers)];
  contentSubcontractors[0].splice(
    1,
    0,
    ...[
      intl.formatMessage({ id: "EXPORT.BUDGET.CATEGORY_NAME" }),
      intl.formatMessage({ id: "EXPORT.BUDGET.BUDGET_LINE" }),
      intl.formatMessage({ id: "EXPORT.BUDGET.SUBCONTRACTOR" }),
    ]
  );

  const computedCategories = budget.sortedLines?.map((category: any) =>
    computeCategory(category.id, project, category)
  );

  const hasUnspecifiedCategory = Object.values(SubcontractorFinanceType).some(
    (financeType) => !!project?.subcontractorsFinanceFiles?.[financeType]?.data?.unspecifiedCategory
  );

  const computedUnspecifiedCategory =
    hasUnspecifiedCategory && computeCategory("unspecifiedCategory", project);

  const generateRow = (valueCategories: any) => {
    const planned = valueCategories.planned ?? 0;

    const plannedIncludingTax = planned + planned * (values.projectOwner.defaultVat ?? 0) ?? 0;

    const ordersAndSupplAgreements =
      (valueCategories.orders ?? 0) + (valueCategories.supplementary_agreements ?? 0);

    const ordersAndSupplAgreementsIncludingTax =
      (valueCategories.orders_including_tax ?? 0) +
      (valueCategories.supplementary_agreements_including_tax ?? 0);

    return [
      '"' + valueCategories.label + '"',
      planned,
      plannedIncludingTax,
      valueCategories.quotes ?? "0",
      valueCategories.quotes_including_tax ?? "0",
      valueCategories.orders ?? "0",
      valueCategories.orders_including_tax ?? "0",
      valueCategories.supplementary_agreements ?? "0",
      valueCategories.supplementary_agreements_including_tax ?? "0",
      ordersAndSupplAgreements,
      ordersAndSupplAgreementsIncludingTax,
      planned ? ordersAndSupplAgreements - planned : "-",
      plannedIncludingTax ? ordersAndSupplAgreementsIncludingTax - plannedIncludingTax : "-",
      planned ? ((ordersAndSupplAgreements - planned) * 100) / planned : "-",
      plannedIncludingTax
        ? ((ordersAndSupplAgreementsIncludingTax - plannedIncludingTax) * 100) / plannedIncludingTax
        : "-",
      valueCategories.invoiced ?? 0,
      valueCategories.invoiced_including_tax ?? 0,
      ordersAndSupplAgreements
        ? ((valueCategories.invoiced ?? 0) * 100) / ordersAndSupplAgreements
        : 0,
      ordersAndSupplAgreementsIncludingTax
        ? ((valueCategories.invoiced_including_tax ?? 0) * 100) /
          ordersAndSupplAgreementsIncludingTax
        : 0,
      ordersAndSupplAgreements - (valueCategories.invoiced ?? 0),
      ordersAndSupplAgreementsIncludingTax - (valueCategories.invoiced_including_tax ?? 0),
      valueCategories.invoicedOrders ?? 0,
      valueCategories.invoicedOrders_including_tax ?? 0,
      (valueCategories.orders ?? 0) - (valueCategories.invoicedOrders ?? 0),
      (valueCategories.orders_including_tax ?? 0) -
        (valueCategories.invoicedOrders_including_tax ?? 0),
      valueCategories.orders
        ? ((valueCategories.invoicedOrders ?? 0) * 100) / valueCategories.orders
        : "-",
      valueCategories.orders_including_tax
        ? ((valueCategories.invoicedOrders_including_tax ?? 0) * 100) /
          valueCategories.orders_including_tax
        : "-",
      valueCategories.invoicedSupplementaryAgreements ?? 0,
      valueCategories.invoicedSupplementaryAgreements_including_tax ?? 0,
      (valueCategories.supplementary_agreements ?? 0) -
        (valueCategories.invoicedSupplementaryAgreements ?? 0),
      (valueCategories.supplementary_agreements_including_tax ?? 0) -
        (valueCategories.invoicedSupplementaryAgreements_including_tax ?? 0),
      valueCategories.supplementary_agreements
        ? ((valueCategories.invoicedSupplementaryAgreements ?? 0) * 100) /
          valueCategories.supplementary_agreements
        : "-",
      valueCategories.supplementary_agreements_including_tax
        ? ((valueCategories.invoicedSupplementaryAgreements_including_tax ?? 0) * 100) /
          valueCategories.supplementary_agreements_including_tax
        : "-",
      valueCategories.discounts ?? 0,
      valueCategories.discounts_including_tax ?? 0,
    ];
  };

  const fullData = computedCategories;

  if (hasUnspecifiedCategory) {
    computedUnspecifiedCategory.label = intl.formatMessage({ id: "BUDGET.NOT_SPECIFIED" });
    fullData.push(computedUnspecifiedCategory);
  }

  const subcontractorAndFilesLoop = (valuesLines: any, valueCategory: string): any => {
    const returnValues: any[] = [];
    const returnValues2: any[] = [];
    valuesLines.subcontractors.forEach((valueSubcontractors: any) => {
      const rowSubcontractors = generateRow(valueSubcontractors);

      returnValues.push(rowSubcontractors);
      if (valueSubcontractors && valueSubcontractors.files)
        valueSubcontractors.files.forEach((valuesFiles: any) => {
          const row = generateRow(valuesFiles);
          returnValues.push(cloneDeep(row));
          row.splice(
            0,
            1,
            ...[valueCategory, valuesLines.label, valuesFiles.label, valueSubcontractors.label]
          );
          returnValues2.push(row);
        });
    });
    return { default: returnValues, subcontractorLines: returnValues2 };
  };

  fullData.forEach((valueCategories: any) => {
    content.push(generateRow(valueCategories));
    valueCategories.lines?.forEach((valueLines: any) => {
      content.push(generateRow(valueLines));
      const subContractorValues = subcontractorAndFilesLoop(valueLines, valueCategories.label);
      content.push(...subContractorValues.default);
      contentSubcontractors.push(...subContractorValues.subcontractorLines);
    });

    valueCategories.unspecifiedLines?.forEach((valueUnspecifiedLines: any) => {
      content.push(generateRow(valueUnspecifiedLines));
      const subContractorValues = subcontractorAndFilesLoop(
        valueUnspecifiedLines,
        valueCategories.label
      );
      content.push(...subContractorValues.default);
      contentSubcontractors.push(...subContractorValues.subcontractorLines);
    });
  });

  exportCSV(values.projectName + "_" + values.name, content);
  exportCSV(
    values.projectName + "_" + values.name + "_" + intl.formatMessage({ id: "BUDGET.EXPENSES" }),
    contentSubcontractors
  );
};
