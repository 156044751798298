import { useMediaMatch } from "rooks";
import { KTUtil } from "../../../../../_metronic/_assets/js/components/util";
import * as CSS from "csstype";

export interface BudgetTextFonts {
  headers: CSS.FontProperty;
  categoryHeaders: CSS.FontProperty;
  lines: CSS.FontProperty;
}

export const useGetBudgetTextFont = (): BudgetTextFonts => {
  const matchXL = useMediaMatch(`(min-width:${KTUtil.getBreakpoint("xl")}px)`);
  const headers = "500 0.8rem Roboto Mono";
  const categoryHeaders = matchXL ? "500 13px Roboto" : "500 12px Roboto";

  const lines = "400 0.925rem Roboto";

  return { headers, categoryHeaders, lines };
};
