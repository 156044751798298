import * as React from "react";

import { IFile } from "data/schemas";

import { IFilesContext } from "app/modules/File/FilesProvider";

import { RequestSignatureDialogProvider } from "./RequestSignatureDialogContext";
import { RequestSignatureDialogContent } from "./RequestSignatureDialogContent";

type TUseFilesUIContext<R extends IFilesContext = IFilesContext> = () => R;

interface RequestSignatureDialogProps {
  fileList: IFile[];
  useFilesUIContext: TUseFilesUIContext;
  requestFileSignature: Function;
}

export const RequestSignatureDialog: React.FunctionComponent<RequestSignatureDialogProps> = ({
  fileList,
  useFilesUIContext,
  requestFileSignature,
}) => {
  const uiContext = useFilesUIContext();
  const uiProps = React.useMemo(() => {
    const {
      selectedId: fileId,
      showRequestSignatureDialog,
      closeRequestSignatureDialog,
    } = uiContext;
    const file = (fileList || []).find((f) => f.id === fileId);

    return { file, fileId, showRequestSignatureDialog, closeRequestSignatureDialog };
  }, [uiContext, fileList]);

  return (
    <RequestSignatureDialogProvider
      {...{
        requestFileSignature,
        showDialog: uiProps.showRequestSignatureDialog,
        closeDialog: uiProps.closeRequestSignatureDialog,
        fileId: uiProps.fileId,
        file: uiProps.file,
      }}
    >
      <RequestSignatureDialogContent />
    </RequestSignatureDialogProvider>
  );
};

export default RequestSignatureDialog;
