import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FieldArray } from "formik";
import { accurateFloatOperation } from "../../_utils/mathUtils";
import { CustomCard, CustomCardLine } from "../../_components/CustomCard";
import { NumberInput } from "../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../_utils/suffixUtils";
import { TBudgetInstalments } from "../../../data/schemas";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useEffect, useMemo } from "react";
import { fetchSettings } from "../Settings/_redux/settingsActions";
import logout from "../Auth/pages/Logout";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_metronic/layout";

interface LeadInstalmentTableProps {
  shareOfLandPrice: number;
  architectEngineeringFeesPrice: number;
  expectedConstructionPrice: number;
  totalInstalments: number;
  budgetInstalments: TBudgetInstalments;
}

export const LeadInstalmentTable: React.FC<LeadInstalmentTableProps> = ({
  architectEngineeringFeesPrice,
  shareOfLandPrice,
  expectedConstructionPrice,
  totalInstalments,
  budgetInstalments,
}) => {
  const { settings } = useAppSelector((state) => ({
    settings: state.settings.settingsForEdit.current,
  }));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!settings) {
      fetchSettings(dispatch).catch(() => {
        logout({});
      });
    }
  }, [settings]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
    };
  }, [uiService]);

  const { REDUCED_VAT, DEFAULT_VAT } = useMemo(() => {
    return {
      REDUCED_VAT: (settings?.reducedVat ?? 0) + 1,
      DEFAULT_VAT: (settings?.defaultVat ?? 0) + 1,
    };
  }, [settings]);

  return (
    <CustomCard
      header={
        <div className="form-row flex-grow-1 mr-12">
          <div className="col-4 pl-4" />
          <div className="col-2 text-right" />
          {[
            accurateFloatOperation(
              expectedConstructionPrice * totalInstalments +
                architectEngineeringFeesPrice +
                shareOfLandPrice,
              2
            ),
            accurateFloatOperation(
              expectedConstructionPrice * totalInstalments * REDUCED_VAT +
                architectEngineeringFeesPrice * DEFAULT_VAT +
                shareOfLandPrice,
              2
            ),
            accurateFloatOperation(
              expectedConstructionPrice * totalInstalments * DEFAULT_VAT +
                architectEngineeringFeesPrice * DEFAULT_VAT +
                shareOfLandPrice,
              2
            ),
          ].map((value, index) => (
            <div className="col-2 text-right" key={index}>
              <NumberInput
                displayType="text"
                value={value}
                decimalScale={2}
                suffix={SUFFIX_EURO_CURRENCY}
                className="font-weight-bold"
              />
            </div>
          ))}
        </div>
      }
    >
      <CustomCardLine>
        <div
          className="form-row flex-grow-1"
          style={{ color: layoutProps.colorsGrayGray500, fontWeight: "600" }}
        >
          <div className="col-4">
            <FormattedMessage id="COMMON.DESCRIPTION" />
          </div>
          <div className="col-2 text-right">
            <FormattedMessage id="BUDGET.INSTALMENT.SINGLE" />
          </div>
          {[0, 3, 17].map((value, index) => (
            <div className="col-2 text-right" key={index}>
              <FormattedMessage id={"COMMON.NUMBER_VAT"} values={{ data: value }} />
            </div>
          ))}
        </div>
      </CustomCardLine>

      <FieldArray
        name="budgetInstalments"
        validateOnChange={false}
        render={() => (
          <>
            <CustomCardLine>
              <div className="form-row flex-grow-1">
                <div className="col-4 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <FormattedMessage id={"COMMON.EXPECTED.TOTAL.SHARE_OF_LAND"} />
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">-</div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <NumberInput
                    displayType="text"
                    value={accurateFloatOperation(shareOfLandPrice, 2)}
                    suffix={SUFFIX_EURO_CURRENCY}
                    className="text-right"
                  />
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">-</div>
                <div className="col-2 d-flex align-items-center justify-content-end">-</div>
              </div>
            </CustomCardLine>
            <CustomCardLine>
              <div className="form-row flex-grow-1">
                <div className="col-4 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <FormattedMessage id={"COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING"} />
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">-</div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <NumberInput
                    displayType="text"
                    value={accurateFloatOperation(architectEngineeringFeesPrice, 2)}
                    suffix={SUFFIX_EURO_CURRENCY}
                    className="text-right"
                  />
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">-</div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <NumberInput
                    displayType="text"
                    value={accurateFloatOperation(architectEngineeringFeesPrice * DEFAULT_VAT, 2)}
                    suffix={SUFFIX_EURO_CURRENCY}
                    className="text-right"
                  />
                </div>
              </div>
            </CustomCardLine>
            {budgetInstalments?.map((instalment) => (
              <CustomCardLine key={instalment.id} draggable={false} removable={false}>
                <div className="form-row flex-grow-1">
                  <div className="col-4 d-flex align-items-center">
                    <div className="flex-grow-1">{instalment.label}</div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <NumberInput
                      displayType="text"
                      value={accurateFloatOperation(instalment.instalment * 100, 2)}
                      decimalScale={2}
                      suffix={SUFFIX_PERCENTAGE}
                      className="text-right"
                    />
                  </div>
                  {[
                    accurateFloatOperation(expectedConstructionPrice * instalment.instalment, 2),
                    accurateFloatOperation(
                      expectedConstructionPrice * instalment.instalment * REDUCED_VAT,
                      2
                    ),
                    accurateFloatOperation(
                      expectedConstructionPrice * instalment.instalment * DEFAULT_VAT,
                      2
                    ),
                  ].map((value, index) => (
                    <div
                      className="col-2 d-flex align-items-center justify-content-end"
                      key={index}
                    >
                      <NumberInput
                        displayType="text"
                        value={value}
                        suffix={SUFFIX_EURO_CURRENCY}
                        className="text-right"
                      />
                    </div>
                  ))}
                </div>
              </CustomCardLine>
            ))}
          </>
        )}
      />
    </CustomCard>
  );
};
