import * as React from "react";
import { useIntl } from "react-intl";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { Input } from "_metronic/_partials/controls";
import { PhoneFormInput } from "_metronic/_partials/controls/forms/PhoneFormInput";

import { IRequestSignatory } from "data/schemas";

import { useRequestSignatureDialogContext } from "../../RequestSignatureDialogContext";

//----------------------------------------------------------------------------//

export interface SignatoryFormProps {
  signatory: IRequestSignatory;
  onUpdate?: (signatory: IRequestSignatory) => void;
}

export const SignatoryForm: React.FunctionComponent<SignatoryFormProps> = ({
  signatory,
  onUpdate,
}) => {
  const intl = useIntl();

  const formRef = React.useRef<FormikProps<IRequestSignatory>>(null);

  const { signatoryHelperId } = signatory;

  //--------------------------------------------------------------------------//

  const { signatories } = useRequestSignatureDialogContext();

  const testEmailNotUsed = React.useCallback(
    (value) => {
      const index = signatories.findIndex(
        (item) => item.signatoryHelperId !== signatory.signatoryHelperId && item.email === value
      );

      return index === -1;
    },
    [signatories, signatory]
  );

  //--------------------------------------------------------------------------//

  const FormSchema = Yup.object().shape({
    fullName: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(100, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 100`),
    email: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`)
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" }))
      .test({
        name: "is-email-not-used",
        message: intl.formatMessage({ id: "USER.EXTERNAL.DANGER.EMAIL.USED" }),
        test: testEmailNotUsed,
      }),
    mobile: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PHONE.REQUIRED" }))
      .min(6, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 6`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
  });

  const formBlurHandler = () => {
    const { values = {} as IRequestSignatory, isValid = false } = formRef.current || {};

    const { fullName, email, mobile, signatoryHelperId } = values;

    const isAllValid = isValid && fullName !== "" && email !== "" && mobile !== "";

    const toUpdate: IRequestSignatory = {
      signatoryHelperId,
      fullName,
      email,
      mobile,
      isValid: isAllValid,
    };

    onUpdate && onUpdate(toUpdate);
  };

  //--------------------------------------------------------------------------//

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={false}
      initialValues={signatory}
      validationSchema={FormSchema}
      onSubmit={
        (/*values*/) => {
          // I'm forced to have this attribute defined...
          // console.log(values);
        }
      }
    >
      {({ handleChange, errors }) => (
        <Form
          className="form-fields"
          data-cy={`form__${signatoryHelperId}`}
          onBlur={formBlurHandler}
        >
          <div className="attribute name" data-error={errors.fullName ?? " "}>
            <Field
              name="fullName"
              data-cy={`input-fullName__${signatoryHelperId}`}
              component={Input}
              onChange={handleChange}
              customFeedbackLabel={" "}
              withFeedbackLabel={false}
            />
          </div>

          <div className="attribute email" data-error={errors.email ?? " "}>
            <Field
              name="email"
              data-cy={`input-email__${signatoryHelperId}`}
              component={Input}
              onChange={handleChange}
              customFeedbackLabel={" "}
              withFeedbackLabel={false}
            />
          </div>

          <div className="attribute phone" data-error={errors.mobile ?? " "}>
            <Field
              name="mobile"
              data-cy={`input-mobile__${signatoryHelperId}`}
              component={PhoneFormInput}
              onChange={handleChange}
              customFeedbackLabel={" "}
              withFeedbackLabel={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignatoryForm;
