import * as React from "react";
import { ReactNode } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycFlowStatus } from "../../Kyc";
import { KycTranslation } from "../../KycTranslation";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { useTranslation } from "../../../../hooks/useTranslation";
import { useWizard } from "../../../../hooks/useWizard";

export const KycStepSuccess = (): JSX.Element => {
  const { setActiveStep } = useWizard();
  const { isClientViewer, isStatusOneOf } = useKycFlow();
  const { fm } = useTranslation();

  type KycStepSuccessLayoutContainerProps = {
    message: string;
    children?: ReactNode;
  };

  const KycStepSuccessLayoutContainer = ({
    message,
    children,
  }: KycStepSuccessLayoutContainerProps): JSX.Element => {
    return (
      <div className="d-flex flex-column align-items-center">
        <span className="svg-icon svg-icon-9x svg-icon-success mb-4">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
        </span>
        <h3 className="text-center">{fm(message)}</h3>
        {children}
      </div>
    );
  };

  const getSuccessScreen = () => {
    if (
      isClientViewer &&
      !isStatusOneOf([
        KycFlowStatus.CLIENT_REVIEW_PENDING,
        KycFlowStatus.CLIENT_REJECT_AND_REVIEW_PENDING,
      ])
    ) {
      setActiveStep(0);
      return (
        <KycStepSuccessLayoutContainer
          message={KycTranslation.SUCCESS_SCREEN_KYC_CLIENT_FORM_COMPLETED}
        />
      );
    }

    return <></>;
  };

  return <KycCenteredStepBodyLayout>{getSuccessScreen()}</KycCenteredStepBodyLayout>;
};
