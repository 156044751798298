import * as React from "react";

import { ITask } from "data/schemas";

import { useAppSelector } from "redux/hooks";

import { ISelectedSort, tasksWithSubtasksUtils } from "app/_utils/tasks";

import { TasksContentViewMode, TTasksContentViewMode } from "./TasksSwitchContentView";
import { TasksBoard } from "./TasksBoard";
import { TasksGanttChart } from "./TasksGanttChart";
import { useEffect } from "react";
import { useSearch } from "../../../../../../hooks/useSearch";

export interface TasksCardContentProps {
  isTab?: boolean;
  filterText?: string;
  selectedSort: ISelectedSort;
  contentMode: TTasksContentViewMode;
}

export const InnerTasksCardContent: React.FunctionComponent<TasksCardContentProps> = ({
  isTab = false,
  filterText,
  selectedSort,
  contentMode,
}) => {
  const { setEntities, entitiesFiltered } = useSearch();
  const { isLoading, rawTasks = [] } = useAppSelector((state) => ({
    isLoading: state.tasks.chunkLoading as boolean,
    rawTasks: state.tasks.entities as ITask[],
  }));
  const refinedTasks = entitiesFiltered;
  const isTasksLoading = isLoading && !rawTasks?.length;

  useEffect(() => {
    setEntities(rawTasks);
  }, [rawTasks]);

  const tasks = React.useMemo(
    () => (refinedTasks.length > 1 ? tasksWithSubtasksUtils(refinedTasks) : refinedTasks),
    [refinedTasks]
  );

  return contentMode === TasksContentViewMode.COLUMNS ? (
    <TasksBoard
      {...{ isTab, filterText, selectedSort, isTasksLoading, tasks, rawTasks: refinedTasks }}
    />
  ) : (
    <TasksGanttChart {...{ filterText, selectedSort, isTasksLoading, tasks }} />
  );
};

export const TasksCardContent = React.memo(InnerTasksCardContent);

export default TasksCardContent;
