import React, { useEffect, useState } from "react";
import { KycBoTableMode, KycNotificationDestination } from "../../../Kyc";
import "./KycFileItemLangChoose.scss";
import cn from "clsx";
import { isArray } from "lodash-es";

interface KycFileItemLangChooseProps {
  cell: any;
  row: any;
  mode: KycBoTableMode;
  updateSignatoryNotificationMethods: Function;
}

export const KycNotificationChoose = ({
  cell,
  row,
  updateSignatoryNotificationMethods,
  mode,
}: KycFileItemLangChooseProps): JSX.Element => {
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);

  useEffect(() => {
    const boNotifications = row?.boDeclaration?.isOfflineSignatory
      ? [KycNotificationDestination.OFFLINE]
      : row?.boDeclaration?.notificationMethods;
    setSelectedNotifications(boNotifications);
  }, [cell]);

  const handleSelectNotificationChannels = (e: any, notificationMethod: string = "") => {
    e.preventDefault();
    let res = isArray(selectedNotifications) ? [...selectedNotifications] : [];
    if (selectedNotifications?.includes(notificationMethod)) {
      if (selectedNotifications.length > 1) {
        res = [...selectedNotifications.filter((nm) => nm !== notificationMethod)];
      }
    } else {
      if (notificationMethod === KycNotificationDestination.OFFLINE) {
        res = [KycNotificationDestination.OFFLINE];
      } else {
        res = [...res.filter((n) => n !== KycNotificationDestination.OFFLINE), notificationMethod];
      }
    }
    setSelectedNotifications(res);
    updateSignatoryNotificationMethods({ signatoryId: row?.id, notificationMethods: res ?? [] });
  };

  const getAvailableNotificationChannels = (): KycNotificationDestination[] => {
    const defaultNotificationMethods = [KycNotificationDestination.OFFLINE];

    if (row?.email && row?.mobile) {
      defaultNotificationMethods.push(KycNotificationDestination.EMAIL);
      defaultNotificationMethods.push(KycNotificationDestination.SMS);
    }

    return defaultNotificationMethods;
  };

  const getContent = () => {
    if (mode === KycBoTableMode.COMPLIANCE) {
      return (
        <div className="d-flex align-items-center">
          {selectedNotifications?.sort()?.map((nm, index) => (
            <div
              className="d-flex align-items-center"
              key={"kyc-notification-choose-" + row?.id + "-" + index}
            >
              {index > 0 && (
                <span className="px-1 text-muted">{index && index > 0 ? "|" : ""}</span>
              )}
              <div>{nm}</div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="d-flex">
          {getAvailableNotificationChannels() &&
            Object.values(getAvailableNotificationChannels())?.map((n: string, index) => (
              <span
                key={"kyc-notification-choose-" + row?.id + "-" + index}
                onClick={(e: any) => handleSelectNotificationChannels(e, n)}
                className={cn(
                  "mx-1 rounded",
                  selectedNotifications?.includes(n) && "kyc-notification-button-selected"
                )}
              >
                {n}
              </span>
            ))}
        </div>
      );
    }
  };

  return <>{getContent()}</>;
};
