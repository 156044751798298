import React from "react";
import SVG from "react-inlinesvg";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { handleDownloadKycFile, isPreviewPossible } from "../../../KycFileUtils";
import { KycFileItemLang } from "./KycFileItemLang";
import { KycBoTableMode, KycFile } from "../../../Kyc";
import { IntlShape } from "react-intl";
import cn from "clsx";
import { isEmpty } from "lodash-es";
import { RowProps } from "react-bootstrap";

interface FormatExtraDataProps {
  intl: IntlShape;
  setCurrentDrawerFiles: Function;
  deleteFile: Function;
  files: KycFile[];
  mode?: KycBoTableMode;
  setCurrentKycFlow: Function;
}

export const KycBoDeclarationDownloadColumnFormatter: ColumnFormatter<
  RowProps,
  FormatExtraDataProps
> = (cell, file, rowIndex, formatExtraData) => {
  let filesToDisplay: any[] = cell;

  const openFileInDrawer = (e: any) => {
    e.preventDefault();

    if (formatExtraData?.setCurrentDrawerFiles) {
      formatExtraData.setCurrentDrawerFiles(filesToDisplay);
    }
  };

  return (
    <div className="d-flex justify-content-end align-items-right">
      {/*MULTI-LANG FILE DOWNLOAD BUTTONS */}
      {/*{formatExtraData?.mode && formatExtraData.mode === KycBoTableMode.COMPLIANCE ? (*/}
      <span className={cn(!isEmpty(cell) && "d-flex px-2")}>
        {cell &&
          // formatExtraData?.files &&
          // @ts-ignore
          Object.values(cell)?.map((file: KycFile, index) => (
            <KycFileItemLang key={"signatory-bo-file-" + index} file={file} index={index} />
          ))}
      </span>

      {/*SINGLE FILE DOWNLOAD BUTTON */}
      {filesToDisplay?.length === 1 && (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={(e: any) => handleDownloadKycFile(e, filesToDisplay?.[0])}
        >
          <span className="svg-icon svg-icon-sm svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
          </span>
        </button>
      )}

      {/*VIEW FILE BUTTON */}
      {isPreviewPossible(filesToDisplay) && (
        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1 my-auto"
          type="button"
          onClick={openFileInDrawer}
        >
          <span className="svg-icon svg-icon-lg svg-icon-info">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
          </span>
        </button>
      )}
    </div>
  );
};
