import * as React from "react";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";

export const InstalmentInvoiceShare: React.FC<HeaderCardProps> = (props) => {
  const { instalmentRevenue, soldNotInvoicedInstalments, soldInvoicedInstalments } =
    useBudgetRevenueContext();

  const invoicedPercentage =
    (soldInvoicedInstalments / (soldNotInvoicedInstalments + soldInvoicedInstalments)) * 100;

  let total = instalmentRevenue?.length ?? 0;
  let fullyInvoiced = 0;
  let partiallyInvoiced = 0;
  let notInvoiced = 0;

  for (const mappedInstalment of instalmentRevenue) {
    if (mappedInstalment.expected === mappedInstalment.invoiced) {
      fullyInvoiced += 1;
    } else if (!mappedInstalment.invoiced) {
      notInvoiced += 1;
    } else {
      partiallyInvoiced += 1;
    }
  }

  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-revenues-instalment-invoice-share"
          value={invoicedPercentage}
          displayType="text"
          suffix={SUFFIX_PERCENTAGE}
          decimalScale={1}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REVENUE.INSTALMENT_INVOICE_SHARE" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-revenues-instalment-invoice-share-total-instalments"
        label="BUDGET.INSTALMENT"
        value={total}
        decimalScale={0}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-instalment-invoice-share-invoiced"
        label="BUDGET.REVENUE.FULLY_INVOICED_INSTALMENTS"
        value={fullyInvoiced}
        decimalScale={0}
        secondValue={{
          dataCy: "input-budget-revenues-instalment-invoice-share-invoiced-percentage",
          value: (fullyInvoiced / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-instalment-invoice-share-partially-invoiced"
        label="BUDGET.REVENUE.PARTIALLY_INVOICED_INSTALMENTS"
        value={partiallyInvoiced}
        decimalScale={0}
        secondValue={{
          dataCy: "input-budget-revenues-instalment-invoice-share-partially-invoiced-percentage",
          value: (partiallyInvoiced / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-instalment-invoice-share-not-invoiced"
        label="BUDGET.REVENUE.NOT_INVOICED_INSTALMENTS"
        value={notInvoiced}
        decimalScale={0}
        secondValue={{
          dataCy: "input-budget-revenues-instalment-invoice-share-not-invoiced-percentage",
          value: (notInvoiced / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
    </HeaderCard>
  );
};
