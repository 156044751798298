import { KycChoiceOption } from "../../Kyc";
import { ArrayHelpers, Field, FieldArray, FieldConfig } from "formik";
import { useTranslation } from "../../../../hooks/useTranslation";
import { findIndex } from "lodash";
import React, { ChangeEvent } from "react";
import { KycCheckbox } from "./kyc-checkbox/KycCheckbox";

interface KycCheckboxListProps {
  field: FieldConfig;
  title?: string;
  options: KycChoiceOption[];
  disabled?: boolean;
}

interface HandleChangeCheckboxProps {
  remove: ArrayHelpers["remove"];
  push: ArrayHelpers["push"];
  key: string;
}

interface HandleChangeTexHProps {
  replace: ArrayHelpers["replace"];
  e: ChangeEvent<HTMLInputElement>;
  key: string;
}

interface ContentProps {
  option: { label: string; value: string };
  remove: ArrayHelpers["remove"];
  push: ArrayHelpers["push"];
  replace: ArrayHelpers["replace"];
  index: number;
}

export const KycCheckboxList = ({
  field,
  title,
  options,
  disabled = false,
}: KycCheckboxListProps): JSX.Element => {
  const { fm } = useTranslation();

  const handleChangeCheckbox = ({ remove, push, key }: HandleChangeCheckboxProps) => {
    const index = findIndex(
      field?.value,
      (data: { option: string; freeText?: string }) => data.option === key
    );
    if (index > -1) {
      remove(index);
    } else {
      push({ option: key });
    }
  };
  const handleChangeText = ({ key, replace, e }: HandleChangeTexHProps) => {
    const index = findIndex(
      field?.value,
      (data: { option: string; freeText?: string }) => data.option === key
    );

    if (index > -1) replace(index, { option: key, freeText: e.target.value });
  };

  const content = ({ option, remove, push, replace, index }: ContentProps) => {
    const isChecked = field.value.find(
      (data: { option: string; freeText?: string }) => data.option === option.value
    );

    return (
      <Field
        inline={false}
        disabled={disabled}
        component={KycCheckbox}
        checkboxInfo={{
          isChecked,
          name: `${field.name}.[${index}].option`,
          onChange: () => handleChangeCheckbox({ remove, push, key: option.value }),
        }}
        freeTextInfo={{
          name: `${field.name}.${findIndex(
            field?.value,
            (data: { option: string; freeText?: string }) => data.option === option.value
          )}.freeText`,
          onChange: (e: ChangeEvent<HTMLInputElement>) =>
            handleChangeText({ replace, e, key: option.value }),
          value:
            field.value?.find(
              (data: { option: string; freeText?: string }) => data.option === option.value
            )?.freeText || "",
        }}
        text={option.label}
      />
    );
  };

  return (
    <>
      <span style={{ fontSize: "1.1em" }}>{title && fm(title)}</span>
      <FieldArray name={field?.name}>
        {({ push, remove, replace }) => {
          return options.map((option, index) => (
            <div key={`kyc-checkbox-${field.name}-${index}`}>
              {content({ option, replace, push, remove, index })}
            </div>
          ));
        }}
      </FieldArray>
    </>
  );
};
