import * as React from "react";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";
import { useBudgetRevenueContext } from "../../../context/BudgetRevenueContext";

export const ExpectedInstalmentRevenues: React.FC<HeaderCardProps> = (props) => {
  const { unsoldTotal, soldInvoicedInstalments, soldNotInvoicedInstalments } =
    useBudgetRevenueContext();

  const total = unsoldTotal + soldInvoicedInstalments + soldNotInvoicedInstalments;

  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-revenues-expected"
          value={total}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={0}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REVENUE.EXPECTED_INSTALMENT_REVENUE" />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-sold-invoiced"
        label="BUDGET.REVENUE.SOLD_AND_INVOICED"
        value={soldInvoicedInstalments}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
        secondValue={{
          dataCy: "input-budget-revenues-expected-sold-invoiced-percentage",
          value: (soldInvoicedInstalments / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-sold-not-invoiced"
        label="BUDGET.REVENUE.SOLD_NOT_INVOICED"
        value={soldNotInvoicedInstalments}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
        secondValue={{
          dataCy: "input-budget-revenues-expected-sold-not-invoiced-percentage",
          value: (soldNotInvoicedInstalments / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
      <HeaderCardSubValue
        dataCy="input-budget-revenues-expected-unsold"
        label="BUDGET.REVENUE.UNSOLD"
        value={unsoldTotal}
        decimalScale={0}
        suffix={SUFFIX_EURO_CURRENCY}
        secondValue={{
          dataCy: "input-budget-revenues-expected-unsold-percentage",
          value: (unsoldTotal / total) * 100,
          decimalScale: 0,
          suffix: SUFFIX_PERCENTAGE,
        }}
      />
    </HeaderCard>
  );
};
