import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ISignatureInfo } from "data/schemas";

import { CardPDFSignedViewer } from "app/_components/CardPDFSignedViewer";

import { SignatureInformationModal } from "app/modules/Sign/pages/SignatureInformationModal";
import { usePublicRequests } from "app/modules/Requests/hooks/usePublicRequests";

import { useRequestIdUrlParam } from "./useRequestIdUrlParam";

import { TModalState, ModalState } from "./definitions";

import { SignatureOtpModal, TRequestDocSignFn } from "./SignatureOtpModal";

import { SignatureContentInfoCard } from "./SignatureContentInfoCard";

//----------------------------------------------------------------------------//

export const SignatureContentCard: React.FunctionComponent = () => {
  const requestId = useRequestIdUrlParam();
  const [modalState, setModalState] = React.useState<TModalState>(ModalState.NONE);

  const {
    isLoading,
    isSigning,
    isInvalidOtp,
    settings,
    user,
    errorMessage,
    getPublicRequestSignatureOtp,
    postPublicRequestSignature,
    setIsInvalidOtp,
  } = usePublicRequests();

  if (isLoading && modalState !== ModalState.OTP) {
    return (
      <SignatureContentInfoCard>
        <span className="spinner spinner-lg spinner-primary" />
      </SignatureContentInfoCard>
    );
  }

  if (!settings) {
    return (
      <SignatureContentInfoCard>
        <span className="text-danger">
          <FormattedMessage id={"SIGN.NOTHING"} />
        </span>
      </SignatureContentInfoCard>
    );
  }

  const requestDocSign: TRequestDocSignFn = (otpCode) => {
    postPublicRequestSignature(requestId, otpCode).then(() => {
      setModalState(ModalState.NONE);
    });
  };

  const requestOtpCode = () => {
    getPublicRequestSignatureOtp(requestId);
  };

  const validateSignatureInfoModal = (requestOtp = true) => {
    setIsInvalidOtp(false);
    setModalState(ModalState.OTP);
    requestOtp && requestOtpCode();
  };

  const onHideSignatureOtpModal = () => {
    setIsInvalidOtp(false);
    setModalState(ModalState.NONE);
  };

  const signatoryInfo = (settings as ISignatureInfo) || {};

  const fileUrl = signatoryInfo.fileUrl;

  const triggerModalStateChange =
    signatoryInfo && !signatoryInfo.signedDate ? setModalState : undefined;

  return (
    <>
      <SignatureInformationModal
        show={modalState === ModalState.INFO}
        onHide={onHideSignatureOtpModal}
        validateSignatureModal={validateSignatureInfoModal}
      />

      <SignatureOtpModal
        isSigning={isSigning}
        isInvalidOtp={isInvalidOtp}
        show={modalState === ModalState.OTP}
        onHide={onHideSignatureOtpModal}
        requestOtpCode={() => requestOtpCode()}
        requestDocSign={(otpCode) => requestDocSign(otpCode)}
      />

      {fileUrl && (
        <CardPDFSignedViewer
          extraCardClassName="w-100"
          signatureInfo={signatoryInfo}
          fileUrl={fileUrl}
          setModalState={triggerModalStateChange}
          disableSignButton={user && !user.gdprApprovedAt && !errorMessage}
        />
      )}
    </>
  );
};

export default SignatureContentCard;
