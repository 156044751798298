import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CLIENT, SUBCONTRACTOR, USER } from "app/_utils/userTypes";
import {
  fetchUsersByUserType,
  requestUserFileSignature,
} from "app/modules/UsersManagement/_redux/usersActions";
import { RequestSignatureDialog } from "app/_components/RequestSignatureDialog";

import { useUserFilesUIContext } from "../UserFilesUIContext";

export const UserRequestSignature: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);

  const { userForEdit } = useSelector(
    (state: any) => ({
      userForEdit: state.users.entityForEdit?.saved,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useUserFilesUIContext}
      fileList={userForEdit?.files}
      requestFileSignature={requestUserFileSignature}
    />
  );
};

export default UserRequestSignature;
