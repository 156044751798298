import { Field, FieldInputProps } from "formik";
import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import cn from "clsx";

export interface CheckboxProps {
  name?: string;
  label: string;
  translateLabel?: boolean;
  customClasses?: string;
  checkboxStyle?: string;
  checkboxLarge?: boolean;
}
export const Checkbox: FC<CheckboxProps & Partial<FieldInputProps<boolean>>> = ({
  name,
  label,
  translateLabel = true,
  customClasses,
  checkboxStyle,
  checkboxLarge = true,
  ...props
}) => {
  return (
    <div className={cn("row", customClasses)}>
      <div className={"col-lg-12 d-flex"}>
        <label
          className={cn(
            "checkbox checkbox-single mr-2",
            checkboxStyle,
            checkboxLarge && "checkbox-lg"
          )}
        >
          <Field type="checkbox" name={name} className={"mr-2"} {...props} />
          <span className={"mr-2"} />
          {translateLabel ? <FormattedMessage id={label} /> : label}
        </label>
      </div>
    </div>
  );
};
