import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import {
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikHandlers,
  FormikProps,
  FormikValues,
} from "formik";
import { difference } from "lodash-es";
import cn from "clsx";

import { Input, Select } from "_metronic/_partials/controls";
import { PhoneFormInput } from "_metronic/_partials/controls/forms/PhoneFormInput";
import { toAbsoluteUrl } from "_metronic/_helpers";

import { canCreate, canDelete, canEdit } from "app/_utils/authUtils";
import { CLIENT, LEGAL, NATURAL, SUBCONTRACTOR, USER } from "app/_utils/userTypes";
import { LEGAL_ENTITY_TYPES, TITLES } from "app/_utils/listUtils";
import { AVAILABLE_LANGUAGES } from "app/_utils/languages";

import { EditAvatarImage } from "app/_components/AvatarImage";

import { MultiSelect } from "app/_components/MultiSelect";
import { AddressEditForm } from "app/_components/AddressEditForm";

import { checkEmailValidity } from "app/modules/UsersManagement/_redux/usersCrud";
import * as actions from "app/modules/UsersManagement/_redux/usersActions";
import { updateUserRights } from "app/modules/UsersManagement/_redux/usersActions";

import { useProfileUIContext } from "app/modules/UsersManagement/pages/profile/ProfileUIContext";
import { IUser } from "../../../../../../data/schemas";
import { useAppSelector } from "../../../../../../redux/hooks";
import { userEditFormYup } from "./UserEditFormYup";

interface FieldInfoRequired {
  handleChange: FormikHandlers["handleChange"];
  setFieldError: any;
  values: FormikValues | any;
  errors: FormikErrors<any>;
  setFieldValue: Function;
}

interface UserEditFormProps {
  user: IUser;
  submitUser: Function;
  saveUserFields: Function;
  originalUser: IUser;
  disabled: boolean;
  isSameUser: boolean;
  hideUserAvatar: boolean;
  isEmailAlreadyExists: boolean;
  onSubmitRef: Function;
}

export const UserEditForm: FC<UserEditFormProps> = ({
  user,
  submitUser,
  saveUserFields = () => undefined,
  originalUser,
  disabled,
  isSameUser,
  hideUserAvatar = false,
  isEmailAlreadyExists,
  onSubmitRef,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const profileUIContext = useProfileUIContext();
  const itsMe = profileUIContext?.pathId?.toLowerCase() === "me";

  const [availableUserTypes, setAvailableUserTypes] = useState<{ value: string; label: string }[]>(
    []
  );
  const [activeUserTypes, setActiveUserTypes] = useState<
    { value: string; label: string; isFixed: boolean }[]
  >([]);

  const { groups, session } = useAppSelector(({ auth }) => ({
    groups: auth.groups,
    session: auth.session,
  }));

  useEffect(() => {
    if (groups && session) {
      const res = [];
      if (canCreate(groups, session, "USER")) {
        res.push({ value: USER, label: intl.formatMessage({ id: "USER.TITLE.SINGLE" }) });
      }
      if (canCreate(groups, session, "CLIENT")) {
        res.push({ value: CLIENT, label: intl.formatMessage({ id: "CLIENT.TITLE.SINGLE" }) });
      }
      if (canCreate(groups, session, "SUBCONTRACTOR")) {
        res.push({
          value: SUBCONTRACTOR,
          label: intl.formatMessage({ id: "SUBCONTRACTOR.TITLE.SINGLE" }),
        });
      }
      setAvailableUserTypes(res);
      setActiveUserTypes(
        user.userTypes
          ?.map((userType) => {
            let isFixed = false;
            if (user.userTypes.length === 1) {
              isFixed = true;
            } else {
              switch (userType) {
                case USER:
                  isFixed = !canDelete(groups, session, "USER");
                  break;
                case CLIENT:
                  isFixed = !canDelete(groups, session, "CLIENT");
                  break;
                case SUBCONTRACTOR:
                  isFixed = !canDelete(groups, session, "SUBCONTRACTOR");
                  break;
                default:
                  break;
              }
            }
            return {
              value: userType,
              label: intl.formatMessage({ id: `${userType}.TITLE.SINGLE` }),
              isFixed,
            };
          })
          .sort((u1, u2) => (u1.isFixed ? -1 : u2.isFixed ? 1 : 0))
      );
    }
  }, [groups, session, user.userTypes]);

  const formRef = useRef<FormikProps<any>>(null);
  useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef?.current?.submitForm());
    }
  }, []);

  const customHandleChange = (e: any) => (handleChange: any) => {
    handleChange(e);
    saveUserFields(e.target.name, e.target.value);

    if (e.target.name === "userTypes") {
      if (!e.target.value.includes(CLIENT)) {
        saveUserFields("referrer", "");
        saveUserFields("legalEntityType", "NATURAL");
      }
      if (!e.target.value.includes(SUBCONTRACTOR) && !e.target.value.includes(CLIENT)) {
        saveUserFields("address", {});
        saveUserFields("company", {});
      }
    }

    if (e.target.name === "legalEntityType") {
      if (e.target.value !== "LEGAL" && !user.userTypes.includes(SUBCONTRACTOR)) {
        saveUserFields("company", {});
      }
    }
  };

  const saveAvatarImage = useCallback(
    (fileName, image, onAfterSave) => {
      if (!originalUser) return;
      const avatar = {
        image,
        fileName,
        userId: originalUser.id,
      };

      actions
        .setUserAvatar(avatar)(dispatch)
        .then((response) => {
          onAfterSave();

          return actions.markUserPictureSetup(user, response.key)(dispatch);
        })
        .then(() => {
          if (!itsMe) return;

          actions.fetchUserMe()(dispatch);
        })
        .catch(console.error);
    },
    [itsMe, originalUser]
  );

  const onChangeUserTypes = ({
    values,
    handleChange,
  }: Pick<FieldInfoRequired, "values" | "handleChange">) => {
    if (values.length === 0) {
      return;
    }
    const newUserTypes = values.map(({ value }: { value: string }) => value);
    if (difference(user.userTypes, newUserTypes)) {
      if (!newUserTypes.includes(USER)) {
        saveUserFields("rights", []);
        dispatch(updateUserRights({ ...user, rights: [] }));
      } else {
        dispatch(updateUserRights({ ...user }));
      }
    }
    customHandleChange({
      target: { name: "userTypes", value: newUserTypes },
    })(handleChange);
  };

  const [editEmail, setEditEmail] = useState<boolean>();
  const [checkingEmail, setCheckingEmail] = useState<boolean>();
  const checkEmail = async ({
    values,
    handleChange,
    setFieldError,
  }: Pick<FieldInfoRequired, "values" | "handleChange" | "setFieldError">) => {
    if (values.email !== user.email) {
      setCheckingEmail(true);
      const response = await checkEmailValidity(user.id, values.email);
      setCheckingEmail(false);
      if (response?.code !== "USER_ALREADY_EXISTS") {
        customHandleChange({ target: { name: "email", value: values.email } })(handleChange);
        setEditEmail(false);
      } else {
        setFieldError(
          "email",
          intl.formatMessage({
            id: "USER.CREATE.ERROR.EMAIL.EXISTS",
          })
        );
      }
    }
  };

  const [editMobile, setEditMobile] = useState<boolean>();

  const fieldLegalEntityType = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <Select
      name="legalEntityType"
      data-cy="select-client-legalEntityType"
      label={intl.formatMessage({
        id: "USER.INFO.LEGAL.ENTITY.TYPE",
      })}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => customHandleChange(e)(handleChange)}
      disabled={disabled || isSameUser}
      customFeedbackLabel={undefined}
    >
      {LEGAL_ENTITY_TYPES.map((clientType) => (
        <option key={clientType.value} value={clientType.value}>
          {intl.formatMessage({ id: clientType.label })}
        </option>
      ))}
    </Select>
  );

  const fieldTitle = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <Select
      name="title"
      data-cy="select-user-title"
      label={intl.formatMessage({
        id: "USER.INFO.TITLE",
      })}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => customHandleChange(e)(handleChange)}
      customFeedbackLabel={" "}
      disabled={disabled || isSameUser}
    >
      {TITLES.map((userTitle) => {
        const titleTranslated = intl.formatMessage({ id: userTitle.label });
        return (
          <option key={userTitle.value} value={userTitle.value}>
            {titleTranslated}
          </option>
        );
      })}
    </Select>
  );

  const fieldCompanyName = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <Field
      data-cy="input-user-company-name"
      name="company.name"
      component={Input}
      onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
      label={intl.formatMessage({
        id: "COMPANY.NAME",
      })}
      disabled={
        !(
          (user.userTypes.includes(SUBCONTRACTOR) && canEdit(groups, session, "SUBCONTRACTOR")) ||
          user.legalEntityType.includes(LEGAL)
        ) && !isSameUser
      }
    />
  );

  const fieldLastName = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <Field
        name="lastName"
        data-cy="input-user-lastname"
        component={Input}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
        customFeedbackLabel={" "}
        label={intl.formatMessage({
          id: "USER.INFO.NAME.LAST",
        })}
        disabled={disabled || isSameUser}
      />
    </>
  );

  const fieldFirstName = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <Field
        name="firstName"
        data-cy="input-user-firstname"
        component={Input}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
        customFeedbackLabel={" "}
        label={intl.formatMessage({
          id: "USER.INFO.NAME.FIRST",
        })}
        disabled={disabled || isSameUser}
      />
    </>
  );

  const fieldLanguage = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <Select
        name="language"
        data-cy="select-user-language"
        label={intl.formatMessage({
          id: "USER.LANGUAGE.CONTACT",
        })}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => customHandleChange(e)(handleChange)}
        customFeedbackLabel={" "}
        disabled={disabled && !isSameUser}
      >
        {AVAILABLE_LANGUAGES.map((language) => (
          <option key={language.lang} value={language.lang}>
            {language.name}
          </option>
        ))}
      </Select>
    </>
  );

  const fieldReferrer = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <Field
        data-cy="input-client-referrer"
        name="referrer"
        component={Input}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
        label={intl.formatMessage({
          id: "REFERRER",
        })}
        disabled={
          !user.userTypes.includes(CLIENT) || !canEdit(groups, session, "CLIENT") || isSameUser
        }
      />
    </>
  );

  const fieldCompanyVatNumber = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <Field
        data-cy="input-user-company-vat-number"
        name="company.vatNumber"
        component={Input}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
        label={intl.formatMessage({
          id: "COMPANY.VAT.NUMBER",
        })}
        disabled={
          !(
            (user.userTypes.includes(SUBCONTRACTOR) && canEdit(groups, session, "SUBCONTRACTOR")) ||
            user.legalEntityType.includes(LEGAL)
          ) && !isSameUser
        }
      />
    </>
  );

  const fieldEmail = ({
    handleChange,
    values,
    setFieldError,
    errors,
    setFieldValue,
  }: FieldInfoRequired) => (
    <>
      <label>
        <FormattedMessage id="USER.INFO.EMAIL" />
      </label>
      <div className={"d-flex"}>
        <Field
          name="email"
          data-cy="input-user-email"
          component={Input}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (!originalUser?.id) {
              customHandleChange(e)(handleChange);
            } else {
              handleChange(e);
            }
          }}
          onFocus={() => setEditEmail(true)}
          onBlur={() => {
            if (user.email === values.email) {
              setEditEmail(false);
            }
          }}
          customFeedbackLabel={" "}
          withFeedbackLabel={false}
          disabled={disabled || isSameUser}
        />
        {originalUser?.id && editEmail && (
          <>
            <button
              className={cn("btn btn-icon btn-light-success ml-2 mr-2 flex-shrink-0")}
              type={"button"}
              onClick={() => checkEmail({ values, handleChange, setFieldError })}
              data-cy="button-user-email-save"
            >
              {checkingEmail ? (
                <span
                  className="spinner spinner-success spinner-md"
                  style={{ width: "18px", height: "18px" }}
                />
              ) : (
                <span className="svg-icon svg-icon-md">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                </span>
              )}
            </button>
            <button
              type={"button"}
              className={"btn btn-icon btn-light-danger flex-shrink-0"}
              onClick={() => {
                setEditEmail(false);
                setFieldValue("email", user.email);
              }}
              disabled={checkingEmail}
              data-cy="button-user-email-cancel"
            >
              <span className="svg-icon svg-icon-md">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
              </span>
            </button>
          </>
        )}
      </div>
      {(errors.email || isEmailAlreadyExists) && (
        <div className="text-danger invalid-feedback d-block">
          {isEmailAlreadyExists
            ? intl.formatMessage({
                id: "USER.CREATE.ERROR.EMAIL.EXISTS",
              })
            : errors.email}
        </div>
      )}
    </>
  );

  const fieldRegistrationNumber = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <Field
      data-cy="input-user-company-registration-number"
      name="company.registrationNumber"
      component={Input}
      onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
      label={intl.formatMessage({
        id: "COMPANY.REGISTRATION.NUMBER",
      })}
      disabled={
        !(
          (user.userTypes.includes(SUBCONTRACTOR) && canEdit(groups, session, "SUBCONTRACTOR")) ||
          user.legalEntityType.includes(LEGAL)
        ) && !isSameUser
      }
    />
  );
  const fieldNationalRegistrationNumber = ({
    handleChange,
  }: Pick<FieldInfoRequired, "handleChange">) => (
    <Field
      data-cy="input-user-company-national-registration-number"
      name="company.nationalRegistrationNumber"
      component={Input}
      onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)(handleChange)}
      label={intl.formatMessage({
        id: "COMPANY.NATIONAL.REGISTRATION.NUMBER",
      })}
      disabled={
        !(
          (user.userTypes.includes(SUBCONTRACTOR) && canEdit(groups, session, "SUBCONTRACTOR")) ||
          user.legalEntityType.includes(LEGAL)
        ) && !isSameUser
      }
    />
  );

  const fieldMobile = ({ handleChange, values, errors, setFieldValue }: FieldInfoRequired) => (
    <>
      <label>
        <FormattedMessage id="USER.INFO.MOBILE" />
      </label>
      <div className="d-flex">
        <Field
          name="mobile"
          component={PhoneFormInput}
          disabled={disabled || isSameUser}
          onChange={(e: any) => {
            if (!originalUser?.id) {
              customHandleChange(e)(handleChange);
            } else {
              handleChange(e);
            }
          }}
          onFocus={() => setEditMobile(true)}
          onBlur={() => {
            if (user.mobile === values.mobile) {
              setEditMobile(false);
            }
          }}
          withFeedbackLabel={false}
        />
        {originalUser?.id && editMobile && (
          <>
            <button
              className={cn("btn btn-icon btn-light-success ml-2 mr-2 flex-shrink-0")}
              type={"button"}
              onClick={() => {
                customHandleChange({ target: { name: "mobile", value: values.mobile } })(
                  handleChange
                );
                setEditMobile(false);
              }}
              data-cy="button-user-mobile-save"
            >
              <span className="svg-icon svg-icon-md">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
              </span>
            </button>
            <button
              type={"button"}
              className={"btn btn-icon btn-light-danger flex-shrink-0"}
              onClick={() => {
                setEditMobile(false);
                setFieldValue("mobile", user.mobile);
              }}
              data-cy="button-user-mobile-cancel"
            >
              <span className="svg-icon svg-icon-md">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
              </span>
            </button>
          </>
        )}
      </div>
      <div className="text-danger invalid-feedback d-block">{errors.mobile}</div>
    </>
  );

  const fieldUserTypes = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <label>
        <FormattedMessage id={"USER.INFO.USER_TYPES"} />
      </label>
      <MultiSelect
        data-cy="select-multi-user-types"
        options={availableUserTypes}
        value={activeUserTypes}
        onChange={(values: { value: string; label: string }) =>
          onChangeUserTypes({ values, handleChange })
        }
      />
    </>
  );

  const fieldAddress = ({ handleChange }: Pick<FieldInfoRequired, "handleChange">) => (
    <>
      <AddressEditForm
        handleChange={handleChange}
        customHandleChange={customHandleChange}
        disabled={
          !(
            (user.userTypes.includes(CLIENT) && canEdit(groups, session, "CLIENT")) ||
            (user.userTypes.includes(SUBCONTRACTOR) && canEdit(groups, session, "SUBCONTRACTOR"))
          ) && !isSameUser
        }
      />
    </>
  );

  const content = ({
    handleChange,
    setFieldError,
    errors,
    setFieldValue,
    values,
  }: FieldInfoRequired) => {
    switch (true) {
      case user.userTypes.includes(CLIENT) && user.legalEntityType === LEGAL: {
        return (
          <>
            <div className="form-group form-row">
              <div className="col-sm-4">{fieldLegalEntityType({ handleChange })}</div>
            </div>
            <h3>
              <FormattedMessage id="COMPANY.TITLE.INFORMATION" />
            </h3>
            <hr />
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldCompanyName({ handleChange })}</div>
              <div className="col-sm-6">{fieldCompanyVatNumber({ handleChange })}</div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldRegistrationNumber({ handleChange })}</div>
              <div className="col-sm-6">{fieldNationalRegistrationNumber({ handleChange })}</div>
            </div>

            <h5 className={"mt-14"}>
              <FormattedMessage id="ADDRESS.TITLE" />
            </h5>
            <hr />
            {fieldAddress({ handleChange })}

            <h3>
              <FormattedMessage id="COMMON.POC" />
            </h3>
            <hr />
            <div className={"form-group form-row ml-0"}>
              <FormattedMessage id="COMMON.INFORM_LEGAL_CLIENT.POC" />
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-2">{fieldTitle({ handleChange })}</div>
              <div className="col-sm-4">{fieldFirstName({ handleChange })}</div>
              <div className="col-sm-4">{fieldLastName({ handleChange })}</div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">
                {fieldEmail({ handleChange, values, errors, setFieldValue, setFieldError })}
              </div>
              <div className="col-sm-6">
                {fieldMobile({ handleChange, setFieldError, setFieldValue, errors, values })}
              </div>
            </div>

            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldLanguage({ handleChange })}</div>
              {!disabled && !isSameUser && availableUserTypes?.length > 0 && user.id && (
                <div className="col-sm-6">{fieldUserTypes({ handleChange })}</div>
              )}
              <div className="col-sm-6">{fieldReferrer({ handleChange })}</div>
            </div>
          </>
        );
      }
      case user.userTypes.includes(CLIENT) && user.legalEntityType === NATURAL:
        return (
          <>
            <div className="form-group form-row">
              <div className="col-sm-4">{fieldLegalEntityType({ handleChange })}</div>
            </div>
            <h3>
              <FormattedMessage id="COMMON.BASIC_INFO" />
            </h3>
            <hr />
            <div className={"form-group form-row"}>
              <div className="col-sm-2">{fieldTitle({ handleChange })}</div>
              <div className="col-sm-4">{fieldFirstName({ handleChange })}</div>
              <div className="col-sm-4">{fieldLastName({ handleChange })}</div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">
                {fieldEmail({ handleChange, values, errors, setFieldValue, setFieldError })}
              </div>
              <div className="col-sm-6">
                {fieldMobile({ handleChange, setFieldError, setFieldValue, errors, values })}
              </div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldLanguage({ handleChange })}</div>
              {!disabled && !isSameUser && availableUserTypes?.length > 0 && user.id && (
                <div className="col-sm-6">{fieldUserTypes({ handleChange })}</div>
              )}
              <div className="col-sm-6">{fieldReferrer({ handleChange })}</div>
            </div>
            <h5 className={"mt-14"}>
              <FormattedMessage id="ADDRESS.TITLE" />
            </h5>
            <hr />
            {fieldAddress({ handleChange })}
          </>
        );

      case user.userTypes.includes(SUBCONTRACTOR): {
        return (
          <>
            <h3>
              <FormattedMessage id="COMPANY.TITLE.INFORMATION" />
            </h3>
            <hr />
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldCompanyName({ handleChange })}</div>
              <div className="col-sm-6">{fieldCompanyVatNumber({ handleChange })}</div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldRegistrationNumber({ handleChange })}</div>
              <div className="col-sm-6">{fieldNationalRegistrationNumber({ handleChange })}</div>
            </div>

            <h5 className={"mt-14"}>
              <FormattedMessage id="ADDRESS.TITLE" />
            </h5>
            <hr />
            {fieldAddress({ handleChange })}

            <h3>
              <FormattedMessage id="COMMON.POC" />
            </h3>
            <hr />
            <div className={"form-group form-row ml-0"}>
              <FormattedMessage id="COMMON.INFORM_SUBCONTRACTOR.POC" />
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-2">{fieldTitle({ handleChange })}</div>
              <div className="col-sm-4">{fieldFirstName({ handleChange })}</div>
              <div className="col-sm-4">{fieldLastName({ handleChange })}</div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">
                {fieldEmail({ handleChange, values, errors, setFieldValue, setFieldError })}
              </div>
              <div className="col-sm-6">
                {fieldMobile({ handleChange, setFieldError, setFieldValue, errors, values })}
              </div>
            </div>
            <div className={"form-group form-row"}>
              <div className="col-sm-6">{fieldLanguage({ handleChange })}</div>
              {!disabled && !isSameUser && availableUserTypes?.length > 0 && user.id && (
                <div className="col-sm-6">{fieldUserTypes({ handleChange })}</div>
              )}
            </div>
          </>
        );
      }
    }
    //userType === [USER]
    return (
      <>
        <h3>
          <FormattedMessage id="COMMON.BASIC_INFO" />
        </h3>
        <hr />
        <div className={"form-group form-row"}>
          <div className="col-sm-2">{fieldTitle({ handleChange })}</div>
          <div className="col-sm-4">{fieldFirstName({ handleChange })}</div>
          <div className="col-sm-4">{fieldLastName({ handleChange })}</div>
        </div>
        <div className={"form-group form-row"}>
          <div className="col-sm-6">
            {fieldEmail({ handleChange, values, errors, setFieldValue, setFieldError })}
          </div>
          <div className="col-sm-6">
            {fieldMobile({ handleChange, setFieldError, setFieldValue, errors, values })}
          </div>
        </div>
        <div className={"form-group form-row"}>
          <div className="col-sm-6">{fieldLanguage({ handleChange })}</div>
          <div className="col-sm-6">{fieldUserTypes({ handleChange })}</div>
        </div>
      </>
    );
  };
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={user}
      validationSchema={() => userEditFormYup(user, intl)}
      onSubmit={() => {
        if (!disabled && !!submitUser) {
          submitUser();
        }
      }}
    >
      {({ values, handleChange, errors, setFieldValue, setFieldError }) => (
        <>
          <Form className="form form-label-right" data-cy="form-user">
            {!hideUserAvatar && (
              <EditAvatarImage
                containerClassName="form-group d-flex flex-column align-items-center"
                isLoading={!originalUser}
                isEditable={!disabled || isSameUser}
                imageAltText="profile avatar"
                editTooltipMessage={<FormattedMessage id="PROFILE.AVATAR.CHANGE" />}
                placeholderStyleBackgroundImage="url('/media/users/blank.png')"
                url={originalUser?.profilePictureUrl}
                onSaveImage={saveAvatarImage}
              />
            )}
            {content({ handleChange, setFieldError, values, setFieldValue, errors })}
          </Form>
        </>
      )}
    </Formik>
  );
};
