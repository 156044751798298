import * as React from "react";
import cn from "clsx";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { cloneDeep, mergeWith } from "lodash-es";
import { Field, FieldArray, Form, Formik } from "formik";
import TimeAgo from "react-timeago/lib";

import { DatePickerField, Input, ModalProgressBar } from "_metronic/_partials/controls";

import { ETaskStatus, TaskTypes } from "data/schemas";
import { CustomSelect, CustomSelectStatus } from "app/_components/CustomSelect";

import { formatDisplayNameMeIntl } from "app/_utils/userUtils";
import { USER, userTypes } from "app/_utils/userTypes";
import { getTaskRelatedToAppearance, getTaskText, getTimeAgoCaption } from "app/_utils/tasks";
import { canCreate, canEdit } from "app/_utils/authUtils";
import { DateUtils } from "app/_utils/DateUtils";
import { TimeUnitTypes } from "app/_utils/timeUnitTypes";

import { CommentsContainer } from "app/_components/Comment/CommentsContainer";
import { Icon, IconType } from "app/_components/Icon";
import { TextEditor } from "app/_components/TextEditor/TextEditor";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";
import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";

import { Reminder } from "./ReminderItem";
import { Subtasks } from "./Subtasks";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./TaskEditDialog.scss";

const GanttChartIcon = ({ isTaskPlanned = false }) => (
  <Icon
    name={IconType.GANTT_CHART}
    width={24}
    className={cn({
      opacity: !isTaskPlanned,
    })}
  />
);

const initTask = {
  parentId: undefined,
  id: undefined,
  name: "",
  description: "",
  descriptionShort: "",
  status: ETaskStatus.PLANNED,
  assignee: "",
  taskType: "CUSTOM",
  dueDate: undefined,
  createdByUserId: "",
  relatedTo: undefined,
  subtasks: [],
  reminders: [],
  comments: [],
};

const sortSubtasksFn = (a, b) => {
  if (a.hasOwnProperty("createdAt")) {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return aDate - bDate;
  }

  const aName = a.name.toUpperCase();
  const bName = b.name.toUpperCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

const processSubtasks = (task) => {
  if (!task.parentId && Array.isArray(task.subtask)) {
    task.subtasks = task.subtasks.sort(sortSubtasksFn);
  }
  return task;
};

const mergeWithInitObject = (obj, initTask) =>
  processSubtasks(
    mergeWith(cloneDeep(initTask), obj, (dest, src) => (src === null ? dest : undefined))
  );

export function TaskEditDialog({ id, show, onHide, mode, relatedTo, relatedToName }) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hideSetPlannedStartDateButton, setHidePlannedStartDateButton] = React.useState(false);
  const [hideSetDueDateButton, setHideDueDateButton] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToTask = (taskId) => {
    history.push(`/tasks/${taskId}/edit`);
  };

  const { entityForEdit, users, listLoading, session, groups, relatedToItemOptions } = useSelector(
    (state) => ({
      entityForEdit: state.tasks.entityForEdit?.current,
      users: state.tasks.assignees || [],
      listLoading: state.tasks.listLoading,
      session: state.auth.session,
      groups: state.auth.groups,
      relatedToItemOptions: state.tasks.relatedToItemOptions,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (id) {
      dispatch(actions.fetchTaskById(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (!show) {
      setHidePlannedStartDateButton(false);
      setHideDueDateButton(false);
    }
  }, [show]);

  const submitTask = (values) => {
    setIsLoading(true);

    let promise;
    if (!values?.id) {
      promise = dispatch(
        actions.createTask({
          ...values,
          createdByUserId: session?.id,
          taskType: TaskTypes.CUSTOM,
          relatedTo: relatedTo || values.relatedTo || null,
          status: values.status || ETaskStatus.PLANNED,
          assignee: values.assignee,
        })
      ).then((entity) => {
        if (mode !== "create") {
          navigateToTask(entity.id);
        }
      });
    } else {
      //update task
      //special case: if user is inside filtered user view and updates the task
      //in a way where no relation remains after update, the task should be filtered out of the tasks array
      //Note: this can happen e.g. if assignee changes
      if (values.reminders) {
        values.reminders = values.reminders.map((reminder) => {
          return values?.dueDate !== reminder.dueDate
            ? updateReminder(values, reminder, values?.dueDate)
            : reminder;
        });
      }

      if (
        relatedTo &&
        relatedTo.startsWith("USER") &&
        !relatedTo.includes(values.assignee?.id) &&
        !relatedTo.includes(values.relatedTo)
      ) {
        promise = dispatch(actions.updateTask(values, true));
      } else {
        promise = dispatch(actions.updateTask(values, false));
      }
    }

    promise.finally(() => {
      setIsLoading(false);
      onHide();
    });
  };

  const duplicateTask = () => {
    const duplicationTask = {
      name: `${entityForEdit.name} - ${intl.formatMessage({
        id: "COMMON.COPY",
      })}`,
      description: entityForEdit?.description || "",
      assignee: entityForEdit?.assignee || {},
      relatedTo: relatedTo || entityForEdit?.relatedTo || null,
      descriptionShort: entityForEdit?.descriptionShort || "",
    };
    submitTask(duplicationTask);
  };

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveTaskFields) {
      handleChange(e);
      saveTaskFields(e.target.name, e.target.value);
    }
  };

  const saveTaskFields = (key, value) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (const k of Object.keys(key)) {
        dispatch(actions.updateTaskFieldLocally(key[k], value[k]));
      }
    } else {
      dispatch(actions.updateTaskFieldLocally(key, value));
    }
  };

  const formatUser = (u) => {
    const user = { ...u };
    user.displayName = formatDisplayNameMeIntl(intl, user, session.id);
    return user;
  };

  const generateFormatSelectedRelatedTo = (relatedToName, relatedToId, localRelatedTo) => {
    return {
      label: relatedToName,
      value: { relatedToId },
      ...getTaskRelatedToAppearance(localRelatedTo),
    };
  };

  function formatSelectedRelatedTo(values) {
    const localRelatedTo = values.relatedTo || relatedTo;
    if (localRelatedTo) {
      const relatedToId = localRelatedTo.split("#")[1];
      if (relatedToItemOptions?.length) {
        const flattenedOptions = relatedToItemOptions.reduce(
          (acc, category) => [...acc, ...category.options],
          []
        );
        return (
          flattenedOptions.find((item) => item.value.id === relatedToId) ??
          generateFormatSelectedRelatedTo(
            values.relatedToName || relatedToName,
            relatedToId,
            localRelatedTo
          )
        );
      } else {
        return generateFormatSelectedRelatedTo(
          values.relatedToName || relatedToName,
          relatedToId,
          localRelatedTo
        );
      }
    }
    return null;
  }

  function updateReminder(task, reminder, dueDate) {
    if (dueDate) {
      reminder = {
        updatedAt: new Date(),
        amount: reminder.amount,
        timeUnit: reminder.timeUnit,
        timeRemaining: reminder.timeRemaining,
        dueDate: dueDate,
        taskId: reminder.taskId,
        userId: session?.id,
        notifyMethodOptions: reminder?.notifyMethodOptions,
        taskName: task.name,
        isTriggered:
          reminder.dueDate !== dueDate && reminder.triggerAt < reminder.dueDate
            ? false
            : reminder.isTriggered,
      };
    }
    return reminder;
  }

  function formatSelectedAssignee(values) {
    return values?.assigneeId && values.assigneeId !== ""
      ? {
          label: formatUser(values.assignee).displayName,
          value: values.assignee,
        }
      : null;
  }

  function cancelTaskEdit(resetForm) {
    onHide();
    resetForm();
  }

  const readOnly = !canEdit(groups, session, "TASK");

  const [assigneesLoading, setAssigneesLoading] = React.useState(false);
  const openAssigneeSelect = (e) => {
    if (!users?.length) {
      setAssigneesLoading(true);
      dispatch(actions.fetchAssignees({ userTypes }, intl)).then(() => setAssigneesLoading(false));
    }
  };

  const [relatedToOptionsLoading, setRelatedToOptionsLoading] = React.useState(false);
  const openRelatedToSelect = (e) => {
    if (!relatedToItemOptions?.length) {
      setRelatedToOptionsLoading(true);
      dispatch(actions.fetchRelatedToItems(intl)).then(() => setRelatedToOptionsLoading(false));
    }
  };

  const displayDate = (date) => {
    if (new Date().getTime() - new Date(date).getTime() < TimeUnitTypes.DAY.value * 7) {
      return intl.formatMessage(
        { id: `TIME.AGO` },
        {
          data: (
            <TimeAgo
              date={date}
              formatter={(value, unit) => {
                return getTimeAgoCaption(entityForEdit, value, unit, intl);
              }}
            />
          ),
        }
      );
    } else {
      return `${DateUtils.format(date, intl, true)}`;
    }
  };

  //--------------------------------------------------------------------------//
  // @begin: subtasks handlers

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const subtaskCreateHandler = (parentTask) => (name, toogleAddNew) => {
    setIsLoading(true);
    const { id: parentId, relatedTo } = parentTask;
    dispatch(
      actions.createSubask({
        name,
        parentId,
        createdByUserId: session?.id,
        taskType: TaskTypes.CUSTOM,
        relatedTo,
        status: ETaskStatus.PLANNED,
      })
    ).finally(() => {
      toogleAddNew();
      setIsLoading(false);
    });
  };

  const subtaskUpdateHandler = (subtask) => {
    setIsLoading(true);
    dispatch(actions.updateSubtask(subtask)).finally(() => {
      setIsLoading(false);
    });
  };

  const subtaskDeleteHandler = (subtask) => {
    const { name } = subtask;
    const config = {
      entityType: "SUBTASK",
      action: { fn: actions.deleteSubtask, props: subtask },
      extraContent: (
        <>
          <br />
          <br />
          <span>{name}</span>
        </>
      ),
    };

    setDeleteEntityDialog(config);
  };

  // @end: subtasks handlers
  //--------------------------------------------------------------------------//

  return (
    <>
      {(!id || id === entityForEdit?.id) && (
        <Modal
          show={show}
          // onHide={onHide}
          aria-labelledby="create-task-modal"
          centered
          backdrop="static"
          size={"xl"}
          scrollable
          // dialogClassName={"task-edit-dialog"}
        >
          {(listLoading || isLoading) && <ModalProgressBar variant="query" />}
          <Modal.Header closeButton>
            <Modal.Title id="create-task-modal-title">
              {mode === "create" ? (
                <FormattedMessage id="TASK.CREATE" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.EDIT" />
              )}
              {mode === "edit" && entityForEdit
                ? " " + getTaskText(intl, entityForEdit.name, entityForEdit.attachments)
                : ""}
            </Modal.Title>
          </Modal.Header>

          <Formik
            enableReinitialize={true}
            initialValues={
              mode === "create" ? initTask : mergeWithInitObject(entityForEdit, initTask)
            }
            onSubmit={submitTask}
          >
            {({ handleChange, values, submitForm, resetForm, setFieldValue }) => {
              const isTaskDone = values ? values.status === ETaskStatus.DONE : false;

              return (
                <>
                  <Modal.Body>
                    <Form data-cy="form-task">
                      <div className="form-group row">
                        {/* @begin: main column */}
                        <div className="col-lg-8">
                          {/* @begin: title row */}
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <Field
                                disabled={readOnly}
                                className="no-validation"
                                name="name"
                                data-cy="input-task-title"
                                component={Input}
                                placeholder={intl.formatMessage({
                                  id: "TASK.LABEL.TITLE",
                                })}
                                label={intl.formatMessage({
                                  id: "TASK.LABEL.TITLE",
                                })}
                                onChange={(e) => customHandleChange(e)(handleChange)}
                                withFeedbackLabel={false}
                                value={getTaskText(intl, values?.name, values?.attachments)}
                              />
                            </div>
                          </div>
                          {/* @end: title row */}

                          {/*<div className="form-group row">*/}
                          {/*  <div className="col-lg-12">*/}
                          {/*    <Field*/}
                          {/*      disabled={readOnly}*/}
                          {/*      className="no-validation"*/}
                          {/*      name="descriptionShort"*/}
                          {/*      data-cy="input-task-summary"*/}
                          {/*      component={Input}*/}
                          {/*      placeholder={intl.formatMessage({*/}
                          {/*        id: "TASK.LABEL.SUMMARY",*/}
                          {/*      })}*/}
                          {/*      label={intl.formatMessage({*/}
                          {/*        id: "TASK.LABEL.SUMMARY",*/}
                          {/*      })}*/}
                          {/*      onChange={(e) => customHandleChange(e)(handleChange)}*/}
                          {/*      withFeedbackLabel={false}*/}
                          {/*      value={getTaskText(*/}
                          {/*        intl,*/}
                          {/*        values?.descriptionShort,*/}
                          {/*        values?.attachments,*/}
                          {/*        values?.parameterValues*/}
                          {/*      )}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*</div>*/}

                          {/* @begin: description row */}
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <label>
                                <FormattedMessage id="TASK.LABEL.DESCRIPTION" />
                              </label>
                              <TextEditor
                                name={"description"}
                                saveMethod={mode === "create" ? setFieldValue : saveTaskFields}
                                data={
                                  mode && mode === "edit"
                                    ? getTaskText(intl, values?.description, values?.attachments)
                                    : ""
                                }
                                data-cy={"editor-task-description"}
                                disabled={
                                  readOnly || values?.taskType === "FILLOUT_AML_KYC_FORM_V2"
                                }
                              />
                            </div>
                          </div>
                          {/* @end: description row */}

                          {/* @begin: dates row */}
                          {readOnly &&
                            values?.dueDate &&
                            values?.taskType === TaskTypes.FILLOUT_AML_KYC_FORM_V2 && (
                              <div className="form-group row">
                                <div className="col-lg-12">
                                  <label>
                                    <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                                  </label>
                                  <Field
                                    className="form-control react-datepicker-ignore-onclickoutside no-validation"
                                    name="dueDate"
                                    component={DatePickerField}
                                    withFeedbackLabel={false}
                                    customHandleChange={customHandleChange}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            )}
                          {!readOnly && (
                            <div className="form-group row">
                              <div className="col-lg-1 center ml-2 mb-2">
                                <GanttChartIcon
                                  isTaskPlanned={
                                    values && values.plannedStartDate && values.dueDate
                                  }
                                />
                              </div>

                              <div className="col-sm-8 col-md-6 col-xl-4">
                                <label>
                                  <FormattedMessage id="TASK.LABEL.DATE.PLANNED_START" />
                                </label>
                                {hideSetPlannedStartDateButton || values.plannedStartDate ? (
                                  <div className="form-group row">
                                    <div className="col-lg-12">
                                      <Field
                                        className="form-control react-datepicker-ignore-onclickoutside no-validation"
                                        name="plannedStartDate"
                                        component={DatePickerField}
                                        withFeedbackLabel={false}
                                        customHandleChange={customHandleChange}
                                        disabled={isTaskDone}
                                      />
                                      {!isTaskDone && (
                                        <span
                                          className="datepicker-close-button"
                                          onClick={() => {
                                            setHidePlannedStartDateButton(false);
                                            customHandleChange({
                                              target: { name: "plannedStartDate", value: "" },
                                            })(handleChange);
                                          }}
                                        >
                                          <i className="fas fa-times" />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group row">
                                    <div className="col-lg-12">
                                      <button
                                        type="button"
                                        data-cy="button-task-set-planned-start-date"
                                        onClick={() => {
                                          setHidePlannedStartDateButton(true);
                                          customHandleChange({
                                            target: {
                                              name: "plannedStartDate",
                                              value: new Date().setDate(new Date().getDate() + 1),
                                            },
                                          })(handleChange);
                                        }}
                                        className="btn btn-primary btn-elevate"
                                        disabled={isTaskDone}
                                      >
                                        <FormattedMessage id="TASK.LABEL.DATE.PLANNED_START.SET" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4">
                                <label>
                                  <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                                </label>
                                {hideSetDueDateButton || values.dueDate ? (
                                  <div className="form-group row">
                                    <div className="col-lg-12">
                                      <Field
                                        className="form-control react-datepicker-ignore-onclickoutside no-validation"
                                        name="dueDate"
                                        component={DatePickerField}
                                        withFeedbackLabel={false}
                                        customHandleChange={customHandleChange}
                                        disabled={isTaskDone}
                                      />
                                      {!isTaskDone && (
                                        <span
                                          className="datepicker-close-button"
                                          onClick={() => {
                                            setHideDueDateButton(false);
                                            customHandleChange({
                                              target: { name: "dueDate", value: "" },
                                            })(handleChange);
                                          }}
                                        >
                                          <i className="fas fa-times" />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group row">
                                    <div className="col-lg-12">
                                      <button
                                        type="button"
                                        data-cy="button-task-set-due-date"
                                        onClick={() => {
                                          setHideDueDateButton(true);
                                          customHandleChange({
                                            target: {
                                              name: "dueDate",
                                              value: new Date(
                                                new Date().setDate(new Date().getDate() + 2)
                                              ),
                                            },
                                          })(handleChange);
                                        }}
                                        className="btn btn-primary btn-elevate"
                                        disabled={isTaskDone}
                                      >
                                        <FormattedMessage id="TASK.LABEL.DATE.DUE.SET" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {/* @end: dates row */}

                          {/* @begin: sub tasks row */}
                          {!(values.parentId || mode === "create") && (
                            <div className="form-group row">
                              <div className="col-lg-12">
                                <Subtasks
                                  {...{
                                    disabled: isLoading,
                                    list: values.subtasks,
                                    onCreate: subtaskCreateHandler(values),
                                    onUpdate: subtaskUpdateHandler,
                                    onDelete: subtaskDeleteHandler,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {/* @end: sub tasks row */}

                          {/* @begin: comments row */}
                          <div className="form-group row">
                            <div className="col-lg-12">
                              {mode === "edit" && (
                                <CommentsContainer
                                  task={values}
                                  session={session}
                                  groups={groups}
                                />
                              )}
                            </div>
                          </div>
                          {/* @end: comments row */}
                        </div>
                        {/* @end: main column */}

                        {/* @begin: side column */}
                        <div className="col-lg-4">
                          {/* @begin: status row */}
                          <div className="form-group row">
                            <div className="col-lg-12" data-cy="select-custom-task-status">
                              <label>
                                <FormattedMessage id="COMMON.STATUS" />
                              </label>
                              <CustomSelectStatus
                                name="status"
                                selected={values.status}
                                disabled={readOnly}
                                onChange={(selected) =>
                                  customHandleChange({
                                    target: {
                                      name: "status",
                                      value: selected?.value,
                                    },
                                  })(handleChange)
                                }
                              />
                            </div>
                          </div>
                          {/* @end: status row */}

                          {/* @begin: assignee row */}
                          <div className="form-group row">
                            <div className="col-lg-12" data-cy="select-custom-task-assignee">
                              <label>
                                <FormattedMessage id="TASK.LABEL.ASSIGNEE" />
                              </label>
                              <CustomSelect
                                placeholder={intl.formatMessage({
                                  id: "TASK.ACTION.LIST.SELECT.ASSIGNEE",
                                })}
                                name="assignee"
                                selected={formatSelectedAssignee(values)}
                                options={users}
                                clearable={true}
                                disabled={
                                  readOnly ||
                                  (mode === "edit" && entityForEdit?.taskType !== TaskTypes.CUSTOM)
                                }
                                onFocus={openAssigneeSelect}
                                onChange={(selected) => {
                                  customHandleChange({
                                    target: { name: "assignee", value: selected?.value },
                                  })(handleChange);
                                  customHandleChange({
                                    target: { name: "assigneeId", value: selected?.value?.id },
                                  })(handleChange);
                                }}
                                isLoading={assigneesLoading}
                                loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                                getOptionValue={(o) => o.value?.id}
                              />
                            </div>
                          </div>
                          {/* @end: assignee row */}

                          {/* @begin: related to row */}
                          <div className="form-group row">
                            <div className="col-lg-12" data-cy="select-custom-task-related-to">
                              <label>
                                <FormattedMessage id="TASK.LABEL.RELATED_TO" />
                              </label>
                              <CustomSelect
                                placeholder={intl.formatMessage({
                                  id: "COMMON.ACTION.SELECT.RELATED_TO",
                                })}
                                disabled={
                                  relatedTo ||
                                  readOnly ||
                                  (mode === "edit" && entityForEdit?.taskType !== TaskTypes.CUSTOM)
                                }
                                name="relatedTo"
                                selected={formatSelectedRelatedTo(values)}
                                options={relatedToItemOptions}
                                clearable={true}
                                onFocus={openRelatedToSelect}
                                onChange={(selected) => {
                                  const type = userTypes.includes(selected?.type?.toUpperCase())
                                    ? USER
                                    : selected?.type?.toUpperCase();
                                  customHandleChange({
                                    target: {
                                      name: "relatedTo",
                                      value: selected ? `${type}#${selected.value.id}` : "",
                                    },
                                  })(handleChange);
                                }}
                                isLoading={relatedToOptionsLoading}
                                loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                                getOptionValue={(o) => o.value?.id}
                              />
                            </div>
                          </div>
                          {/* @end: related to row */}

                          {/* @begin: reminders row */}
                          {!readOnly && !isTaskDone && values.dueDate && (
                            <>
                              <label>
                                <FormattedMessage
                                  id="TASK.LABEL.REMINDER.TITLE"
                                  values={{ count: values.reminders.length }}
                                />
                              </label>
                              <FieldArray name="reminders">
                                {({ remove }) => (
                                  <div>
                                    {values?.reminders &&
                                      values?.reminders?.map((reminder, index) => (
                                        <Reminder
                                          key={index}
                                          values={cloneDeep(values)}
                                          customHandleChange={customHandleChange}
                                          reminder={reminder}
                                          index={index}
                                          remove={remove}
                                          handleChange={handleChange}
                                          dueDate={values.dueDate}
                                          notifyMethodOptions={values.reminder?.notifyMethodOptions}
                                        />
                                      ))}
                                    <div className="form-group row">
                                      {values.reminders.length < 5 && (
                                        <div className="col-lg-12">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              let reminders = cloneDeep(values.reminders) || [];
                                              reminders.push({
                                                createdAt: new Date(),
                                                amount: 1,
                                                timeUnit: "HOUR",
                                                dueDate: values?.dueDate,
                                                taskId: values?.id,
                                                userId: session?.id,
                                                notifyMethodOptions: values.notifyMethodOptions
                                                  ? values.notifyMethodOptions
                                                  : "SMS",
                                              });
                                              customHandleChange({
                                                target: { name: "reminders", value: reminders },
                                              })(handleChange);
                                            }}
                                            data-cy="button-task-add-reminder"
                                            className="btn btn-elevate btn-primary"
                                          >
                                            <FormattedMessage id="TASK.ACTION.REMINDER.ADD" />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </>
                          )}
                          {/* @end: reminders row */}

                          {mode === "edit" && (
                            <>
                              {/* @begin: create/update row */}
                              <div className="form-group row">
                                <div className="col-lg-12">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>{intl.formatMessage({ id: "CREATED" })} </td>
                                        <td className="pl-2">
                                          {displayDate(entityForEdit.createdAt)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{intl.formatMessage({ id: "UPDATED" })} </td>
                                        <td className="pl-2">
                                          {displayDate(entityForEdit.updatedAt)}
                                        </td>
                                      </tr>

                                      {entityForEdit.doneDate &&
                                        entityForEdit.status === ETaskStatus.DONE && (
                                          <tr>
                                            <td>{intl.formatMessage({ id: "RESOLVED" })} </td>
                                            <td className="pl-2">
                                              {displayDate(entityForEdit.doneDate)}
                                            </td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* @begin: create/update row */}
                            </>
                          )}
                        </div>
                        {/* @end: side column */}
                      </div>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <div className="mr-auto">
                      {mode !== "create" &&
                        !readOnly &&
                        entityForEdit?.taskType === "CUSTOM" &&
                        canCreate(groups, session, "TASK") && (
                          <button
                            type="button"
                            data-cy="button-task-duplication"
                            className="btn btn-outline-primary font-weight-bold ml-2"
                            onClick={duplicateTask}
                          >
                            <FormattedMessage id="COMMON.ACTION.DUPLICATION" />
                          </button>
                        )}
                    </div>
                    <div>
                      <button
                        type="button"
                        data-cy="button-cancel"
                        className="btn btn-light btn-elevate"
                        disabled={isLoading}
                        onClick={() => cancelTaskEdit(resetForm)}
                      >
                        {!readOnly ? (
                          <FormattedMessage id="COMMON.ACTION.CANCEL" />
                        ) : (
                          <FormattedMessage id="COMMON.ACTION.CLOSE" />
                        )}
                      </button>
                      {!readOnly && (
                        <button
                          type="button"
                          data-cy="button-task-save"
                          className="btn btn-primary font-weight-bold ml-2"
                          disabled={isLoading || !(values?.name && values?.name?.trim().length > 0)}
                          onClick={submitForm}
                        >
                          {mode === "create" ? (
                            <FormattedMessage id="COMMON.ACTION.CREATE" />
                          ) : (
                            <FormattedMessage id="COMMON.ACTION.SAVE" />
                          )}
                        </button>
                      )}
                    </div>
                  </Modal.Footer>
                </>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
}
