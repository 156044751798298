import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { formatDisplayLang, getFilesForKycFile } from "../../../KycFileUtils";
import { KycBoTableMode, KycFile, KycSignatory } from "../../../Kyc";
import "./KycFileItemLangChoose.scss";
import cn from "clsx";
import { getLangShort } from "../../../KycUtils";

interface KycFileItemLangChooseProps {
  files: KycFile[];
  cell: any;
  signatory: KycSignatory;
  mode: KycBoTableMode;
  updateSignatoryBoDeclarationLang: Function;
}

export const KycFileItemLangChoose = ({
  files,
  cell,
  signatory,
  updateSignatoryBoDeclarationLang,
  mode,
}: KycFileItemLangChooseProps): JSX.Element => {
  const [selectedLang, setSelectedLang] = useState<string>();

  let filesToDisplay = getFilesForKycFile(cell, files);

  useEffect(() => {
    if (!selectedLang) {
      setSelectedLang(signatory?.boDeclaration?.lang);
    }
  }, [selectedLang]);

  const handleSelectLang = (e: any, lang: string = "") => {
    e.preventDefault();
    setSelectedLang(getLangShort(lang));

    updateSignatoryBoDeclarationLang({ signatoryId: signatory?.id, lang: getLangShort(lang) });
  };

  return (
    <div className="d-flex">
      {filesToDisplay &&
        Object.values(filesToDisplay)
          ?.filter((file: KycFile) =>
            mode === KycBoTableMode.COMPLIANCE
              ? file?.lang && getLangShort(file?.lang) === signatory?.boDeclaration?.lang
              : file
          )
          ?.map((file: KycFile, index) => (
            <div className="d-flex align-items-center" key={`signature-request-${index}`}>
              <Button
                className={cn(
                  file?.lang &&
                    selectedLang === getLangShort(file?.lang) &&
                    mode === KycBoTableMode.SIGNATURE_REQUEST &&
                    "kyc-lang-button-selected"
                )}
                size="sm"
                variant="outline"
                onClick={(e) => handleSelectLang(e, file?.lang)}
              >
                {formatDisplayLang(file?.lang ?? "")}
              </Button>
            </div>
          ))
          .sort()}
    </div>
  );
};
