import { useWizard } from "app/hooks/useWizard";
import { KycTranslation } from "../../KycTranslation";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { KycFormTitle } from "../layout/KycFormTitle";
import React, { ChangeEvent } from "react";
import { KycCenteredStepBodyLayout } from "./KycCenteredStepBodyLayout";
import { Field } from "formik";
import { KycCheckbox } from "../controls/kyc-checkbox/KycCheckbox";
import { useTranslation } from "../../../../hooks/useTranslation";

export const KycStepPropDevDisclaimer = (): JSX.Element => {
  const { setDisableNext } = useWizard();
  const { setFlowValues, values } = useKycFlow();
  const { fm } = useTranslation();

  return (
    <>
      <KycFormTitle label={KycTranslation.STEPS_DISCLAIMER_TITLE} />
      <KycCenteredStepBodyLayout>
        <div className="text-justify mx-auto" style={{ maxWidth: "80%" }}>
          {fm(KycTranslation.DISCLAIMER_CONTENT, {
            br: <br />,
          })}
        </div>
        <div className="mt-8 mx-auto align-items-center " style={{ maxWidth: "80%" }}>
          <div>
            <Field
              inline={false}
              component={KycCheckbox}
              disabled={values?.form?.acceptTerms?.isNextClicked}
              checkboxInfo={{
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) {
                    setFlowValues({
                      "form.acceptTerms": {
                        isAccepted: true,
                        isNextClicked: false,
                        acceptedAt: new Date().toISOString(),
                      },
                    });
                  } else {
                    setFlowValues({
                      "form.acceptTerms": {
                        isAccepted: false,
                        isNextClicked: false,
                        acceptedAt: "",
                      },
                    });
                  }
                  setDisableNext(!e.target.checked);
                },
                name: "form.acceptTerms.isAccepted",
                isChecked: !!values?.form?.acceptTerms?.isAccepted,
              }}
              text={KycTranslation.DISCLAIMER_ACCEPT_TERMS}
            />
          </div>
        </div>
      </KycCenteredStepBodyLayout>
    </>
  );
};
