/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./ProductFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { useSelector } from "react-redux";
import { canReadAllDocument } from "../../../../../_utils/authUtils";
import { SearchTypeValues } from "../../../../../contexts/SearchContext";

const ProductFilesUIContext = createContext();

export function useProductFilesUIContext() {
  return useContext(ProductFilesUIContext);
}

export const ProductFilesUIConsumer = ProductFilesUIContext.Consumer;

export function ProductFilesUIProvider({ currentProductId, children, readOnly, context }) {
  const filesContext = useFilesContext();

  const [productId, setProductId] = useState(currentProductId);
  const [filters, setFilters] = useState(initialFilter);

  const { groups, session } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const initFile = {
    id: undefined,
    file: undefined,
    friendlyName: "",
    fileType: "OTHER",
    productId,
    isConfidential: canReadAllDocument(groups, session, "PRODUCT"),
  };

  useEffect(() => {
    initFile.productId = currentProductId;
    setProductId(currentProductId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);

  const value = {
    ...filesContext,
    productId,
    setProductId,
    filters,
    setFilters,
    initFile,
    readOnly,
    context,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [...filesContext.filterList],
      },
      {
        name: "COMMON.TYPE",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "fileType",
        values: [],
        labelKey: "PROJECT.PRODUCT.FILE.TYPES",
      },
      { name: "COMMON.UPDATED.AT", value: "uploadedAt", type: SearchTypeValues.SEARCH_DATE },
    ],
  };

  return <ProductFilesUIContext.Provider value={value}>{children}</ProductFilesUIContext.Provider>;
}
