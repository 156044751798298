import { isString } from "lodash-es";

export const RowTheme = {
  PRIMARY: "primary",
  INFO: "info",
  GREY: "grey",
} as const;
export type TRowTheme = typeof RowTheme[keyof typeof RowTheme] | string[];
// We can choose the prebuilt theme or use custom classes for each depth level
export const defaultRowThemes = {
  primary: [
    "bg-primary-o-60-solid",
    "bg-primary-o-15-solid bg-hover-primary-o-1-solid",
    "bg-white bg-hover-gray-300",
    "bg-white bg-hover-gray-200",
  ],
  info: [
    "bg-info-o-60-solid",
    "bg-info-o-15-solid bg-hover-info-o-1-solid",
    "bg-white bg-hover-gray-300",
    "bg-white bg-hover-gray-200",
  ],
  grey: ["bg-dark-o-30-solid"],
};
export const getRowTheme = (rowTheme: TRowTheme = "primary", depth: number) =>
  isString(rowTheme) ? defaultRowThemes[rowTheme][depth] : rowTheme[depth];
