import { useSearch } from "../../hooks/useSearch";
import * as React from "react";
import {
  IFilterSearchTypeCheckBox,
  IFilterSearchTypeDate,
  IFilterSearchTypeDropdown,
  IFilterSearchTypeText,
  SearchTypeValues,
} from "../../contexts/SearchContext";
import { SearchText } from "./SearchText";
import { SearchDropdown } from "./SearchDropdown";
import { SearchDate } from "./SearchDate";
import { FormattedMessage } from "react-intl";
import { SearchCheckBox } from "./SearchCheckBox";

interface SearchLocalProps {
  useEntityUIContext: any;
}

export const SearchLocal: React.FC<SearchLocalProps> = ({ useEntityUIContext }): JSX.Element => {
  const { filters, setFilters } = useSearch();
  const ctx = useEntityUIContext();
  const [isShowAdvanced, setIsShowAdvanced] = React.useState<boolean>(false);
  const isAdvancedDisplayed = filters.some((f) => f.isAdvanced);

  React.useEffect(() => {
    setFilters(ctx.filtersUI ?? []);
  }, []);

  const displayFilters = (
    filters: (IFilterSearchTypeText | IFilterSearchTypeDate | IFilterSearchTypeDropdown)[]
  ) => {
    const values: JSX.Element[] = [];
    filters.forEach(
      (filter: IFilterSearchTypeText | IFilterSearchTypeDate | IFilterSearchTypeDropdown) => {
        switch (filter.type) {
          case SearchTypeValues.SEARCH_TEXT_COLUMNS:
            const filterText = filter as IFilterSearchTypeText;
            values.push(
              <SearchText
                name={filterText.name}
                value={filterText.selected}
                key={"filter_Text_" + filterText.name}
              />
            );
            break;

          case SearchTypeValues.SEARCH_DROPDOWN:
            const filterDD = filter as IFilterSearchTypeDropdown;
            values.push(
              <SearchDropdown
                name={filterDD.value}
                label={filterDD.name}
                labelKey={filterDD?.labelKey}
                values={filterDD?.values}
                groupBy={filterDD.groupBy}
                formatter={filterDD.formatter}
                isFlexGrow={filterDD.isAdvanced}
                key={"filter_Dropdown_" + filterDD.name}
              />
            );
            break;

          case SearchTypeValues.SEARCH_DATE:
            const filterDate = filter as IFilterSearchTypeDate;
            values.push(
              <SearchDate
                name={filterDate.value}
                label={filterDate.name}
                isFuture={filterDate.isFuture}
                isFlexGrow={filterDate.isAdvanced}
                key={"filter_Date_" + filterDate.name}
              />
            );
            break;

          case SearchTypeValues.SEARCH_CHECKBOX:
            const filterCB = filter as IFilterSearchTypeCheckBox;
            values.push(
              <SearchCheckBox
                name={filterCB.value}
                label={filterCB.name}
                isChecked={filterCB.isChecked}
                isFlexGrow={filterCB.isAdvanced}
                key={"filter_CB_" + filterCB.name}
              />
            );
            break;
        }
      }
    );
    return values;
  };

  return (
    <div className="form form-label-right w-100">
      <div className={"d-flex flex-column flex-sm-row flex-wrap"}>
        {displayFilters(filters?.filter((f: any) => !f.isAdvanced))}
        {isAdvancedDisplayed && !isShowAdvanced && (
          <div className="mr-md-2 mr-0 mt-2">
            <button
              type="button"
              className="btn btn-primary w-sm-100 flex-grow-1 min-h-40px"
              onClick={() => setIsShowAdvanced(!isShowAdvanced)}
            >
              <FormattedMessage id="COMMON.SEARCH.ADVANCED_FILTERS" />
            </button>
          </div>
        )}
      </div>
      {isShowAdvanced && (
        <div className={"d-flex flex-column flex-sm-row flex-wrap"}>
          {displayFilters(filters?.filter((f: any) => f.isAdvanced))}
        </div>
      )}
    </div>
  );
};
