// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { useDidMount, useDidUpdate } from "rooks";

import { useAppSelector } from "redux/hooks";

import { getSelectRow, NoRecordsFoundMessage, sortCaret } from "_metronic/_helpers";
import { Pagination } from "_metronic/_partials/controls";

import { searchUtils, selectField } from "app/_utils/searchUtils";
import { CLIENT, SUBCONTRACTOR } from "app/_utils/userTypes";

import {
  ActionsColumnFormatter,
  FullNameFormatter,
  PhoneNumberFormatter,
  PlatformAccessColumnFormatter,
  ProfilePictureColumnFormatter,
} from "app/_components/column-formatters";

import * as actions from "../../../../UsersManagement/_redux/usersActions";
import * as uiHelpers from "../UsersUIHelpers";
import { useUsersUIContext } from "../UsersUIContext";
import { formatDisplayNameColumn } from "../../../../../_utils/userUtils";

export function UsersTable() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const ctx = useUsersUIContext();

  const uiProps = useMemo(() => {
    return {
      ids: ctx.ids,
      setIds: ctx.setIds,
      queryParams: ctx.queryParams,
      setQueryParams: ctx.setQueryParams,
      filters: ctx.filters,
      setFilters: ctx.setFilters,
      readOnly: ctx.readOnly,
      context: ctx.context,
      action: ctx.action,
      openEntityDetailsPage: ctx.openEntityDetailsPage,
      openRemoveUser: ctx.openRemoveUser,
      canOpenRemoveUser: ctx.canOpenRemoveUser,
      userType: ctx.userType,
      filterList: ctx.filterList,
    };
  }, [ctx]);

  const { entities, listLoading } = useAppSelector(
    (state) => ({
      entities: uiProps.context ? state[uiProps.context].entities : state.users.entities,
      listLoading: uiProps.context ? state[uiProps.context].listLoading : state.users.listLoading,
    }),
    shallowEqual
  );

  const fetchUsersByType = () => {
    if (uiProps !== "leadClients") {
      uiProps.setIds([]);
      dispatch(
        actions.fetchUsersByUserType(
          { ...uiProps.queryParams, userTypes: [uiProps.userType] },
          uiProps.context
        )
      );
    }
  };
  useDidMount(() => fetchUsersByType());
  useDidUpdate(() => fetchUsersByType(), [uiProps.userType]);

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(uiProps?.filters?.freeText, entities, selectField(uiProps), intl)
    );
  }, [entities, uiProps?.filters?.freeText, uiProps?.filters?.filterSelected]);

  const columns = [
    {
      dataField: "user",
      text: "",
      sort: false,
      formatter: ProfilePictureColumnFormatter,
    },
    {
      dataField: "fullName",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      formatter: FullNameFormatter,
      sortValue: (cell, row) => formatDisplayNameColumn(row),
      sortCaret: sortCaret,
    },
    {
      dataField: "leadsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "LEAD.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
      hidden: uiProps.userType !== CLIENT,
    },
    {
      dataField: "projectsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "PROJECT.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
      hidden: uiProps.userType !== SUBCONTRACTOR,
    },
    {
      dataField: "mobile",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.MOBILE",
      }),
      sort: true,
      formatter: PhoneNumberFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.EMAIL",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "isDisabled",
      headerClasses: "text-center text-nowrap",
      classes: "text-center",
      text: intl.formatMessage({
        id: "COMMON.HAS_PLATFORM_ACCESS",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: PlatformAccessColumnFormatter,
      sortValue: (cell, row) => !row.isDisabled,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: (id) => uiProps.openRemoveUser(id, uiProps),
        actionList: ["TRASH"],
        canDelete: (row) => {
          return uiProps.canOpenRemoveUser(row);
        },
        entityType: uiProps.userType,
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: uiProps.readOnly || !!uiProps?.action,
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.filters.pageSize,
    page: uiProps.filters.pageNumber,
  };

  return (
    <div data-cy="table-list">
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                keyField="id"
                data={entitiesFiltered || []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                noDataIndication={() => <NoRecordsFoundMessage entities={entitiesFiltered} />}
                selectRow={
                  uiProps.action === "LEAD_CREATION" || uiProps.action === "MULTI_TASK_CREATION"
                    ? getSelectRow({
                        entities: entitiesFiltered,
                        ids: uiProps.ids,
                        setIds: uiProps.setIds,
                      })
                    : undefined
                }
                hover
                //TODO nasty fix for client not clickable in leads
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    if (
                      uiProps.action !== "LEAD_CREATION" &&
                      uiProps.action !== "MULTI_TASK_CREATION"
                    ) {
                      uiProps.openEntityDetailsPage
                        ? uiProps.openEntityDetailsPage(row)
                        : history.push(`/clients/${row.id}`);
                    }
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
                {...paginationTableProps}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </div>
  );
}
