import { useKycFlow } from "../../../hooks/useKycFlow";
import { KycUploadComponent } from "./controls/kyc-upload/KycUploadComponent";
import { KycTranslation } from "../KycTranslation";
import { getKyAdditionalDocumentsRelatedEntityType } from "../KycUtils";

export const KycAdditionalDocumentsListedCompany = (): JSX.Element => {
  const { isClientViewer, values } = useKycFlow();

  const getKycRelatedEntity = () => {
    return getKyAdditionalDocumentsRelatedEntityType(values);
  };

  return (
    <>
      <KycUploadComponent
        name="evidenceProveOfContributions"
        subLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_OF_CONTRIBUTIONS}
        label={KycTranslation.EVIDENCE_DOCUMENT_NAME_PROVE_OF_CONTRIBUTIONS_SHORT}
        kycRelatedToEntity={getKycRelatedEntity()}
        multiple={true}
      />
      {!isClientViewer && (
        <>
          <KycUploadComponent
            name="evidenceLFCLCWorldCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK}
            label={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_WORLD_CHECK_SHORT}
            kycRelatedToEntity={getKycRelatedEntity()}
            multiple={true}
          />
          <KycUploadComponent
            name="evidenceLFCLCInternetCheck"
            subLabel={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK}
            label={KycTranslation.EVIDENCE_DOCUMENT_L_FC_LC_INTERNET_CHECK_SHORT}
            kycRelatedToEntity={getKycRelatedEntity()}
            multiple={true}
          />
        </>
      )}
    </>
  );
};
