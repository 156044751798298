import * as React from "react";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../_utils/suffixUtils";
import { FormattedMessage } from "react-intl";
import { IProject } from "../../../../../../../data/schemas";
import { HeaderCard, HeaderCardProps } from "../../../../components/HeaderCard";
import { HeaderCardSubValue } from "../../../../components/HeaderCardSubValue";

export interface RemainingToInvoiceBudgetCardProps {
  canSeeHiddenLine: boolean;
  actualBudget: number;
  project: Partial<IProject>;
}

export const RemainingToInvoiceBudgetCard: React.FC<
  RemainingToInvoiceBudgetCardProps & HeaderCardProps
> = ({ canSeeHiddenLine, project, actualBudget, ...props }) => {
  const totalKey = canSeeHiddenLine ? "general" : "public";
  const remainingToInvoice = project.subcontractorsFinanceFiles
    ? actualBudget - project.subcontractorsFinanceFiles.invoices.total[totalKey]
    : 0;

  const remainingToInvoicePercentage =
    remainingToInvoice && actualBudget ? (remainingToInvoice / actualBudget) * 100 : 0;
  return (
    <HeaderCard
      mainContent={
        <NumberInput
          data-cy="input-budget-remaining-to-invoice"
          value={remainingToInvoice}
          displayType="text"
          suffix={SUFFIX_EURO_CURRENCY}
          decimalScale={2}
          fixedDecimalScale={true}
          className="ml-auto font-weight-bold"
        />
      }
      label={<FormattedMessage id="BUDGET.REMAINING_TO_INVOICE" values={{ br: <br /> }} />}
      {...props}
    >
      <HeaderCardSubValue
        dataCy="input-budget-remaining-to-invoice-percentage"
        value={remainingToInvoicePercentage}
        decimalScale={2}
        suffix={SUFFIX_PERCENTAGE}
      />
    </HeaderCard>
  );
};
