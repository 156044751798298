import { API } from "aws-amplify";
import { getTimeAgoStringForIntl } from "app/_utils/tasks";
import { formatNotification } from "../../../_utils/notificationUtils";

export async function getLatest(intl) {
  const notifications = await API.get("API", `/notifications`, {});

  const formattedNotifications = [];
  for (const notification of notifications) {
    const { notificationType, parameters } = notification;
    if (
      notificationType &&
      !["RESET_USER_CREDENTIALS", "SEND_USER_CREDENTIALS"].includes(notificationType)
    ) {
      const templateString = intl
        .formatMessage({ id: "IN_APP_NOTIFICATION." + notificationType })
        .includes("timeRemaining");
      if (intl && templateString) {
        parameters.timeRemaining = getTimeAgoStringForIntl(
          undefined,
          parameters?.amount,
          parameters?.timeUnit,
          intl
        );
      }
      //TODO - needs a use effect or something to trigger formatNotification if intl changes
      formattedNotifications.push(formatNotification(notification, intl));
    }
  }
  return formattedNotifications;
}

export async function updateNotification(notification) {
  return await API.post("API", `/notifications/${notification.id}`, {});
}
