import { Field } from "formik";
import { KycTranslation } from "../../KycTranslation";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycEntityType, KycItemType } from "../../Kyc";
import * as React from "react";
import { ChangeEvent } from "react";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycRadioGroup } from "../controls/kyc-radio-group/KycRadioGroup";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import {
  getTranslationForLegalType,
  isBigOwner,
  isInvestmentFund,
  isRepresentedByAgent,
  isTrust,
  KYC_AGENT,
  KYC_BENEFICIAL_OWNER,
  KYC_REPRESENTATIVE,
} from "../../KycUtils";
import { KycFormSection } from "../layout/KycFormSection";
import { KycAddressForm } from "../KycAddressForm";
import { KycMultiEntity } from "../controls/kyc-multi-entity-form/KycMultiEntity";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import "../KycToolTip.scss";
import { KycFormSectionTitle } from "../KycFormSectionTitle";
import { KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION } from "../../KycTranslationUtils";

export const KycStepClientPage2Legal = (): JSX.Element => {
  const { values, customHandleChange } = useKycFlow();
  const { fm } = useTranslation();

  const getKycRelatedEntity = () => {
    return {
      id: values?.form?.client?.id,
      type: KycEntityType.CLIENT_LEGAL,
    };
  };

  return (
    <>
      <KycFormSectionTitle title="COMMON.COMPANY" />
      <Field
        name="form.client.company.name"
        component={KycInput}
        label={KycTranslation.COMPANY_NAME}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name="form.client.legalForm"
        component={KycInput}
        placeholder="S.à r.l."
        label={KycTranslation.LEGAL_FORM}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycUploadComponent
        name="evidenceLegalForm"
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={getTranslationForLegalType(values?.form?.client?.legalType) ?? ""}
        multiple={true}
      />
      <KycFormSection label={KycTranslation.COMPANY_ADDRESS}>
        <KycAddressForm name="form.client.address" />
      </KycFormSection>
      <Field
        name="form.client.company.registrationNumber"
        component={KycInput}
        placeholder=""
        label={KycTranslation.TRADE_REGISTER_NAME}
        infoLabel={KycTranslation.TRADE_REGISTER_NAME_TOOLTIP}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name="form.client.company.nationalRegistrationNumber"
        component={KycInput}
        placeholder=""
        label={KycTranslation.TRADE_REGISTER_NUMBER}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycUploadComponent
        name="evidenceTradeRegisterRCS"
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_1}
      />
      <KycUploadComponent
        name="evidenceTradeRegisterRBE"
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_2}
      />
      <KycFormSection label={KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL}>
        <KycUploadComponent
          name="evidenceAdditionalDocuments"
          kycRelatedToEntity={getKycRelatedEntity()}
          infoLabel={KycTranslation.EVIDENCE_DOCUMENT_ADDITIONAL}
          othersOnly={true}
          options={KYC_V2_OPTIONS_PURPOSE_OF_ACQUISITION}
          multiple={true}
        />
      </KycFormSection>
      {isTrust(values) && fm(KycTranslation.EVIDENCE_DOCUMENT_NAME_TRADE_REGISTER_NO_DOCS)}
      <Field
        name="form.client.website"
        component={KycInput}
        label={KycTranslation.WEBSITE}
        placeholder="https://my-company.com"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <hr className="mb-4" />
      {/* Beneficial owner(s) section*/}
      <KycFormSectionTitle
        title={KycTranslation.BENEFICIAL_OWNER_OF_LEGAL_PERSON}
        infoLabel={KycTranslation.BENEFICIAL_OWNER_INFO_SECTION}
      />
      <KycUploadComponent
        name="evidenceBeneficialOwnerOrganisationalChart"
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_ORGANISATION_CHART}
      />
      <Field
        component={KycRadioGroup}
        name="form.client.isBigOwner"
        label={KycTranslation.IS_BIG_OWNER}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        infoLabel={!isBigOwner(values) && KycTranslation.INFO_REPRESENTATIVES_ARE_BOS}
      />
      {isBigOwner(values) && (
        <Field
          component={KycMultiEntity}
          itemType={KycItemType.PERSONAL_INFO}
          title={KycTranslation.BENEFICIAL_OWNER_TITLE}
          name="form.client.beneficialOwners"
          initObj={KYC_BENEFICIAL_OWNER}
          defaultHeaderTitle={KycTranslation.BENEFICIAL_OWNER}
          labelButtonAdd="COMMON.ACTION.ADD"
        />
      )}
      <Field
        title={KycTranslation.COMPANY_REPRESENTATIVE}
        infoLabel={KycTranslation.INFO_NATURAL_PERSON_OCCUPIES_EXECUTIVE_POSITION}
        component={KycMultiEntity}
        itemType={KycItemType.PERSONAL_INFO}
        name="form.client.representatives"
        initObj={KYC_REPRESENTATIVE}
        defaultHeaderTitle={KycTranslation.REPRESENTATIVE}
        labelButtonAdd="COMMON.ACTION.ADD"
      />
      {/*TODO recheck case LEGAL_TYPE_INVESTMENT_FUND and evidence no big owner*/}
      {!isBigOwner(values) && isInvestmentFund(values) && (
        <KycUploadComponent
          name="evidenceAgentNoBigOwner"
          kycRelatedToEntity={getKycRelatedEntity()}
          infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_CONFIRMATION_NOT_BIG_OWNER}
        />
      )}
      <Field
        component={KycRadioGroup}
        name="form.client.isRepresentedByAgent"
        label={KycTranslation.IS_CLIENT_REPRESENTED_BY_AGENT}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      {isRepresentedByAgent(values) && (
        <Field
          component={KycMultiEntity}
          title={KycTranslation.AGENTS}
          itemType={KycItemType.PERSONAL_INFO}
          name="form.client.agents"
          initObj={KYC_AGENT}
          defaultHeaderTitle={KycTranslation.AGENT}
          labelButtonAdd="COMMON.ACTION.ADD"
        />
      )}
    </>
  );
};
