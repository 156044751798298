import * as React from "react";
import cn from "clsx";

export interface HeaderCardBase {
  mainContent: React.ReactNode;
  label: React.ReactNode;
}

export interface HeaderCardProps {
  className?: string;
}

export const HeaderCard: React.FC<HeaderCardProps & HeaderCardBase> = ({
  className,
  mainContent,
  label,
  children,
}) => {
  return (
    <div className={cn("col-6 col-md-4 col-xl-3 d-flex flex-column", className)}>
      <div className="card card-custom bg-light flex-grow-1">
        <div className="card-body d-flex flex-column px-8 py-4">
          <div className="text-inverse-light font-weight-bold font-size-md mt-1">{label}</div>
          <div className="text-inverse-light font-weight-bolder font-size-h3">{mainContent}</div>
          {children}
        </div>
      </div>
    </div>
  );
};
