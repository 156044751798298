/* eslint-disable no-unused-vars */
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDidMount, useDidUpdate } from "rooks";
import { IFile, ILinkedFile } from "../../../data/schemas";
import { ENTITY_TYPE_PATH } from "../../_utils/listUtils";
import * as requestFromServer from "./filesCrud";
import { useDispatch } from "react-redux";
import * as projectsActions from "../PropertiesManagement/_redux/projects/projectsActions";
import * as leadsActions from "../PropertiesManagement/_redux/leads/leadsActions";
import * as productsActions from "../PropertiesManagement/_redux/products/productsActions";
import * as usersActions from "../UsersManagement/_redux/usersActions";
import {
  IFilterSearchTypeDate,
  IFilterSearchTypeDropdown,
  IFilterSearchTypeText,
} from "../../contexts/SearchContext";

const entityActions: Record<string, any> = {
  PROJECT: projectsActions,
  LEAD: leadsActions,
  PRODUCT: productsActions,
  USER: usersActions,
};

export interface IFilesContext {
  ids: string[];
  setIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedId?: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string | undefined>>;
  newFile: boolean;
  setNewFile: React.Dispatch<React.SetStateAction<boolean>>;
  showEditFileDialog: boolean;
  setShowEditFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openNewFileDialog: () => void;
  openRequestSignatureDialog: (id: string) => void;
  closeRequestSignatureDialog: () => void;
  showRequestSignatureDialog: boolean;
  openEditFileDialog: (id: string) => void;
  closeEditFileDialog: () => void;
  openEditLinkedFile: (file: ILinkedFile) => void;
  filterList: { value: string; label: string }[];
  openLinkedFile: (fileId: ILinkedFile) => void;
  addLinkedFiles: (file: IFile, linkedFiles: ILinkedFile[]) => void;
  removeLinkedFile: (file: IFile, linkedFile: ILinkedFile) => void;
  isUploadingFile: boolean;
  setUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  setFormSubmitRef: (callback: Function) => void;
  resetFormSubmitRef: () => void;
  triggerFormSubmit: () => void;
  setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitDisabled: boolean;
  submitButtonLabel?: string;
  setSubmitButtonLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  headerLabel?: string;
  setHeaderLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  footerRef: React.MutableRefObject<any>;
  filtersUI: (IFilterSearchTypeText | IFilterSearchTypeDate | IFilterSearchTypeDropdown)[];
}

const FilesContext = React.createContext<IFilesContext>({} as IFilesContext);

export function useFilesContext() {
  return React.useContext(FilesContext);
}

export const FilesConsumer = FilesContext.Consumer;

const filterList = [
  { value: "friendlyName", label: "COMMON.NAME" },
  { value: "fileType", label: "COMMON.TYPE" },
  { value: "uploadedAt", label: "COMMON.UPDATED.AT" },
];

export const FilesProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [ids, setIds] = React.useState<string[]>([]);
  const [selectedId, setSelectedId] = React.useState<string>();
  const [newFile, setNewFile] = React.useState(false);
  const [showEditFileDialog, setShowEditFileDialog] = React.useState(false);
  const [isUploadingFile, setUploadingFile] = React.useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = React.useState<string | undefined>();
  const [headerLabel, setHeaderLabel] = React.useState<string | undefined>();

  const footerRef = React.useRef(null);

  const openEditFileDialogLogic = (search = location.search) => {
    const queryParams = new URLSearchParams(search);
    const fileId = queryParams.get("fileId");
    if (fileId) {
      setSelectedId(fileId);
      setShowEditFileDialog(true);
    }
  };

  useDidMount(() => {
    openEditFileDialogLogic(location.search);
  });

  useDidUpdate(() => {
    openEditFileDialogLogic(location.search);
  }, [location]);

  const openNewFileDialog = () => {
    setSelectedId(undefined);
    setUploadingFile(true);
    setNewFile(true);
    setShowEditFileDialog(true);
  };

  const openEditFileDialog = (id: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("fileId", id);
    const search = queryParams.toString();
    history.push({ search });
    openEditFileDialogLogic(search);
  };

  const openEditLinkedFile = (file: ILinkedFile) => {
    window.open(
      `/${ENTITY_TYPE_PATH[file.relatedEntity.userType ?? file.relatedEntity.type]}/${
        file.relatedEntity.id
      }?t=files&fileId=${file.id}`,
      "_blank"
    );
  };

  const closeEditFileDialog = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("fileId");
    history.push({ search: queryParams.toString() });
    setSelectedId(undefined);
    setShowEditFileDialog(false);
    setUploadingFile(false);
    setNewFile(false);
  };

  const [showRequestSignatureDialog, setShowRequestSignatureDialog] = React.useState(false);

  const openRequestSignatureDialog = (id: string) => {
    setSelectedId(id);
    setShowRequestSignatureDialog(true);
  };

  const closeRequestSignatureDialog = () => {
    setSelectedId(undefined);
    setShowRequestSignatureDialog(false);
  };

  const openLinkedFile = (file: ILinkedFile) => {
    const newWindow = window.open("", "_blank");

    if (newWindow) {
      return requestFromServer
        .getFile(file)
        .then((response) => {
          newWindow.location = response.url;
        })
        .catch((error) => {
          newWindow.close();
        });
    }
  };

  const addLinkedFiles = async (file: IFile, linkedFiles: ILinkedFile[]) => {
    await requestFromServer.updateLinkedFiles({
      ...file,
      linkedFiles: [...(file.linkedFiles ?? []), ...linkedFiles],
    });
    dispatch(
      entityActions[file.relatedEntity.type].addLinkedFiles({ fileId: file.id, linkedFiles })
    );
    // Update local state if there is new linked files for the same entity
    // TODO rework this when refactoring File entities, this is a mix between generic files and redux entity files
    for (const linkedFile of linkedFiles) {
      if (linkedFile.relatedEntity.id === file.relatedEntity.id) {
        const { id, friendlyName, relatedEntity } = file;
        dispatch(
          entityActions[linkedFile.relatedEntity.type].addLinkedFiles({
            fileId: linkedFile.id,
            linkedFiles: [{ id, friendlyName, relatedEntity }],
          })
        );
      }
    }
  };

  const removeLinkedFile = async (file: IFile, linkedFile: ILinkedFile) => {
    await requestFromServer.updateLinkedFiles({
      ...file,
      linkedFiles: file.linkedFiles.filter((lf) => lf.id !== linkedFile.id),
    });
    dispatch(
      entityActions[file.relatedEntity.type].removeLinkedFile({
        fileId: file.id,
        linkedFileToRemove: linkedFile.id,
      })
    );
    // Update local state if there is new linked files for the same entity
    // TODO rework this when refactoring File entities, this is a mix between generic files and redux entity files
    if (linkedFile.relatedEntity.id === file.relatedEntity.id) {
      dispatch(
        entityActions[linkedFile.relatedEntity.type].removeLinkedFile({
          fileId: linkedFile.id,
          linkedFileToRemove: file.id,
        })
      );
    }
  };

  const formSubmitCallbackRef = React.useRef<Function | undefined>(undefined);

  const setFormSubmitRef = (callback: Function) => {
    formSubmitCallbackRef.current = callback;
  };

  const resetFormSubmitRef = () => {
    formSubmitCallbackRef.current = undefined;
  };

  const triggerFormSubmit = () => {
    const { current: formSubmit } = formSubmitCallbackRef;
    if (!formSubmit) return;
    formSubmit();
  };

  const value: IFilesContext = {
    ids,
    setIds,
    selectedId,
    setSelectedId,
    newFile,
    setNewFile,
    showEditFileDialog,
    setShowEditFileDialog,
    openNewFileDialog,
    openRequestSignatureDialog,
    closeRequestSignatureDialog,
    showRequestSignatureDialog,
    openEditFileDialog,
    closeEditFileDialog,
    filterList,
    openEditLinkedFile,
    openLinkedFile,
    addLinkedFiles,
    removeLinkedFile,
    isUploadingFile,
    setUploadingFile,
    setFormSubmitRef,
    resetFormSubmitRef,
    triggerFormSubmit,
    setIsSubmitDisabled,
    isSubmitDisabled,
    submitButtonLabel,
    setSubmitButtonLabel,
    headerLabel,
    setHeaderLabel,
    footerRef,
    filtersUI: [],
  };

  return <FilesContext.Provider value={value}>{children}</FilesContext.Provider>;
};
