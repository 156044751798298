import { useCallback, useState } from "react";
import { useDidMount, useDidUpdate } from "rooks";

import { TaskEditDialog } from "./task-edit-dialog/TaskEditDialog";
import { TasksUIProvider } from "./TasksUIContext";
import { TasksCard } from "./TasksCard";
import { TasksContentViewMode } from "./TasksCard/TasksCardContent";
import { SearchProvider } from "../../../../contexts/SearchContext";

export function TasksPageTab({
  triggerOpenNewTaskDialog = "",
  history,
  relatedToName,
  relatedTo,
  selectedSort,
  contentView = TasksContentViewMode.COLUMNS,
  toggleContentView = () => undefined,
}) {
  const [showModal, setShowModal] = useState(false);

  const [id, setId] = useState("");
  const [mode, setMode] = useState("");

  const [ganttShowDetails, setGanttShowDetails] = useState(false);

  //--------------------------------------------------------------------------//

  const buildUrlSearchParams = (extraParams = {}) => {
    const params = new URLSearchParams(history.location.search);
    params.set("t", "tasks");

    Object.entries(extraParams).forEach(([key, value]) => {
      params.set(key, value);
    });

    return params.toString();
  };

  const setUrlParams = (extraParams = {}) => {
    history.push({
      search: buildUrlSearchParams(extraParams),
    });
  };

  const uiStateUrlParams = useCallback(
    (shouldModalParams = false) => {
      const params = new URLSearchParams(history.location.search);

      if (contentView === TasksContentViewMode.GANTT_CHART) {
        params.set("viewMode", "gantt");

        if (ganttShowDetails) {
          params.set("showDetails", true);
        } else {
          params.has("showDetails") && params.delete("showDetails");
        }
      } else {
        params.has("viewMode") && params.delete("viewMode");
        params.has("showDetails") && params.delete("showDetails");
      }

      if (shouldModalParams) {
        params.has("mode") && params.delete("mode");
        params.has("id") && params.delete("id");
      }

      history.push({
        search: params.toString(),
      });
    },
    [contentView, ganttShowDetails]
  );

  const hideHandler = () => {
    setShowModal(false);
    setMode("");
    setId(null);
    setUrlParams();
    uiStateUrlParams(/*shouldModalParams*/ true);
  };

  //--------------------------------------------------------------------------//

  const newTaskButtonClick = () => {
    setMode("create");
    setId(null);
    setShowModal(true);
    setUrlParams({ mode: "create" });
  };

  const buildEditTaskUrl = (id) => {
    const search = buildUrlSearchParams({ mode: "edit", id });
    const { pathname = "" } = history.location;
    return `${pathname}?${search}`;
  };

  const openEditTaskPage = (id) => {
    setMode("edit");
    setId(id);
    setShowModal(true);
    setUrlParams({ mode: "edit", id });
  };

  const toggleGanttChartDetails = (showDetails) => {
    showDetails !== undefined
      ? setGanttShowDetails(showDetails)
      : setGanttShowDetails((previousValue) => !previousValue);
  };

  const tasksUIEvents = {
    newTaskButtonClick,
    buildEditTaskUrl,
    openEditTaskPage,
    toggleContentView,
    toggleGanttChartDetails,
  };

  //--------------------------------------------------------------------------//

  useDidUpdate(() => {
    const params = new URLSearchParams(history.location.search);
    const searchParamId = params.get("id");
    const searchParamMode = params.get("mode");

    if (id && searchParamId && id !== searchParamId) {
      setShowModal(false);
      window.setTimeout(() => {
        setId(searchParamId);
        setShowModal(true);
      }, 100);
    } else if (!searchParamId && searchParamMode !== "create") {
      setShowModal(false);
    }
  }, [history.location, id, mode]);

  useDidUpdate(() => {
    newTaskButtonClick();
  }, [triggerOpenNewTaskDialog]);

  useDidUpdate(() => {
    uiStateUrlParams();
  }, [uiStateUrlParams]);

  useDidMount(() => {
    const { search } = history.location;

    if (!search) {
      toggleContentView(TasksContentViewMode.COLUMNS);
      return;
    }

    const params = new URLSearchParams(search);
    const viewMode = params.has("viewMode");
    const showDetails = params.has("showDetails") && params.get("showDetails") === "true";

    toggleContentView(viewMode ? TasksContentViewMode.GANTT_CHART : TasksContentViewMode.COLUMNS);
    setGanttShowDetails(showDetails);

    if (params.has("mode")) {
      if (params.has("id") && params.get("mode") === "edit") {
        openEditTaskPage(params.get("id"));
      } else {
        newTaskButtonClick();
      }
    }
  });

  //--------------------------------------------------------------------------//

  return (
    <TasksUIProvider {...{ contentView, ganttShowDetails, tasksUIEvents }}>
      <TaskEditDialog
        {...{
          id,
          relatedToName,
          relatedTo,
          mode,
          show: showModal,
          onHide: hideHandler,
        }}
      />

      <SearchProvider>
        <TasksCard
          {...{
            displayMode: "tab",
            relatedTo,
            selectedSort,
          }}
        />
      </SearchProvider>
    </TasksUIProvider>
  );
}
