import { TInvoiceStatus } from "../../../../data/schemas";

export interface IRevenueColumns {
  label: string;
  instalmentPercentage?: number;
  expected?: number;
  sold?: number;
  invoiced?: number;
  invoicedPercentage?: number;
  paymentStatus?: TInvoiceStatus;
  supplementMargin?: number;
  supplementMarginPercentage?: number;
}

export const currencyColumns = ["expected", "sold", "invoiced", "supplementMargin"];
export type TCurrencyColumns = keyof Pick<
  IRevenueColumns,
  "expected" | "sold" | "invoiced" | "supplementMargin"
>;

export interface IInstalmentRevenue extends Partial<IRevenueColumns> {
  id: string;
  label: string;
  paymentStatusCount: Record<string, number>;
}

export interface ISupplementRevenue extends Partial<IRevenueColumns> {
  id: string;
  label: string;
  budgetInstalmentId?: string;
  paymentStatusCount?: Record<string, number>;
}
