import { get } from "lodash-es";
import { FormikErrors, FormikTouched } from "formik";
import { KycFormElementBaseLayout } from "../KycControlsBaseLayout";
import { KycInfoBox } from "../../KycInfoBox";

import "./../KycControls.scss";
import { KycFieldLock } from "../KycFieldLock";
import cn from "clsx";
import { formatDataCy } from "../../../KycUtils";
import { DataCyCompPrefix } from "../../../Kyc";

const getFieldCSSClasses = (
  touched: FormikTouched<{
    [field: string]: any;
  }>,
  errors: FormikErrors<{
    [field: string]: any;
  }>,
  values: FormikErrors<{
    [field: string]: any;
  }>
) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

type KycInputProps = {
  field: any;
  customHandleChange?: any;
  withFeedbackLabel: boolean;
  inline?: boolean;
  label?: string;
  props?: any;
  disabled?: boolean;
  form?: { touched: any; errors: any; values: any }; // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  customFeedbackLabel?: string;
  type?: string;
  className?: string;
  infoLabel?: string;
  disableLabel?: boolean;
  locked?: boolean;
  renderAsLabel?: boolean;
};

export const KycInput = ({
  field,
  // @ts-ignore
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel = "",
  inline = true,
  type = "text",
  className = "",
  disabled = false,
  infoLabel,
  disableLabel = false,
  locked = false,
  renderAsLabel = false,
  ...props
}: KycInputProps): JSX.Element => {
  const content = (
    <>
      <KycFieldLock disabled={disabled} field={field} locked={locked} renderAsLabel={renderAsLabel}>
        <input
          data-cy={formatDataCy(DataCyCompPrefix.INPUT, field?.name)}
          type={type}
          disabled={disabled}
          className={cn(
            getFieldCSSClasses(
              get(touched, field.name),
              get(errors, field.name),
              get(values, field.name)
            ),
            className,
            "form-control-reduce-space",
            renderAsLabel && " form-control-as-label"
          )}
          {...field}
          {...props}
          placeholder=""
        />
      </KycFieldLock>
    </>
  );
  return (
    <>
      <KycFormElementBaseLayout
        renderAsLabel={renderAsLabel}
        field={field}
        content={content}
        disabled={disabled}
        disableLabel={disableLabel}
        label={label}
        inline={inline}
      />
      {infoLabel && <KycInfoBox label={infoLabel} />}
    </>
  );
};
