import React, { useState } from "react";
import { initialFilter } from "./definitions";
import PropTypes from "prop-types";
import { useKycFile } from "../../../../hooks/useKycFile";

interface KycFilesUIProps {
  children: React.ReactNode;
}

export interface KycFilesUiContextValue {
  filters: any[];
  setFilters: (value: any[]) => void;
  filtersUI: any[];
}

export const KycFilesUIContext = React.createContext<KycFilesUiContextValue>({
  filters: [],
  setFilters: () => undefined,
  filtersUI: [],
});

export const KycFilesUIProvider = ({ children }: KycFilesUIProps): JSX.Element => {
  const filesContext = useKycFile();
  const [filters, setFilters] = useState<any[]>([initialFilter]);

  return (
    <KycFilesUIContext.Provider
      value={{
        ...filesContext,
        filters,
        setFilters,
        filtersUI: [...filters],
      }}
    >
      {children}
    </KycFilesUIContext.Provider>
  );
};

KycFilesUIProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const KycFilesUIConsumer = KycFilesUIContext.Consumer;
