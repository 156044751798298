/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import cn from "clsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { cloneDeep, isEqual, mergeWith } from "lodash-es";
import { useQueryState } from "react-router-use-location-state";

import * as actions from "../../../_redux/products/productsActions";
import * as projectsActions from "../../../_redux/projects/projectsActions";
import { Locker } from "../../../../../_components/Locker";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ProductFilesUIProvider } from "../product-files/ProductFilesUIContext";
import { ProductFiles } from "../product-files/ProductFiles";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useAutoSave } from "../../../../../_utils/useAutoSave";
import {
  canCreate,
  canEdit,
  canReadAll,
  canSeeAuditTrail,
  canSeeNote,
} from "../../../../../_utils/authUtils";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";
import { Sorter } from "app/_components/Sorter";
import { TASK_SORTER_OPTION } from "../../../../../_utils/listUtils";
import { LeadTab } from "../../../../Lead/components/LeadTab";
import { TabNavHeader } from "../../../../../_components/TabNavHeader";
import { Photos } from "../../../../../_components/Photos";

import { TasksPageTab } from "app/modules/PropertiesManagement/pages/tasks/TasksPageTab";
import {
  TasksContentViewMode,
  TasksSwitchContentView,
  useTasksSwitchContentView,
} from "app/modules/PropertiesManagement/pages/tasks/TasksCard/TasksCardContent/TasksSwitchContentView";

import { ProductEditForm } from "./ProductEditForm";
import { ProductEditInformationForm } from "./ProductEditInformationForm";
import { AuditTrailViewer } from "../../../../../_components/AuditTrail/AuditTrailViewer";
import { useAuditTrail } from "../../../../../hooks/useAuditTrail";
import { PRODUCT } from "../../../../../_utils/dataTypes";

const initProduct = {
  id: undefined,
  name: "",
  description: "",
  projectId: "",
  projectName: "",
  productType: "-",
  addressType: "CUSTOM",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrRegion: "",
    postalCode: "",
    countryCode: "",
  },
  propertyDetails: {
    propertyType: "-",
    acre: 0.0,
    parcel: "",
    landRegistryNumber: "",
    landArchitectNumber: "",
    numberBlockStaircaseLevelCadastreCellar: "",
    thousands: 0.0,
    rooms: 0.0,
    bedrooms: 0.0,
    bathrooms: 0.0,
    parkings: [],
    cellars: [],
    gardens: [],
    balconies: [],
    terraces: [],
    combinePlots: [],
    livingSurface: 0.0,
    floor: 0,
    energyPerformanceClass: "",
    environmentalPerformanceClass: "",
    thermalInsulationClass: "",
    heatingType: "",
    specification: "",
  },
  financialDetails: {
    price: 0.0,
    architectEngineeringFees: 0.0,
    shareOfLand: 0.0,
    charges: 0.0,
  },
  additionalInformationType: "CUSTOM",
  availability: "",
  constructionYear: "",
  photos: [],
  photosOrder: [],
};

const mergeWithInitProduct = (obj) => {
  return mergeWith(cloneDeep(initProduct), obj, (dest, src) => (src === null ? dest : undefined));
};

export function ProductEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Subheader
  const subheader = useSubheader();

  // Tabs
  const [tab, setTab] = useQueryState("t", "general");
  const [title, setTitle] = useState("");

  const { contentView, toggleContentView } = useTasksSwitchContentView();
  const [triggerOpenNewTaskDialog, setTriggerOpenNewTaskDialog] = useState("");

  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const {
    actionsLoading,
    productForEdit,
    originalProductForEdit,
    session,
    groups,
    projects,
    project,
    isLoadingProject,
  } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading || state.products.listLoading,
      originalProductForEdit: state?.products?.productForEdit?.saved
        ? state.products.productForEdit.saved
        : state?.products?.entities?.find((entity) => entity.id === id),
      productForEdit: state?.products?.productForEdit?.current
        ? state.products.productForEdit.current
        : state?.products?.entities?.find((entity) => entity.id === id),
      session: state.auth.session,
      groups: state.auth.groups,
      projects: state.projects?.entities,
      project: state.projects?.projectForEdit.current,
      isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
    }),
    shallowEqual
  );

  useAutoSave(productForEdit, originalProductForEdit, actions.updateProduct);

  const readOnly = id
    ? !canEdit(groups, session, "PRODUCT")
    : !canCreate(groups, session, "PRODUCT");

  const { setFromUrlTo, goBack } = useCustomLocationState();

  useEffect(() => {
    console.log("fetchProduct...");
    dispatch(actions.fetchProduct(id));
  }, [id]);

  useEffect(() => {
    if (projects.length === 0) {
      dispatch(projectsActions.fetchProjects());
    }
  }, []);

  useEffect(() => {
    if (!readOnly) {
      dispatch(projectsActions.fetchProjects());
    }
  }, [readOnly]);

  const { fetchAuditTrail, setCurrentEntityObject } = useAuditTrail();

  React.useEffect(() => {
    if (tab === "history") {
      fetchAuditTrail(id, PRODUCT);
    }
  }, [tab]);

  React.useEffect(() => {
    if (productForEdit) {
      setCurrentEntityObject(productForEdit);
    }
  }, [productForEdit]);

  useEffect(() => {
    let _title = "";
    if (!id || originalProductForEdit?.id === id) {
      _title = !id ? intl.formatMessage({ id: "PRODUCT.TITLE.NEW" }) : originalProductForEdit.name;
      subheader.setTitle(_title);
    }
    setTitle(_title);
  }, [originalProductForEdit, id]);

  const [selectedSort, setSelectedSort] = useState();

  const saveProductFields = (key, value) => {
    if (Array.isArray(key) && key.length === value.length && value) {
      for (const k of Object.keys(key)) {
        dispatch(actions.updateProductFieldLocally(key[k], value[k]));
      }
    } else {
      dispatch(actions.updateProductFieldLocally(key, value));
    }
  };

  const submitProduct = () => {
    if (!id) {
      const notes = `<h2>${productForEdit?.name} - Notes</h2><p>&nbsp;</p><p>&nbsp;</p>`;
      dispatch(actions.createProduct(mergeWithInitProduct({ ...productForEdit, notes }))).then(
        (product) => {
          if (!!product?.id) {
            history.push(`/products/${product?.id}${history.location.search}`);
          }
        }
      );
    }
  };

  const productEditFormCallbackRef = useRef(null);

  const backButtonClick = () => {
    goBack("/products");
  };

  const openTaskDetails = () => {
    setTriggerOpenNewTaskDialog(new Date().toISOString());
  };

  return (
    <Card style={{ height: tab === "tasks" ? "calc(100% - 1rem)" : "auto" }}>
      {actionsLoading && <ModalProgressBar />}

      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backButtonClick}
            data-cy="button-back"
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left" />
            {!id && <FormattedMessage id="COMMON.ACTION.CANCEL" />}
          </button>

          {!readOnly && !id && (
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={productEditFormCallbackRef?.current}
              data-cy="button-product-create"
              disabled={actionsLoading}
            >
              <FormattedMessage id="COMMON.ACTION.CREATE" />
            </button>
          )}

          {tab === "tasks" && canCreate(groups, session, "TASK") && (
            <div className={"ml-4 d-flex"}>
              <Sorter
                {...{
                  selectedSort,
                  setSelectedSort,
                  option: TASK_SORTER_OPTION,
                  disabled: contentView === TasksContentViewMode.GANTT_CHART,
                }}
              />

              <TasksSwitchContentView
                {...{
                  className: "ml-4",
                  mode: contentView,
                  onChange: toggleContentView,
                }}
              />

              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() => openTaskDetails()}
              >
                <FormattedMessage id="TASK.ACTION.NEW" />
              </button>
            </div>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody className={cn(tab === "tasks" && "d-flex flex-column")}>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("general")}>
            <a
              className={`nav-link ${tab === "general" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "general").toString()}
            >
              <FormattedMessage id="COMMON.TAB.TITLE.GENERAL" />
            </a>
          </li>
          {id && (
            <>
              <li className="nav-item" onClick={() => setTab("property")}>
                <a
                  className={`nav-link ${tab === "property" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "property").toString()}
                >
                  <FormattedMessage id="COMMON.TAB.TITLE.PRODUCT" />
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("files")}>
                <a
                  className={`nav-link ${tab === "files" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "files").toString()}
                >
                  <FormattedMessage id="COMMON.TAB.TITLE.FILE" />
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("photos")}>
                <a
                  className={`nav-link ${tab === "photos" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "photos").toString()}
                >
                  <FormattedMessage id="COMMON.TAB.TITLE.PHOTO" />
                </a>
              </li>
              {canSeeNote(groups, session, "PRODUCT") && (
                <li className="nav-item" onClick={() => setTab("notes")}>
                  <a
                    className={`nav-link ${tab === "notes" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "notes").toString()}
                  >
                    <Locker />
                    <FormattedMessage id="COMMON.TAB.TITLE.NOTE" />
                    {!isEqual(productForEdit?.notes || "", originalProductForEdit?.notes || "") &&
                      "*"}
                  </a>
                </li>
              )}
              {canReadAll(groups, session, "LEAD") && (
                <TabNavHeader
                  name={"leads"}
                  tab={tab}
                  setTab={setTab}
                  translationId={"COMMON.TAB.TITLE.LEAD"}
                  showLocker={true}
                />
              )}
              <li className="nav-item" onClick={() => setTab("tasks")}>
                <a
                  className={`nav-link ${tab === "tasks" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  data-cy="tab-actions"
                  aria-selected={(tab === "tasks").toString()}
                >
                  <FormattedMessage id="COMMON.TAB.TITLE.TASK" />
                </a>
              </li>
              {canSeeAuditTrail(groups, session, "PRODUCT") && (
                <li className="nav-item" onClick={() => setTab("history")}>
                  <a
                    className={`nav-link ${tab === "history" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "history").toString()}
                  >
                    <Locker />
                    <FormattedMessage id="COMMON.TAB.TITLE.HISTORY" />
                  </a>
                </li>
              )}
            </>
          )}
        </ul>

        <div className={cn("mt-5", tab === "tasks" && "flex-grow-1")}>
          {tab === "general" && (
            <ProductEditForm
              actionsLoading={actionsLoading}
              product={mergeWithInitProduct(productForEdit)}
              saveProductFields={saveProductFields}
              disabled={readOnly}
              submitProduct={submitProduct}
              setFromUrlTo={setFromUrlTo}
              projects={projects}
              onSubmitRef={(callback) => {
                productEditFormCallbackRef.current = callback;
              }}
            />
          )}

          {tab === "photos" && id && !!productForEdit && (
            <>
              {!!project && productForEdit?.projectId && (
                <Photos
                  item={project}
                  actions={projectsActions}
                  parentType={"project"}
                  displayLabel={true}
                  isLoading={isLoadingProject}
                />
              )}
              {!!productForEdit && (
                <Photos
                  item={productForEdit}
                  actions={actions}
                  parentType={"product"}
                  displayLabel={true}
                  isLoading={actionsLoading}
                />
              )}
            </>
          )}

          {tab === "property" && id && (
            <ProductEditInformationForm
              submitProduct={submitProduct}
              saveProductFields={saveProductFields}
              product={mergeWithInitProduct(productForEdit)}
              disabled={readOnly}
              isLoading={actionsLoading}
            />
          )}

          {tab === "files" && id && (
            <ProductFilesUIProvider currentProductId={id} readOnly={readOnly}>
              <ProductFiles />
            </ProductFilesUIProvider>
          )}

          {tab === "notes" && id && !!productForEdit && canSeeNote(groups, session, "PRODUCT") && (
            <>
              <TextEditor
                name={"notes"}
                data={productForEdit?.notes}
                saveMethod={saveProductFields}
                disabled={readOnly}
              />
            </>
          )}

          {tab === "tasks" && productForEdit && id && (
            <TasksPageTab
              {...{
                triggerOpenNewTaskDialog,
                contentView,
                toggleContentView,
                history,
                selectedSort,
                relatedToName: productForEdit.name,
                relatedTo: `PRODUCT#${productForEdit.id}`,
              }}
            />
          )}

          {tab === "history" && id && canSeeAuditTrail(groups, session, "PRODUCT") && (
            <AuditTrailViewer />
          )}

          {tab === "leads" && id && canReadAll(groups, session, "LEAD") && (
            <LeadTab
              context={"productLeads"}
              name={productForEdit?.name}
              queryParam={{ productId: id }}
              readOnly={readOnly}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
}
