import { ChangeEvent } from "react";
import { Field } from "formik";
import { KycInput } from "../controls/kyc-input/KycInput";
import { KycTranslation } from "../../KycTranslation";
import { KycRadioGroup } from "../controls/kyc-radio-group/KycRadioGroup";
import { KycUploadComponent } from "../controls/kyc-upload/KycUploadComponent";
import { KycAddressForm } from "../KycAddressForm";
import { KycDropdownSelect } from "../controls/kyc-dropdown-select/KycDropdownSelect";
import {
  getNationalityOptions,
  KYC_V2_OPTIONS_COUNTRIES,
  KYC_V2_OPTIONS_PERSON_TITLE,
} from "../../KycTranslationUtils";
import { KycFormSection } from "../layout/KycFormSection";
import { KycDatePicker } from "../controls/kyc-date-picker/KycDatePicker";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { useTranslation } from "../../../../hooks/useTranslation";
import { DateUtils } from "../../../../_utils/DateUtils";
import { useIntl } from "react-intl";
import { KycEntityType } from "../../Kyc";

export const KycStepClientPage2Natural = (): JSX.Element => {
  const { values, customHandleChange } = useKycFlow();
  const { fm } = useTranslation();
  const intl = useIntl();

  const getKycRelatedEntity = () => {
    return {
      id: values?.form?.client?.id,
      type: KycEntityType.CLIENT_NATURAL,
    };
  };

  return (
    <>
      <Field
        component={KycDropdownSelect}
        name="form.client.title"
        options={KYC_V2_OPTIONS_PERSON_TITLE}
        translateLabels={true}
        placeholder={fm("TITLES.MR")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label="USER.INFO.TITLE"
      />
      <Field
        name="form.client.firstName"
        component={KycInput}
        label={KycTranslation.FIRST_NAME}
        placeholder="John"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name="form.client.lastName"
        component={KycInput}
        label={KycTranslation.FAMILY_NAME}
        placeholder="Smith"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />

      <KycFormSection label={KycTranslation.PRIVATE_ADDRESS}>
        <KycAddressForm label={KycTranslation.ADDRESS_PERSONAL} name="form.client.privateAddress" />
        <KycUploadComponent
          name="form.client.evidencePrivateAddress"
          kycRelatedToEntity={getKycRelatedEntity()}
          infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_UTILITY_BILL}
        />
      </KycFormSection>

      <Field
        className="form-control react-datepicker-ignore-onclickoutside no-validation"
        name="form.client.dateOfBirth"
        component={KycDatePicker}
        label={KycTranslation.DATE_OF_BIRTH}
        selected={DateUtils.subtractYears(new Date(), 18)}
        minDate={DateUtils.subtractYears(new Date(), 100)}
        maxDate={new Date()}
        withFeedbackLabel={false}
        onChange={(val: any) =>
          customHandleChange({
            target: { name: "form.client.dateOfBirth", value: val?.toISOString() ?? null },
          })
        }
        showTimeSelect={false}
        dateFormat="P"
        popperPlacement="bottom-start"
        autoComplete="off"
      />
      <Field
        name="form.client.placeOfBirth"
        component={KycInput}
        label={KycTranslation.PLACE_OF_BIRTH}
        placeholder="Dudelange"
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name="form.client.countryOfBirth"
        component={KycDropdownSelect}
        placeholder="Luxembourg"
        options={KYC_V2_OPTIONS_COUNTRIES}
        translateLabels={true}
        translateRegion={true}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.COUNTRY_OF_BIRTH}
      />
      <Field
        component={KycDropdownSelect}
        name="form.client.nationality"
        options={getNationalityOptions(intl)}
        translateLabels={false}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        label={KycTranslation.NATIONALITY}
      />
      <Field
        name="form.client.passportOrIdNumber"
        component={KycInput}
        label={KycTranslation.PASSPORT_ID_NUMBER}
        placeholder="VMQ17WQEY"
        // infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycUploadComponent
        name="form.client.evidencePassportId"
        kycRelatedToEntity={getKycRelatedEntity()}
        infoLabel={KycTranslation.EVIDENCE_DOCUMENT_NAME_PASSPORT_ID_COPY}
      />
      <Field
        className="form-control react-datepicker-ignore-onclickoutside no-validation"
        name="form.client.idValidityDate"
        component={KycDatePicker}
        label={KycTranslation.ID_VALIDITY_DATE}
        withFeedbackLabel={false}
        onChange={(val: any) =>
          customHandleChange({
            target: { name: "form.client.idValidityDate", value: val?.toISOString() ?? null },
          })
        }
        showTimeSelect={false}
        dateFormat="P"
        popperPlacement="bottom-start"
        autoComplete="off"
      />
      <Field
        name="form.client.registrationNumber"
        component={KycInput}
        label={KycTranslation.REGISTRATION_NUMBER}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <Field
        name="form.client.profession"
        component={KycInput}
        placeholder="Software Engineer"
        label={KycTranslation.PROFESSION}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      <KycFormSection label={KycTranslation.PROFESSIONAL_ADDRESS}>
        <KycAddressForm name="form.client.professionalAddress" />
      </KycFormSection>
      <Field
        component={KycRadioGroup}
        name="form.client.isMarried"
        label={KycTranslation.IS_MARRIED}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />

      {values?.form?.client?.isMarried === "true" && (
        <>
          <Field
            name="form.client.isMarriedMatrimonialRegime"
            component={KycInput}
            label={KycTranslation.IS_MARRIED_YES_MATRIMONIAL_REGIME}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />
          <Field
            component={KycRadioGroup}
            name="form.client.isMarriedAcquisitionMartialHome"
            label={KycTranslation.IS_MARRIED_YES_ACQUISITION_MARTIAL_HOME}
            onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
          />
        </>
      )}

      <Field
        component={KycRadioGroup}
        name="form.client.isPep"
        label={KycTranslation.IS_PEP}
        onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
      />
      {values?.form?.client?.isPep === "true" && (
        <Field
          name="form.client.publicMandate"
          component={KycInput}
          placeholder=""
          label={KycTranslation.PUBLIC_MANDATE}
          onChange={(e: ChangeEvent<HTMLInputElement>) => customHandleChange(e)}
        />
      )}
    </>
  );
};
