import { ColumnFormatter } from "react-bootstrap-table-next";
import cn from "clsx";
import * as React from "react";
import { getStatusProperties, KycStatusMode } from "../../../KycTranslationUtils";

interface FormatExtraDataProps {
  fm: Function;
}

export const KycStatusFormatter: ColumnFormatter<any, FormatExtraDataProps, string> = (
  status,
  row,
  rowIndex,
  formatExtraData
) => {
  const statusProperties =
    formatExtraData?.fm && getStatusProperties(status, KycStatusMode.KYC_FLOW, formatExtraData?.fm);
  return (
    <span className={cn("label label-lg label-inline", statusProperties?.color)}>
      {statusProperties?.label}
    </span>
  );
};
