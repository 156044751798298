import { useTranslation } from "../../../../hooks/useTranslation";
import SVG from "react-inlinesvg";
import { useKycFlow } from "../../../../hooks/useKycFlow";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import cn from "clsx";
import { KycChoiceOption } from "../../Kyc";
import { KycTranslation } from "../../KycTranslation";
import { ReactNode } from "react";
import { Suffix } from "../../../../_utils/suffixUtils";
import "./KycFieldLock.scss";

export type KycFieldLockProps = {
  field: any;
  disabled?: boolean;
  type?: string;
  suffix?: Suffix | string;
  translations?: KycChoiceOption[];
  placeholder?: string;
  children?: ReactNode;
  locked?: boolean;
  renderAsLabel?: boolean;
};
export const KycFieldLock = ({
  placeholder,
  field,
  disabled = false,
  children,
  suffix,
  type = "text",
  translations,
  locked = false,
  renderAsLabel = false,
}: KycFieldLockProps): JSX.Element => {
  const { fm, fDate, fNumber, fPhoneNumber } = useTranslation();
  const { isFieldLocked } = useKycFlow();

  const getLabelText = () => {
    if (!field?.value) {
      return disabled || isFieldLocked(field) ? fm(KycTranslation.NOT_SPECIFIED) : "";
    }
    if (field === 0) return `${field.value}${suffix ?? ""}`;

    if (field.value?.[0] === "+") {
      return fPhoneNumber(field?.value);
    }

    if (type === "fileUpload") {
      return field?.value?.name;
    }

    if (type === "date") {
      return fDate(new Date(field?.value), false);
    }

    if (type === "number") {
      return fNumber(field.value, suffix);
    }

    if (translations) {
      const messageId = translations.find(
        (translation) => translation.value === field.value
      )?.label;

      if (messageId) {
        return `${fm(messageId)}${suffix ?? ""}`;
      } else {
        return `${field.value}${suffix ?? ""}`;
      }
    }

    return `${field.value}${suffix ?? ""}`;
  };

  return (
    <div
      className={cn(
        "kyc-field-lock",
        "position-relative",
        (disabled || isFieldLocked(field) || locked) && "not-allowed"
      )}
    >
      {disabled || isFieldLocked(field) || locked ? (
        <>
          <div
            className={cn(
              renderAsLabel ? "form-control-as-label" : "form-control form-control-locked",
              "d-flex",
              type === "number" && "text-right pr-15"
            )}
          >
            {getLabelText() ? (
              <div className={cn(disabled && !field?.value && "text-muted")}>{getLabelText()}</div>
            ) : (
              <div style={{ color: "red !important" }}>{placeholder}</div>
            )}
          </div>
          {!renderAsLabel && (
            <>
              {(isFieldLocked(field) || locked) && (
                <div className="svg-icon svg-icon-lg svg-icon-light form-control-icon-right">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")} />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
