import axios from "axios";
import { KycFile, KycFlow } from "./Kyc";
import { filter, groupBy, isArray, isEmpty } from "lodash-es";
import { getLangShort } from "./KycUtils";
import { IntlShape } from "react-intl";

export const downloadFile = (fileUrl: string, fileName: string, setProgress?: Function) => {
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      if (setProgress) {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)); // you can use this to show user percentage of file downloaded
      }
    },
  }).then((response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
};

export const handleDownload = (fileUrl: string, fileName: string) => {
  const downloadUrl = fileUrl;
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getFileForId = (kycFlow: KycFlow, fileId: string) => {
  const indexOfFileUrl = kycFlow?.files?.findIndex((file: KycFile) => file?.id === fileId);
  return kycFlow?.files?.[indexOfFileUrl];
};

export const getFileInFilesByBucketFileId = (files: any[], fileId: string) => {
  const indexOfFileUrl = files?.findIndex((file: KycFile) => file?.id === fileId);
  return files?.[indexOfFileUrl];
};

export const formatDisplayLang = (langCode: string): string => {
  if (!langCode) {
    return "";
  }
  return langCode?.split("-")?.[0]?.toUpperCase();
};

export const formatFriendlyNameWithLang = (friendlyName: string, locale: string): string => {
  const langFormatted = getLangShort(locale, true);
  return langFormatted ? friendlyName + " (" + langFormatted + ")" : friendlyName;
};

export const formatFileFriendlyNameWithLang = (file: KycFile): string => {
  if (!file?.lang) {
    return file?.friendlyName;
  }
  return formatFriendlyNameWithLang(file?.friendlyName, file?.lang);
};

export const handleDownloadKycFile = (e: any, file: KycFile) => {
  e.preventDefault();
  e.stopPropagation();
  if (file?.url) {
    downloadFile(file.url, file?.friendlyName);
  }
};

export const getFilesForKycFile = (f: KycFile, files?: KycFile[]) => {
  if (!files) {
    return;
  }
  const processedFiles = [];
  if (isArray(f)) {
    processedFiles.push(...getLanguageFilesOfKycFileItem(files, f));
  } else if (f?.url) {
    processedFiles.push(f);
  } else if (f?.de || f?.fr || f?.en) {
    processedFiles.push(...getLanguageFilesOfKycFileItem(files, Object.values(f)));
  }
  return processedFiles;
};

export const getLanguageFilesOfKycFileItem = (files: any[], kycFileItem: any[]) => {
  return kycFileItem?.map((file: any) => {
    if (file?.url) {
      return file;
    } else if (file?.id) {
      return getFileInFilesByBucketFileId(files, file.id);
    }
    return undefined;
  });
};

export const findAndReplaceFile = (files: KycFile[], file: KycFile) => {
  const indexOfItem = files?.findIndex((item) => item?.id === file?.id);
  const updatedItems = [...files];
  if (indexOfItem === -1) {
    console.error("file not found in array");
    updatedItems.push(file);
    return updatedItems;
  }
  updatedItems[indexOfItem] = file;
  return updatedItems;
};

export const findAndDeleteFile = (files: any[], file: KycFile) => {
  const indexOfItem = files?.findIndex((item) => item?.id === file?.id);
  if (indexOfItem === -1) {
    console.error("file not found in array");
    return files;
  }
  const updatedItems = [...files];
  updatedItems.splice(indexOfItem, 1);
  return updatedItems;
};

const UNSUPPORTED_FILE_EXTENSIONS = ["docx"];

export const isPreviewPossible = (filesToDisplay: any) => {
  let isFileExtensionSupported = true;

  if (isArray(filesToDisplay)) {
    for (const f of filesToDisplay) {
      if (UNSUPPORTED_FILE_EXTENSIONS.includes(f?.fileExtension)) {
        isFileExtensionSupported = false;
        return;
      }
    }
    return isFileExtensionSupported;
  } else if (filesToDisplay?.fileExtension) {
    isFileExtensionSupported = !UNSUPPORTED_FILE_EXTENSIONS.includes(filesToDisplay.fileExtension);
  } else {
    console.warn("failed to find file extension, file preview deactivated");
    isFileExtensionSupported = false;
  }

  return isFileExtensionSupported;
};

export const isEvidenceOptionSet = (files: KycFile[], option: string): boolean => {
  if (!files || !isArray(files)) {
    return false;
  }
  return files.find((file) => file?.option === option) !== undefined;
};

export const isEvidenceProvided = (files: KycFile[], name: string): boolean => {
  if (!files || !isArray(files)) {
    return false;
  }
  return (
    files.find((file) => file?.categoryId === name && file?.status === "UPLOADED") !== undefined
  );
};

export const isFileUploaded = (file: KycFile): boolean => {
  return ["UPLOADED_TO_S3", "UPLOADED"].includes(file?.status ?? "");
};

export const isGeneratedFile = (file: KycFile) => {
  return file?.categoryId && ["boDeclaration", "kycDocument"].includes(file?.categoryId ?? "");
};

export const getGroupedFiles = (files: any[]): any[] => {
  if (!files || !isArray(files)) {
    return [];
  }

  //Note: the kyc package (=zip file) is not shown in the file list on purpose.
  //the package can be downloaded using 3-dots menu instead
  const filesWithoutLang = files?.filter(
    (file: KycFile) => !file?.lang && file?.id !== "kycPackage"
  );

  const multiLangFiles = groupBy(
    filter(files, (f) => f.lang),
    "categoryId"
  );

  const multiFiles = [...filesWithoutLang];

  Object.values(multiLangFiles).forEach((groupedFile: any) => {
    const sourceFile = groupedFile[0];

    const multiFile = {
      ...sourceFile,
      // files: sourceFile.id === "kycDocument" ? currentKycFlow?.kycDocument?.files :groupedFile,
      files: groupedFile,
    };
    multiFiles.push(multiFile);
  });

  return multiFiles;
};

export const orderByLang = (files: any[], intl: IntlShape) => {
  if (!files || isEmpty(files) || files.length === 1) {
    return files;
  } else {
    const fs = [...files];
    const indexFirstEntry = fs?.findIndex((f: any) => f?.lang === intl.locale);
    if (indexFirstEntry > -1) {
      const entry0 = { ...fs?.[indexFirstEntry] };
      fs.splice(indexFirstEntry, 1);
      return [entry0, ...fs];
    }
    return files;
  }
};
