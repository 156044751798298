import "./KycWizard.scss";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { KycLoading } from "./Kyc";
import { WizardStepper } from "../../_components/wizard/WizardStepper";
import { useKyc } from "../../hooks/useKyc";
import { useLoading } from "../../hooks/useLoading";
import { KycStepLoadingPage } from "./components/steps/KycStepLoadingPage";

export const KycContentContainer = (): JSX.Element => {
  let { id } = useParams<any>();

  const { getKycFlow } = useKyc();

  const { isCurrentlyLoading, loadings } = useLoading();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id && id !== "new") {
      getKycFlow(id, { createAudit: true });
    }
  }, [id]);

  useEffect(() => {
    setIsLoading(isCurrentlyLoading(KycLoading.GET_KYC_FORM));
  }, [loadings]);

  return <>{isLoading ? <KycStepLoadingPage /> : <WizardStepper />}</>;
};
