export const roles = {
 "MASTER": [
  [
   "*",
   [
    "*"
   ]
  ]
 ],
 "ADMIN": [
  [
   "PROJECT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "SHARE",
    "ANALYSIS",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "BUDGET",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "HIDDEN_LINE"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "NOTE"
   ]
  ],
  [
   "CHAT",
   [
    "CREATE"
   ]
  ],
  [
   "USER_ACTION",
   [
    "CREATE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ",
    "UPDATE"
   ]
  ],
  [
   "PROPCO",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE"
   ]
  ],
  [
   "KYC_FLOW",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "AUDIT_TRAIL"
   ]
  ]
 ],
 "COMPLIANCE_OFFICER": [
  [
   "PROJECT",
   [
    "READ",
    "AUDIT_TRAIL",
    "ANALYSIS",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "BUDGET",
   [
    "READ",
    "HIDDEN_LINE"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "AUDIT_TRAIL",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ"
   ]
  ],
  [
   "KYC_FLOW",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "AUDIT_TRAIL"
   ]
  ]
 ],
 "CONTENT_MANAGER": [
  [
   "PROJECT",
   [
    "READ",
    "UPDATE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "UPDATE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "UPDATE"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ"
   ]
  ]
 ],
 "CONTRIBUTOR": [
  [
   "PROJECT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "SHARE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "CREATE",
    "UPDATE"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "CHAT",
   [
    "CREATE"
   ]
  ],
  [
   "USER_ACTION",
   [
    "CREATE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ"
   ]
  ]
 ],
 "FINANCIAL_CONTROLLER": [
  [
   "PROJECT",
   [
    "READ",
    "UPDATE",
    "ANALYSIS"
   ]
  ],
  [
   "BUDGET",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "HIDDEN_LINE"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "NOTE"
   ]
  ],
  [
   "CHAT",
   [
    "CREATE"
   ]
  ]
 ],
 "PROJECT_MANAGER": [
  [
   "PROJECT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "SHARE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "BUDGET",
   [
    "READ"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "USER",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "AUDIT_TRAIL",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "DELETE",
    "NOTE"
   ]
  ],
  [
   "CHAT",
   [
    "CREATE"
   ]
  ],
  [
   "USER_ACTION",
   [
    "CREATE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ",
    "UPDATE"
   ]
  ]
 ],
 "PROJECT_RESELLER": [
  [
   "CLIENT",
   [
    "CREATE",
    "UPDATE"
   ]
  ],
  [
   "LEAD",
   [
    "CREATE",
    "UPDATE"
   ]
  ]
 ],
 "SALES_MANAGER": [
  [
   "PROJECT",
   [
    "READ",
    "NOTE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "UPDATE",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO"
   ]
  ],
  [
   "USER",
   [
    "READ"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "CREATE",
    "UPDATE",
    "NOTE"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "USER_ACTION",
   [
    "CREATE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ"
   ]
  ],
  [
   "KYC_FLOW",
   [
    "READ",
    "CREATE",
    "UPDATE"
   ]
  ]
 ],
 "VIEWER": [
  [
   "PROJECT",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "BUDGET",
   [
    "READ"
   ]
  ],
  [
   "PRODUCT",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "USER",
   [
    "READ"
   ]
  ],
  [
   "CLIENT",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "SUBCONTRACTOR",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "LEAD",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "TASK",
   [
    "READ",
    "NOTE"
   ]
  ],
  [
   "USER_RIGHTS",
   [
    "READ"
   ]
  ]
 ],
 "NONE": [],
 "CUSTOM": []
}