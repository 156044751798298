import * as React from "react";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { KycFile, KycStepEvent } from "../../../Kyc";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useKycFile } from "../../../../../hooks/useKycFile";
import { useKyc } from "../../../../../hooks/useKyc";

type KycUploadInputProps = {
  file?: KycFile;
  disabled?: boolean;
  signatory: any;
};

export const KycUploadInputSimple = ({
  disabled = false,
  file,
  signatory,
}: KycUploadInputProps): JSX.Element => {
  const { replaceFile } = useKycFile();
  const { currentKycFlow, createKycEventMessage } = useKyc();
  const { fm } = useTranslation();

  const hiddenFileInput: any = useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleFileUpload = (e: any) => {
    if (e?.target?.files?.[0]) {
      const sourceFileRaw = e?.target?.files?.[0];
      if (sourceFileRaw && sourceFileRaw?.size) {
        const f: any = {
          ...file,
          friendlyName: sourceFileRaw?.name,
          fileExtension: sourceFileRaw?.name?.split(".")?.pop(),
          mimeType: sourceFileRaw.type,
          name: sourceFileRaw.name,
          freeText: "",
          body: sourceFileRaw,
        };
        replaceFile(f);
        currentKycFlow &&
          createKycEventMessage(currentKycFlow?.id, KycStepEvent.ADMIN_UPLOAD_PDF_BO_SIGNED, {
            boName: signatory?.fullName,
            boEmail: signatory?.email,
          });
      }
    }
  };

  return (
    <>
      <Button
        disabled={disabled}
        size="sm"
        onClick={handleClick}
        className=" btn-primary form-control-reduce-space ml-1 text-nowrap"
      >
        {fm("FILE.ACTIONS.UPLOAD.NEW")}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept=".jpg, .jpeg, .png, .bmp, .pdf, .txt, .docx, .doc, .xls, .xlsx"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
    </>
  );
};
