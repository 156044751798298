import * as React from "react";
import { useKyc } from "../../../../hooks/useKyc";
import { useIntl } from "react-intl";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "../../../../hooks/useTranslation";
import { KycStatusFormatter } from "./column-formatters/KycStatusFormatter";
import { KycDateTimeFormatter } from "./column-formatters/KycDateTimeFormatter";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../_metronic/_helpers";
import "./KycComplianceTableStyles.scss";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "_metronic/_partials/controls";
import { KycFlow, KycLoading, KycStepEvent } from "../../Kyc";
import { useLoading } from "../../../../hooks/useLoading";
import { defaultBootstrapTableOptions, defaultPaginationOptions } from "./definitions";
import { KycComplianceDropdownMenu } from "./KycComplianceDropdownMenu";
import { useHistory } from "react-router-dom";
import { KycTranslation } from "../../KycTranslation";
import { useSession } from "../../../../hooks/useSession";
import { useAppSelector } from "../../../../../redux/hooks";
import { canDelete, canEdit, isOwner } from "../../../../_utils/authUtils";
import { KycBreadcrumbs } from "../../KycBreadcrumbs";

interface KycComplianceTableProps {
  userId: string;
}

export const KycComplianceTable = ({ userId }: KycComplianceTableProps): JSX.Element => {
  const { currentKycFlows, createKycEventMessage } = useKyc();
  const { session } = useSession();
  const { isCurrentlyLoading } = useLoading();
  const { fm } = useTranslation();
  const intl = useIntl();

  const { groups } = useAppSelector((state) => ({
    groups: state.auth.groups,
  }));

  const columns: ColumnDescription[] = [
    {
      dataField: "form.client.lead.name",
      text: fm("LEAD.TITLE.SINGLE"),
      classes: "text-left align-middle",
      sort: true,
      style: { minWidth: 250, maxWidth: 250 },
    },
    {
      dataField: "status",
      text: fm("COMMON.STATUS"),
      formatter: KycStatusFormatter,
      sort: true,
      style: { minWidth: 100, maxWidth: 100 },
      formatExtraData: {
        fm: fm,
      },
    },
    {
      dataField: "createdAt",
      text: fm("COMMON.CREATED.AT"),
      sort: true,

      formatter: KycDateTimeFormatter,
      formatExtraData: { intl, showTime: true },
      style: { minWidth: 100, maxWidth: 100 },
    },
    {
      dataField: "updatedAt",
      text: fm("COMMON.UPDATED.AT"),
      sort: true,
      sortCaret: sortCaret,
      formatter: KycDateTimeFormatter,
      formatExtraData: { intl, showTime: true },
      style: { minWidth: 100, maxWidth: 100 },
    },
  ];

  if (
    canEdit(groups, session, "KYC_FLOW") ||
    canDelete(groups, session, "KYC_FLOW") ||
    currentKycFlows.filter((k) => k?.fileOwner?.id === session.id || isOwner(session, k)).length > 0
  ) {
    columns.push({
      dataField: "id",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      classes: "text-right",
      formatter: (cell: string, row: KycFlow) => (
        <KycComplianceDropdownMenu kycId={cell} status={row.status} kycFlow={row} />
      ),
      headerStyle: { width: "auto" },
    });
  }

  const history = useHistory();

  return (
    <>
      <KycBreadcrumbs userId={userId} />
      <div style={{ marginTop: 0 }}>
        <PaginationProvider
          pagination={paginationFactory({
            ...defaultPaginationOptions,
            totalSize: currentKycFlows?.length,
          })}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={isCurrentlyLoading(KycLoading.CREATE_KYC_FLOW)}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  {...paginationTableProps}
                  {...defaultBootstrapTableOptions}
                  keyField="id"
                  data={currentKycFlows || []}
                  columns={columns}
                  sort={{
                    dataField: "updatedAt",
                    order: "desc",
                    sortCaret: sortCaret,
                  }}
                  hover
                  rowEvents={{
                    onClick: (e, kyc, rowIndex) => {
                      createKycEventMessage(kyc?.id, KycStepEvent.OPEN_KYC_FORM, {});
                      history.push(`/clients/${userId}/kyc/${kyc?.id}`);
                    },
                  }}
                  noDataIndication={() => (
                    <NoRecordsFoundMessage
                      entities={currentKycFlows}
                      messageId={KycTranslation.NO_KYC_FLOWS_AVAILABLE}
                    />
                  )}
                >
                  <PleaseWaitMessage entities={currentKycFlows} />
                  <NoRecordsFoundMessage entities={currentKycFlows} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </>
  );
};
