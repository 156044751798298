import { Drawer } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import DocViewer, { IDocument } from "@cyntler/react-doc-viewer";
import PNGRenderer from "@cyntler/react-doc-viewer/dist/plugins/png";
import { useFileViewerDrawer } from "../../../hooks/useFileViewerDrawer";
import JPGRenderer from "@cyntler/react-doc-viewer/dist/plugins/jpg";
import MSDocRenderer from "@cyntler/react-doc-viewer/dist/plugins/msdoc";
import BMPRenderer from "@cyntler/react-doc-viewer/dist/plugins/bmp";
import HTMLRenderer from "@cyntler/react-doc-viewer/dist/plugins/csv";
import TXTRenderer from "@cyntler/react-doc-viewer/dist/plugins/txt";
import { CustomPdfRenderer } from "./CustomPdfRenderer";
import { KycFileViewerDrawerHeader } from "./KycFileViewerDrawerHeader";
import { mapFilesToDoc } from "../KycMappingUtils";
import { useWebsocket } from "../../../hooks/useWebsocket";
import { isEmpty } from "lodash-es";
import { useKycFile } from "../../../hooks/useKycFile";
import { diff } from "deep-object-diff";
import { LoaderSpinner } from "../../../_components/LoaderSpinner";

/**
 * Component to visualise documents in a side drawer
 */

export const KycFileViewerDrawer = (): JSX.Element => {
  const [state, setState] = useState({
    right: false,
  });

  const { latestMessage } = useWebsocket();

  const { showDrawer, currentDrawerFiles, closeDrawer, isMaximised, setCurrentDrawerFiles } =
    useFileViewerDrawer();

  const { currentFiles } = useKycFile();

  const [mappedDrawerFiles, setMappedDrawerFiles] = useState<IDocument[]>([]);

  const toggleDrawer = (side: any, open: any) => {
    setState({ ...state, [side]: open });
  };

  useEffect(() => {
    if (currentDrawerFiles) {
      toggleDrawer("right", showDrawer);
    }
  }, [showDrawer]);

  const handleKeyDown = () => {
    return;
  };

  const MyLoadingRenderer = ({ document, fileName }: any) => {
    return (
      <LoaderSpinner
        style={{ height: "100%", width: "100%", margin: "auto" }}
        className="justify-content-center align-items-center"
      />
    );
  };

  useEffect(() => {
    //fetch latest kyc from backend, when updated from counterparty (e.g. PropDev validated/relaunched kyc form completion flow, client completed form);
    //this only happens if user is currently viewing the kyc (with matching id) which has been updated// useEffect(() => {

    if (isEmpty(currentDrawerFiles)) {
      return;
    }
    const updatedFileIds = latestMessage?.parameters?.updatedFileIds;

    const updatedFiles = currentDrawerFiles ? [...currentDrawerFiles] : [];

    if (!isEmpty(updatedFileIds)) {
      for (const updatedFileId of updatedFileIds) {
        const indexInCurrentDrawerFiles: number =
          currentDrawerFiles?.findIndex((file) => file?.id === updatedFileId) ?? -1;
        if (indexInCurrentDrawerFiles > -1) {
          updatedFiles[indexInCurrentDrawerFiles] = currentFiles?.find(
            (file) => file?.id === updatedFileId
          );
        }
      }

      setCurrentDrawerFiles(updatedFiles);
    }
  }, [latestMessage]);

  useEffect(() => {
    const mappedFiles: IDocument[] = mapFilesToDoc(currentDrawerFiles) ?? [];

    if (!isEmpty(diff(mappedFiles, mappedDrawerFiles))) setMappedDrawerFiles(mappedFiles);
  }, [currentDrawerFiles]);

  return (
    <Drawer
      anchor="right"
      open={state.right}
      onClose={() => closeDrawer()}
      onKeyDown={handleKeyDown}
      onKeyPress={handleKeyDown}
      onKeyUp={handleKeyDown}
    >
      <div
        role="presentation"
        className="d-flex flex-column"
        key={"drawer-" + isMaximised}
        style={{ width: isMaximised ? "99vw" : "60vw", height: "100vh" }}
      >
        <DocViewer
          config={{
            header: {
              overrideComponent: KycFileViewerDrawerHeader,
            },
            loadingRenderer: {
              overrideComponent: MyLoadingRenderer,
            },
          }}
          documents={mappedDrawerFiles}
          pluginRenderers={[
            CustomPdfRenderer,
            PNGRenderer,
            JPGRenderer,
            BMPRenderer,
            HTMLRenderer,
            MSDocRenderer,
            TXTRenderer,
          ]}
        />
      </div>
    </Drawer>
  );
};
