import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual } from "react-redux";
import { isEqual } from "lodash-es";

import { DefaultUserRoleType, IUser, IUserRight, UserRightType } from "data/schemas";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { isAdmin } from "app/_utils/authUtils";
import { useAutoSave } from "app/_utils/useAutoSave";

import {
  updateUserFieldLocally,
  updateUserRights,
} from "app/modules/UsersManagement/_redux/usersActions";

import { ALL_RIGHTS, getTranslatedLists, MASTER_ROLE_RIGHTS, RIGHTS_ATTR } from "./definitions";

import UserPreSelectedRoleSelectionInner from "./UserPreSelectedRoleSelection";
import { TUpdateRightFn, UserRightsSelection } from "./UserRightsSelection";
import { isKycAvailable } from "../../../../../../_utils/configUtils";

//----------------------------------------------------------------------------//

const findUserRight =
  ({ entityType, rightType }: IUserRight) =>
  ({ entityType: userEntityType, rightType: userRightType }: IUserRight) =>
    userEntityType === entityType && userRightType === rightType;

export interface UserRightsProps {
  userId: string;
  readOnly?: boolean;
}

export const UserRights: React.FunctionComponent<UserRightsProps> = ({
  userId,
  readOnly = false,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { entityForEdit, originalEntityForEdit, userRights, session, groups } = useAppSelector(
    (state) => {
      const {
        users: { entityForEdit, entities },
        auth: { session, groups },
      } = state;

      const entity = (entities as IUser[]).find((entity) => entity.id === userId);

      const { saved = entity, current = entity } = entityForEdit;

      return {
        originalEntityForEdit: saved as Partial<IUser>,
        entityForEdit: current as Partial<IUser>,
        userRights: current?.rights || [],
        session,
        groups,
      };
    },
    shallowEqual
  );

  const { rightsList, preDefinedRoles } = React.useMemo(
    () => getTranslatedLists(intl),
    [intl, intl.locale]
  );

  const isMaster = React.useMemo(() => !!isAdmin(groups, session), [groups, session]);

  const ignoredProps = React.useMemo(
    () => Object.keys(entityForEdit).filter((prop) => prop !== RIGHTS_ATTR),
    [entityForEdit]
  );

  useAutoSave(entityForEdit, originalEntityForEdit, updateUserRights, ignoredProps);

  //--------------------------------------------------------------------------//
  // @begin: pre defined role logic

  const [preDefinedRoleSelected, setPreDefinedRoleSelected] = React.useState("");

  const updateSelectedRole = React.useCallback(
    (value: string) => {
      setPreDefinedRoleSelected(value);
      if (!value) return;

      updateUserFieldLocally(
        RIGHTS_ATTR,
        preDefinedRoles.find((preDefinedRole) => preDefinedRole.value === value)?.rights
      )(dispatch);
    },
    [preDefinedRoles]
  );

  // @end: pre defined role logic
  //--------------------------------------------------------------------------//
  // @begin: rights selection logic

  const updateRight: TUpdateRightFn = React.useCallback(
    ({ entityType, rightType }) => {
      let newUserRights: IUserRight[];
      if (isEqual(userRights, MASTER_ROLE_RIGHTS)) {
        newUserRights = [...ALL_RIGHTS];
      } else {
        newUserRights = [...userRights];
      }

      if (rightType === UserRightType.DELETE && !isMaster) return;

      const rightIndex = newUserRights.findIndex(findUserRight({ entityType, rightType }));

      // update the newUserRights array
      if (rightIndex < 0) {
        const userRight: IUserRight = { entityType, rightType: UserRightType.READ };
        if (
          [`${UserRightType.UPDATE}`, `${UserRightType.DELETE}`].includes(rightType) &&
          newUserRights.findIndex(findUserRight(userRight)) < 0
        ) {
          newUserRights.push(userRight);
        }

        newUserRights.push({ entityType, rightType });
      } else {
        newUserRights.splice(rightIndex, 1);

        if (rightType === UserRightType.READ) {
          const rightUpdateIndex = newUserRights.findIndex(
            findUserRight({ entityType, rightType: UserRightType.UPDATE })
          );
          if (rightUpdateIndex >= 0) newUserRights.splice(rightUpdateIndex, 1);
        }
      }

      if (newUserRights.length === ALL_RIGHTS.length) {
        newUserRights = [...MASTER_ROLE_RIGHTS];
        setPreDefinedRoleSelected(DefaultUserRoleType.MASTER);
      }

      updateUserFieldLocally(RIGHTS_ATTR, newUserRights)(dispatch);
    },
    [userRights]
  );

  React.useEffect(() => {
    const userRightsLength = userRights.length;

    if (userRightsLength === 0) {
      setPreDefinedRoleSelected(DefaultUserRoleType.NONE);
      return;
    }

    const preDefinedRole = preDefinedRoles.find(({ rights: definedRoleRights }) => {
      const definedRoleRightsLength = definedRoleRights.length;

      return (
        definedRoleRightsLength === userRightsLength &&
        definedRoleRights.filter((r) =>
          userRights.find(findUserRight({ entityType: r.entityType, rightType: r.rightType }))
        ).length === definedRoleRightsLength
      );
    });

    setPreDefinedRoleSelected(preDefinedRole?.value || "");
  }, [preDefinedRoles, userRights]);

  // @end: rights selection logic
  //--------------------------------------------------------------------------//

  return (
    <div className="border-top-lg my-2">
      <h3 className="my-5">
        <FormattedMessage id="RIGHT.TITLE" />
      </h3>

      <UserPreSelectedRoleSelectionInner
        {...{ preDefinedRoles, preDefinedRoleSelected, updateSelectedRole, isMaster, readOnly }}
      />

      {/* @begin: rights selection */}
      <div className="form-group">
        <div className="row my-2 d-flex">
          {/* Do not show KYC section in user rights if module is not activated*/}
          {rightsList
            .filter((rightsItem) =>
              isKycAvailable() ? rightsItem : rightsItem?.entityType !== "KYC_FLOW"
            )
            .map(({ label, entityType, disabledFields }) => (
              <UserRightsSelection
                {...{
                  key: label,
                  label,
                  entityType,
                  disabledFields,
                  updateRight,
                  readOnly,
                  isMaster,
                  userRights,
                }}
              />
            ))}
        </div>
      </div>
      {/* @end: rights selection */}
    </div>
  );
};

export default UserRights;
